import React, { useEffect, useState } from 'react'
import { useAuth } from "../hooks/auth";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate,Link  } from 'react-router-dom';
import { isExpired, decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
// import {initApp} from '../onesignal';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import helper from '../helper/index';

const Home = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [ user, setUser ] = useState({});
    // const [ profile, setProfile ] = useState([]);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('password');

    const [errorEmail, setEmailMessage] = useState("");
    const [errorPassword, setPasswordMessage] = useState("");

    // eslint-disable-next-line no-unused-vars
    let [loading, setLoading] = useState(false)

    /**
     * Need to check if any user login, if login then redirect to it's dashboard page according to the role
     * */ 

    const checkIfUserLoggedIn = async () => {
        const token = Cookies.get('token');
        // get user data from token in cookie
        if(token !== "" && typeof token !== "undefined"){
            const isMyTokenExpired = isExpired(token);
            const decoded = decodeToken(token);
            if(decoded.userlevel === 2){
                navigate('/host/dashboard');
            }else if(decoded.userlevel === 3){
                navigate('/cleaner/dashboard');
            } 
            if(!isMyTokenExpired){
            }
        }
        return null;
    };

    useEffect(() => {
        checkIfUserLoggedIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const glogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            setLoading(true)
            axios.post(process.env.REACT_APP_BACKENDURL + 'api/auth/validate-google-token', 
                {
                    token: user.access_token
                },
                {
                    headers: {'Content-Type': 'application/json'}
                }
            ).then((Gres) => {
                if(Gres.data.status === 1){
                    // valid token
                    if(Gres.data.data.is_register === true){
                        // already connect with housekeeper, now login
                        googleLoginSubmission(Gres.data.data.email,user.access_token);
                    }else{
                        // autofill data with register
                        navigate(`/register?first_name=${Gres.data.data.given_name}&last_name=${Gres.data.data.family_name}&email=${Gres.data.data.email}`);
                    }
                }else{
                    // Invalid token
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            });
        }
    }, [user]);

    const loderWrap  = {
        position: "absolute",
        width: "100%",
        background: "rgba(237, 232, 232, 0)",
        height: "100%",
        zIndex: "99999999"
    };

    const override = {
        display: "block",
        position: "fixed",
        top: "47%",
        left: "47%",
        zIndex: "99999",
    };

    const googleLoginSubmission = async (email, token) => {
        let pwd = '';
        let socialLogin = true;
        let res = await login({ email, pwd, socialLogin, token});
        setLoading(false)
        if(res.status === 1 && res.token !== ""){
            navigate('/verify/otp');
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setPasswordMessage('');
        setEmailMessage('');

        let error = 0;
        if(email.trim().length === 0) {
            setEmailMessage('Email field is required.');
            error = 1;
        }else{
            // check email address in valid
            let vEmail = String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            
            if(vEmail === null){
                setEmailMessage('Email Address is invalid.');
                error = 1;
            }
        }

        if(password.trim().length === 0) {
            setPasswordMessage('Password field is required.');
            error = 1;
        }

        if(error === 1){
            setLoading(false);
            return;
        }
        
        let res = await login({ email, password });
        setLoading(false);
        if(res.status === 1 && res.token !== ""){
            navigate('/verify/otp');
        }
    };

    const handleToggle = () => {
        if (type==='password'){
            setType('text')
        } else {
            setType('password')
        }
    }

    return (    
        <>
            {loading &&  <div style={loderWrap}>
                <ClipLoader
                    color="#8F368D"
                    cssOverride={override}
                    loading
                    size={45}
                    speedMultiplier={1}
                    />
                </div>
            }
            
            <div id="login-bg"  className="auth-page-wrapper auth-bg-cover py-3 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card overflow-hidden">
                                    <div className="row g-0">
                                        <div className="col-lg-6">
                                            <div className="p-lg-5 p-4 left-icon h-100">
                                                <div className="bg-overlay"></div>
                                                <div className="position-relative h-100 d-flex justify-content-center align-items-center flex-column">
                                                    <div className="mx-auto">
                                                        <img src="assets/images/logo-hk.png" alt="" />
                                                    </div>
                                                    <div className="mt-2">
                                                        <p>simplified cleaning</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="px-lg-5  p-4">
                                                <div className="contant-area">
                                                    <h1>Sign in</h1>
                                                    <p className="form-sub-title">Enter your email and password to login.</p>
                                                </div>

                                                <div className="mt-4">
                                                    <form onSubmit={handleSubmit} >
                                                        <div className="mb-3">
                                                            <label htmlFor="username" className="form-label">Email</label>
                                                            <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            id="email" 
                                                            placeholder="Enter email"
                                                            onKeyDown={(e) => helper.checkSpaceInput(e)}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            onInput={(e) => e.target.value = ("" + e.target.value).toLowerCase()}
                                                            />
                                                            {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="password-input">Password</label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <input 
                                                                    type={type} 
                                                                    className="form-control pe-5 password-input" 
                                                                    placeholder="Enter password" 
                                                                    id="password-input" 
                                                                    onChange={(e) => setPassword(e.target.value)} />
                                                                    {errorPassword && <div className="text-danger"> {errorPassword} </div>}

                                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={handleToggle} ><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>

                                                        <Link to="/forgot-password" className="fw-semibold primary-text" > Recover Account</Link>
                                                        <div className="mt-4">
                                                            <button className="btn primary-btn btn-shadow w-100" type="submit">Sign In</button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <div className="signin-other-title">
                                                                <h5 className="fs-13 mb-4 title">OR</h5>
                                                            </div>

                                                            <div className="icon-group d-flex gap-2 justify-content-center">
                                                                <button type="button" className="btn btn-danger btn-label"  onClick={() => glogin()} ><i className="ri-google-fill label-icon align-middle fs-16 me-2"></i> Login With Google</button>
                                                                
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>

                                                <div className="mt-5 text-center singup-text">
                                                    <p className="mb-0">Don't have an account?&nbsp; 
                                                        <Link to="/register" className="fw-semibold primary-text" > Register Here</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                
                                </div> 
                            
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;
