import React,{useContext} from 'react'
import { Link } from 'react-router-dom';
import { HostContext } from '../../Contexts/HostContext';

const Settings = () => {
    const {decoded} = useContext(HostContext);

  return (
    <div className="page-content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Settings</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/host/dashboard">Overview</Link></li>
                                <li className="breadcrumb-item active">Settings</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-start flex-column ">
                                <h4 className="card-title mb-0 flex-grow-1">Account</h4>
                                <p className="ass-number"><span>{decoded.name}</span></p>
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="row setting-list mt-4">
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to="/host/profile" className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="bx bx-id-card"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Personal Info</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                {/* <div className="col-xxl-4 col-md-4 p-2">
                                    <div className="card p-lg-3 assigment-card mb-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="image-box d-flex align-items-center gap-3">
                                                <div className="autr-img">
                                                    <i className="bx bx-shield-quarter"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="name pt-1 mb-1">Login & Security</h4>
                                        <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                    </div>

                                </div> */}
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to="/host/payments-and-transactions" className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="b bx bx-credit-card "></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Payments & Payouts</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                {/* <div className="col-xxl-4 col-md-4 p-2">
                                    <div className="card p-lg-3 assigment-card mb-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="image-box d-flex align-items-center gap-3">
                                                <div className="autr-img">
                                                    <i className=" bx bx-file-blank"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="name pt-1 mb-1">Taxes</h4>
                                        <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                    </div>

                                </div> */}
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to="/host/notification" className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="bx bx-volume-full"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Notifications setting</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2 ">
                                    <Link to="/host/privacy-policy" className='nav-link menu-link'>
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className=" bx bx-low-vision"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Privacy & Sharing</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2 ">
                                    <Link to="/host/terms-and-conditions" className='nav-link menu-link'>
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="bx bx-spreadsheet"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Terms & Conditions</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={`/host/admin/messages`} className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className='bx bxs-contact'></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Contact with support</h4>
                                            <p className="disc mb-0"> In case if you need any help or have any query contact with support.</p>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to="/host/feedback" className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="bx bx-notepad"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Give us feedback</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div> 

                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to="/host/update-password" className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="bx bx-lock"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Update Password</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div> 

                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to="/host/taxes" className="nav-link menu-link">
                                        <div className="card p-lg-3 assigment-card mb-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className='bx bx-receipt'></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1 text-prime">Taxes</h4>
                                            <p className="disc mb-0"> It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
  )
}

export default Settings;
