/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { HostContext } from '../../../Contexts/HostContext';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import helper from '../../../helper/index';
import moment from 'moment';

const CheckList = () => {
    const [PageCount, setPageCount] = useState(0);
    const {token} = useContext(HostContext);   
    const [title, setTitle] = useState("");
    const [checkListArray, setCheckList] = useState([]);
    const [isInitialState, setIsInitialState] = useState(true)

    useEffect(() => {
        getCheckList();
    }, []);

    useEffect(() => {
        if(isInitialState === true){
            setIsInitialState(false);
        }
    }, [])
    
    
    

    const getCheckList = (datas) => {

        let pageNo = (datas) ? datas.selected : 0;
        axios.post(process.env.REACT_APP_BACKENDURL+'api/host/get-checklist', 
        {
            page: pageNo,
            limit: 10,
        },
        {
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        }
        )
        .then(function (response) {
            if(response.data.status === 1){
                setCheckList(response.data.data);
                setPageCount(response.data.total_pages);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    } 

    const removeCheckList = (id) => {
        Swal.fire({
            text: 'Are you sure to remove this checklist!',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.post(process.env.REACT_APP_BACKENDURL+'api/host/remove-checklist',
                {id: id},
                {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
                })
                .then(function (response) {
                    if(response.data.status === 1){
                        helper.Toast().fire({ icon: 'success', title: response.data.message });
                        getCheckList();
                    } else {
                        helper.Toast().fire({ icon: 'error', title: response.data.message });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        })
        
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                {/* Breadcrumbs */}
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Manage Checklist</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/host/assignment/list">Assignment</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Checklist</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row gy-4">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header align-items-center d-flex justify-content-between">
                                <div className="d-flex align-items-start flex-column ">
                                    <h4 className="card-title mb-0 text-capti">You have {checkListArray.length} checklist(s)</h4>
                                </div>
                                <Link to={'/host/checklist/add'} className="btn btn-outline-list list-btn waves-effect waves-light">New Checklist</Link>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered checklist-table" id="checklist-table">
                                    <thead>
                                        <tr>
                                            <th style={{"textAlign": "left", "padding": "4px 10px", "width": "25%" }}>Name</th>
                                            <th style={{"textAlign": "left", "padding": "4px 10px", "width": "25%" }}>Created On</th>
                                            <th style={{"textAlign": "left", "padding": "4px 10px", "width": "25%" }}>Items</th>
                                            <th style={{"textAlign": "left", "padding": "4px 10px", "width": "15%" }}>Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {checkListArray.map((item, index) =>
                                        <tr key={index}>
                                            <td style={{ "textAlign": "left", "padding" : "4px 10px"}}>{item.title}</td>
                                            <td style={{ "textAlign": "left", "padding" : "4px 10px"}}>{moment(item.createdAt).format('LL')}</td>
                                            <td style={{ "textAlign": "left", "padding" : "4px 10px"}}>{item.checklistData.length}</td>
                                            <td style={{ "textAlign": "left", "padding" : "4px 10px"}}>
                                                <div className="hstack gap-3 flex-wrap">
                                                    <Link to={'/host/checklist/edit/'+item._id}
                                                        className="edit-icon fs-18">
                                                        <img src="/assets/images/sidebar-icon/edit.svg" 
                                                            alt="editIcon"
                                                            height="23" />
                                                    </Link>
                                                    <a href="#javascript"
                                                        onClick={(e) => removeCheckList(item._id)} 
                                                        className="link-danger delete-icon fs-18">
                                                        <img src="/assets/images/sidebar-icon/Delete.svg" 
                                                            alt="deleteIcon"
                                                            height="23" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel="..."
                            pageCount={PageCount}
                            pageRangeDisplayed={3}
                            onPageChange={getCheckList}
                            containerClassName={'pagination pagination-rounded mt-5 mb-5 justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            renderOnZeroPageCount={null}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckList;
