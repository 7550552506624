import React, { useState, useRef } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import helper from '../../../../helper/index.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import generatePayoutPdf from '../../../../helper/cleaner-payout-invoice-pdf.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Payout = (props) => {
  const manageModal = useRef(null)
  const [modalFlag, setModalFlag] = useState(0);
  const token = Cookies.get('token');

  const [MRoutingNumber, setMRoutingNumber] = useState('');
  const [MAccountHolderName, setMAccountHolderName] = useState('');
  const [MBankName, setMBankName] = useState('');
  const [MLastF, setMLastF] = useState('');
  const [MPayoutId, setMPayoutId] = useState('');
  const [MStatus, setMStatus] = useState('');
  const [MAmount, setMAmount] = useState('');
  const [MArrivalDate, setMArrivalDate] = useState('');

  const [stripe_payout_list, setStripePayoutList] = useState([]);
  const [upcoming_payout_list, setUpcomingPayoutList] = useState([]);
  const [page_count, setPageCount] = useState(0);
  const [logoBase64, setLogoBase64] = useState("");
  const [TransData, setTransData] = useState({});

  useEffect(() => {
    fetchStripePayoutList();
    fetchUpcomingPayoutList();
    getBase64ImageFromUrl();
  }, [])

  const getBase64ImageFromUrl = async() => {
    props.setLoading(true);
    var res = await fetch('../assets/images/das-logo.png');
    var blob = await res.blob();
    if(blob.type !== 'image/png') {
      props.setLoading(false);
      return true;
    }
    return new Promise((resolve, reject) => {
        var reader  = new FileReader();
        reader.addEventListener("load", function () {
            setLogoBase64(reader.result);
            props.setLoading(false);
            resolve(reader.result);
        }, false);
    
        reader.onerror = () => {
          props.setLoading(false);
          return reject(this);
        };
        reader.readAsDataURL(blob);
    })
  }

  // Fetch Payouts list details
  const fetchStripePayoutList = async () => {
    const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/fetch-payout-list',{
        headers: {
            'Content-Type': 'application/json',
            'token': token
        }
    });
    if(data.status === 1){
      setStripePayoutList(data.data.data);
    }
  };

  const fetchUpcomingPayoutList = async () => {
    const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/fetch-upcomming-payout-list',{
        headers: {
            'Content-Type': 'application/json',
            'token': token
        }
    });
    if(data.status === 1){
      setUpcomingPayoutList(data.data);
      setPageCount(data.total_pages);
    }
  };

  const fetchTranasctionDetail = async (object) => {
    let formdata = {
      'payout_id'       : object.id,
      'bank_account_id' : object.destination,
      'status'          : object.status,
      'amount'          : (object.amount)/100,
      'arrival_date'    : object.arrival_date
    };

    await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/receipt-detail',formdata,{
        headers: {
            'Content-Type': 'application/json',
            'token': token
        }
    })
    .then(function (response) {
      if(response.data.status === 1){
        setTransData({...response.data.data});
      }else{
        setTransData({});
        helper.Toast().fire({ icon: 'error', title: response.data.message });
      }
    })
    .catch(function (error) {
      console.log('Error: ',error);
    });
  };

  const openNewWindow = async (trans_id) => {        
      await fetchTranasctionDetail(trans_id);
  }

  useEffect(() => {
      if(Object.keys(TransData).length > 0) {
          const invoiceContent = generatePayoutPdf(TransData, logoBase64);
          if(Object.keys(invoiceContent).length > 0) {
              try {
                pdfMake.createPdf(invoiceContent).open();
              } catch(err) {
                if(err === "Open PDF in new window blocked by browser") {
                    alert('Please allow popup for housekeeper site to view invoice pdf.');
                }
            }
          }
          setTransData({});
      }
  }, [TransData])

  const ViewInvoice = async (object) => {
    let formdata = {
      'payout_id'       : object.id,
      'bank_account_id' : object.destination,
      'status'          : object.status,
      'amount'          : (object.amount)/100,
      'arrival_date'    : object.arrival_date
    };

    const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/receipt-detail',formdata,{
        headers: {
            'Content-Type': 'application/json',
            'token': token
        }
    });
    manageModal.current.click()
    if(data.status === 1){
      setMRoutingNumber(data.data.routing_number)
      setMAccountHolderName(data.data.account_holder_name)
      setMBankName(data.data.bank_name)
      setMLastF(data.data.last4)
      setMPayoutId(data.data.payout_id)
      setMStatus(data.data.status)
      setMAmount(data.data.amount)
      setMArrivalDate(data.data.arrival_date)
      setModalFlag(1)
    }else{
      setModalFlag(0)
    }
  };

  return (
    <>
      <div className="card-header align-items-center d-flex justify-content-between">
        <div className="d-flex align-items-center gap-3 ">
            <h4 className="card-title mb-0 flex-grow-1">Payouts</h4>
        </div>
        <div id="history-tab">
          <ul className="nav nav-pills custom-nav nav-justified" role="tablist" id="propert-step-io">
            <li className="nav-item" role="presentation">
                <button className="nav-link done active" id="payment-card-io-tab" data-bs-toggle="pill"
                    data-bs-target="#payment-card-io" type="button" role="tab"
                    aria-controls="payment-card-io" aria-selected="true">Completed</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link " id="transaction-io-tab" data-bs-toggle="pill"
                    data-bs-target="#transaction-io" type="button" role="tab"
                    aria-controls="transaction-io" aria-selected="false"
                    tabIndex="-1">Upcoming</button>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content">
        <div className="tab-pane fade active show" id="payment-card-io" role="tabpanel"
            aria-labelledby="payment-card-io-tab">
          <div className="card-body">
            <div className="table-responsive table-card data-table" id="transaction-table">
                <table className="table table-borderless table-nowrap align-middle mb-0 ">
                    <thead className="">
                        <tr className="text-muted">
                            <th scope="col" width="200">Payment Number</th>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (stripe_payout_list.length >= 1) ?
                            stripe_payout_list.map(function(object, i){
                                return <tr key={i}>
                                            <td className="number">{object.id}</td>
                                            <td className="number">{moment.unix(object.arrival_date).format('MMM Do YYYY, hh:mm A') }</td>
                                            <td>
                                                <div className="number">${parseFloat(object.amount/100).toFixed(2)}</div>
                                            </td>
                                            <td><span className="badge completed p-2 text-capitalize">{object.status}</span>
                                            </td>
                                            <td>
                                              <span className="p-2 text-capitalize view-receipt-color badge" onClick={(e)=>ViewInvoice(object)}>View Receipt</span>
                                              <span className = "btn btn-sm btn-success ms-2" onClick={(e)=>openNewWindow(object)}><i className = "bx bx-download"></i> Invoice</span>
                                            </td>
                                        </tr>;
                            })
                        :
                        <>
                            <tr>
                                <td className='text-center' colSpan="5">Payouts not recorded yet</td>
                            </tr>
                        </>
                    }
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        
        <div className="tab-pane fade" id="transaction-io" role="tabpanel"
            aria-labelledby="transaction-io-tab">
          <div className="card-body">
            <div className="table-responsive table-card data-table" id="upcoming-payouts-table">
                <table className="table table-borderless table-nowrap align-middle mb-0 ">
                    <thead className="">
                        <tr className="text-muted">
                            <th scope="col">Title</th>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (upcoming_payout_list.length >= 1) ?
                          upcoming_payout_list.map(function(object, i){
                              return (
                                    <tr key={i}>
                                          <td className="number">{object.title}</td>
                                          <td className="number">{ (object.date) ? moment(object.date).format('MMM Do YYYY') : "" }</td>
                                          <td>
                                              <div className="number">${(parseFloat(object.price) - parseFloat(object.cleaner_transaction_fee)).toFixed(2)}</div>
                                          </td>
                                          <td><span className="badge completed p-2 text-capitalize">Pending</span>
                                          </td>
                                      </tr>
                                    );
                          })
                        :
                        <>
                            <tr>
                                <td className='text-center' colSpan="4">No Upcoming Payouts Available</td>
                            </tr>
                        </>
                    }

                    </tbody>
                </table>

                {
                  (upcoming_payout_list.length >= 1) ?
                    <>
                      <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel="..."
                        pageCount={page_count}
                        pageRangeDisplayed={3}
                        onPageChange={fetchUpcomingPayoutList}
                        containerClassName={'pagination pagination-rounded mt-5 mb-5 justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        renderOnZeroPageCount={null}
                        activeClassName={'active'}
                      />
                    </>
                  :
                  <></>
                }

            </div>
          </div>
        </div>
      </div>

      <button style={{'display': 'none'}} type="button" data-bs-toggle="modal" data-bs-target="#myModal" ref={manageModal} className="btn btn-add w-100 mb-2 me-2">View Receipt</button>
      <div id="myModal" className="modal fade" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{'display': 'none'}}> 
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Payout Details</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              { 
                (modalFlag === 1) ? 
                  <>
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <p className="fw-medium text-muted mb-0 number">
                              Payout ID: {MPayoutId}<br></br>
                              Payout Date: {moment.unix(MArrivalDate).format('MMM Do YYYY, hh:mm A') } 
                              
                            </p>
                          </div>
                          <div>
                            <h4 className="fs-20 fw-semibold ff-secondary">
                              <span className="badge completed p-2 text-capitalize">{MStatus}</span>
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-4 pt-4 border-top">
                          <div className="flex-grow-1">
                              <h5 className="text-uppercase  fw-semibold text-muted mb-0 number">Account Holder Name</h5>
                              <p className="text-uppercase  fw-semibold text-muted mb-0 number ">Routing Number</p>
                              <p className="ff-secondary fw-semibold text-prime">{MRoutingNumber}</p>
                          </div>
                          <div>
                            <h5 className="text-uppercase  fw-semibold text-muted mb-0 number text-end">{MAccountHolderName}</h5>
                            <p className="text-uppercase  fw-semibold text-muted mb-0 number text-end">Last 4</p>
                            <p className="ff-secondary fw-semibold text-prime text-end">{MLastF}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center pt-4 border-top">
                            <div className="flex-grow-1">
                                <h5 className="text-uppercase  fw-semibold text-muted mb-0 number ">Amount</h5>
                            </div>
                            <div>
                                <h4 className="fs-20 fw-medium ff-secondary text-prime">${parseFloat(MAmount).toFixed(2)}</h4>
                            </div>
                        </div>
                      </div>
                    </div>

                  </> 
                : 
                  <>
                    Sorry! We are unable to find more details.
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payout;