import React,{useMemo, useContext, useState} from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import helper from '../../../../helper/index.js';

const CardsList = (props) => {
  return (
        <>
          {(props.cardsData.length > 0) ?
            props.cardsData.map((item, index) => (
              <React.Fragment key={index}>
                <div className={(props.ActiveCard.id !== "undefined" && props.ActiveCard.id !== item.id) ? "card p-2 border shadow-none mb-0 mt-2" : "card p-2 border active-border shadow-none mb-0 mt-2" } key={index.toString()}>
                  <div className="d-flex gap-2">
                    <div className="flex-shrink-0 card-icon mt-2">
                      {/* <i className="ri-bank-card-fill"></i> */}
                      <img src="/assets/images/icons/quick-pay.png" alt="bank-account" style={{'height': '60px'}} />
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <div
                        className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <h6 className="text-capitalize mb-0">{item.name}</h6>
                          <p className="mb-0 number">**** **** {item.last4}</p>
                        </div>
                        <div>
                          <h6 className="text-capitalize mb-0 text-end">{item.brand}</h6>
                          <p className="mb-0 text-end number">Expire on {item.exp_month}/{item.exp_year}</p>
                        </div>
                      </div>
                      {
                        (props.ActiveCard.id !== "undefined" && props.ActiveCard.id !== item.id) ? 
                        <>
                          <button type="button" className="btn btn-sm float-end mt-2 ms-2 btn-add" onClick={(e) => props.updateDefaultCard(item.id)}>Set As Default</button>
                        </>
                        :
                        <></>
                      }
                      <button type="button" className="btn btn-add btn-sm float-end mt-2" onClick={(e) => props.deleteCard(item.id)}>Delete</button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))  
            : <p>Please add new card. No card added yet.</p>
          }  
        </>     
  )
}

export default CardsList