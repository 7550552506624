import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
// import Cookies from 'js-cookie';
// import moment from 'moment';

const TermsAndConditions = () => {
    // const token = Cookies.get('token');
    const fullurl =  window.location.href;
    const segment = fullurl.split("/"); 
    // let role_id = 0;
    let role_name = '';
    if(segment[3] !== "undefined" && segment[3] === "host"){
        // role_id = 2;
        role_name = "host";
    }else if(segment[3] !== "undefined" && segment[3] === "cleaner"){
        // role_id = 3;
        role_name = "cleaner";
    }
    const [termsContent, setTermsContent] = useState(null);

    const getContent = async() => {
        const {data} = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/get-terms-condition', {
                            headers: {
                                'Content-Type': 'text/html',
                                // 'token': token
                            }
                        })
                        .catch(function (error) {
                            console.log('Something went wrong');
                        });
        if(data.status === 1) {
            setTermsContent(data.content);
        }
    }

    useEffect(() => {
        getContent();
    }, [])

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Terms & Conditions</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to={`/${role_name}/dashboard`}>Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={`/${role_name}/settings`}>Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Terms & Conditions</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="live-preview p-5 policy-section">
                                    {
                                        termsContent && parse(termsContent)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;
