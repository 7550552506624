import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import helper from '../../../helper/index.js'
import { HostContext } from '../../../Contexts/HostContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import parse from 'html-react-parser';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Cookies from 'js-cookie';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

function PropertyDetail(props) {

    const { decoded } = useContext(HostContext);
    const navigate = useNavigate();
    const params = useParams();
    const token = Cookies.get('token');

    const [propertyDetail, setPropertyDetail] = React.useState({
        description: "",
        lat: 30.878920,
        lng: 75.841844
    })
    const propertyId = (params.id) ? params.id : params.propertyId;
    const hostId = (decoded.user_id) ? decoded.user_id : 0;
    useEffect(() => {
        fetchPropertDetails();
    }, []);


    // Fetch Host Details
    const fetchPropertDetails = async () => {
        props.setLoading(true);
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL + 'api/host/property-detail', {
            params: {
                id: propertyId.trim(),
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log(error);
        });

        if (data.status === 1) {
            props.setLoading(false);
            setPropertyDetail(data.data)
        } else {
            props.setLoading(false);
            navigate('/host-list');
        }
    };
    
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const types = helper.types
    const subTypes = helper.subTypes


    const center = useMemo(() => ({ lat: parseFloat(propertyDetail.lat), lng: parseFloat(propertyDetail.lng) }), [parseFloat(propertyDetail.lat), parseFloat(propertyDetail.lng)]);
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
    })

    const containerStyle = {
      width: '100%',
      height: '60vh'
    };

    // eslint-disable-next-line no-unused-vars
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
  
      setMap(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])



    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Property Detail</h4>

                            <div className="page-title-right">

                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/host/property/list">My Properties</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Property Detail</li>
                                </ol>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="live-preview">
                                    <div className="row gx-lg-5">

                                        <div className="col-xl-5 col-md-5 mx-auto">

                                            <>
                                                <Swiper
                                                    style={{
                                                        '--swiper-navigation-color': '#fff',
                                                        '--swiper-pagination-color': '#fff',
                                                    }}
                                                    spaceBetween={10}
                                                    navigation={false}
                                                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                                    modules={[FreeMode, Navigation, Thumbs]}
                                                    className="mySwiper2"
                                                >
                                                    {(propertyDetail.images && propertyDetail.images.length > 0) ?
                                                        propertyDetail.images.map((img, i) => (

                                                            <SwiperSlide key={img.filename}>
                                                                <img src={process.env.REACT_APP_BACKENDURL + 'images/' + img.filename} key={i} alt={img.filename} />
                                                            </SwiperSlide>

                                                        ))
                                                        : <></>}

                                                </Swiper>
                                                <Swiper
                                                    onSwiper={setThumbsSwiper}
                                                    spaceBetween={10}
                                                    slidesPerView={3}
                                                    freeMode={true}
                                                    watchSlidesProgress={true}
                                                    className="mySwiper"
                                                >
                                                    {(propertyDetail.images && propertyDetail.images.length > 0) ?
                                                        propertyDetail.images.map((img, i) => (

                                                            <SwiperSlide key={img.filename}>
                                                                <img src={process.env.REACT_APP_BACKENDURL + 'images/' + img.filename} key={i} alt={img.filename} />
                                                            </SwiperSlide>

                                                        ))
                                                        : <></>}

                                                </Swiper>
                                            </>

                                        </div>


                                        <div className="col-xl-7">
                                            <h4 className="card-title mb-3">{propertyDetail.propertyName}</h4>
                                            <div className="row">
                                                <div className="col-md-6 pro-type">
                                                    <h5>Property Type</h5>
                                                    <p className="number">
                                                        {
                                                            (propertyDetail.type > 0) ?
                                                                types.map((item, i) => (
                                                                    (propertyDetail.type === item.id) ? 
                                                                    item.name : <></>
                                                                ))
                                                            :<></>
                                                        }
                                                            
                                                            &nbsp;|&nbsp; 
                                                         
                                                        {
                                                            (propertyDetail.subType > 0) ?
                                                                subTypes.map((item, i) => (
                                                                    (propertyDetail.subType === item.id) ? 
                                                                    item.name : <></>
                                                                ))
                                                            :<></>
                                                        }
                                                    </p>
                                                </div>
                                                <div className="col-md-6 pro-dimensions">
                                                    <h5>Dimensions</h5>
                                                    <p className="number">{propertyDetail.size} {(propertyDetail.sizeType === "m") ? "m²" : "ft²"}</p>
                                                </div>
                                                <div className="col-md-6 pro-sum">
                                                    <h5>Property Summary</h5>
                                                    <ul className="number">
                                                        <li> {propertyDetail.bedrooms} Bedrooms</li>
                                                        <li> {propertyDetail.bed} Bed</li>
                                                        <li> {propertyDetail.bathroom} Bathroom</li>
                                                        <li> {propertyDetail.kitchen} Kitchen</li>
                                                        <li> {propertyDetail.garage} Garage</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 pro-address">
                                                    <h5>Location</h5>
                                                   
                                                    <div className="full-addres">
                                                       <div className="d-flex align-items-center gap-1">
                                                            <h6>FullAddress:</h6>
                                                            <p>{propertyDetail.fullAddress}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>City:</h6>
                                                            <p>{propertyDetail.city}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>State:</h6>
                                                            <p>{propertyDetail.state}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>Pincode:</h6>
                                                            <p>{propertyDetail.pincode}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>Country:</h6>
                                                            <p>{propertyDetail.country}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>StreetAddress:</h6>
                                                            <p>{propertyDetail.streetAddress}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>Lat:</h6>
                                                            <p>{propertyDetail.lat}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <h6>Lng:</h6>
                                                            <p>{propertyDetail.lng}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div>
                                                        <h5>Description</h5>
                                                        <p>{parse(propertyDetail.description)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div>
                                                        <h5>Instructions on how cleaners can access your property</h5>
                                                        <p>{propertyDetail.accessibility}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 mt-4">
                                            {isLoaded ? (
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={center}
                                                    zoom={7}
                                                    onLoad={onLoad}
                                                    onUnmount={onUnmount}
                                                >
                                                    <></>
                                                </GoogleMap>
                                            ) : <></>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default PropertyDetail
