import React,{useState, useEffect, useContext, useRef} from 'react'
import { Link, useNavigate, useOutletContext, useSearchParams, useLocation } from 'react-router-dom';
import { HostContext } from '../../../Contexts/HostContext';
import axios from 'axios';
import helper from '../../../helper/index.js';
import Withdrawal from './additional/Withdrawal.js';
import Payout from './additional/Payout.js';
import Cookies from 'js-cookie';
import moment from 'moment';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import generateInvoicePdf from '../../../helper/cleaner-transaction-invoice-pdf.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Index = (props) => {
    const location = useLocation();
    const fromPage = location.state?.fromPage ?? "";
    const token = Cookies.get('token');
    const [getProfileStatus] = useOutletContext();

    const {decoded} = useContext(HostContext);   

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [stripe_transfer_list, setStripeTransferList] = useState([]);
    const [SpilMsg, setSpilMsg] = useState('No transfers recorded yet');
    const [PrevBtnFlag, setPrevBtnFlag] = useState(0);
    const [NextBtnFlag, setNextBtnFlag] = useState(0);
    const [show_bank_form, setShowBankForm] = useState(1);
    const [BankAccountLength, setBankAccountLength] = useState(0);
    //Bank account details constants starts
    const [stripe_info, setStripeInfo] = useState("");
    const [account_holder_name, setAccountHolderName] = useState("");
    const [routing_number, setRoutingNumber] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [bank_account_id, setBankAccountId] = useState("");

    const [errorAccountHolderName, setBAHNErrorMessage] = useState("");
    const [errorRoutingNumber, setBRNErrorMessage] = useState("");
    const [errorAccountNumber, setBANErrorMessage] = useState("");
    //Bank account details constants ends

    const manageModal = useRef(null)
    const [modalFlag, setModalFlag] = useState(0);

    const [MPaymentId, setMTransId] = useState('');
    const [MCreatedDate, setMCreatedDate] = useState('');
    const [MTransGroup, setMTransGroup] = useState('');
    const [MTitle, setMTitle] = useState('');
    const [MAmount, setMAmount] = useState('');
    const [MTFee, setMTFee] = useState('');
    const [MTFeeP, setMTFeeP] = useState('');
    const [MTAmount, setMTAmount] = useState('');
    const [logoBase64, setLogoBase64] = useState("");
    const [TransData, setTransData] = useState({});
    const [accountName, setAccountName] = useState("");

    useEffect(() => {
        fetchUserDetails();
        fetchBankAccountDetails();
        fetchStripeTransferList();
        getBase64ImageFromUrl();
    }, [])

    const getBase64ImageFromUrl = async() => {
        props.setLoading(true);
        var res = await fetch('../assets/images/das-logo.png');
        var blob = await res.blob();
        if(blob.type !== 'image/png') {
            props.setLoading(false);
            return true;
        }
        return new Promise((resolve, reject) => {
            var reader  = new FileReader();
            reader.addEventListener("load", function () {
                setLogoBase64(reader.result);
                props.setLoading(false);
                resolve(reader.result);
            }, false);
        
            reader.onerror = () => {
                props.setLoading(false);
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }

    // Fetch Bank Account Details
    const fetchStripeTransferList = async (btnType="", id = '') => {
        let starting_after = ''; 
        let ending_before = '';
        if(btnType === "next"){
            starting_after=id;
        }else{
            ending_before=id;
        }
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/fetch-transfers-list',
            {
                role_id: 3,
                starting_after: starting_after,
                ending_before: ending_before,
                limit:10
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
        );
        if(data.status === 1){
            setStripeTransferList(data.data.data);
            if(btnType !== ""){
                if(btnType === "prev" && data.data.has_more === true){
                    setPrevBtnFlag(1)
                }
                if(btnType === "prev" && data.data.has_more === false){
                    setPrevBtnFlag(0)
                }
                if(btnType === "next"){
                    setPrevBtnFlag(1)
                }
                setSpilMsg("No more records available");
            }else{
                if(data.data.has_more === true){
                    setNextBtnFlag(1)
                }else{
                    setNextBtnFlag(0)
                }
            }
        }
    };

    // Fetch stripe payout list
    const fetchBankAccountDetails = async () => {
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/fetch-bank-account-details',{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        if(data.status === 1){
            setAccountHolderName(data.data[0].account_holder_name)
            setBankAccountId(data.data[0].id)
            setStripeInfo(data.data)
            setBankAccountLength(data.data.length)
        }
    };

    const fetchUserDetails = async () => {
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/my-profile',{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            params: {
                role_id: 3
            }
        })
        .catch(function (error) {
            console.log('Something went wrong');
        });
        if(data.status === 1){
            let name = data.data.first_name +' '+ data.data.last_name;
            setAccountName(name);
        }
    };

    /* Submit Account details code start */
    const submitAccountDetailsData = async (event) => {
        event.preventDefault();

        setBAHNErrorMessage("");
        setBRNErrorMessage("");
        setBANErrorMessage("");

        let error = 0;
        if(account_holder_name.trim().length === 0) {
            setBAHNErrorMessage('Account Holder Name field is required.');
            error = 1;
        } 
        // else if(account_holder_name.toLowerCase() !== accountName.toLowerCase()) {
        //     setBAHNErrorMessage('Account Holder Name should matched with registered name.');
        //     error = 1;
        // }
        if(routing_number.trim().length === 0) {
            setBRNErrorMessage('Routing Number field is required.');
            error = 1;
        }
        if(account_number.trim().length === 0) {
            setBANErrorMessage('Account Number field is required.');
            error = 1;
        }
        
        if(error === 1){
            return;
        }
        
        const formData = new FormData(event.target);
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-bank-account-details',formData,{
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                props.setLoading(false)
                fetchBankAccountDetails();
                getProfileStatus();
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    };
    
    /* Submit Update Account details code start */
    const submitUpdateAccountDetailsData = async (event) => {
        event.preventDefault();

        setBAHNErrorMessage("");

        let error = 0;
        if(account_holder_name.trim().length === 0) {
            setBAHNErrorMessage('Account Holder Name field is required.');
            error = 1;
        } 
        // else if(account_holder_name.trim() !== accountName) {
        //     setBAHNErrorMessage('Account Holder Name should matched with registered name.');
        //     error = 1;
        // }
        
        if(error === 1){
            return;
        }
        
        const formData = new FormData(event.target);
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-existing-bank-account-details',formData,{
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                props.setLoading(false)
                fetchBankAccountDetails();
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    };

    const fetchTranasctionDetail = async (object) => {
        let formdata = {
            'transaction_id'  : object.id,
            'amount'          : (object.amount)/100,
            'created_date'    : object.created,
            'transfer_group'  : object.transfer_group
        };
      
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/transaction-detail',formdata,{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                setTransData({...response.data.data});
            }else{
                setTransData({});
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            console.log('Error: ',error);
        });
    };
    
    const openNewWindow = async (trans_id) => {        
        await fetchTranasctionDetail(trans_id);
    }

    useEffect(() => {
        if(Object.keys(TransData).length > 0) {
            const invoiceContent = generateInvoicePdf(TransData, logoBase64);
            if(Object.keys(invoiceContent).length > 0) {
                try {
                    pdfMake.createPdf(invoiceContent).open();
                } catch(err) {
                    if(err === "Open PDF in new window blocked by browser") {
                        alert('Please allow popup for housekeeper site to view invoice pdf.');
                    }
                }
            }
            setTransData({});
        }
    }, [TransData])

    const ViewInvoice = async (object) => {
        let formdata = {
          'transaction_id'  : object.id,
          'amount'          : (object.amount)/100,
          'created_date'    : object.created,
          'transfer_group'  : object.transfer_group
        };
    
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/transaction-detail',formdata,{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        manageModal.current.click()

        setMTransId('')
        setMCreatedDate('')
        setMTransGroup('')
        setMTitle('')
        setMAmount('')
        setMTFee('')
        setMTFeeP('')
        setMTAmount('')

        if(data.status === 1){
            setMTransId(data.data.transactionId)
            setMCreatedDate(data.data.createdDate)
            setMTransGroup(data.data.transferGroup)
            setMTitle(data.data.assignmentTitle)
            setMAmount(data.data.currentTrans.amount)
            setMTFee(data.data.currentTrans.transactionFee)
            setMTFeeP(data.data.currentTrans.transFeePerct)
            setMTAmount(data.data.currentTrans.totalAmount)
            setModalFlag(1)
        }else{
            setModalFlag(0)
        }
    };

    /** Update default bank account code starts */
    const updateDefaultBankAccount = async (bank_account_id) => {
        const formData = new FormData();
        formData.append(`bank_account_id`,bank_account_id);
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/update-default-bank-account', formData, {
        headers: {
            'token': token,
            'Content-Type': 'application/json'
        }
        })
        .then(function (response) {
            if (response.data.status === 1) {
                fetchBankAccountDetails();
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            } else {
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
        });
    };

    /** Delete bank account code starts */
    const deleteBankAccount = async (bank_account_id) => {
        Swal.fire({
            text: 'Are you sure you want to delete this account?',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Cancel`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.append(`bank_account_id`,bank_account_id);
                    props.setLoading(true)
                    axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/delete-bank-account', formData, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(function (response) {
                    if (response.data.status === 1) {
                        fetchBankAccountDetails();
                        getProfileStatus();
                        props.setLoading(false)
                        helper.Toast().fire({
                            icon: 'success',
                            title: response.data.message
                        })
                    } else {
                        props.setLoading(false)
                        helper.Toast().fire({
                            icon: 'error',
                            title: response.data.message
                        })
                    }
                })
                .catch(function (error) {
                    props.setLoading(false)
                });
            }
        });
    };

    return (
            <div className="page-content cleaner-payment-and-transactions">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Payments and Transactions</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/cleaner/dashboard">Overview</Link>
                                        </li>
                                        {(fromPage === 'insight') ?
                                            <li className="breadcrumb-item">
                                                <Link to="/cleaner/insight">Insight</Link>
                                            </li>
                                            :
                                            <li className="breadcrumb-item">
                                                <Link to="/cleaner/settings">Settings</Link>
                                            </li>
                                        }
                                        <li className="breadcrumb-item active">Payments & Payouts</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-md-12">

                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-center gap-3 ">
                                <h4 className="card-title mb-0 flex-grow-1 d-none">Payments</h4>
                            </div>

                            <div id="payment-tab">

                                <ul className="nav nav-pills custom-nav nav-justified" role="tablist" id="propert-step">
                                    <li className="nav-item" role="presentation">
                                        <button className={(searchParams.get("active-tab") === null || searchParams.get("active-tab") === "payment-methods") ? "nav-link done active" : "nav-link" } id="payment-card-tab" data-bs-toggle="pill"
                                            data-bs-target="#payment-card" type="button" role="tab"
                                            aria-controls="payment-card" aria-selected={(searchParams.get("active-tab") === null || searchParams.get("active-tab") === "payment-methods") ? "true" : "false"} onClick={(e) => navigate('?active-tab=payment-methods') }>Payment Methods</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={(searchParams.get("active-tab") !== null && searchParams.get("active-tab") === "transactions") ? "nav-link done active" : "nav-link" } id="transaction-tab" data-bs-toggle="pill"
                                            data-bs-target="#transaction" type="button" role="tab"
                                            aria-controls="transaction" aria-selected={(searchParams.get("active-tab") !== null && searchParams.get("active-tab") === "transactions") ? "true" : "false"}
                                            tabIndex="-1" onClick={(e) => navigate('?active-tab=transactions') }>Transactions</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={(searchParams.get("active-tab") !== null && searchParams.get("active-tab") === "payouts") ? "nav-link done active" : "nav-link" } id="payouts-tab" data-bs-toggle="pill"
                                            data-bs-target="#payouts" type="button" role="tab"
                                            aria-controls="payouts" aria-selected={(searchParams.get("active-tab") !== null && searchParams.get("active-tab") === "payouts") ? "true" : "false"}
                                            tabIndex="-1" onClick={(e) => navigate('?active-tab=payouts') }>Payouts</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center gap-3 icon-btn d-none">
                                <button className="btn btn-outline-list list-btn waves-effect waves-light">Make a new payment</button>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content">
                                <div className={(searchParams.get("active-tab") === null || searchParams.get("active-tab") === "payment-methods") ? "tab-pane fade show active" : "tab-pane fade" } id="payment-card" role="tabpanel"
                                    aria-labelledby="payment-card-tab">

                                    <div className="row mt-4" id="payment-chioce">
                                        <div className="col-12">
                                            <div className="chioce-method">
                                                <div className="row box">
                                                    <div className="col-md-2 py-3 payment-tab px-0">
                                                        <div className="nav flex-column nav-pills text-center"
                                                            id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                                            <a className="nav-link text-uppercase active" id="v-pills-banking-tab"
                                                                data-bs-toggle="pill" href="#v-pills-banking" role="tab"
                                                                aria-controls="v-pills-banking"
                                                                aria-selected="false">
                                                                <div className='row'>
                                                                    <div className='col-md-3 px-1 text-start'><img src="/assets/images/icons/bank-account.png" alt="bank-account" style={{'height': '27px'}} /></div>
                                                                    <div className='col-md-9 px-0 text-start'>Bank Account</div>
                                                                </div>
                                                            </a>

                                                            <a className="nav-link text-uppercase" id="v-pills-withdrawal-tab"
                                                                data-bs-toggle="pill" href="#v-pills-withdrawal" role="tab"
                                                                aria-controls="v-pills-withdrawal"
                                                                aria-selected="false">
                                                                <div className='row'>
                                                                    <div className='col-md-3 px-1 text-start'><img src="/assets/images/icons/balance.png" alt="check-balance" style={{'height': '24px'}} /></div>
                                                                    <div className='col-md-9 px-0 text-start'>Payout Balance</div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-10 py-3 payment-content">
                                                        <div className="tab-content text-muted mt-4 mt-md-0"
                                                            id="v-pills-tabContent">
                                                            
                                                            <div className="tab-pane fade show active" id="v-pills-banking"
                                                                role="tabpanel" aria-labelledby="v-pills-banking-tab">
                                                                <div className="collapse show" id="netbanking">
                                                                    <div className= "row">
                                                                        <div className="col-7">
                                                                            <div className="p-2 shadow-none mb-2" id = "pills-account-details">
                                                                                <h5 className="text-uppercase">Bank Account Details</h5>
                                                                                {
                                                                                    (show_bank_form === 1) ?
                                                                                        <form onSubmit={submitAccountDetailsData} id = "submit-account-details-data" method="post" encType="multipart/form-data">
                                                                                            <div className="row gy-3">
                                                                                                <div className="col-md-12">
                                                                                                    <label className="form-label">Account Holder Name</label>
                                                                                                    <input type="text" 
                                                                                                        className="form-control" 
                                                                                                        name='account_holder_name'
                                                                                                        value={account_holder_name}
                                                                                                        onKeyDown={(e) => helper.checkAlphabetInput(e)}
                                                                                                        onChange={(e) => setAccountHolderName(e.target.value)}
                                                                                                        placeholder='Enter Account Holder Name'
                                                                                                        maxLength={60} />
                                                                                                    {errorAccountHolderName && <div className="text-danger"> {errorAccountHolderName} </div>}
                                                                                                    <small className="text-muted">Full name as displayed on Account</small>
                                                                                                </div>

                                                                                                <div className="col-md-12">
                                                                                                    <label className="form-label">Routing Number</label>
                                                                                                    <input type="text" 
                                                                                                        className="form-control" 
                                                                                                        name='routing_number'
                                                                                                        value={routing_number}
                                                                                                        onKeyDown={(e) => helper.checkNumericInput(e)}
                                                                                                        onChange={(e) => setRoutingNumber(e.target.value)}
                                                                                                        placeholder='Enter Routing Number'
                                                                                                        maxLength={9} />
                                                                                                    {errorRoutingNumber && <div className="text-danger"> {errorRoutingNumber} </div>}
                                                                                                </div>

                                                                                                <div className="col-md-!2">
                                                                                                    <label className="form-label">Account Number</label>
                                                                                                    <input type="text" 
                                                                                                        className="form-control" 
                                                                                                        name='account_number'
                                                                                                        value={account_number}
                                                                                                        onKeyDown={(e) => helper.checkNumericInput(e)}
                                                                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                                                                        placeholder='Enter Account Number'
                                                                                                        maxLength={12} />
                                                                                                    {errorAccountNumber && <div className="text-danger"> {errorAccountNumber} </div>}
                                                                                                </div>

                                                                                                <div className="col-12">
                                                                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                                                                        <button type="submit" className="btn btn-add ms-auto">Update</button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </form>
                                                                                    : 
                                                                                    <>
                                                                                        <form onSubmit={submitUpdateAccountDetailsData} id = "submit-update-account-details-data" method="post" encType="multipart/form-data">
                                                                                            <div className="row gy-3">
                                                                                                <div className="col-md-12">
                                                                                                    <label className="form-label">Account Holder Name</label>
                                                                                                    <input type="text" 
                                                                                                        className="form-control" 
                                                                                                        name='account_holder_name'
                                                                                                        value={account_holder_name}
                                                                                                        onChange={(e) => setAccountHolderName(e.target.value)}
                                                                                                        placeholder='Enter Account Holder Name'
                                                                                                        maxLength={100} />
                                                                                                    <input type="hidden" 
                                                                                                        className="form-control" 
                                                                                                        name='bank_account_id'
                                                                                                        value={bank_account_id} />    
                                                                                                    {errorAccountHolderName && <div className="text-danger"> {errorAccountHolderName} </div>}
                                                                                                    <small className="text-muted">Full name as displayed on Account</small>
                                                                                                </div>

                                                                                                <div className="col-12">
                                                                                                    <div className="d-flex align-items-start gap-3">
                                                                                                        <button type="submit" className="btn btn-add">Update</button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </form>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-5 pills-account-details">
                                                                            {
                                                                                (BankAccountLength > 0) ?
                                                                                    stripe_info.map(function(object, i){
                                                                                        return <div className="col-md-12 payment-and-trancations-bank mb-2" key={i}>
                                                                                                    <div className={(object.default_for_currency === false) ? "card-body account px-3 py-2 not-default" : "card-body account default px-3 py-2"}>
                                                                                                        <a className="d-flex align-items-start" data-bs-toggle="collapse" href="#leadDiscovered1"
                                                                                                            role="button" aria-expanded="false" aria-controls="leadDiscovered1">
                                                                                                            <div className="flex-shrink-0">
                                                                                                                {/* <i className="ri-home-fill"></i>  */}
                                                                                                                <img src="/assets/images/icons/bank-account.png" alt="bank-account" style={{'height': '60px'}} />
                                                                                                            </div>
                                                                                                            <div className="flex-grow-1 ms-3">
                                                                                                                <h6 className="fs-14 mb-1">{object.account_holder_name}</h6>
                                                                                                                <div className="gap-3 align-items-center">
                                                                                                                    {/* <p className="text-muted mb-0">Routing Number {object.routing_number}</p>
                                                                                                                    <p className="text-muted mb-0 text-end">Account Number **** **** {object.last4}</p> */}
                                                                                                                    <div className='row'>
                                                                                                                        <div className='col-md-6'>
                                                                                                                            <p className="text-muted mb-0">Routing Number </p>
                                                                                                                            <p className="text-muted mb-0">{object.routing_number}</p>
                                                                                                                        </div>
                                                                                                                        <div className='col-md-6'>
                                                                                                                            <p className="text-muted mb-0 text-end">Account Number</p>
                                                                                                                            <p className="text-muted mb-0 text-end">**** **** {object.last4}</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    (object.default_for_currency === false) ? 
                                                                                                                    <>
                                                                                                                        <button type="button" className="btn btn-sm float-end btn-add mt-2" onClick={(e) => deleteBankAccount(object.id)}>Delete</button>
                                                                                                                        <button type="button" className="btn btn-sm float-end btn-add mt-2 me-2" onClick={(e) => updateDefaultBankAccount(object.id)}>Make Default</button>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <span className="badge completed p-2 text-capitalize mb-0 float-end">Default</span>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                               </div>
                                                                                    })
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="tab-pane fade" id="v-pills-withdrawal"
                                                                role="tabpanel" aria-labelledby="v-pills-withdrawal-tab">
                                                                <div className="collapse show" id="withdrawal">
                                                                <Withdrawal setLoading={props.setLoading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                                
                                <div className={(searchParams.get("active-tab") !== null && searchParams.get("active-tab") === "transactions") ? "tab-pane fade show active" : "tab-pane fade" } id="transaction" role="tabpanel"
                                    aria-labelledby="transaction-tab">
                                    <div className="card mt-4">
                                        <div className="card-header align-items-center d-flex justify-content-between">
                                            <div className="d-flex align-items-center gap-3 ">
                                                <h4 className="card-title mb-0 flex-grow-1 d-none">Transaction</h4>

                                            </div>
                                        </div>
            
                                        <div className="card-body">
                                            <div className="table-responsive table-card data-table" id="transaction-table">
                                                <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                                    <thead className="">
                                                        <tr className="text-muted">
                                                            <th scope="col" width="200">Payment Number</th>
                                                            <th scope="col">Date & Time</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        (stripe_transfer_list.length >= 1) ?
                                                            stripe_transfer_list.map(function(object, i){
                                                                return <tr key={i}>
                                                                            <td className="number">{object.id}</td>
                                                                            <td className="number">{moment.unix(object.created).format('MMM Do YYYY, hh:mm A') }</td>
                                                                            <td>
                                                                                <div className="number">${parseFloat(object.amount/100).toFixed(2)}</div>
                                                                            </td>
                                                                            <td><span className="badge completed p-2 text-capitalize">Success</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="p-2 text-capitalize badge view-receipt-color" onClick={(e)=>ViewInvoice(object)}>View Receipt</span>
                                                                                <span className = "btn btn-sm btn-success ms-2" onClick={(e)=>openNewWindow(object)}><i className = "bx bx-download"></i> Invoice</span>
                                                                            </td>
                                                                        </tr>;
                                                            })
                                                        :
                                                        <>
                                                            <tr>
                                                                <td colSpan="5">{SpilMsg}</td>
                                                            </tr>
                                                        </>
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='text-center'>
                                                <button type="button" className="btn btn-add view-receipt-color" disabled={(PrevBtnFlag === 0) ? true : false} onClick={(e) => fetchStripeTransferList('prev', (stripe_transfer_list.length >= 1) ?stripe_transfer_list[0].id : '') }
                                                >Prev</button>
                                                <button type="button" className="btn btn-add mx-2 view-receipt-color" disabled={(NextBtnFlag === 0) ? true : false} onClick={(e) => fetchStripeTransferList('next', (stripe_transfer_list.length >= 1) ? stripe_transfer_list.slice(-1)[0].id: '') }>Next</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className={(searchParams.get("active-tab") !== null && searchParams.get("active-tab") === "payouts") ? "tab-pane fade show active" : "tab-pane fade" } id="payouts" role="tabpanel" aria-labelledby="payouts-tab">
                                    <div className="card mt-4">
                                        <Payout setLoading={props.setLoading} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                    </div>


                </div>
                
                <button style={{'display': 'none'}} type="button" data-bs-toggle="modal" data-bs-target="#transactionHistoryModal" ref={manageModal} className="btn btn-add w-100 mb-2 me-2">View Receipt</button>
                <div id="transactionHistoryModal" className="modal fade" tabIndex="-1" aria-labelledby="transactionHistoryModalLabel" aria-hidden="true" style={{'display': 'none'}}> 
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Transaction Details</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                { 
                                (modalFlag === 1) ? 
                                    <>
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="flex-grow-1">
                                                <p className="fw-medium text-muted mb-0 number">
                                                    Transaction ID: {MPaymentId}<br></br>
                                                    Transaction Date: {moment.unix(MCreatedDate).format('MMM Do YYYY, hh:mm A') } 
                                                    
                                                </p>
                                                </div>
                                                <div>
                                                <h4 className="fs-20 fw-semibold ff-secondary">
                                                    <span className="badge completed p-2 text-capitalize">Success</span>
                                                </h4>
                                                </div>
                                            </div> 
                                            <div className="d-flex align-items-center pt-2 mb-2 border-top">
                                                <div className="flex-grow-1">
                                                    <p className="text-uppercase fw-semibold text-muted mb-0 number ">Assigment Name</p>
                                                </div>
                                                <div>
                                                    <p className="fw-medium ff-secondary mb-0">{MTitle}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2 border-top">
                                                <div className="flex-grow-1">
                                                    <p className="text-uppercase  fw-semibold text-muted mb-0 number ">Amount</p>
                                                    <p className="text-uppercase  fw-semibold text-muted mb-0 number ">Transaction Fee ({MTFeeP}%)</p>
                                                </div>
                                                <div>
                                                    <p className="fw-medium ff-secondary mb-0 text-end">${parseFloat(MTAmount).toFixed(2)}</p>
                                                    <p className="fw-medium ff-secondary mb-0 text-end">-${parseFloat(MTFee).toFixed(2)}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center pt-2 border-top">
                                                <div className="flex-grow-1">
                                                    <h5 className="text-uppercase  fw-semibold text-muted mb-0 number ">Total Amount</h5>
                                                </div>
                                                <div>
                                                    <h4 className="fs-20 fw-medium ff-secondary">${parseFloat(MAmount).toFixed(2)}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    </> 
                                : 
                                    <>
                                    Sorry! We are unable to find more details.
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Index;
