import React, { useState, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function generateInvoicePdf(TransData, logo) {
    const pdfContent = {
        content: [
            {
                layout: 'noBorders',
                fontSize: 11,
                table:{
                    widths: ['100%'],
                    body:[
                        [
                            (logo !== "") ? {image: logo, width: 100, style: 'header', margin:[0,0,0,25]} : null
                        ],
                        [
                            (TransData.taxInfo && Object.keys(TransData.taxInfo).length > 0) ? {text:'Business Address: '+TransData.taxInfo.streetAddress+', '+TransData.taxInfo.city+', '+TransData.taxInfo.state+', '+TransData.taxInfo.country,margin:[5,4,0,0]} : null 
                        ],
                        [
                            (TransData.taxInfo && Object.keys(TransData.taxInfo).length > 0) ? {text:'Tax ID Number: '+TransData.taxInfo.taxIdNumber, margin:[5,0,0,5]} : null 
                        ],
                        [
                            {text:'Payout ID: '+TransData.payout_id, margin:[5,0,0,0]}
                        ]
                    ]
                }
            },
            {
                fontSize: 11,
                table:{
                    widths: ['50%', '50%'],
                    body:[
                        [
                            {text:'Payout Date: '+moment.unix(TransData.arrival_date).format('MMM Do YYYY, hh:mm A'),  border:[false,false,false,true], margin:[0,0,0,5]},
                            {text:'Paid', alignment:'right', border:[false,false,false,true],margin:[0,0,0,5]}
                        ],
                        [
                            {text:'ACCOUNT HOLDER NAME', border:[false,false,false,true], margin:[0,5,0,5]},
                            {text:TransData.account_holder_name, alignment:'right', border:[false,false,false,true],margin:[0,5,0,5]}
                        ],
                        [
                            {text:'ROUTING NUMBER', border:[false,false,false,false], margin:[0,5,0,0]},
                            {text:TransData.routing_number, alignment:'right', border:[false,false,false,false], margin:[0,5,0,0]}
                        ],
                        [
                            {text:'LAST 4 DIGITS', border:[false,false,false,true], margin:[0,0,0,5]},
                            {text:TransData.last4, alignment:'right', border:[false,false,false,true],margin:[0,0,0,5]}
                        ],
                        [
                            {text:'TOTAL', border:[false,false,false,false], margin:[0,0,0,10]},
                            {text:'$'+parseFloat(TransData.amount).toFixed(2), alignment:'right', border:[false,false,false,false],margin:[0,0,0,10]}
                        ]
                    ]
                }
            }
        ],
        styles: {
            header: {
                alignment: 'center'
            }
        }
    };

    return pdfContent;
}
export default generateInvoicePdf;