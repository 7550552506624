import React, { useRef, useState, useEffect } from 'react'
import { Outlet, useOutletContext, useLocation, useBeforeUnload, Link  } from "react-router-dom";
import { useAuth } from '../../hooks/auth';
import { decodeToken } from "react-jwt";
import Cookies from 'js-cookie';
import axios from 'axios';

const PDFLayout = () => {

    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);
    const filePath = process.env.REACT_APP_BACKENDURL + 'images/';
    return (
        <>
            <div id="pdf-main-wrapper">
                <header id="pdf-topbar">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <div>
                                    <a href="index.html" className="pdf-logo">
                                        <span >
                                            <img src={`/assets/images/das-logo.png`} alt="" height="45" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <Outlet />

                {/* <footer className="footer-pdf">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                © Housekeeper.</div>
                            <div className="col-sm-6">
                                <div className="text-sm-end d-none d-sm-block">Design &amp; Develop by Soften</div>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </div>
        </>
    )
}

export default PDFLayout;
