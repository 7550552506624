import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const Withdrawal = (props) => {
  const token = Cookies.get('token');

  const [amount, setAmount] = useState("0.00");

  useEffect(() => {
    fetchStripeAccountBalance();
  }, [])

  // Fetch stripe account balance
  const fetchStripeAccountBalance = async () => {
    const { data } = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/fetch-stripe-account-balance',{
        headers: {
            'Content-Type': 'application/json',
            'token': token
        }
    });
    if(data.status === 1){
      setAmount(data.total_balance);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-7">
          <div className="p-2 shadow-none mb-2">
            <h5 className="text-uppercase">Pending payout balance: ${parseFloat(amount/100).toFixed(2)}</h5>
          </div>
        </div>
      </div>
    </>
  )
}

export default Withdrawal;