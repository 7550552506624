import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import { HostContext } from '../../../Contexts/HostContext';
import moment from 'moment';

function Calendar(props) {

    const token = Cookies.get('token');
    const [ events, setEvents ] = useState([]);
    const [ assignments, setassignments ] = useState([]);
    const [ upcomingAssignments, setUpcomingsAssignments ] = useState([]);
    const {decoded} = useContext(HostContext);
    const hostId = decoded.user_id; 

    const handleDateClick = async(e) => {
        props.setLoading(true)
        let currentDate = (new Date().toISOString().split('T')[0]);
        if(typeof e !== 'undefined') {
            if(e.dateStr !== undefined) {
                currentDate = e.dateStr;
            } else {
                currentDate = e.event.startStr;
            }
        }

        const {data} = await axios.get(process.env.REACT_APP_BACKENDURL+'api/host/assignment/calendar-assignments', 
        {
            params: {
                // date : (typeof e !== 'undefined') ? e.dateStr : (new Date().toISOString().split('T')[0])
                date : currentDate
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log('Something went wrong');
        });

        if(data.status === 1){
            setassignments(data.data);
        }
        props.setLoading(false);
    }

    const fetchAssessmentCount = async(e) => {
        props.setLoading(true);
        const {data}  = await axios.get(process.env.REACT_APP_BACKENDURL+'api/host/assignment/count-by-date', 
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log('Something went wrong');
        });

        if(data.status === 1){
            setEvents(data.data)
        }
        props.setLoading(false);
    };
    
    // start 
    const fetchUpcomingAssignments = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACKENDURL + 'api/host/get-assignment', {
                params: {
                    limit: 4,
                    hostId: props.hostId,
                    sortBy: 'asc', // Sort by ascending order of dates
                    assignmentType: "upcoming"
                },
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
            .catch(function (error) {
                console.log('Something went wrong');
            });
    
            if (data.status === 1) {
                // Sort the assignments by date before setting the state
                const sortedAssignments = data.data.sort((a, b) => new Date(a.date) - new Date(b.date));
                setUpcomingsAssignments(sortedAssignments);
            }
        } catch (error) {
            console.error('Error fetching upcoming assignments:', error);
        }
    };
// end 

    useEffect(() => {
        fetchAssessmentCount();
        fetchUpcomingAssignments();
        handleDateClick();
    }, []);

    return (
        <div className="page-content">
            <style>
                {`
                .fc-event { /* Target event elements */
                    cursor: pointer !important; /* Set cursor to pointer */
                }

                .fc-day-top { /* Target day elements */
                    cursor: default !important; /* Set cursor to default */
                }
                `}
            </style>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Calendar</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Calendar</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body pt-4">
                                <div className="row g-0">
                                    <div className="col" id='task-calander'>
                                            <FullCalendar
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                                                headerToolbar={{
                                                    left: 'prev,next',
                                                    center: 'title',
                                                    // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                                    right: 'dayGridMonth'
                                                  }}
                                                initialView='dayGridMonth'
                                                weekends={true}
                                                buttonIcons={{
                                                    prev: '',
                                                    next: '',
                                                  }}
                                                events={events}
                                                selectable={true}
                                                dateClick={handleDateClick}
                                                eventClick={handleDateClick}
                                                // select={(selectionInfo) => {
                                                //     console.log('Selected dates:', selectionInfo.startStr);
                                                // }}
                                            />
                                            
                                       
                                    </div>
                                    <div className="col" id='latest-task' >
                                        <div className='d-flex justify-content-end'>
                                            <Link to={'/host/assignment/list'} 
                                                className='btn btn-sm btn-outline-list list-btn fs-15 waves-effect waves-light'>View all assignment</Link>
                                        </div>
                                        { (assignments.length > 0) ?
                                            <div className="mt-3 mt-md-0 py-2 px-3">
                                                {assignments.map((item, i) => {
                                                    return ( 
                                                        <div  key={i} className="row border-bottom mt-4">
                                                            <div className="col-md-12">
                                                                <div className="d-flex gap-1 align-items-basline">
                                                                    { item.propertyDetail[0].images.length > 0 ?
                                                                        <img src={process.env.REACT_APP_BACKENDURL+'images/'+ item.propertyDetail[0].images[0].filename} alt="" className="rounded avatar-md me-2 img-thumbnail" />

                                                                        :<></>
                                                                    }
                                                                    <div className="form-check mb-2 p-0 w-100">
                                                                        <label className="form-check-label w-100" htmlFor="formCheck1">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <Link to={'/host/assignment/detail/'+ item._id} className="check-title fs-16 ">{item.title}</Link>
                                                                                <div>
                                                                                <span className="number working-date">
                                                                                    <i className="bx bx-calendar me-1"></i>{moment(item.date).format('MMM Do YYYY') }
                                                                                </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex align-items-end justify-content-between">
                                                                                <div>
                                                                                    {(item.status === 5) && <p className="complet-date number mt-1 mb-1 text-danger">Cancelled</p>}
                                                                                    {(item.status === 4) && <p className="complet-date number mt-1 mb-1 text-success">Completed</p>}
                                                                                    {(item.status === 3) && <p className="complet-date number mt-1 mb-1 text-warning">Running</p>}
                                                                                    {(item.status < 3) && <p className="complet-date number mt-1 mb-1 text-danger">Pending</p>}
                                                                                    <p className="number mb-2">{item.propertyDetail[0].streetAddress}</p>
                                                                                </div>
                                                                                <div>
                                                                                    {/* Start and end Time */}
                                                                                    <span className="number working-date">
                                                                                        <i className="bx bx-alarm me-1"></i>
                                                                                        <span className="fs-12">{moment(item.startTime).format('hh:mm A') +"-"+ moment(item.endTime).format('hh:mm A') }</span>
                                                                                    </span>

                                                                                    <div className="d-flex align-items-end justify-content-end gap-3">
                                                                                        <h5 className="number mb-0">${item.price}</h5>
                                                                                        {(item.status !== 0 && item.cleanerProfile !== undefined) ?
                                                                                            <Link to={'/host/cleaner-detail/'+item.cleanerProfile?.userId} 
                                                                                                    target="_blank"
                                                                                                    title={item.cleanerDetail?.first_name+" "+item.cleanerDetail?.last_name}>
                                                                                                {(item.cleanerProfile?.image && Object.keys(item.cleanerProfile.image).length > 0) ?
                                                                                                    <img src={process.env.REACT_APP_BACKENDURL+'images/'+item.cleanerProfile.image.filename} 
                                                                                                        alt="cleanerImage" 
                                                                                                        className="rounded-circle avatar-xs" />
                                                                                                        :
                                                                                                        <img src="/assets/images/image-placeholder.png" 
                                                                                                        alt="cleanerImage" 
                                                                                                        className="rounded-circle avatar-xs" />
                                                                                                }
                                                                                            </Link>
                                                                                            :
                                                                                            <></>
                                                                                        }
                                                                                        
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        :
                                            <div className="row"><div className="col-xl-12 mt-3 ms-2 me-2"><div className="alert alert-danger" role="alert">No assignment yet.</div></div></div>
                                        }


                                        {/* start upcoming assign  */}
                                        <div>
                                            {(assignments.length < 4 && upcomingAssignments.length > 0) ? (
                                                <>
                                                    <div className='d-flex justify-content-end'>
                                                        <Link to={'/host/assignment/list/upcoming'} className='btn btn-sm btn-outline-list list-btn fs-15 waves-effect waves-light'>Upcoming assignment</Link>
                                                    </div>
                                                    <div className="mt-3 mt-md-0 py-2 px-3">
                                                        {upcomingAssignments.map((item, i) => {
                                                            if (i < (4 - assignments.length)) {
                                                                // Render upcoming assignment details here
                                                                return (
                                                                    <div key={i} className="row border-bottom mt-4">
                                                                        <div className="col-md-12">
                                                                            <div className="d-flex gap-1">
                                                                                {item.propertyDetail && item.propertyDetail.image && Object.keys(item.propertyDetail.image).length > 0 ? (
                                                                                    <img src={process.env.REACT_APP_BACKENDURL + 'images/' + item.propertyDetail.image.filename} alt="" className="rounded avatar-md me-2 img-thumbnail" />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <div className="form-check mb-2 p-0 w-100">
                                                                                    <label className="form-check-label w-100" htmlFor="formCheck1">
                                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                                            <Link to={'/host/assignment/detail/' + item._id} className="check-title fs-16 ">{item.title}</Link>
                                                                                            <div>
                                                                                                <span className="number working-date">
                                                                                                    <i className="bx bx-calendar me-1"></i>{moment(item.date).format('MMM Do YYYY')}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-end justify-content-between">
                                                                                            <div>
                                                                                                {(item.status === 5) && <p className="complet-date number mt-1 mb-1 text-danger">Cancelled</p>}
                                                                                                {(item.status === 4) && <p className="complet-date number mt-1 mb-1 text-success">Completed</p>}
                                                                                                {(item.status === 3) && <p className="complet-date number mt-1 mb-1 text-warning">Running</p>}
                                                                                                {(item.status < 3) && <p className="complet-date number mt-1 mb-1 text-danger">Pending</p>}
                                                                                                <p className="number mb-2">{item.propertyDetail && item.propertyDetail.fullAddress ? item.propertyDetail.fullAddress : ''}</p>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span className="number working-date">
                                                                                                    <i className="bx bx-alarm me-1"></i>
                                                                                                    <span className="fs-12">{moment(item.startTime).format('hh:mm A')} - {moment(item.endTime).format('hh:mm A')}</span>
                                                                                                </span>

                                                                                                <div className="d-flex align-items-end justify-content-end gap-3">
                                                                                                    <h5 className="number mb-0">${item.price}</h5>
                                                                                                    {item.status !== 0 ? (
                                                                                                        <Link to={'/host/cleaner-detail/' + item.cleanerProfile?.userId} target="_blank" title={item.cleanerDetail?.first_name + " " + item.cleanerDetail?.last_name}>
                                                                                                            {(item.cleanerProfile?.image && Object.keys(item.cleanerProfile.image).length > 0) ? (
                                                                                                                <img src={process.env.REACT_APP_BACKENDURL + 'images/' + item.cleanerProfile.image.filename} alt="cleanerImage" className="rounded-circle avatar-xs" />
                                                                                                            ) : (
                                                                                                                <img src="/assets/images/image-placeholder.png" alt="cleanerImage" className="rounded-circle avatar-xs" />
                                                                                                            )}
                                                                                                        </Link>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                </>
                                            ) : <></>}
                                        </div>
                                        {/* end upcoming assign  */}

                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}


export default Calendar