import React, { createContext } from 'react';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';

export const HostContext = createContext();

const PageContextProvider = (props) => {
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = (token !== undefined) ? decodeToken(token) : '';
    return (
        <HostContext.Provider value={{ decoded: decoded, token: token}}>
        	{props.children}
        </HostContext.Provider>
    );
}
export default PageContextProvider;
