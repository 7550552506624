import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import moment from 'moment';
import helper from '../../../helper/index';

function CleanerDetail() {  
    const params = useParams();
    const token = Cookies.get('token');
    const hostId = (params.hostId) ? params.hostId : '';
    const [cDetail, setHostDetail] = React.useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number:"",
        total_rating: 0,
        rating_count: 0,
        reviews: [],
        experience: "",
        languages: "",
        address: {},
        image: "",
        bio: ""
    });

    useEffect(() => {
        fetchHostDetail();
    }, []);


    // Fetch Host Details
    const fetchHostDetail = async () => {
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL + 'api/cleaner/host-detail', {
            params: {
                id: hostId.trim(),
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log('Something went wrong');
        });
      
        if (data.status === 1) {
            setExistingState(data.data)
        } else {
            //navigate('/host-list');
        }
    };

    const setExistingState = (data) => {
        cDetail['first_name'] = data.first_name
        cDetail['last_name'] = data.last_name
        cDetail['email'] = data.email
        cDetail['phone_number'] = data.phone_number
        cDetail['total_rating'] = data.total_rating
        cDetail['rating_count'] = data.rating_count
        cDetail['reviews'] = data.reviews
        cDetail['experience'] = data.experience
        cDetail['languages'] = data.languages
        cDetail['address'] = data.address
        cDetail['image'] = data.image
        cDetail['bio'] = data.bio
        cDetail['isEmailVerify'] = data.is_email_verify
        cDetail['isPhoneVerify'] = data.is_phone_verify
        cDetail['school'] = data.school
        setHostDetail({...cDetail})
    }


  return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Host Detail</h4>

                            <div className="page-title-right">

                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Host Detail</li>
                                </ol>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">

                        <div className="card">
                            <div className="card-body pt-4">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6 text-center cleaner-profile">

                                                            {
                                                                (cDetail.image) ?
                                                                    <img src={cDetail.image} alt="profileImage" className="img-thumbnail rounded-circle avatar-xl" />
                                                                : 
                                                                    <img src="/assets/images/image-placeholder.png" alt="profileImage" className="img-thumbnail rounded-circle avatar-xl" />
                                                            }

                                                            <h2 className="mb-0">{cDetail.first_name + " " + cDetail.last_name}</h2>
                                                        </div>
                                                        <div className="col-6 cleaner-details">
                                                            <div className="cleaner-info">
                                                                <h3 className="mb-0 number">{cDetail.rating_count}</h3>
                                                                <h6>Reviews</h6>
                                                            </div>
                                                            <div className="cleaner-info">
                                                                {(cDetail.total_rating > 0) ?
                                                                    <> 
                                                                        <h3 className="mb-0 number mt-2">{helper.roundNumber(cDetail.total_rating)} <i className="ri-star-fill text-warning align-bottom"></i></h3>
                                                                        <div className='pb-1 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                                    </>
                                                                :
                                                                    <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                                }
                                                                <h6>Rating</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body verifation-card">
                                                    <h3>{cDetail?.first_name + " " + cDetail.last_name} Confirmed Information</h3>
                                                    <ul className="mt-1 mb-0">
                                                        <li className="d-flex align-items-center gap-2">
                                                            <img src="/assets/images/icons/check-circle-verified.png" alt='verified' style={{'height': '16px'}} /> 
                                                            <p className="mb-0">Identity</p>
                                                        </li>
                                                        <li className="d-flex align-items-center gap-2">
                                                            <span className="tick-icon">
                                                                {
                                                                    (cDetail.isEmailVerify === 1) ?
                                                                    <img src="/assets/images/icons/check-circle-verified.png" alt='verified' style={{'height': '16px'}} /> 
                                                                    :
                                                                    <img src="/assets/images/icons/check-circle-unverified.png" alt='unverified' style={{'height': '16px'}} /> 
                                                                }
                                                            </span>
                                                            <p className="mb-0">Email address</p>
                                                        </li>
                                                        <li className="d-flex align-items-center gap-2">
                                                            <span className="tick-icon">
                                                                {
                                                                    (cDetail.isPhoneVerify === 1) ?
                                                                    <img src="/assets/images/icons/check-circle-verified.png" alt='verified' style={{'height': '16px'}} /> 
                                                                    :
                                                                    <img src="/assets/images/icons/check-circle-unverified.png" alt='unverified' style={{'height': '16px'}} /> 
                                                                }
                                                            </span>
                                                            <p className="mb-0">Phone number</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 more-detail">
                                        <h2>About {cDetail?.first_name + " " + cDetail.last_name}</h2>
                                        <div className="row my-2 cleaner-loc">
                                            <div className="col-6 d-flex align-items-center gap-2">
                                                <i className="ri-user-line"></i>
                                                <p className="mb-0">{cDetail.bio}</p>
                                            </div>
                                            
                                            <div className="col-6 d-flex align-items-center gap-2">
                                                <i className=" ri-earth-fill "></i>
                                                <p className="mb-0">{cDetail.languages}</p>
                                            </div>
                                            {/* { ( typeof cDetail.address.fullAddress !== "undefined") ?
                                                <div className="col-6 d-flex align-items-center gap-2">
                                                    <i className="ri-map-pin-3-fill"></i>
                                                    <p className="mb-0">{cDetail.address.fullAddress}</p>
                                                </div>
                                                :<></>
                                            } */}
                                            {/* <div className="col-6 d-flex align-items-center gap-2">
                                                <i className="ri-mail-fill"></i>
                                                <p className="mb-0">{cDetail.email}</p>
                                            </div>
                                            <div className="col-6 d-flex align-items-center gap-2">
                                                <i className=" ri-phone-fill "></i>
                                                <p className="mb-0 number">+1 {cDetail.phone_number}</p>
                                            </div> */}

                                            {(typeof cDetail.school !== "undefined" && cDetail.school !== "") &&
                                                <div className="col-6 d-flex align-items-center gap-2">
                                                    <i className="bx bxs-graduation"></i>
                                                    <p className="mb-0">{cDetail.school}</p>
                                                </div>
                                            }

                                        </div>
                                        {(cDetail.reviews.length > 0) ?
                                            <>
                                                <h4>{cDetail?.first_name + " " + cDetail.last_name} reviews</h4>
                                                <div className="swiper project-swiper navigation-swiper">

                                            
                                                    <Swiper
                                                        style={{
                                                            '--swiper-navigation-color': '#fff',
                                                            '--swiper-pagination-color': '#fff',
                                                        }}
                                                        navigation={false}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        keyboard={true}
                                                        slidesPerView={2}
                                                        modules={[FreeMode, Navigation, Pagination]}
                                                        className="mySwiperNavigation"
                                                    >
                                                        {cDetail.reviews.map((item, i) => {
                                                            return (
                                                                <SwiperSlide key={i} style={{ height: '264px' }}>
                                                                    <div key={i} className="card profile-project-card shadow-none profile-project-success mb-0" style={{ height: '230px' }}>
                                                                        <div className="card-body p-4">
                                                                            <div className="hstack gap-1 flex-wrap rating-icon mb-2">
                                                                                {[...Array(5)].map((star, index) => {
                                                                                    index += 1;
                                                                                    return (
                                                                                        <i key={index} className={ 'bx fs-16 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                            <div className='pb-1' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1 text-muted overflow-hidden">

                                                                                    <p className="text-muted mb-0">{item.comment}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="d-flex align-items-start gap-2">
                                                                                        <div className="avatar-group">
                                                                                            <div className="avatar-group-item">
                                                                                                <div className="avatar-xs">
                                                                                                    {item.cleanerProfile?.image ?
                                                                                                    <img src={process.env.REACT_APP_BACKENDURL+'images/'+item.cleanerProfile?.image.filename} 
                                                                                                        alt="cleanerProfileImage"
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                        :
                                                                                                    <img src="/assets/images/users/avatar-4.jpg" 
                                                                                                        alt="cleanerProfileImage"
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h5 className="fs-12 text-muted mb-0">{item.cleanerDetail?.first_name + " " + item.cleanerDetail.last_name}</h5>
                                                                                            <p><span className="number"> {moment(item.createdAt).format('MMM Do YYYY') }</span></p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </SwiperSlide>
                                                            );
                                                        })}
                                                    </Swiper>
                                            
                                        

                                                </div>

                                                <Link to={ "/cleaner/host-reviews/" + hostId} className="btn btn-add  waves-effect waves-light mt-4 number">Show all reviews</Link>
                                            </>  
                                            : <></>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
  )
}

export default CleanerDetail