import { Outlet, Navigate  } from 'react-router-dom';
import { useAuth } from '../auth';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';

export const ProtectRoutes = ({module, name}) => {

    var is_login = 0;
    var uselevel = 0;
    var is_verify = 0
    var pathName = "";
    
    const token = Cookies.get('token');
    if(token){
        // get user data from token in cookie
        const decoded = decodeToken(token);
        uselevel = decoded.userlevel;
        if(decoded.isLoginVerify === 1){
            is_verify = 1;
        }
        if((decoded.userlevel === 2 ||  decoded.userlevel === 3)){
            is_login = 1;
            if(decoded.userlevel === 2){
                pathName = "/host"
            }else{
                pathName = "/cleaner"
            }
        }

    }
    
    return (is_login === 1 && is_verify === 1) ?
                ((module === uselevel) ? <Outlet/> : <Navigate to={pathName+'/dashboard'} exact />)
            : ((is_login === 0)) ? <Navigate to='/login' exact /> : <Navigate to='/verify/otp' exact />
};