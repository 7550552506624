import React from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { decodeToken } from 'react-jwt';

const Insight = (props) => {
    const token = Cookies.get('token');
    const decoded = decodeToken(token)
    const cleanerId = decoded.user_id
  return (
    <div className="page-content">
        <div className="container-fluid">

            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Insight</h4>
                <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                    <Link to="/cleaner/dashboard">Overview</Link>
                    </li>
                    <li className="breadcrumb-item active">
                    Insight
                    </li>
                </ol>
                </div>
            </div>
            
              <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-start flex-column ">
                                <h4 className="card-title mb-0 flex-grow-1 ">Insights</h4>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row setting-list mt-4">
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/perfomace"}>
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-bar-chart-grouped-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1">
                                                <span className="text-prime">Performance</span>
                                            </h4>
                                            <p className="disc mb-0"> Track and view your performance.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/customer"}>
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-user-2-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1">
                                                <span className="text-prime">Customers</span>
                                            </h4>
                                            <p className="disc mb-0"> View your previous customer data.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/project"}>
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-calendar-line"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1">
                                                <span className="text-prime">Assignment</span>
                                            </h4>
                                            <p className="disc mb-0"> Assignment history and insights.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={"/cleaner/insight/earning"}>
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-wallet-2-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1">
                                                <span className="text-prime">Payouts</span>
                                            </h4>
                                            <p className="disc mb-0"> View payout summary.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2">
                                    <Link to={'/cleaner/my-reviews'} state={{ fromPage: 'insight' }}>
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-edit-box-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1">
                                                <span className="text-prime">Reviews</span>
                                            </h4>
                                            <p className="disc mb-0"> Review and feedback history.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-xxl-4 col-md-4 p-2 ">
                                    <Link to={"/cleaner/payments-and-transactions?active-tab=payouts"} state={{ fromPage: 'insight' }}>
                                        <div className="card p-lg-3 assigment-card mb-0 card-animate">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="image-box d-flex align-items-center gap-3">
                                                    <div className="autr-img">
                                                        <i className="ri-hand-coin-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="name pt-1 mb-1">
                                                <span className="text-prime">Transaction History </span>
                                            </h4>
                                            <p className="disc mb-0"> Track transactions and more.</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>    
  )
}

export default Insight;
