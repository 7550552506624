import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

const FeedbackList = () => {
    const token = Cookies.get('token');
    const fullurl =  window.location.href;
    const segment = fullurl.split("/"); 
    let role_id = 0;
    let role_name = '';
    if(segment[3] !== "undefined" && segment[3] === "host"){
        role_id = 2;
        role_name = "host";
    }else if(segment[3] !== "undefined" && segment[3] === "cleaner"){
        role_id = 3;
        role_name = "cleaner";
    }

    const [data, setData] = useState([]);
    const [taxData, setTaxData] = useState({});
    const [allowAdd, setAllowAdd] = useState(true);
    
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        axios.get(process.env.REACT_APP_BACKENDURL + 'api/common/fetch-tax-info', {
            params: {
                role_id: role_id,
            },
            headers: { 'Content-Type': 'application/json', 'token': token }
        })
        .then(function (response) {
            if (response.data.status === 1) {
                if(response.data.data.length > 0){
                    setTaxData(response.data.data[0]);
                    setAllowAdd(false);
                }

                setData(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Taxes</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to={`/${role_name}/dashboard`}>Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={`/${role_name}/settings`}>Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Taxes</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className='text-end'>
                            {allowAdd === true &&
                                <button className="btn btn-add add-btn mb-3">
                                    <Link to={`/${role_name}/add-taxes`} style={{color:"white"}}>Add Tax Info</Link>
                                </button>
                            }
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <div className="live-preview">
                                    {(data.length > 0) ?
                                        <>
                                            <div className="table-responsive mb-3">
                                                <table className="table table-striped table-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Tax ID Number</th>
                                                            <th scope="col">Country/Region</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i+1}</td>
                                                                    <td> {item.userDetail.name} </td>
                                                                    <td> {item.taxIdNumber} </td>
                                                                    <td> {item.country} </td>
                                                                    <td>
                                                                        <div className="d-flex gap-2">
                                                                            <div className="edit">
                                                                                <Link to={`/${role_name}/edit-tax`}>
                                                                                    <button type="button" className="btn btn-sm btn-primary edit-item-btn">Edit Details</button>
                                                                                </Link>
                                                                                
                                                                            </div>
                                                                            <div className='edit'>
                                                                                <button className="btn btn-sm btn-success mb-3" data-bs-toggle="modal" data-bs-target="#viewTaxInfo">
                                                                                    View Details
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h5>No tax information found</h5>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade zoomIn" id="viewTaxInfo" tabIndex="-1" aria-labelledby="viewTaxLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content border-0">
                            <div className="modal-header p-3 bg-soft-info">
                                <h5 className="modal-title" id="viewTaxLabel">Tax Information</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    id="close-modal"></button>
                            </div>
                            
                            <div className="modal-body">
                                <div className="row g-3">
                                    <div className="col-lg-12">
                                        <div> <h3> {taxData?.userDetail?.name} </h3></div>
                                        <div> <h4>Form W-9</h4> </div>
                                        <hr className='m-0' />
                                    </div>
                                    <div className="col-lg-12">
                                        <div>
                                            <label className="form-label"><strong>Last Updated</strong></label>
                                            <p> {moment(taxData?.updatedAt).format('MMM D, YYYY')} </p>
                                        </div>
                                        <div>
                                            <label className="form-label"><strong> Country/Region </strong></label>
                                            <p>{taxData?.country}</p>
                                        </div>
                                        <div>
                                            <label className="form-label"><strong> Tax Classification </strong></label>
                                            <p>{taxData?.taxClassification}</p>
                                        </div>
                                        <div>
                                            <label className="form-label"><strong> Tax ID Number </strong></label>
                                            <p>{taxData?.taxIdNumber}</p>
                                        </div>
                                        <div>
                                            <label className="form-label"><strong> Address </strong></label>
                                            <p>{taxData?.fullAddress}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackList;
