import React  from 'react'
import Tabs from './component/Tabs';
import { Link, useParams } from 'react-router-dom';
const Home = (props) => {
    const params  = useParams();
    const step = (params.step) ? params.step : 'running';
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Assignment Listing</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/host/dashboard">Overview</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Assignment Listing</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <div className="row g-3 mb-3">

                        <div className="col-md-4">
                            <input type="text" className="form-control" 
                                name="searchText"
                                onChange={(e) => handleFilters('searchText', e.target.value)}
                                value={filterRecords.searchText}
                                placeholder="Search for something" />
                        </div>

                        <div className="col-md-4">
                            <div className="input-light">
                                <select className="form-control" 
                                    name="propertyId" 
                                    onChange={(e) => handleFilters('propertyId', e.target.value)}>
                                    <option value="">Filter by property</option>
                                    {properties.map((item) => {
                                        return(
                                            <option value={item._id}>{item.propertyName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="input-light">
                                <select className="form-control" name="sortBy" 
                                    value={filterRecords.sortBy}
                                    onChange={(e) => handleFilters('sortBy', e.target.value)}>
                                    <option value="">Sort by</option>
                                    <option value="asc" >Ascending</option>
                                    <option value="desc" >Descending </option>
                                </select>
                            </div>
                        </div>

                    </div> */}


                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-header align-items-center">
                                <div className='align-items-center float-start ms-4'>
                                    <h4 className="card-title mb-0 ">Assignments</h4>
                                </div>
                                <div className='align-items-center float-end me-4'>
                                    <Link to="/host/checklist" className="btn btn-add list-btn waves-effect waves-light me-2">Manage Checklist</Link>
                                    <Link to="/host/assignment/add" className="btn btn-add list-btn waves-effect waves-light">Create New  Assignments</Link>
                                </div>
                            </div>

                            <div className="card-body">
                                <Tabs setLoading={props.setLoading} step={step}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        
        </>
    )
}

export default Home;
