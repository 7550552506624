/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { HostContext } from '../../../Contexts/HostContext';
import Swal from 'sweetalert2';
import helper from '../../../helper';
let limit = 10;

const ClenerList = () => {
    const {token} = useContext(HostContext)
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [filterRecords, setFilterRecords] = useState({
        sortBy: "desc",
        search_string: "",
        status: 1
    });

    useEffect(() => {
        getData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRecords]);

    const getData = (datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'api/host/get-host-cleaners',{
            params: {
                page: pageNo,
                limit: limit,
                sortBy: filterRecords.sortBy,
                search_string: filterRecords.search_string,
                status: filterRecords.status
            },
            headers: {'Content-Type': 'application/json', 'token': token}
        }
        )
        .then(function (response) {
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

    const handleRemovePartner = (ev) => {
        // ev.preventDefault();
        let id = ev.target.value;
        Swal.fire({
            text: 'Are you sure you want to remove this partner?',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Remove',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                /**
                 * Remove cleaner ID
                 * */ 
                axios.post(
                            process.env.REACT_APP_BACKENDURL+'api/host/remove-partner',
                            { id: id },
                            { headers: {'Content-Type': 'application/json', 'token': token} }
                        )
                .then(function (response) {
                    console.log('response', response);
                    if(response.data.status === 1){
                        helper.Toast().fire({
                            icon: 'success',
                            title: response.data.message
                        })
                        getData([])
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else if (result.isDenied) {
                
            }
        })
        
    }

  return (
    <div className="page-content">
                <div className="container-fluid">

                    
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Cleaning Partners</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/host/dashboard">Overview</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Cleaning Partners</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    

                    <div className="row">
                        <div className="col-xl-12">

                            {/* <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="text" className="form-control" 
                                                name="searchText"
                                                onChange={(e) => handleFilters('searchText', e.target.value)}
                                                value={filterRecords.searchText}
                                                placeholder="Search by name, email and phone number" />
                                        </div>
                                        <div className="col-md-3">
                                            <select 
                                                className="form-select"
                                                value={filterRecords.status}
                                                onChange={(e) => handleFilters('status', e.target.value)}
                                            >
                                                <option value="">Select Status</option>
                                                <option value={1}>Active</option>
                                                <option value={2}>Inactive</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select 
                                            className="form-select"
                                            value={filterRecords.sortBy}
                                            onChange={(e) => handleFilters('sortBy', e.target.value)}
                                            >
                                                <option value="">Sort By</option>
                                                <option value="desc">DESC</option>
                                                <option value="asc">ASC</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                <div className="gap-3 icon-btn mb-3 text-end">
                                    <Link to="/find/cleaners" className="btn btn-outline-list list-btn waves-effect waves-light">Add new Partner</Link>
                                </div>

                                <div className="accordion mb-4" id="default-accordion-example">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Filter cleaners by using search
                                        </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#default-accordion-example">
                                            <div className="accordion-body">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input type="text" className="form-control" name="search_string" placeholder="Search by name, email and phone number" onChange={(e) => handleFilters('search_string', e.target.value)} value={filterRecords.search_string}/>
                                                        </div>
                                                        <div className="col-3">
                                                            <select 
                                                                className="form-select"
                                                                value={filterRecords.status}
                                                                onChange={(e) => handleFilters('status', e.target.value)}
                                                                >
                                                                <option value="">Select Status</option>
                                                                <option value={1}>Active</option>
                                                                <option value={2}>Inactive</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-3">
                                                            <select 
                                                                className="form-select"
                                                                value={filterRecords.sortBy}
                                                                onChange={(e) => handleFilters('sortBy', e.target.value)}
                                                                >
                                                                <option value="">Sort By</option>
                                                                <option value="desc">Z to A</option>
                                                                <option value="asc">A to Z</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form> 
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            {(data.length > 0) ?
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive table-card data-table">
                                                <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                                    <thead className="">
                                                        <tr className="text-muted">
                                                            <th scope="col" style={{'width': '5%'}}></th>
                                                            <th scope="col" style={{'width': '35%'}}>Cleaner</th>
                                                            <th scope="col">Rating</th>
                                                            <th scope="col" style={{'width': '35%'}}>Description</th>
                                                            {/* <th scope="col" style={{'width': '12%'}}></th> */}
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                    {data.map((item, i) => { 
                                                        return ( 
                                                            <tr key={i} id={`row-${item._id}`}>
                                                            <td>
                                                                <button 
                                                                    type="button" 
                                                                    className="btn-close" 
                                                                    onClick={handleRemovePartner}
                                                                    value={item._id}
                                                                    aria-label="Close"></button>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2 align-items-center">
                                                                <Link to={'/host/cleaner-detail/'+item._id} 
                                                                    target="_blank"
                                                                    title={item.first_name+" "+item.last_name}>
                                                                    {
                                                                    (item.image) ?
                                                                        <img src={item.image} alt="profileImage" className="rounded-circle avatar-sm me-2" />
                                                                    : 
                                                                        <img src="/assets/images/image-placeholder.png" alt="profileImage" className="rounded-circle avatar-sm me-2" />
                                                                    }
                                                                </Link>
                                                                <div >
                                                                    <a href="#javascript: void(0);"
                                                                    className="text-body fw-medium">{item.first_name} {item.last_name}</a>
                                                                    {
                                                                        (typeof item.address.fullAddress !== "undefined") ?
                                                                            <p className="mb-0 pt-1 number">{item.address.fullAddress}</p>
                                                                        : <></>
                                                                    }
                                                                </div>

                                                                </div>
                                                                
                                                            </td>
                                                            <td className="rating-icon">
                                                                {(item.stars > 0) ?
                                                                    <>
                                                                        <div className="hstack gap-1 flex-wrap">
                                                                            {[...Array(5)].map((star, index) => {
                                                                                index += 1;
                                                                                return (
                                                                                    <i key={index} className={ 'bx fs-16 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        <div className='pb-1 text-muted' style={{ fontSize: '12px' }}>Rating</div>
                                                                    </>
                                                                    :
                                                                    <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                                }
                                                            </td>
                                                            <td> {item.description} </td>
                                                            {/* <td>
                                                                <Link to={'/host/messages/'+item.firebase_uid} className="btn btn-add waves-effect waves-light">Message</Link>
                                                            </td> */}

                                                        </tr>
                                                        )
                                                    })}  
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <ReactPaginate
                                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                        breakLabel="..."
                                        pageCount={PageCount}
                                        pageRangeDisplayed={3}
                                        onPageChange={getData}
                                        containerClassName={'pagination pagination-rounded mt-5 mb-5 justify-content-center'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        renderOnZeroPageCount={null}
                                        activeClassName={'active'}
                                    />
                                </>

                            : 
                                <div className="card">
                                    <div className="card-body">
                                        <div className="default-item">
                                            <img src="/assets/images/deafult-logo.png" alt=''/>
                                            <h6 className="default-dis py-2"> You don't have any partners.</h6>
                                            <Link to="/find/cleaners" className="btn btn-add waves-effect waves-light w-25">Add New Partner</Link>
                                        </div>
                                    </div>
                                </div> 
                            }
                            
                        </div>
                    </div>
                   
            </div>
    </div>
  )
}

export default ClenerList;
