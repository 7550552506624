import React,{ useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router";
import { Link, useParams } from 'react-router-dom';
import { db, auth } from '../../fireabse';
import moment from 'moment';
import axios from 'axios';
import Cookies from 'js-cookie';
import helper from '../../helper';
import Swal from 'sweetalert2';
import Popup from 'reactjs-popup';
import { decodeToken  } from "react-jwt";
import {
    addDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    where,
    or,
    and,
    getDocs,
    writeBatch,
    doc
} from "firebase/firestore";

const updateIsReadStatus = async (userId) => {
    const updateDoc = {
        isRead: true
    }
    const docQuery = query(collection(db, "chats"), where('senderId', '==', userId), where('receiverId', '==', auth.currentUser.uid), where('isRead', '==', false));
    getDocs(docQuery).then(response => {
        let batch = writeBatch(db);
        response.docs.forEach((document) => {
            const docRef = doc(db, 'chats', document.id);
            batch.update(docRef, updateDoc);
        })
        batch.commit();
    })
}

const ImagePopupModal = (props) => (
    props.type === 'video/mp4' ?
    <Popup trigger={<img src="/assets/images/placeholder/video.png" className="chat-document-icon" alt=""/>} modal nested>
        {close => (
            <>
            <div className="modal chat-modal mt-2">
                <div className="header text-start">
                    {props.name}
                    <button className="close float-end chat-modal-close-btn modal-close-btn" onClick={close}>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path className="fslightbox-svg-path cross-color" d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" ></path></svg>
                    </button>
                </div>
                <div className="content">
                    <video style={{width:"100%", height: "86vh"}} controls controlsList="nodownload">
                        <source src={props.src} type={props.type} />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            </>
        )}
    </Popup>
    :
    <Popup trigger={<img src={props.thumb} className="chat-document-icon" alt=""/>} modal nested>
        {close => (
            <>
            <div className="modal chat-modal mt-2">
                <div className="header text-start">
                    {props.name}
                    <button className="close float-end chat-modal-close-btn modal-close-btn" onClick={close}>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path className="fslightbox-svg-path cross-color" d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" ></path></svg>
                    </button>
                </div>
                <div className="content">
                    <img src={props.src} style={{width:"100%", height: "86vh"}} alt="popup"/>
                </div>
            </div>
            </>
        )}
    </Popup>
);

function UsersComponent(props) {
    const params = useParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const token = Cookies.get('token');
    const decoded = decodeToken(token);


    useEffect(() => {
        auth.onAuthStateChanged(function (user) {
            const chatId = params.id;
            if (chatId !== undefined) {
                setActiveIndex(chatId);
            }
        });
    }, []);

    const handleToggle = (username, userId, status, profileImg) => {
        props.setReceiverData({
            username: username,
            userId: userId,
            status: status,
            profileImg: profileImg
        });
        setActiveIndex(userId);
        if(decoded.userlevel === 2) {
            props.navigate(`/host/admin/messages/${userId}`);
        } else if(decoded.userlevel === 3) {
            props.navigate(`/cleaner/admin/messages/${userId}`);
        }
        updateIsReadStatus(userId);
    };
    
    let userList = props.users;
    if((props.searchItem).trim() !== "") {
        userList = props.filteredUsers;
    }

    userList?.sort((a, b) => b?.lastMessageTimestamp - a?.lastMessageTimestamp);
    const approvedUserIds = props.approvedUserIds;
    return (
        <ul className="list-unstyled chat-list chat-user-list" id="userList">
            {userList?.map((value, index) => {
                if ((props.currentUserId !== value.uid && (value.role_id === 1 || (props.lastChats[value.uid]?.createdTimestamp !== undefined && value.role_id === 4))) && approvedUserIds.includes(value.uid)) {
                    const profileData = (props.userProfile).find(userData => userData.fid === value.uid);
                    const time = props.lastChats[value.uid] ? moment(props.lastChats[value.uid].createdTimestamp).format('LT') : "";
                    const chatText = props.lastChats[value.uid] ? ((props.lastChats[value.uid].message !== undefined) ? props.lastChats[value.uid].message : "") : "";
                    let lastText = "";
                    let fileName = "";
                    if(chatText === "") {
                        const fileObj = props.lastChats[value.uid] ? (props.lastChats[value.uid].document ? props.lastChats[value.uid].document[0] : '') : '';
                        fileName = fileObj.originalName ? fileObj.originalName : (fileObj.name ? fileObj.name : '');
                    } else {
                        lastText = chatText.length > 34 ? chatText.substring(0,34)+'...' : chatText;
                    }

                    const profileImg = profileData?.image;
                    let contentStr = "";
                    const chatTotalCount = props.unreadChatCounts[value.uid] ? props.unreadChatCounts[value.uid] : 0;
                    if(chatTotalCount > 0) {
                        contentStr = <p className="text-muted fs-8 mb-0  number-chat text-right"> {chatTotalCount} </p>;
                    } else if(props.lastChats[value.uid] !== undefined && props.lastChats[value.uid].senderId === auth.currentUser.uid) {
                        if(props.lastChats[value.uid].isRead) {
                            contentStr = <i className="bx bx-check-double"></i>;
                        } else {
                            contentStr = <i className="bx bx-check"></i>;
                        }
                    }
                    return (
                        <li key={value.uid} className={activeIndex === value.uid ? 'active-user' : ''}>
                            <button onClick={() => {
                                handleToggle(value.name, value.uid, value.status, profileImg);
                            }} >
                                <div className="d-flex align-items-basline justify-content-between px-1 py-2">
                                    <div className="flex-grow-1">
                                        <div className="d-flex align-items-basline">
                                            <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-2 ms-0">
                                                <img src={profileImg} className="rounded-circle avatar-sm chat-profile" alt="" />
                                            </div>
                                            <div className="text-start flex-grow-1 overflow-hidden">
                                                <h5 className="text-truncate mb-0 fs-14 px-0">
                                                    <div className="text-reset username text-capitalize username-list">{`${value.name}`}</div>
                                                </h5>
                                                <p className="text-muted fs-10 mb-0 userStatus">
                                                    {
                                                        (lastText === "") ? ((fileName !== "") && <span><i className="ri-file-text-fill"></i> {fileName}</span>) : <span>{lastText}</span>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-0">
                                        <h6 className="text-truncate mb-0 fs-10 px-0 number text-right chat-time">
                                            { time }
                                        </h6>
                                        { contentStr }
                                    </div>
                                </div>
                            </button>
                        </li>
                    );
                }
            })}
        </ul>
    );
}

function Loading() {
    return (
        <div className="loading d-flex flex-column text-center">
            <i className="ri-loader-fill" style={{animation: "spin 2s linear infinite", border: "4px solid rgba(255, 255, 255, 0.3)", fontSize: 'large' }}></i>
        </div>
    );
}

const ChatIndex = () => {
    const params = useParams();
    const [approvedUserIds, setApprovedUserIds] = useState([]);
    const [users, setUsers] = useState([]);
    const [receiverData, setReceiverData] = useState(null);
    const [chatMessage, setChatMessage] = useState("");
    const [allMessages, setAllMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const fileInput = useRef(null);
    const [userChats, setUserChats] = useState([]);
    const [lastChats, setLastChats] = useState([]);
    const [unreadChatsCount, setUnreadChatsCount] = useState([]);
    const [searchItem, setSearchItem] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const user = auth.currentUser;
    const [userProfile, setUserProfile] = useState([]);
    const defaultProfile = '/assets/images/image-placeholder.png';
    const [authUserProfileImg, setAuthUserProfileImg] = useState(defaultProfile);
    const [authUserProfileName, setAuthUserProfileName] = useState("");
    const filePath = process.env.REACT_APP_BACKENDURL+'images/';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userlevel, setUserlevel] = useState('');
    const token = Cookies.get('token');
    const decoded = decodeToken(token);

    const UpdateReceiverData = (chatId) => {
        if (chatId !== undefined && receiverData?.userId !== chatId) {
            var userData = users.find(currentUser => currentUser.uid === chatId);
            var userProfileData = userProfile.find(user => user.fid === chatId);
            if(userData !== undefined) {
                let imagePath = userProfileData ? userProfileData.image : defaultProfile;
                setReceiverData({
                    username: userData.name,
                    userId: userData.uid,
                    status: userData.status,
                    profileImg: imagePath
                });
            }
        }
    }

    auth.onAuthStateChanged(function (user) {
        const chatId = params.id;
        if (chatId !== undefined) {
            UpdateReceiverData(chatId);
        }
    });

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }, 1500);
    }

    const prevDateArr = [];
    const isTodayOrYesterday = (timestamp) => {
        const todayDate = moment().format('LL');
        const yesterdayDate = moment().subtract(1, "day").format('LL');
        const givenDate = moment(timestamp).format('LL');
        let res = "";
        if (givenDate === todayDate) {
            res = "Today";
        } else if (givenDate === yesterdayDate) {
            res = "Yesterday";
        } else {
            res = givenDate;
        }
        if (!prevDateArr.includes(res)) {
            prevDateArr.push(res);
            return res;
        } else {
            return "";
        }
    };

    const setLastMessageTimestamp = () => {
        if(users.length > 0) {
            var newUsers = users.slice();
            newUsers.map((value, index) => {
                if(lastChats[value.uid] !==  undefined) {
                    value.lastMessageTimestamp = lastChats[value.uid].createdTimestamp ? lastChats[value.uid].createdTimestamp : 0;
                } else {
                    value.lastMessageTimestamp = 0;
                }
            });
            setUsers(newUsers);
        }
    }

    const userSearch = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
        const filteredItems = users.filter((user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filteredItems);
    }
    
    const fetchUserProfile = async() => {
        const token = Cookies.get('token');
        const decoded = decodeToken(token);
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-user-profiles',
                        {
                            params: {
                                "role_id": decoded.userlevel
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                'token': token
                            }
                        })
                        .catch(function (error) {
                            console.log('Something went wrong');
                        });

        if(data.status === 1){
            setUserProfile(data.profiles);
            const userIds = data.profiles.map((object) => object.fid);
            setApprovedUserIds(userIds);
            const auth_user = Cookies.get('auth_user');
            const profileData = (data.profiles).find(value => value.fid === auth_user);
            if(profileData !== undefined) {
                setAuthUserProfileName(profileData?.name);
                if(profileData !== undefined && (profileData?.image).length > 0) {
                    // setAuthUserProfileImg(filePath+profileData.image[0]);
                    setAuthUserProfileImg(profileData.image);
                }
            }
        }
    }

    useEffect(() => {
        fetchUserProfile();
    }, []);

    useEffect(() => {
        if (auth.currentUser !== null) {
            if(userProfile.length > 0) {
                setUserlevel(decoded.userlevel);
                const snapshotChatQuery = query(collection(db, "chats"));
                onSnapshot(snapshotChatQuery, (snapshot) => {
                    setUserChats(snapshot.docs.map((doc) => doc.data()));
                });

                const snapshotUsersQuery = query(collection(db, "users"), where("role_id", "in", [1,4]));
                onSnapshot(snapshotUsersQuery, (snapshot) => {
                    snapshot.docs.map((doc) => {
                        if(receiverData && receiverData.userId === doc.id) {
                            const status = doc.data()?.status ? doc.data()?.status : false;
                            setReceiverData((receiverData) => { 
                                return({ ...receiverData, status: status }); 
                            });
                        }
                    });
                    setUsers(snapshot.docs.map((doc) => doc.data()));
                    setLastMessageTimestamp();
                    const chatId = params.id;
                    if (chatId !== undefined) {
                        setReceiverData({});
                        UpdateReceiverData(chatId);
                    }
                });

                // fetchUserProfile();
            }
        } else if(auth && auth.currentUser === null) {
            helper.Toast().fire({ icon: 'error', title: "Due to some issue you can't use chat feature yet. Please contact the admin." }).then(() => {
                if(decoded.userlevel === 2) {
                    navigate(`/host/dashboard`);
                } else if(decoded.userlevel === 3) {
                    navigate(`/cleaner/dashboard`);
                }
            });
        }
    }, [userProfile]);

    useEffect(() => {
        let unreadChatCountArr = [];
        let lastText = [];
        userChats.map((value, index) => {
            if(value.isRead === false && value.receiverId === auth.currentUser.uid) {
                unreadChatCountArr[value.senderId] = 1 + (unreadChatCountArr[value.senderId] ? unreadChatCountArr[value.senderId] : 0);
            }
            setUnreadChatsCount(unreadChatCountArr);
            if(value.senderId === auth.currentUser.uid || value.receiverId === auth.currentUser.uid) {
                if(value.senderId === auth.currentUser.uid) {
                    if(lastText[value.receiverId] === undefined || lastText[value.receiverId]?.createdTimestamp < value.createdTimestamp) {
                        lastText[value.receiverId] = value;
                    }
                } else if(value.receiverId === auth.currentUser.uid) {
                    if(lastText[value.senderId] === undefined || lastText[value.senderId]?.createdTimestamp < value.createdTimestamp) {
                        lastText[value.senderId] = value;
                    }
                }
                setLastChats(lastText);
            }
        });
    }, [userChats]);

    useEffect(() => {
        setLastMessageTimestamp();
    }, [lastChats]);

    useEffect(() => {
        if (receiverData && Object.keys(receiverData).length > 0 && user) {
            const snapshotQuery = query(collection(db, "chats"), or(
                                    and(
                                        where("senderId", "==", user?.uid),
                                        where("receiverId", "==", receiverData?.userId)
                                    ),
                                    and(
                                        where("senderId", "==", receiverData?.userId),
                                        where("receiverId", "==", user?.uid)
                                    ),
                                ),orderBy("createdTimestamp"));

            const unsub = onSnapshot(snapshotQuery, (snapshot) => {
                setAllMessages(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        messages: doc.data(),
                    }))
                );
                scrollToBottom();
                updateIsReadStatus(receiverData.userId);
            });
            return unsub;
        }
    }, [receiverData?.userId]);

    const sendMessage = async (event) => {
        event.preventDefault();
        try {
            if (user && receiverData) {
                if(uploadedFiles.length > 0) {
                    setIsLoading(true);
                    const formData = new FormData();
                    uploadedFiles.forEach((file, index) => {
                        formData.append(`images`, file);
                    })

                    await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/upload-chat-document', formData,
                    {
                        headers: {
                            'content-type': 'multipart/form-data',
                        }
                    }).then( async (response) => {
                        if(response.data.status === 1) {
                            setFiles([]);
                            setUploadedFiles([]);
                            fileInput.current.value = '';
                            let documentArr = [];
                            let chatMsg = "";
                            (response.data.files).map((value) => {
                                documentArr.push({
                                    'name': value.filename,
                                    'originalName': value.originalname,
                                    'type': value.mimetype
                                });
                            });
                            if(chatMessage.trim() !== "") {
                                chatMsg = chatMessage;
                            }
                            const created_timestamp = Date.now();
                            const randomId = Math.round(Math.random() * 1000000);
                            await addDoc(
                                collection(
                                    db,
                                    "chats"
                                ),
                                {
                                    _id: randomId,
                                    message: chatMsg,
                                    document: documentArr,
                                    receiverId: receiverData.userId,
                                    senderId: user.uid,
                                    isRead: false,
                                    createdTimestamp: created_timestamp
                                }
                            );
                            // sendPushNotification(receiverData.userId, user.uid, documentArr[0].originalName);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                            helper.Toast().fire({ icon: 'error', title: response.data.message });
                            setFiles([]);
                            setUploadedFiles([]);
                            fileInput.current.value = '';
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        helper.Toast().fire({ icon: 'error', title: "Something went wrong. If your message is not sent please check and send again." });
                        setFiles([]);
                        setUploadedFiles([]);
                        fileInput.current.value = '';
                        return false;
                    });
                } else if (uploadedFiles.length === 0 && chatMessage.trim() !== "") {
                    setIsLoading(true);
                    const created_timestamp = Date.now();
                    const randomId = Math.round(Math.random() * 1000000);
                    await addDoc(
                        collection(
                            db,
                            "chats"
                        ),
                        {
                            _id: randomId,
                            message: chatMessage,
                            receiverId: receiverData.userId,
                            senderId: user.uid,
                            isRead: false,
                            createdTimestamp: created_timestamp
                        }
                    );
                    // sendPushNotification(receiverData.userId, user.uid, chatMessage);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false);
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. If your message is not sent please check and send again." });
        }
        setChatMessage("");
        scrollToBottom();
    }

    const handleFileChange = (e,type, path = "", name = "") => {
        setIsLoading(true);
        if(type === 'add') {
            Object.keys(e.target.files).map((key) => {
                if((e.target.files[key].type === 'video/mp4' && e.target.files[key].size <= 52428800) || (e.target.files[key].type !== 'video/mp4' && e.target.files[key].size <= 10485760)) {
                    if(uploadedFiles.length <= 9) {
                        let typeArray = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'video/mp4', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/ms-excel', 'application/msexcel', 'application/rtf', 'excel/*', 'text/plain', 'text/richtext', 'text/rtf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                        if(typeArray.includes(e.target.files[key].type)) {
                            files.push({
                                'path': URL.createObjectURL(e.target.files[key]),
                                'type': e.target.files[key].type,
                                'name': e.target.files[key].name
                            })
                            uploadedFiles.push(e.target.files[key]);
                        } else {
                            setIsLoading(false);
                            let msg = 'Media type ('+e.target.files[key].type+') not allowed. Only media types (.mp4, .png, .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .txt) are allowed.';
                            helper.Toast().fire({ icon: 'error', title: msg });
                        }
                    } else {
                        setIsLoading(false);
                        helper.Toast().fire({ icon: 'warning', title: "You can only upload up to 10 attachments at once." });
                    }
                } else {
                    setIsLoading(false);
                    if(e.target.files[key].type === 'video/mp4') {
                        helper.Toast().fire({ icon: 'warning', title: "You can only upload up to 50 mb video file." });
                    } else {
                        helper.Toast().fire({ icon: 'warning', title: "You can only upload up to 10 mb file." });
                    }
                }
            })
            setUploadedFiles([...uploadedFiles]);
            setFiles([...files]);

        } else if(type === 'delete') {
            setFiles((files) => files.filter((data) => data.path !== path));
            setUploadedFiles((files) => files.filter((data) => data.name !== name ));
            setIsLoading(false);
        }
        return;
    }

    useEffect(() => {
        if(uploadedFiles.length === 0 && fileInput.current !== null) {
            fileInput.current.value = '';
        }
        setIsLoading(false);
    }, [uploadedFiles])

    const downloadFile = async (e, fileUrl, filename) => {
        e.preventDefault();
        const response = await fetch(process.env.REACT_APP_BACKENDURL+"chatdocs/"+fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    };

    const sendPushNotification = async(receiverId, senderId, message) => {
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/send-push-notification', {
            receiverId: receiverId,
            senderId: senderId,
            message: message
        },{
            headers: {
                'content-type': 'application/json'
            }
        });
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Messages</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            {
                                (userlevel === 2) && 
                                <>
                                    <li className="breadcrumb-item"> <Link to="/host/dashboard">Overview</Link> </li> 
                                    <li className="breadcrumb-item"> <Link to="/host/settings">Settings</Link> </li>
                                </>
                            }
                            {
                                (userlevel === 3) && 
                                <>
                                    <li className="breadcrumb-item"> <Link to="/cleaner/dashboard">Overview</Link> </li>
                                    <li className="breadcrumb-item"> <Link to="/cleaner/settings">Settings</Link> </li>    
                                </>
                            }
                            <li className="breadcrumb-item active"> Messages </li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="Index-wrapper d-lg-flex gap-1 mx-n4 p-1">

                            {/* SideBar */}
                            <div className="chat-leftsidebar">
                                <div className="px-3 pt-4 mb-1">
                                    <div className="flex-grow-1 overflow-hidden pb-1">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                <img src={authUserProfileImg} className="rounded-circle avatar-sm chat-profile" alt="" />
                                            </div>
                                            <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="text-truncate mb-0 fs-16">
                                                    {
                                                        (authUserProfileName !== "") ? authUserProfileName : auth.currentUser?.displayName
                                                    }
                                                </h5>
                                                <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                                    {userlevel === 2 ? <small>Host</small> : <small>Cleaner</small>}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <Search /> */}
                                    <div className="search-box d-none">
                                        <input type="text" className="form-control bg-light border-light" placeholder="Search here..." onKeyUp={userSearch}/>
                                        <i className="ri-search-2-line search-icon"></i>
                                    </div>
                                    <hr className="mb-0" />
                                </div>

                                <div className="tab-content text-muted">
                                    <div className="tab-pane active show" id="chats" role="tabpanel">
                                        <div className="chat-room-list pt-3" data-simplebar="init">
                                            <div className="simplebar-wrapper" style={{ margin: '-16px 0px 0px' }}>
                                                <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer"></div>
                                                </div>
                                                <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                                        <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{height: 'auto', overflow: 'hidden scroll'}}>
                                                            <div className="simplebar-content">
                                                                <div className="chat-message-list">
                                                                    <UsersComponent
                                                                        users={users}
                                                                        setReceiverData={setReceiverData}
                                                                        navigate={navigate}
                                                                        currentUserId={user?.uid}
                                                                        unreadChatCounts={unreadChatsCount}
                                                                        lastChats = {lastChats}
                                                                        filteredUsers = {filteredUsers}
                                                                        searchItem = {searchItem}
                                                                        userProfile = {userProfile}
                                                                        approvedUserIds = {approvedUserIds}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="simplebar-placeholder" style={{ width: 'auto', height: '497px' }}></div>
                                            </div>
                                            <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                                                <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}>
                                                </div>
                                            </div>
                                            <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                                                <div className="simplebar-scrollbar" style={{ height: '25px', display: 'block', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Right SideBar */}
                            {
                                (receiverData === null) ?
                                    <div className="user-chat w-100 overflow-hidden text-center chat-first-bg">
                                        <div className="chat-first">
                                            <img src="/assets/images/chat-screen.png" alt='' />
                                            <h3>Select a conversation</h3>
                                        </div>
                                    </div>
                                    :
                                    <>
                                    <div className="user-chat w-100 overflow-hidden">
                                        <div className="chat-content d-lg-flex">
                                            <div className="w-100 overflow-hidden position-relative">
                                                <div className="position-relative">
                                                    <div className="position-relative" id="users-chat" style={{ display: 'block' }}>
                                                        <div className="p-3 user-chat-topbar">
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-12">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 d-block d-lg-none me-3">
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                                                    <img src={receiverData.profileImg ? receiverData.profileImg : defaultProfile} className="rounded-circle avatar-xs chat-profile" alt="" />
                                                                                    {
                                                                                        (receiverData?.status === true) ? <span className="user-status"></span> : ''
                                                                                    }
                                                                                </div>
                                                                                <div className="flex-grow-1 overflow-hidden">
                                                                                    <h5 className="text-truncate mb-0 fs-16"><a className="text-reset username text-capitalize" data-bs-toggle="offcanvas" href="#userProfileCanvasExample" aria-controls="userProfileCanvasExample">{receiverData ? receiverData.username : " "}
                                                                                    </a></h5>
                                                                                    <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                                                                        {
                                                                                            (receiverData?.status === true) ? <small>Online</small> : <small>Offline</small>
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="chat-conversation p-3 p-lg-4 simplebar-scrollable-y" id="chat-conversation" data-simplebar="init">
                                                            <div className="simplebar-wrapper" style={{ margin: '-24px' }}>
                                                                <div className="simplebar-height-auto-observer-wrapper">
                                                                    <div className="simplebar-height-auto-observer"></div>
                                                                </div>
                                                                <div className="simplebar-mask">
                                                                    <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                                                        <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content"
                                                                            style={{ height: 'auto', overflow: 'hidden scroll' }}>
                                                                            <div className="simplebar-content" style={{ padding: '24px' }}>
                                                                                <div id="elmLoader"></div>
                                                                                <ul className="list-unstyled chat-conversation-list" id="users-conversation">
                                                                                    {allMessages &&
                                                                                        allMessages.map(({ id, messages }) => {
                                                                                            const ts = messages.createdTimestamp;
                                                                                            const time = moment(ts).format('LT');
                                                                                            let timestampDay = isTodayOrYesterday(ts);
                                                                                            const docLength = (messages?.document)?.length;
                                                                                            const receiverProfileImage = receiverData.profileImg ? receiverData.profileImg : defaultProfile;
                                                                                            const classStatic = (user?.uid === messages.senderId) ? 'flex-row-reverse' : '';
                                                                                            return (
                                                                                                <React.Fragment key={id}>
                                                                                                    { timestampDay !== "" ? (
                                                                                                        <p className="text-center"><span className="timestamp-text">{timestampDay}</span></p>
                                                                                                    ) : '' }
                                                                                                    <li key={id} className={`chat-list ${user?.uid === messages.senderId ? "right" : "left"}`} >
                                                                                                        <div className="conversation-list">
                                                                                                            <div className="chat-avatar">
                                                                                                                <img src={user?.uid === messages.senderId ? authUserProfileImg : receiverProfileImage} className="chat-profile" alt="" />
                                                                                                            </div>
                                                                                                            <div key={id} className="user-chat-content">
                                                                                                                <div key={'1'+ts} className="ctext-wrapp">
                                                                                                                    {docLength > 0 &&
                                                                                                                        <div className={"d-flex flex-wrap document-listing ctext-wrap-content p-0 media-box " + classStatic}>
                                                                                                                        {
                                                                                                                            (messages.document).map((value, index) => {
                                                                                                                                const fileDownloadName = value.originalName ? value.originalName : value.name;
                                                                                                                                if(value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png') {
                                                                                                                                    return <div className="ctext-wrap-contentt m-1" id={index} key={index}>
                                                                                                                                        <ImagePopupModal
                                                                                                                                        src= {process.env.REACT_APP_BACKENDURL+"chatdocs/"+value.name}
                                                                                                                                        thumb={process.env.REACT_APP_BACKENDURL+"chatdocs/thumbnail-"+value.name}
                                                                                                                                        name= {value.name}
                                                                                                                                        type= {value.type}
                                                                                                                                        />
                                                                                                                                        <div className="message-img-link text-end">
                                                                                                                                            <ul className="list-inline mb-0">
                                                                                                                                                <li className="list-inline-item dropdown">
                                                                                                                                                    <button className="dropdown-item" onClick={(e) => downloadFile(e, value.name, fileDownloadName)}>
                                                                                                                                                        <i className="ri-download-2-line mx-1 text-muted align-bottom color-white"></i>
                                                                                                                                                    </button>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                } else if(value.type === 'application/pdf') {
                                                                                                                                    return <div className="ctext-wrap-contentt" id={index} key={index}>
                                                                                                                                        <button className="dropdown-item" onClick={(e) => downloadFile(e, value.name, fileDownloadName)}>
                                                                                                                                            <img src="/assets/images/placeholder/pdf.png" className="chat-document-icon" alt="pdf" />
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                } else if(value.type === 'video/mp4') {
                                                                                                                                    return <div className="ctext-wrap-contentt" id={index} key={index}>
                                                                                                                                        <ImagePopupModal
                                                                                                                                        src= {process.env.REACT_APP_BACKENDURL+"chatdocs/"+value.name}
                                                                                                                                        name= {value.name}
                                                                                                                                        type= {value.type} 
                                                                                                                                        />
                                                                                                                                        <div className="message-img-link text-end">
                                                                                                                                            <ul className="list-inline mb-0">
                                                                                                                                                <li className="list-inline-item dropdown">
                                                                                                                                                    <button className="dropdown-item" onClick={(e) => downloadFile(e, value.name, fileDownloadName)}>
                                                                                                                                                        <i className="ri-download-2-line mx-1 text-muted align-bottom color-white"></i>
                                                                                                                                                    </button>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                } else if(value.type === 'application/msword' || value.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                                                                                                                                    return <div className="ctext-wrap-contentt" id={index} key={index}>
                                                                                                                                        <button className="dropdown-item" onClick={(e) => downloadFile(e, value.name, fileDownloadName)}>
                                                                                                                                            <img src="/assets/images/placeholder/doc.png" className="chat-document-icon" alt="Word" />
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                } else if(value.type === 'application/ms-excel' || value.type === 'application/msexcel' || value.type === 'excel/*' || value.type === 'application/vnd.ms-excel' || value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                                                                                                                                    return <div className="ctext-wrap-contentt" id={index} key={index}>
                                                                                                                                        <button className="dropdown-item" onClick={(e) => downloadFile(e, value.name, fileDownloadName)}>
                                                                                                                                            <img src="/assets/images/placeholder/xls.png" className="chat-document-icon" alt="xls" />
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                } else if(value.type === 'application/rtf' || value.type === 'text/plain' || value.type === 'text/richtext' || value.type === 'text/rtf') {
                                                                                                                                    return <div className="ctext-wrap-contentt" id={index} key={index}>
                                                                                                                                        <button className="dropdown-item" onClick={(e) => downloadFile(e, value.name, fileDownloadName)}>
                                                                                                                                            <img src="/assets/images/placeholder/txt.png" className="chat-document-icon" alt="txt" />
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                }

                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="ctext-wrap mt-1">
                                                                                                                    {(messages.message !== undefined && (messages.message).trim() !== "") &&
                                                                                                                    <div className={`ctext-wrap-content-text text-start ctext-wrap-content ${user?.uid !== messages.senderId && "ctext-wrap-content-left"}`} id="1">
                                                                                                                        <p className="mb-0 ctext-content">{messages.message}</p>
                                                                                                                    </div>
                                                                                                                    }

                                                                                                                </div>
                                                                                                                <div className="conversation-name">
                                                                                                                    <span className="d-none name"></span>
                                                                                                                    <small className="text-muted time">{time}</small>
                                                                                                                    <span className="text-success check-message-icon">
                                                                                                                        {
                                                                                                                            messages.isRead ? <i className="bx bx-check-double"></i> : <i className="bx bx-check"></i>
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                    <div ref={messagesEndRef}></div>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="simplebar-placeholder" style={{ width: '635px', height: '681px' }}></div>
                                                            </div>
                                                            <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                                                                <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
                                                            </div>
                                                            <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                                                                <div className="simplebar-scrollbar" style={{ height: '25px', display: 'block', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="chat-input-section p-3 p-lg-4">
                                                        <form id="chatinput-form" onSubmit={(event) => sendMessage(event)} method="post" encType="multipart/form-data" >
                                                            <div className="row g-0 align-items-center">
                                                                {(files.length > 0 ) &&
                                                                    <div className="row border border-dashed rounded gx-2 select-media">
                                                                        {files.map((doc, index) => (
                                                                            <React.Fragment key={index}>
                                                                            {(doc.type === "image/jpeg" || doc.type === "image/jpg" || doc.type === "image/png") &&
                                                                                <div className="col-2 m-1">
                                                                                    <div className='chat-icon-container' style={{'width': '100', 'height': '100'}}>
                                                                                        <i className="ri-close-line image-delete" onClick={(e) => handleFileChange(e,'delete', doc.path, doc.name)}></i>
                                                                                        <a href={doc.path} target='blank'>
                                                                                            <img src={doc.path} alt="" className="img-fluid rounded" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {(doc.type === "application/pdf") &&
                                                                                <div className="col-2 m-1">
                                                                                    <div className='chat-icon-container' style={{'width': '100', 'height': '100'}}>
                                                                                        <i className="ri-close-line image-delete" onClick={(e) => handleFileChange(e,'delete', doc.path, doc.name)}></i>
                                                                                        <a href={doc.path} target='blank'>
                                                                                            <img src="/assets/images/placeholder/pdf.png" alt="" className="img-fluid rounded" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {(doc.type === "video/mp4") &&
                                                                                <div className="col-2 m-1">
                                                                                    <div className='chat-icon-container' style={{'width': '100', 'height': '100'}}>
                                                                                        <i className="ri-close-line image-delete" onClick={(e) => handleFileChange(e,'delete', doc.path, doc.name)}></i>
                                                                                        <a href={doc.path} target='blank'>
                                                                                            <img src="/assets/images/placeholder/video.png" alt="" className="img-fluid rounded" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {(doc.type === "application/msword" || doc.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
                                                                                <div className="col-2 m-1">
                                                                                    <div className='chat-icon-container' style={{'width': '100', 'height': '100'}}>
                                                                                        <i className="ri-close-line image-delete" onClick={(e) => handleFileChange(e,'delete', doc.path, doc.name)}></i>
                                                                                        <a href={doc.path} target='blank'>
                                                                                            <img src="/assets/images/placeholder/doc.png" alt="" className="img-fluid rounded" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {(doc.type === "application/ms-excel" || doc.type === "application/msexcel" || doc.type === "excel/*" || doc.type === 'application/vnd.ms-excel' || doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
                                                                                <div className="col-2 m-1">
                                                                                    <div className='chat-icon-container' style={{'width': '100', 'height': '100'}}>
                                                                                        <i className="ri-close-line image-delete" onClick={(e) => handleFileChange(e,'delete', doc.path, doc.name)}></i>
                                                                                        <a href={doc.path} target='blank'>
                                                                                            <img src="/assets/images/placeholder/xls.png" alt="" className="img-fluid rounded" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {(doc.type === "application/rtf" || doc.type === "text/plain" || doc.type === "text/richtext" || doc.type === "text/rtf") &&
                                                                                <div className="col-2 m-1">
                                                                                    <div className='chat-icon-container' style={{'width': '100', 'height': '100'}}>
                                                                                        <i className="ri-close-line image-delete" onClick={(e) => handleFileChange(e,'delete', doc.path, doc.name)}></i>
                                                                                        <a href={doc.path} target='blank'>
                                                                                            <img src="/assets/images/placeholder/txt.png" alt="" className="img-fluid rounded" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col">
                                                                    <div className="chat-input-feedback">
                                                                        Please Enter a Message
                                                                    </div>
                                                                    <input type="text" className="form-control chat-input bg-light border-light" id="chat-input" placeholder="Type your message..." value={chatMessage}  onChange={(e) => setChatMessage(e.target.value)} autoComplete="off" />
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <div className="chat-input-links ms-2">
                                                                        <div className="dropzone d-flex align-items-center dropzone-btn-container btn-attachment">
                                                                            <div className="fallback">
                                                                                <input 
                                                                                    name="images" 
                                                                                    type="file" 
                                                                                    multiple={true}
                                                                                    ref={fileInput}
                                                                                    onChange={(e) => handleFileChange(e,'add')}
                                                                                    />
                                                                            </div>
                                                                            <div className="dz-message needsclick text-center dropzone-btn">
                                                                                <div className="mb-3">
                                                                                    <i className="ri-attachment-2 align-bottom"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="chat-input-links ms-2">
                                                                        <div className="links-list-item">
                                                                        {isLoading ? (
                                                                                <Loading />
                                                                            ) : (
                                                                            <button type="submit" className="btn btn-success chat-send waves-effect waves-light">
                                                                                <i className="ri-send-plane-2-fill align-bottom"></i>
                                                                            </button>
                                                                        )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatIndex;
