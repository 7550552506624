import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import helper from '../../../helper';
import { decodeToken } from "react-jwt";
import Cookies from 'js-cookie';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Navigation, Pagination, Scrollbar, A11y  } from 'swiper/modules';
import { socket } from '../../../socket';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const AssignmentClass = (props) => {
  const token = Cookies.get('token');
  // get user data from token in cookie
  const decoded = decodeToken(token);

  const navigate = useNavigate();
  const segment = decoded.user_id;
  const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
  const subTypes = helper.subTypes


  const [ step1, setStep1 ] = useState("active");
  const [ step2, setStep2 ] = useState("");
  const [ step3, setStep3 ] = useState("");
  const [minStartTime, setMinStartTime] = useState(null);
  const [maxStartTime, setMaxStartTime] = useState(null);

  useEffect(() => {
    checkIsPaymentMethodAdded();
    getHostProperties();
    getCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setMinMaxTime(true);
  }, []);
  // Manage State for create assignemnt
  const [basicDetail, setBasicDetail] = useState({
    platform: "web",
    title: "",
    propertyId: "",
    date: new Date(),
    startTime: "",
    endTime: "",
    checkList: [],
    cleanerList: [],
    specificRequest: "",
    price: "",
    addedBy: decoded.user_id
  });

  const [errorBasicDetail, setErrorBasicDetail] = useState({
    title: "",
    propertyId: "",
    date: "",
    startTime: "",
    endTime: "",
    checkList: "",
    cleanerList: "",
    specificRequest: "",
    price: "",
  });

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [propertyDetail, setpropertyDetail] = useState([]);
  const [cleanerDetail, setCleanerDetail] = useState([]);
  const [properties, setProperties] = useState([]);
  const [checkListArray, setCheckList] = useState([]);
  const [optionsCheckList, setOptionsCheckList] = useState([]);
  const [cleanerListArray, setCleanerList] = useState([]);
  const [otherCleanerListArray, setOtherCleanerList] = useState([]);
  const [formValidationPass, setFormValidationPass] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  const [assignmentStartTime, setAssignmentStartTime] = useState('');
  const [assignmentEndTime, setAssignmentEndTime] = useState('');
  const [characterCount, setCharacterCount] = useState(0);
  const [selectedCleanerError, setSelectedCleanerError] = useState('');
  const [filteredOtherCleaners, setFilteredOtherCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [ratingFilter, setRatingFilter] = useState('');
  const [mileFilter, setMileFilter] = useState('');
  const [filterRecords, setFilterRecords] = useState({
    searchText: ""
  });
  const [isPaymentMethod, setIsPaymentMethod] = useState(1);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = () => {
      setIsExpanded(!isExpanded);
  };

  // For other cleaner

  const [isExpanded2, setIsExpanded2] = useState(false);

  const toggleShowMore2 = () => {
      setIsExpanded2(!isExpanded2);
  };

  useEffect(() => {
    filterRecords['searchText'] = searchFilter;    
    setFilterRecords({ ...filterRecords });
  }, [searchFilter])
  
  useEffect(() => {
    if(assignmentEndTime !== '' && assignmentStartTime !== '' && selectedPropertyId !== '') {
      getCleanerList(selectedPropertyId, assignmentStartTime, assignmentEndTime, filterRecords);
    }
  }, [selectedPropertyId, assignmentStartTime, assignmentEndTime, filterRecords])

  useEffect(() => {
    let ratingValue = "";
    let mileValue = "";
    if(ratingFilter !== "") {
      ratingValue = parseInt(ratingFilter);
    }

    if(mileFilter !== "") {
      mileValue = parseFloat(mileFilter);
    }

    let filteredOtherCleanersList = '';
    let filteredCleaner = '';
    if(ratingValue !== "" && mileValue !== "") {
      if(parseInt(mileValue) < 91) {
        filteredOtherCleanersList = otherCleanerListArray.filter((cleanerData) => cleanerData.stars >= ratingValue && cleanerData.stars < ratingValue+1 && parseFloat(cleanerData.distance) <= mileValue);
        filteredCleaner = cleanerListArray.filter((cleanerData) => cleanerData.stars >= ratingValue && cleanerData.stars < ratingValue+1 && parseFloat(cleanerData.distance) <= mileValue);
      } else {
        filteredOtherCleanersList = otherCleanerListArray.filter((cleanerData) => cleanerData.stars === ratingValue && parseFloat(cleanerData.distance) >= mileValue);
        filteredCleaner = cleanerListArray.filter((cleanerData) => cleanerData.stars >= ratingValue && cleanerData.stars < ratingValue+1 && parseFloat(cleanerData.distance) >= mileValue);
      }
    } else if(ratingValue !== "" && mileValue === "") {
      filteredOtherCleanersList = otherCleanerListArray.filter((cleanerData) => (cleanerData.stars >= ratingValue && cleanerData.stars < ratingValue+1));
      filteredCleaner = cleanerListArray.filter((cleanerData) => (cleanerData.stars >= ratingValue && cleanerData.stars < ratingValue+1));
    } else if(ratingValue === "" && mileValue !== "") {
      if(parseInt(mileValue) < 91) {
        filteredOtherCleanersList = otherCleanerListArray.filter((cleanerData) => parseFloat(cleanerData.distance) <= mileValue);
        filteredCleaner = cleanerListArray.filter((cleanerData) => parseFloat(cleanerData.distance) <= mileValue);
      } else {
        filteredOtherCleanersList = otherCleanerListArray.filter((cleanerData) => parseFloat(cleanerData.distance) >= mileValue);
        filteredCleaner = cleanerListArray.filter((cleanerData) => parseFloat(cleanerData.distance) >= mileValue);
      }
    }
    setFilteredOtherCleaners(filteredOtherCleanersList);
    setFilteredCleaners(filteredCleaner);
  }, [ratingFilter, mileFilter])

  const checkIsPaymentMethodAdded = async() => {
    props.setLoading(true);
    const {data} = await axios.get(process.env.REACT_APP_BACKENDURL + 'api/common/check-payment-method',{
                      params: {
                        role_id: 2
                      },
                      headers: {'Content-Type': 'application/json', 'token': token}
                    })
                    .catch(function (error) {
                      console.log('Something went wrong');
                    });
    props.setLoading(false);
    if(data.status === 0) {
      setIsPaymentMethod(0);
    } else if(data.status === 1) {
      setIsPaymentMethod(1);
    }

  }

  const getHostProperties = () => {
    axios.get(process.env.REACT_APP_BACKENDURL + 'api/host/get-properties',{
      params: {
        'page': 0,
        'limit': 100
      },
      headers: {'Content-Type': 'application/json', 'token': token}
    }
    )
      .then(function (response) {
        setProperties(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getCleanerList = (propertyId = "", assignmentStartTime, assignmentEndTime, filterRecords) => {
    let pageNo = (cleanerListArray) ? cleanerListArray.selected : 0;
    axios.get(process.env.REACT_APP_BACKENDURL + 'api/host/invite-cleaners-by-property', {
      params: {
        propertyId: propertyId,
        assignmentStartTime: assignmentStartTime,
        assignmentEndTime: assignmentEndTime,
        page: pageNo,
        limit: 100,
        filterRecords: filterRecords
      },
      headers: {'Content-Type': 'application/json', 'token': token}
    })
      .then(function (response) {
        if (response.data.status === 1) {
          let newArray = [];
          let otherNewArray = [];

          response.data.data.hostPartnersList.map((item) => {
            newArray.push({ value: item._id, label: item.first_name + ' ' + item.last_name, image: item.image, stars: item.stars, distance: item.distance, distanceString:  item.distanceString})
          });

          response.data.data.otherCleanersList.map((it) => {
            otherNewArray.push({ value: it._id, label: it.first_name + ' ' + it.last_name, image: it.image, stars: it.stars, distance: it.distance, distanceString:  it.distanceString })
          });
          setOtherCleanerList(otherNewArray)
          setCleanerList(newArray);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getCheckList = () => {
    axios.post(process.env.REACT_APP_BACKENDURL + 'api/host/get-checklist',
      { hostId: segment },
      {headers: {'Content-Type': 'application/json', 'token': token}}
    )
      .then(function (response) {
        if (response.data.status === 1) {
          let newArray = [];
          let optionsArray = [];
          response.data.data.map((item) => {
            newArray.push({value: item._id, label: item.title})
            optionsArray.push({value: item._id, label: item.title, options: item.checklistData})
          });
          setCheckList(newArray);
          setOptionsCheckList(optionsArray);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const updateBasicDetail = (name, value) => {
    if (name === "price") {
      value = rx_live.test(value) ? value : basicDetail.price;
    }

    if(name === "propertyId" && value !== ''){
      // get property detail
      let pData = properties.filter(item => item._id === value);
      if(typeof pData !== 'undefined'){
        setSelectedPropertyId(value)
        setpropertyDetail(pData);
      }else{
        setSelectedPropertyId("")
      }
    }

    if(name === "startTime" && value !== ''){
      setAssignmentStartTime(value);
    }

    if(name === "endTime" && value !== ''){
      setAssignmentEndTime(value);
    }

    if(name === "specificRequest") {
      setCharacterCount(value.length);
    }

    if(name === "date") {
      setSelectedDate(value);
      const formatDate = moment(value).format('YYYY-MM-DD');
      basicDetail[name] = formatDate;


      const currentDate = moment().startOf('day');
      const givenDate = moment(formatDate);
      const isToday = givenDate.isSame(currentDate, 'day');

      if(isToday) {
        setMinMaxTime(true);
      } else {
        setMinMaxTime(false);
      }
    } else {
      basicDetail[name] = value;
    }

    setBasicDetail({ ...basicDetail });
    //checkFormValidation()
  }

  const setMinMaxTime = (isToday) => {
    if(isToday) {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currTime = new Date();
      currTime.setHours(currentHour, currentMinute + 1);
      setMinStartTime(currTime);
      setMaxStartTime(new Date("2100-01-01T23:59:59"));
    } else {
      setMinStartTime(null);
      setMaxStartTime(null);
    }
  }

  // const checkFormValidation = () => {
  //   setFormValidationPass(true);
  //   Object.keys(basicDetail).map(key => {
  //     if (basicDetail[key] === "") {
  //       setFormValidationPass(false);
  //     }
  //   })
  // }

  const handleChecklist = (selectedOption) => {
    basicDetail['checkList'] = selectedOption;
    setBasicDetail({ ...basicDetail });
    //checkFormValidation();
  };

  const handleSearchFilter = () => {
    const selectedValue = searchValue;
    setSearchFilter(selectedValue);
  }

  const handleRatingFilter = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;
    setRatingFilter(selectedValue);
  }

  const handleMileFilter = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;
    setMileFilter(selectedValue);
  }

  const handleInviteCleaners = (ev) => {
        let item = ev.target.value;        
        if(basicDetail.cleanerList.indexOf(item) === -1 && !ev.target.className.includes('added-btn')) {
            ev.target.className = "btn added-btn w-100 btn-add btn-label waves-effect waves-light"
            ev.target.innerHTML = `<i class="ri-check-double-line label-icon align-middle fs-16 me-2"></i> Invited`
            basicDetail['cleanerList'].push(item);
        }else if(ev.target.className.includes('added-btn')){
            ev.target.className = "btn btn-outline-list w-100  waves-effect waves-light"
            ev.target.innerHTML = `Invite`
            let index = basicDetail.cleanerList.indexOf(item);
            basicDetail['cleanerList'].splice(index, 1);
        }

        setBasicDetail({ ...basicDetail });

        if(basicDetail.cleanerList.length !== 0){
          let cList = [];
          let allCleaners = otherCleanerListArray.concat(cleanerListArray)
          basicDetail.cleanerList.map((cleanerUser, i) => {
            let cData = allCleaners.filter(item => item.value === cleanerUser);
           
            if(typeof cData !== 'undefined' && cData.length > 0){
              let pCleaners = {
                "label": cData[0].label,
                "value": cData[0].value,
                "image": cData[0].image,
                "stars": cData[0].stars,
                "distanceString": cData[0].distanceString,
              }
              cList.push(pCleaners);
            }
            setCleanerDetail(cList);
            setSelectedCleanerError('');
          });
        }else{
          setCleanerDetail([]);
          setSelectedCleanerError('Please select at least one cleaner.');
        }
  }

  const stepOneSub = async (e) => {
    e.preventDefault();

    await resetAllErrorMeg();
    const resp = await validateStepForm1();

    if(resp === 1){
      return false;
    }

    setStep1("");
    setStep2("active");
    setStep3("");
  }

  const stepTwoSub = (e) => {
    e.preventDefault();
    
    if(cleanerDetail.length === 0) {
      setSelectedCleanerError('Please select at least one cleaner.');
      return false;
    }

    setStep1("");
    setStep2("");
    setStep3("active");
    
  }

  const backToStepOne = () => {
    setStep1("active");
    setStep2("");
    setStep3("");
  }

  const backToStepTwo = () => {
    setStep1("");
    setStep2("active");
    setStep3("");
  }

  const resetAllErrorMeg = async () => {
    
      errorBasicDetail['title'] = "";
      errorBasicDetail['propertyId'] = "";
      errorBasicDetail['date'] = "";
      errorBasicDetail['startTime'] = "";
      errorBasicDetail['endTime'] = "";
      errorBasicDetail['checkList'] = "";
      errorBasicDetail['specificRequest'] = "";
      errorBasicDetail['price'] = "";
    
    setErrorBasicDetail({ ...errorBasicDetail });
  }

  const validateStepForm1 = async () => {
    
    let error  = 0;
    if(basicDetail.title === ''){
      errorBasicDetail['title'] = "Assignment name field is required.";
      error = 1;
    }

    if(basicDetail.propertyId === ''){
      errorBasicDetail['propertyId'] = "Property selection is required.";
      error = 1;
    }

    if(basicDetail.date === ''){
      errorBasicDetail['date'] = "Assignment date field is required.";
      error = 1;
    }

    if(basicDetail.startTime === ''){
      errorBasicDetail['startTime'] = "Start time field is required.";
      error = 1;
    }

    if(basicDetail.endTime === ''){
      errorBasicDetail['endTime'] = "End time field is required.";
      error = 1;
    }

    if(basicDetail.startTime !== '' || basicDetail.endTime !== '') {
      let start_time = moment(basicDetail.startTime);
      let end_time = moment(basicDetail.endTime);
      let diff = end_time.diff(start_time, "hours", true);
      if(diff <= 0) {
        errorBasicDetail['endTime'] = "End time should be greater than start time.";
        error = 1;
      }
    }

    if(basicDetail.price === '' || basicDetail.price === "0"){
      errorBasicDetail['price'] = "Price field is required.";
      error = 1;
    }

    if(basicDetail.checkList.length === 0){
      errorBasicDetail['checkList'] = "Checklist field is required.";
      error = 1;
    }

    if(basicDetail.specificRequest === ''){
      errorBasicDetail['specificRequest'] = "Specific request field is required.";
      error = 1;
    }

    setErrorBasicDetail({ ...errorBasicDetail });
    return error;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    props.setLoading(true);
    axios.post(process.env.REACT_APP_BACKENDURL + 'api/host/create-assignment', 
      basicDetail,
      {headers: {'Content-Type': 'application/json', 'token': token}}
    )
      .then(function (response) {
        props.setLoading(false)
        if (response.data.status === 1) {
          sendNotificationToCleaners()
          navigate('/host/assignment/list');
          helper.Toast().fire({
            icon: 'success',
            title: response.data.message
          })
        } else {
          helper.Toast().fire({
            icon: 'error',
            title: response.data.message
          })
        }
      })
      .catch(function (error) {
        props.setLoading(false)
        console.log(error);
      });
  }

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }
    
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);


    socket.on('save-socket-id', (socketId) => {
        UpdateSocketId(socketId, decoded.userlevel)
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
}, []);

  const sendNotificationToCleaners = () => {
    // Connect Socket if not connected
    if(!isConnected){
        socket.connect();
    }
    socket.emit('send-notification-signal-to-cleaners', basicDetail.cleanerList);
}

const UpdateSocketId = (socketId, roleId) => {        
  axios.post(
              process.env.REACT_APP_BACKENDURL+"api/common/update-socket", 
              {
                  socketId: socketId,
                  role_id: roleId
              },
              {
                  headers: {'Content-Type': 'application/json', 'token': token}
              }
          )
  }

  let otherCleaners = (otherCleanerListArray.length > 0) ? otherCleanerListArray : [];
  let partnerCleanerList = (cleanerListArray.length > 0) ? cleanerListArray : [];
  if(ratingFilter !== '' || mileFilter !== '') {
    otherCleaners = (filteredOtherCleaners.length > 0) ? filteredOtherCleaners : [];
    partnerCleanerList = (filteredCleaners.length > 0) ? filteredCleaners : [];
  }

  return (
    <div className="page-content">
      <div className="container-fluid">


        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">Create Assignment</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="/host/dashboard">Overview</Link></li>
                  <li className="breadcrumb-item"><Link to={"/host/assignment/list/"}>Assignment List</Link></li>
                  <li className="breadcrumb-item active">Create Assignment</li>
                </ol>
              </div>

            </div>
          </div>
        </div>

        <div className="row mb-4">

          <div className="col-xl-12 col-md-12">
            {(isPaymentMethod === 0) ? 
              <div className="card">
                  <div className="card-body">
                    <div className="alert alert-message-box align-items-center d-flex justify-content-between" style={{top: '0'}}>
                      <div className="messagr-text align-items-center d-flex gap-2">
                          <i className="ri-home-fill"></i>
                          <h6 className="mb-0 p-1">You have to add payment method before creating the assignment, Please add your credit card details.</h6>
                      </div>
                      <Link className="btn white-btn waves-effect waves-light" to="/host/payments-and-transactions/card-details"> Click here </Link>                      
                    </div>
                    <div className="default-item">
                      <img src="/assets/images/deafult-logo.png" alt='' />
                      <h6 className="default-dis py-2"> You don't have permission to access this page.</h6>
                    </div>
                  </div>
              </div>
              : 
              <>
                <div className="step-arrow-nav mb-4">
                  <ul className="nav nav-pills custom-nav nav-justified" role="tablist"
                    id="propert-step">
                    <li className="nav-item" role="presentation">
                      <button className={'nav-link ' +  ((step1 === 'active') ? 'active' : '' )} data-bs-toggle="pill" data-bs-target="#basic-info" type="button" role="tab" aria-controls="basic-info" aria-selected="true" disabled="disabled">Basic Details</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className={'nav-link ' +  ((step2 === 'active') ? 'active' : '' )} data-bs-toggle="pill" data-bs-target="#invite-cleaners" type="button" role="tab" aria-controls="invite-cleaners" aria-selected="false" disabled="disabled">Invite Cleaners</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className={'nav-link ' +  ((step3 === 'active') ? 'active' : '' )} data-bs-toggle="pill" data-bs-target="#preview" type="button" role="tab" aria-controls="preview" aria-selected="false" disabled="disabled">Preview</button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content">
                  <div className={'tab-pane fade ' +  ((step1 === 'active') ? 'show active' : '' )} id="basic-info" role="tabpanel" >
                    <form onSubmit={stepOneSub} id="assignment-form">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 mb-4">
                              <div className="input-group">
                                <span id="basic-addon1" className="input-group-text" ><i className="ri-user-fill "></i></span>
                                <input className="form-control"
                                  name='title'
                                  placeholder="Enter Assignment Name"
                                  onChange={(e) => updateBasicDetail('title', helper.capitalizeFirstLetter(e.target.value))}
                                  value={basicDetail.title}
                                  maxLength={100} 
                                />
                              </div>
                              {errorBasicDetail.title && <div className="text-danger"> {errorBasicDetail.title} </div>}
                            </div>

                            <div className="col-md-6 mb-4">
                              <div className="input-group">
                                <span id="basic-addon1" className="input-group-text" ><i className="ri-home-5-line"></i></span>
                                <select className="form-control"
                                  name='type'
                                  onChange={(e) => updateBasicDetail('propertyId', e.target.value)}
                                  value={basicDetail.propertyId}>
                                  <option value="" >Select Property</option>
                                  {properties.map(item => {
                                    return (
                                      <option key={item._id} value={item._id} >{item.propertyName}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorBasicDetail.propertyId && <div className="text-danger"> {errorBasicDetail.propertyId} </div>}
                            </div>



                            <div className="col-6 mb-4">
                              <div className="input-group flex-nowrap">
                                <span id="basic-addon1" className="input-group-text" ><i className="ri-calendar-event-fill"></i></span>
                                <DatePicker className='form-control'
                                  // selected={basicDetail.date}
                                  selected={selectedDate}
                                  minDate={new Date()}
                                  placeholderText="Select Assessment Date"
                                  onChange={(date) => updateBasicDetail('date', date)} />
                              </div>
                              {errorBasicDetail.date && <div className="text-danger"> {errorBasicDetail.date} </div>}
                            </div>

                            <div className="col-6">
                              <div className="row time-selecter">
                                <div className="col-md-6 number mb-4">
                                  <div className="input-group flex-nowrap">
                                    <span id="basic-addon1" className="input-group-text" ><i className="ri-time-fill"></i></span>
                                    <DatePicker
                                      className='form-control'
                                      selected={basicDetail.startTime}
                                      onChange={(date) => updateBasicDetail('startTime', date)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={60}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                      minTime={minStartTime}
                                      maxTime={maxStartTime}
                                      placeholderText="Start Time"
                                    />

                                  </div>
                                  {errorBasicDetail.startTime && <div className="text-danger"> {errorBasicDetail.startTime} </div>}
                                </div>
                                <div className="col-md-6 number end-time mb-4">
                                  <div className="input-group flex-nowrap">
                                    <span id="basic-addon1" className="input-group-text" ><i className="ri-time-fill"></i></span>
                                    <DatePicker
                                      className='form-control'
                                      selected={basicDetail.endTime}
                                      onChange={(date) => updateBasicDetail('endTime', date)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setMinutes((basicDetail.startTime ? basicDetail.startTime : null), 40)}
                                      maxTime={setHours(setMinutes((basicDetail.startTime ? basicDetail.startTime : null), 45), 23)}
                                      timeIntervals={60}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                      placeholderText="End Time"
                                    />

                                  </div>
                                  {errorBasicDetail.endTime && <div className="text-danger"> {errorBasicDetail.endTime} </div>}
                                </div>
                              </div>
                            </div>

                            <div className="col-6 mb-4">
                              <div className="input-group">
                                <span id="basic-addon1" className="input-group-text" ><i className="ri-refund-2-fill"></i></span>
                                <input
                                  type="text"
                                  maxLength={5}
                                  className='form-control number'
                                  name='price'
                                  placeholder='Price'
                                  onChange={(e) => updateBasicDetail('price', e.target.value)}
                                  value={basicDetail.price} />
                              </div>
                              {errorBasicDetail.price && <div className="text-danger"> {errorBasicDetail.price} </div>}
                            </div>

                            <div className={((checkListArray.length === 0) ? "col-5" : "col-6") + " checklist mb-4"}>
                              <div className="input-group flex-nowrap">
                                <span id="basic-addon1" className="input-group-text" ><i className=" ri-survey-line "></i></span>
                                <Select
                                  name='type'
                                  options={checkListArray}
                                  onChange={handleChecklist}
                                  closeMenuOnSelect={true}
                                  value={basicDetail.checkList} 
                                  placeholder="Select Checklist"/>
                              </div>
                              {errorBasicDetail.checkList && <div className="text-danger"> {errorBasicDetail.checkList} </div>}
                            </div>

                            {(checkListArray.length === 0) &&
                              <div className="col-1 mb-4">
                                <Link to="/host/checklist/add" target="_blank" className="btn btn-add waves-effect waves-light me-2">Add</Link>
                              </div>
                            }

                            <div className="col-12 mb-3">
                              
                              <div>
                                <textarea maxLength={500} className="form-control" value={basicDetail.specificRequest} onChange={(e) => updateBasicDetail('specificRequest', e.target.value)} rows="5" placeholder="Specific Requests"></textarea>
                              </div>
                              <div className="float-end me-1 word-limit-count">{characterCount}/500 Characters</div>
                              {errorBasicDetail.specificRequest && <div className="text-danger"> {errorBasicDetail.specificRequest} </div>}
                            </div>

                          </div>

                        </div>
                      </div>

                      <div className="d-flex align-items-start gap-3 mt-4">

                        <button type="submit" className="btn btn-add btn-label right ms-auto" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next</button>
                      </div>
                    </form>

                  </div>


                  <div className={'tab-pane fade ' +  ((step2 === 'active') ? 'show active' : '' )} id="invite-cleaners" role="tabpanel">
                    <div className="accordion mb-4" id="default-accordion-example">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Filter cleaners by using search
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                          data-bs-parent="#default-accordion-example">
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-6">
                                <input type="text" className="form-control" name="searchText" placeholder="Search by name, email and phone" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onBlur={handleSearchFilter}/>
                              </div>
                              <div className="col-3">
                                <select className="form-select" onChange={(e) => handleMileFilter(e)}>
                                  <option value="">Select Miles</option>
                                  <option value="30">Upto 30 miles</option>
                                  <option value="60">Upto 60 miles</option>
                                  <option value="90">Upto 90 miles</option>
                                  <option value="91">All</option>
                                </select>
                              </div>
                              <div className="col-3">
                                <select className="form-select" onChange={(e) => handleRatingFilter(e)}>
                                  <option value="">Select Rating</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">

                        {/* <div className="row">
                          <div className="col-lg-12">

                            <div className="list-grid-nav hstack gap-1 float-end mb-4">
                              <a type="button" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 filter-button active"><i className="ri-grid-fill"></i></a>
                              <a href="cleaner-add - list.html" type="button" id="list-view-button" className="btn btn-outline-list  nav-link btn-icon fs-14 filter-button"><i className="ri-list-unordered"></i></a>

                            </div>

                          </div>
                        </div> */}


                        <div className='col-md-12 mb-5'>
                          <h4>Our Partners</h4>
                        </div>
                        {(partnerCleanerList.length === 0) && <div className="text-center p-2"><strong>No Cleaner Found</strong></div>}
                        <div className="row row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4 cleaner-list">

                          {/* {cleanerListArray.map((item, i) => { */}
                          {partnerCleanerList.map((item, i) => {
                            let cleanerStars = (typeof item.stars !== "undefined") ? item.stars : -1
                            let dynamicClass = isExpanded || i <= 4 ? " alwaysShow" : " mayBeHide";
                            return (
                              <>
                              <div className={"col mb-4"+ dynamicClass} key={i}>
                                <div className="card">

                                  <div className="card-body text-center">
                                  {
                                      (item.image) ?
                                          <img src={item.image} alt="profileImage" className="avatar-md mt-n5 rounded-circle mx-auto d-block object-cover" />
                                      : 
                                          <img src="/assets/images/image-placeholder.png" alt="profileImage" className="avatar-md mt-n5 rounded-circle mx-auto d-block object-cover" />
                                  }
                                  
                                    <h5 className="mt-3 mb-1">
                                      <a href="#javascript" className="cursor-pointer">{item.label}</a>
                                    </h5>
                                    <p className="text-muted d-flex align-items-center justify-content-center gap-2 number mb-1">
                                      {
                                        item.distanceString !== "" ?
                                        <>
                                          <i className="ri-map-pin-fill"></i> 
                                          <small className="text-muted">
                                            {item.distanceString}
                                          </small>
                                        </>:
                                        <></>
                                      }                                  
                                    </p>
                                    {(cleanerStars > 0) ?
                                      <>
                                        <div className="hstack gap-1 flex-wrap rating-icon justify-content-center mb-1">
                                          {[...Array(5)].map((star, index) => {
                                            index += 1;
                                            return (
                                                <i key={index} className={ 'bx fs-22 ' + (index <= cleanerStars ? "bxs-star" : "bx-star")} ></i>  
                                            );
                                          })}
                                        </div>
                                        <div className='mb-3 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                      </>
                                      :
                                      <div className='py-2 mt-2 mb-3 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                    }
                                    <div>
                                        <button type="button"
                                          className="btn btn-outline-list w-100 waves-effect waves-light"
                                          onClick={handleInviteCleaners}
                                          value={item.value}
                                          >Invite</button> 
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </>
                            )
                          })}
                        </div>

                        {partnerCleanerList.length > 5 ? 
                          <div style={{'textAlign': 'center'}}>
                            <button className='btn btn-add btn-sm list-btn waves-effect waves-light' onClick={toggleShowMore}>{isExpanded ? 'Show Less' : 'Show More'}</button>
                          </div>
                            :
                            <></>
                        }

                        <div className='col-md-12 mb-5'>
                          <h4>Other Cleaners</h4>
                        </div>
                        {(otherCleaners.length === 0) && <div className="text-center p-2"><strong>No Cleaner Found</strong></div>}
                        <div className="row row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4 cleaner-list">
                          {/* {otherCleanerListArray.map((oItem, i) => { */}
                          {otherCleaners.map((oItem, i) => {
                          let cleanerStars = (typeof oItem.stars !== "undefined") ? oItem.stars : -1
                          let dynamicClass2 = isExpanded2 || i <= 4 ? " alwaysShow" : " mayBeHide";
                            return (
                              <div className={"col mb-4"+ dynamicClass2} key={i}>
                                <div className="card">

                                  <div className="card-body text-center">
                                  {
                                      (oItem.image) ?
                                          <img src={oItem.image} alt="profileImage" className="avatar-md mt-n5 rounded-circle mx-auto d-block object-cover" />
                                      : 
                                          <img src="/assets/images/image-placeholder.png" alt="profileImage" className="avatar-md mt-n5 rounded-circle mx-auto d-block object-cover" />
                                  }
                                  
                                    <h5 className="mt-3 mb-1">
                                      <a href="#javascript" className="cursor-pointer">{oItem.label}</a>
                                    </h5>
                                    <p className="text-muted d-flex align-items-center justify-content-center gap-2 number mb-1">
                                      {
                                        oItem.distanceString !== "" ?
                                        <>
                                          <i className="ri-map-pin-fill"></i> 
                                          <small className="text-muted">
                                            {oItem.distanceString}
                                          </small>
                                        </>:
                                        <></>
                                      }                                  
                                    </p>
                                    {(cleanerStars > 0) ?
                                      <>
                                        <div className="hstack gap-1 flex-wrap rating-icon justify-content-center mb-1">
                                        {[...Array(5)].map((star, index) => {
                                            index += 1;
                                            return (
                                                <i key={index} className={ 'bx fs-22 ' + (index <= cleanerStars ? "bxs-star" : "bx-star")} ></i>
                                            );
                                          })}
                                        </div>
                                        <div className='mb-3 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                      </>
                                      :
                                      <div className='py-2 mt-2 mb-3 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                    }
                                    <div>
                                        <button type="button"
                                          className="btn btn-outline-list w-100 waves-effect waves-light"
                                          onClick={handleInviteCleaners}
                                          value={oItem.value}
                                          >Invite</button> 
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                        </div>

                        {otherCleaners.length > 5 ? 
                          <div style={{'textAlign': 'center'}}>
                            <button className='btn btn-add btn-sm list-btn waves-effect waves-light' onClick={toggleShowMore2}>{isExpanded2 ? 'Show Less' : 'Show More'}</button>
                          </div>
                            :
                            <></>
                        }
                        {selectedCleanerError && <div className="text-danger"> {selectedCleanerError} </div>}
                      </div>
                    </div>

                    <div className="d-flex align-items-start gap-3 mt-4">
                      <span onClick={backToStepOne} className="btn btn-outline-list btn-label"><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>Previous</span>
                      <span onClick={stepTwoSub} className="btn btn-add btn-label right ms-auto"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next</span>
                    </div>

                  </div>


                  <div className={'tab-pane fade ' +  ((step3 === 'active') ? 'show active' : '' )} id="preview" role="tabpanel">
                    { (propertyDetail.length !== 0) ?

                      <div className="col-xl-12 col-md-12">

                        <div className="card">
                          <div className="row g-0">
                            <div className="col-md-4">
                              <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={false}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                // onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={() => console.log('slide change')}
                                
                              >
                                {(propertyDetail[0].images && propertyDetail[0].images.length > 0) ? 
                                  propertyDetail[0].images.map((img, i) => (
                                
                                  <SwiperSlide key={img.filename}>
                                    <img  src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} key={i} alt={img.filename} />
                                  </SwiperSlide>
                                ))
                                : <></>
                                }
                              </Swiper>
                            </div>
                            <div className="col-md-8">
                              <div className="card-header">
                                <h5 className="card-title mb-1">{propertyDetail[0].propertyName}</h5>
                              
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-5">
                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Title: </h6>
                                      <p className="mb-0">{basicDetail.title}</p>
                                    </div>

                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Type: </h6>
                                      <p className="mb-0">
                                        {subTypes.map((item, i) => (
                                            (propertyDetail[0].subType === item.id) && 
                                            <span key={i} >{item.name}</span>
                                        ))}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Size:</h6>
                                      <p className="mb-0 number">{propertyDetail[0].size} {(propertyDetail[0].sizeType === "m") ? "m²" : "ft²"}</p>
                                    </div>
                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Date:</h6>
                                      <p className="mb-0 number">{ (basicDetail.date) ? moment(basicDetail.date).format('MMM Do YYYY') : "" }</p>
                                    </div>

                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Checklist:</h6>
                                      {optionsCheckList.map((data, index) => {
                                      return(
                                          (basicDetail.checkList.value === data.value) &&
                                            <ul className='checklist-list' key={index}>
                                              {data.options.map((item) => (
                                                <li key={item}>{item}</li>
                                              ))}
                                            </ul>
                                          )
                                      })
                                      }
                                    </div>

                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Time:</h6>
                                      <p className="mb-0 number"> Between { (basicDetail.startTime) ? moment(basicDetail.startTime).format('hh:mm A') : "" } - {(basicDetail.endTime) ? moment(basicDetail.endTime).format('hh:mm A') : ""}</p>
                                    </div>
                                    
                                    <div className="d-flex align-items-baseline gap-1 mb-3">
                                      <h6 className="mb-0">Payout:</h6>
                                      <p className="mb-0 number"><strong>${basicDetail.price}</strong></p>
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div>
                                      <h5>Description</h5>
                                      <p>{basicDetail.specificRequest}</p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      : ""
                      }  

                    { (cleanerDetail.length !== 0) ?
                          
                    <div className="row row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-5">
                      {cleanerDetail.map((item, i) => { 
                          return ( 
                          <div className="col mb-3"  key={i}>
                            <div className="card">

                              <div className="card-body text-center">
                                {
                                  (item.image) ?
                                      <img src={item.image} alt="profileImage" className="avatar-md mt-n5 rounded-circle mx-auto d-block object-cover" />
                                  : 
                                      <img src="/assets/images/image-placeholder.png" alt="profileImage" className="avatar-md mt-n5 rounded-circle mx-auto d-block object-cover" />
                                }

                                <h5 className="mt-3 mb-1"><a href="cleaner-detail.html">{item.label}</a></h5>
                                <p className="text-muted d-flex align-items-center justify-content-center gap-2 number mb-1">
                                  {
                                    item.distanceString !== "" ?
                                    <>
                                      <i className="ri-map-pin-fill"></i> 
                                      <small className="text-muted">
                                        {item.distanceString}
                                      </small>
                                    </>:
                                    <></>
                                  }       
                                </p>
                                <div className="hstack gap-1 flex-wrap rating-icon justify-content-center mb-3">
                                {(item.stars > 0) ?
                                  <>
                                    <div className="hstack gap-1 flex-wrap rating-icon justify-content-center mb-1">
                                      {[...Array(5)].map((star, index) => {
                                        index += 1;
                                        return (
                                            <i key={index} className={ 'bx fs-22 ' + (index <= item.stars ? "bxs-star" : "bx-star")} ></i>  
                                        );
                                      })}
                                    </div>
                                    <div className='pb-1 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                  </>
                                  :
                                  <div className='pb-1 mt-4 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>  
                                }
                                </div>
                                <div>
                                  <button type="button" className="btn added-btn btn-add w-100 btn-label waves-effect waves-light"><i
                                    className="ri-check-double-line label-icon align-middle fs-16 me-2"></i>
                                    Invited</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          )
                      })}

                    </div>
                    : ""
                    }  

                    <div className="d-flex align-items-start gap-3 mt-4">
                      <span onClick={backToStepTwo} className="btn btn-outline-list btn-label" ><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>Previous</span>
                      <span onClick={submitForm} className="btn btn-add btn-label right ms-auto" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Send Invitation</span>
                    </div>

                  </div>

                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignmentClass;
