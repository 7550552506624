/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect, useContext} from 'react'
import { useOutletContext, Link, useParams } from 'react-router-dom';
import helper from '../../helper';
import axios from 'axios';
import Address from './component/Address';
import { HostContext } from '../../Contexts/HostContext';
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { socket } from '../../socket';
import { updateUserName } from '../../helper/fs-common.js';
import Modal from '../../components/common/Modal.js';
import FsLightbox from "fslightbox-react";

const Profile = (props) => {

    const {token, decoded} = useContext(HostContext);  
    const userId = decoded.user_id;
    const params = useParams(); 
    const [getProfileStatus, profileErrorFields, profileCompleteStatus] = useOutletContext();
    const [documentType, setDocumentType] = useState('passport');

    const [authData, setAuthData] = useState({
        first_name : '',
        last_name : '',
        phone_number : '',
        email : '',
        dob : new Date(),
        languages: '',
        bio: '',
        image: {},
        describeBest: '',
        experience: '',
        shortTermRental: '',
        school: ''
    });

    const [authValidations, setAuthValidations] = useState({
        first_name : '',
        last_name : '',
        phone_number : '',
        email : '',
        dob : '',
        languages: '',
        bio: '',
        image: '',
        describeBest: '',
        experience: '',
        shortTermRental: '',
        isImageUploaded: ''
    });

    const placeHolderImage = "/assets/images/placeholder/document-card.png";
    const [passportFrontImage, setPassportFrontImage] = useState(placeHolderImage);
    const [drivingFrontImage, setDrivingFrontImage]   = useState(placeHolderImage);
    const [drivingBackImage, setDrivingBackImage]     = useState(placeHolderImage);
    const [website, setWebsite] = useState("");
    const [percentage, setPercentage] = useState(0);
    const [isIdentityDocumentUploaded, setIsIdentityDocumentUploaded] = useState(0);

    const [docValidation, setDocValidation] = useState({
        social_security_number: "",
        website: "",
        tax_payer_name: "",
        front_image: "",
        back_image: "",
        isFrontPassImgUploaded: 0,
        isFrontDrivingImgUploaded: 0,
        isBackDrivingImgUploaded: 0,
    });
    
    const [tax_payer_id, setTaxPayerId] = useState("");
    const [tax_payer_name, setTaxPayerName] = useState("");
    const [social_security_number, setSocialSecurityNumber] = useState("");
    const [stepKey, setStepKey] = useState(1);
    const [ step1, setStep1 ] = useState("active");
    const [ step2, setStep2 ] = useState("");
    const [ step3, setStep3 ] = useState("");

    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
    const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");
    const [enteredOTP, setEnteredOTP] = useState("");
    const [updatePhoneError, setUpdatePhoneError] = useState("");
    const [updatePhoneMsg, setUpdatePhoneMsg] = useState("");
    const [OTPError, setOTPError] = useState("");
    const [isSSNModalOpen, setIsSSNModalOpen] = useState(false);
    const [enteredSSNNumber, setEnteredSSNNumber] = useState("");
    const [updatedSSNError, setUpdatedSSNError] = useState("");
    const [phoneUpdateSection, setPhoneUpdateSection] = useState(false);
    const [phoneOtpSection, setPhoneOtpSection] = useState(false);

    const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
    const [verificationOtp, setVerificationOtp] = useState("");
    const [verificationOtpError, setVerificationOtpError] = useState("");
    const [verificationType, setVerificationType] = useState("");
    const [SSNValue, setSSNValue] = useState("");
    const [SSNFocus, setSSNFocus] = useState(false);
    const [documentVerificationError, setDocumentVerificationError] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);

    const errorDot = {
        'background':'#f06548',
        'borderRadius': '100%',
        'width': '10px',
        'position': 'relative',
        'paddingTop': '10px'
    }

    const [ addressArray, setAddressArray ] = useState({
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        streetAddress: "",
        lat: 37.09024,
        lng: -95.712891
    })

    const [validateAddress, setValidateAddress] = useState({
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        streetAddress: "",
    })

    // eslint-disable-next-line no-unused-vars
    const [place, setPlace] = React.useState(null)

    const [ documentImgArr, setDocumentImgArr ] = useState([]);
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

    useEffect(() => {
        setDocumentVerificationError(false);
        setSSNFocus(false);
        if(profileErrorFields === "SSN") {
            setSSNFocus(true);
            setDocumentVerificationError(true);
        }
    }, [profileErrorFields])

    useEffect(() => {
        if(place !== null && place !== undefined && place.geometry !== undefined){
            getAddressFromPlace(place)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[place])

    useEffect(() => {
        let data = fetchCleanerDetails()
        setAuthData(data);
    }, [])

    useEffect(() => {
        const ptype = (params.step) ? params.step : params.step;
        if(ptype !== null){
            if(ptype === "personalDetails"){
                activeStep(1);
            }else if(ptype === "address"){
                activeStep(2);
            }else if(ptype === "documentVerification"){
                activeStep(3);
            }
        }
    }, [params])

    function capitalizeFirstLetter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }

    const manageAuthState = (e) => {
        let keyValue = e.target.name;
        let value = '';
        if(keyValue === 'first_name' || keyValue === 'last_name') {
            value = capitalizeFirstLetter(e.target.value);
        } else {
            value = e.target.value;
        }
        authData[keyValue] = value
        setAuthData({ ...authData })
    }

    // const managePhone = (e) => {
    //     let value = e.target.value.replace(/\D/g, '');
    //     let keyValue = e.target.name;
    //     authData[keyValue] = value
    //     setAuthData({ ...authData })
    // }

    const manageTabData = (key, value) => {
        if(key === "school") {
            authData[key] = value;
        } else {
            authData[key] = value;
        }
        if(key === "bio") {
            setCharacterCount(value.length);
        }
        setAuthData({...authData})
    }

    function handleFileChange(e,type) {
        let typeArray = ['image/jpeg', 'image/jpg', 'image/png'];
        if(e.target.files.length > 0) {
            if(typeArray.includes(e.target.files[0].type)) {
                if(e.target.files[0].size <= 10485760) {
                    switch(type) {
                        case '0':
                            authValidations['isImageUploaded'] = 1;
                            authData['image'] = URL.createObjectURL(e.target.files[0]);
                            setAuthValidations({ ...authValidations });
                            return setAuthData({ ...authData });
                            //return setProfileImage(URL.createObjectURL(e.target.files[0]));
                        case '1':
                            docValidation['isFrontPassImgUploaded'] = 1;
                            setDocValidation({ ...docValidation });
                            return setPassportFrontImage(URL.createObjectURL(e.target.files[0]));
                        case '3':
                            docValidation['isFrontDrivingImgUploaded'] = 1;
                            setDocValidation({ ...docValidation });
                            return setDrivingFrontImage(URL.createObjectURL(e.target.files[0]));
                        case '4':
                            docValidation['isBackDrivingImgUploaded'] = 1;
                            setDocValidation({ ...docValidation });
                            return setDrivingBackImage(URL.createObjectURL(e.target.files[0]));
                        default:
                        //default code goes here
                    }
                } else {
                    helper.Toast().fire({ icon: 'warning', title: "File size should not be greater than 10 Mb" });
                }
            } else {
                helper.Toast().fire({ icon: 'warning', title: "Only .jpg, .jpeg and .png file types are allowed"});
            }
        }
    }


    const getAddressFromPlace = (place) => {
        addressArray['lat'] = place.geometry.location.lat()
        addressArray['lng'] = place.geometry.location.lng()
        addressArray['fullAddress'] = place.name
        addressArray['streetAddress'] = place.name
        for (var j = 0; j < place.address_components.length; j++) {
            for (var k = 0; k < place.address_components[j].types.length; k++) {

                if (place.address_components[j].types[k] === "locality") {
                    addressArray['city'] = place.address_components[j].short_name
                }
                if (place.address_components[j].types[k] === "postal_code") {
                    addressArray['pincode'] = place.address_components[j].short_name
                }
                if (place.address_components[j].types[k] === "country") {
                    addressArray['country'] = place.address_components[j].long_name
                }
                if (place.address_components[j].types[k] === "administrative_area_level_1") {
                    addressArray['state'] = place.address_components[j].long_name
                }
            }
        }
        setAddressArray({...addressArray})
    }

    const manageCheckBox = (e) => {
        if(e.target.checked){
            authData['shortTermRental'] = 'yes';
        }else{
            authData['shortTermRental'] = 'no';
        }
        setAuthData({...authData})
    }


    const submitPersonalData = async (event) => {
        event.preventDefault();

        authValidations['first_name'] = '';
        authValidations['last_name'] = '';
        authValidations['phone_number'] = '';
        authValidations['email'] = '';
        authValidations['dob'] = '';
        authValidations['languages'] ='';
        authValidations['bio'] ='';
        authValidations['image'] ='';
        authValidations['describeBest'] ='';
        authValidations['experience'] ='';
        authValidations['shortTermRental'] ='';

        setAuthValidations({ ...authValidations })

        let error = 0;
        if (authData.first_name.trim().length === 0) {
            authValidations['first_name'] ='First name field is required.';
            error = 1;
        }
        if (authData.last_name.trim().length === 0) {
            authValidations['last_name'] = 'Last name field is required.';
            error = 1;
        }

        // if (authData.email.trim().length === 0) {
        //     authValidations['email'] = 'Email Address field is required.';
        //     error = 1;
        // }else{
        //     // check email address in valid
        //     let vEmail = String(authData.email)
        //     .toLowerCase()
        //     .match(
        //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     );
            
        //     if(vEmail === null){
        //         authValidations['email'] = 'Email Address is invalid.';
        //         error = 1;
        //     }
        // }

        // if (authData.phone_number.trim().length === 0) {
        //     authValidations['phone_number'] = 'Phone Number field is required.';
        //     error = 1;
        // }else if((authData.phone_number.trim().length !== 10)){
        //     authValidations['phone_number'] = 'Phone Number is invalid.';
        //     error = 1;
        // }

        if (authData.dob === null) {
            authValidations['dob'] = 'Date Of birth field is required.';
            error = 1;
        }else if(moment().diff(moment(authData.dob), 'years') < 18){
            authValidations['dob'] = 'The minimum age requirement is 18 years or older.';
            error = 1;
        } else if(moment().diff(moment(authData.dob), 'years') > 100) {
            authValidations['dob'] = 'Date of birth should not be greater than 100 years';
            error = 1;
        }

        if (authData.languages.trim().length === 0) {
            authValidations['languages'] = 'Language field is required.';
            error = 1;
        }

        // if (authData.bio.trim().length === 0) {
        //     authValidations['bio'] = 'Bio field is required.';
        //     error = 1;
        // }

        if (authData.experience.trim().length === 0) {
            authValidations['experience'] = 'Experience selection is required.';
            error = 1;
        }

        if (authData.describeBest.trim().length === 0) {
            authValidations['describeBest'] = 'Describe best selection is required.';
            error = 1;
        }

        if(authValidations.isImageUploaded === 0){
            authValidations['image'] = "Image field is required.";
            error = 1;
        }

        if (error === 1) {
            setAuthValidations({ ...authValidations })
            return;
        }

        const formData = new FormData(event.target); // Some date append from inputs
        
        if(!helper.isDateFormatCorrect(formData.get('dob'), "MM/DD/YYYY")) {
            authValidations['dob'] = 'Date of birth is not correct';
            error = 1;
        }
        if (error === 1) {
            setAuthValidations({ ...authValidations })
            return;
        }

        formData.append(`shortTermRental`,authData.shortTermRental);
        formData.delete('phone_number');
        formData.delete('email');

        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/update-personal-details',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                socket.emit('send-notification-signal-to-admin',1)
                socket.emit('send-profile-update-signal', userId);
                const firestoreData = {
                    'name': authData.first_name+' '+authData.last_name,
                    'email': authData.email
                }
                updateUserName(firestoreData);
                props.setLoading(false)
                setPercentage(response.data.percentage);
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
                getProfileStatus();
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
        });
    };

    const submitAddressData = async (event) => {

        event.preventDefault();

        validateAddress['fullAddress'] = '';
        validateAddress['city'] = '';
        validateAddress['state'] = '';
        validateAddress['pincode'] = '';
        validateAddress['country'] = '';
        validateAddress['streetAddress'] = '';
        setValidateAddress({ ...validateAddress })

        let error = 0;
        if (addressArray.city.trim().length === 0) {
            validateAddress['city'] = "City field is required.";
            error = 1;
        }

        if (addressArray.state.trim().length === 0) {
            validateAddress['state'] = "State field is required.";
            error = 1;
        }

        if (addressArray.pincode.trim().length === 0) {
            validateAddress['pincode'] = "Pincode field is required.";
            error = 1;
        } else if (addressArray.pincode.trim().length !== 5) {
            validateAddress['pincode'] = "Pincode is not correct.";
            error = 1;
        }

        if (addressArray.country.trim().length === 0) {
            validateAddress['country'] = "Country field is required.";
            error = 1;
        }

        if (addressArray.streetAddress.trim().length === 0) {
            validateAddress['streetAddress'] = "Street address field is required.";
            error = 1;
        }

        if(error === 1){
            setValidateAddress({...validateAddress });
            return false;
        }

        const formData = new FormData(event.target);
        formData.append(`lat`,addressArray.lat);
        formData.append(`lng`,addressArray.lng);
        formData.append(`fullAddress`,addressArray.fullAddress);

        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/update-address-details',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                socket.emit('send-notification-signal-to-admin',1)
                props.setLoading(false)
                setPercentage(response.data.percentage);
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
                getProfileStatus();
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
        });
    }
  
    /* Submit identity proof code start */
    const submitIdentityProofData = async (event) => {
        event.preventDefault();

        docValidation['social_security_number'] = "";
        docValidation['website'] = "";
        docValidation['tax_payer_name'] = "";
        docValidation['front_image'] = "";
        docValidation['back_image'] = "";
        setDocValidation({...docValidation });

        let error = 0;
        if(SSNValue.trim().length !== 0) {
            if(SSNValue.trim().length !== 9){
                docValidation['social_security_number'] = 'SSN is invalid.'
                error = 1;
            }
        } else {
            if (social_security_number.trim().length === 0) {
                docValidation['social_security_number'] = 'SSN field is required.'
                error = 1;
            }else if(social_security_number.trim().length !== 9){
                docValidation['social_security_number'] = 'SSN is invalid.'
                error = 1;
            }
        }

        if (tax_payer_name.trim().length === 0) {
            docValidation['tax_payer_name'] = 'Legal Name field is required.'
            error = 1;
        }

        if (website.trim().length > 0) {
            let web = website.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if(web === null){
                docValidation['website'] = 'Website url is invalid.'
                error = 1;
            }
        }

        if(documentType === "passport"){
            if(docValidation.isFrontPassImgUploaded === 0){
                docValidation['front_image'] = 'Front image is required.';
                error = 1;
            }
        }else{
            if(docValidation.isFrontDrivingImgUploaded === 0){
                docValidation['front_image'] = 'Front image is required.';
                error = 1;
            }

            if(docValidation.isBackDrivingImgUploaded === 0){
                docValidation['back_image'] = 'Front image is required.';
                error = 1;
            }
        }

        if(error === 1){
            setDocValidation({...docValidation });
            return false;
        }

        const formData = new FormData(event.target);
        formData.delete('social_security_number');
        if(SSNValue.trim().length === 9) {
            formData.append(`social_security_number`, SSNValue);
        } else {
            formData.append(`social_security_number`, social_security_number);
        }

        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/update-documents',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                socket.emit('send-notification-signal-to-admin',1)
                props.setLoading(false)
                
                setPercentage(response.data.percentage);
                setIsIdentityDocumentUploaded(1);
                getProfileStatus();
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
        });
    };

    const fetchCleanerDetails = async () => {
        props.setLoading(true);
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/cleaner/profile',{
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log('Something went wrong');
        });
        props.setLoading(false);
        if(data.status === 1){
            setExistingState(data.data);
            setPercentage(data.percentage);
        }
    };

    const setExistingDocValidation = (data) => {
        if((Object.keys(data.passport.frontImage).length !== 0)) {
            docValidation['isFrontPassImgUploaded'] = 1;
            setDocValidation({ ...docValidation });
        } else if((Object.keys(data.drivingLicense.frontImage).length !== 0)) {
            docValidation['isFrontDrivingImgUploaded'] = 1;
            setDocValidation({ ...docValidation });
        } else if((Object.keys(data.drivingLicense.backImage).length !== 0)) {
            docValidation['isBackDrivingImgUploaded'] = 1;
            setDocValidation({ ...docValidation });
        }
    }

    const setExistingState = (data) => {
        let {first_name, last_name, phone_number, email, dob, isEmailVerify, isPhoneVerify} = data.userData;

        //set general details
        authData['first_name'] = first_name;
        authData['last_name'] = last_name;
        authData['phone_number'] = phone_number;
        authData['email'] = email;
        authData['dob'] = (typeof dob !== "undefined") ? new Date(dob) : "";
        authData['describeBest'] = data.describeBest;
        authData['experience'] = data.experience;
        authData['shortTermRental'] = data.shortTermRental;
        authData['languages'] = data.languages;
        authData['bio'] = data.bio;
        authData['school'] = data.school;
        authData['isEmailVerify'] = isEmailVerify;
        authData['isPhoneVerify'] = isPhoneVerify;
        
        authData['image'] = (data.profile_image !== null) ? data.profile_image : '/assets/images/image-placeholder.png';
        setAuthData({...authData})

        authValidations['isImageUploaded'] = (Object.keys(data.image).length !== 0) ?  1 : 0;
        setAuthValidations({...authValidations})


        addressArray['fullAddress'] = data.address.fullAddress
        addressArray['city'] = data.address.city
        addressArray['state'] = data.address.state
        addressArray['pincode'] = data.address.pincode
        addressArray['country'] = data.address.country
        addressArray['streetAddress'] = data.address.streetAddress
        addressArray['lat'] = data.address.lat
        addressArray['lng'] = data.address.lng
        setAddressArray({...addressArray})

        setSocialSecurityNumber(data.ssn_no);
        setWebsite(data.website);
        setTaxPayerId(data.taxPayerId);
        setTaxPayerName(data.businessName);

        //setProfileImage((Object.keys(data.image).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.image.filename : placeHolderImage);
        setPassportFrontImage((Object.keys(data.passport.frontImage).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.passport.frontImage.filename : placeHolderImage);
        setDrivingFrontImage((Object.keys(data.drivingLicense.frontImage).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.drivingLicense.frontImage.filename : placeHolderImage);
        setDrivingBackImage((Object.keys(data.drivingLicense.backImage).length !== 0) ? process.env.REACT_APP_BACKENDURL+"images/"+data.drivingLicense.backImage.filename : placeHolderImage);
        
        if((Object.keys(data.passport.frontImage).length !== 0) || (Object.keys(data.drivingLicense.frontImage).length !== 0) || (Object.keys(data.drivingLicense.backImage).length !== 0)) {
            setExistingDocValidation(data);
        }

        if(data.documentType !== ''){
            setDocumentType(data.documentType);
        }

        let tempArr = [];
        if(Object.keys(data.passport.frontImage).length > 0) {
            tempArr.push(process.env.REACT_APP_BACKENDURL + "images/" + data.passport.frontImage.filename);
        }
        if(Object.keys(data.drivingLicense.frontImage).length > 0) {
            tempArr.push(process.env.REACT_APP_BACKENDURL + "images/" + data.drivingLicense.frontImage.filename);
        }
        if(Object.keys(data.drivingLicense.backImage).length > 0) {
            tempArr.push(process.env.REACT_APP_BACKENDURL + "images/" + data.drivingLicense.backImage.filename);
        }
        setDocumentImgArr(tempArr);

        if( (Object.keys(data.passport.frontImage).length !== 0) || (Object.keys(data.drivingLicense.frontImage).length !== 0) ){
            setIsIdentityDocumentUploaded(1)
        }
    }

    // Handle Address form state callback
    const handleAddressCallback = ( keyValue, value) => {
        addressArray[keyValue] = value
        setAddressArray({ ...addressArray })
    } 
    
    
    const manageDocType = (value) => {
        setDocumentType(value)
    }

/**
 * Manage Radio Iputs
 * */ 
    const RadioInput = (props) => {
        return (
            <>
                <label className="list-group-item">
                    <input 
                        className="form-check-input me-1" 
                        name="describeBest" 
                        type="radio" 
                        checked={(props.value === authData.describeBest)}
                        onChange={(e) => {manageTabData('describeBest', e.target.value)}}
                        value={props.value}/>
                        {props.value}
                </label>
            </>
        )
    }

    function togglePhoneModal() {
        setUpdatedPhoneNumber("");
        setUpdatePhoneError("");
        setUpdatePhoneMsg("");
        setOTPError("");
        setEnteredOTP("");
        if(isPhoneModalOpen === false) {
            setPhoneUpdateSection(true);
        } else {
            setPhoneUpdateSection(false);
            setPhoneOtpSection(false);
        }
        setIsPhoneModalOpen(!isPhoneModalOpen);
    }

    const verifyUpdatedPhone = () => {
        setUpdatePhoneError("");
        setUpdatePhoneMsg("");
        setEnteredOTP("");
        let error = 0;

        let enterValue = updatedPhoneNumber.replaceAll(/\s/g,'');
        if(enterValue.length !== 10) {
            setUpdatePhoneError("Phone number must be of 10 digits.");
            error = 1;
            return;
        }

        if(error !== 1) {
            props.setLoading(true);
            axios.post(process.env.REACT_APP_BACKENDURL+'api/common/send-phone-update-otp',{
                role_id: 3,
                phone_number: enterValue
            },{
                headers: {
                    'token': token,
                }
            })
            .then(function (response) {
                if(response.data.status === 1){
                    props.setLoading(false)
                    setUpdatePhoneMsg(response.data.message);
                    setPhoneUpdateSection(false);
                    setPhoneOtpSection(true);
                }else{
                    props.setLoading(false)
                    setUpdatePhoneError(response.data.message);
                }
            })
            .catch(function (error) {
                props.setLoading(false)
            });
        }
    }

    const submitPhoneUpdate = () => {
        setUpdatePhoneError("");
        setUpdatePhoneMsg("");
        setOTPError("");
        let error = 0;
        let enterValue = updatedPhoneNumber.replaceAll(/\s/g,'');
        let OTPValue = enteredOTP.replaceAll(/\s/g,'');
        if(OTPValue.length === 0) {
            setOTPError("Please enter the OTP.");
            error = 1;
            return;
        } else if(OTPValue.length > 0 && OTPValue.length !== 6) {
            setOTPError("OTP is not correct. Please check and try again");
            error = 1;
            return;
        }

        if(error !== 1) {
            props.setLoading(true);
            axios.post(process.env.REACT_APP_BACKENDURL+'api/common/verify-otp-to-update-phone',{
                role_id: 3,
                phone_number: enterValue,
                otp: OTPValue
            },{
                headers: {
                    'token': token,
                }
            })
            .then(function (response) {
                togglePhoneModal();
                if(response.data.status === 1){
                    props.setLoading(false)
                    fetchCleanerDetails();
                    authData['phone_number'] = enterValue;
                    setAuthData({ ...authData });
                    helper.Toast().fire({ icon: 'success', title: response.data.message });
                }else{
                    props.setLoading(false)
                    helper.Toast().fire({ icon: 'error', title: response.data.message });
                }
            })
            .catch(function (error) {
                props.setLoading(false)
                togglePhoneModal();
                helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
            });
        }
    }

    function toggleSSNModal() {
        setUpdatedSSNError("");
        setEnteredSSNNumber("");
        setIsSSNModalOpen(!isSSNModalOpen);
    }

    const updateSsnNumber = () => {
        setUpdatedSSNError("");
        let error = 0;

        let enterValue = enteredSSNNumber.replaceAll(/\s/g,'');
        if(enterValue.length !== 9) {
            setUpdatedSSNError("SSN number should be 9 digits.");
            error = 1;
            return;
        }

        if(error !== 1) {
            setSocialSecurityNumber(enterValue);
            toggleSSNModal();
            return;
        }
    }

    function toggleVerificationModal() {
        setVerificationOtp("");
        setVerificationOtpError("");
        setIsVerificationModalOpen(!isVerificationModalOpen);
    }

    const sendAccountVerifyOtp = async(typeValue) => {
        props.setLoading(true);
        setVerificationType('');
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/send-account-verification-otp',{
            role_id: 3,
            type: typeValue
        },{
            headers: {
                'Content-Type': 'application/json',
                'token': token,
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                setVerificationType(typeValue);
                toggleVerificationModal();
                // helper.Toast().fire({
                //     icon: 'success',
                //     title: response.data.message
                // });    
            }else{
                helper.Toast().fire({ icon: 'error', title: response.data.message });
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            helper.Toast().fire({ icon: 'error', title: 'Something went wrong. Please try again.' });
        });
    }

    const verifyAccountVerificationOTP = async() => {
        setVerificationOtpError("");
        let error = 0;
        if(verificationOtp.length === 0) {
            setVerificationOtpError("Please enter OTP");
            error = 1;
        } else if(verificationOtp.length !== 6) {
            setVerificationOtpError("OTP should be 6 digits number");
            error = 1;
        }
     
        if(error === 1){
            return;
        }
        props.setLoading(true);
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/verify-account-otp',{
            role_id: 3,
            type: verificationType,
            otp: verificationOtp
        },{
            headers: {
                'Content-Type': 'application/json',
                'token': token,
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                fetchCleanerDetails();
                setVerificationType('');
                toggleVerificationModal();
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                });
            }else{
                setVerificationOtpError(response.data.message);
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            toggleVerificationModal();
            helper.Toast().fire({
                icon: 'error',
                title: 'Something went wrong. Please try again.'
            });
        });
    }

    function activeStep(step = 1){
        setStepKey(stepKey+1);
        if(step === 1){
            setStep1('active');
            setStep2('');
            setStep3('');
        }else if(step === 2){
            setStep1('');
            setStep2('active');
            setStep3('');

        }else if(step === 3){
            setStep1('');
            setStep2('');
            setStep3('active');
        }
    };

    const years = range(1950, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const asteriskMarkComponent = () => {
        return (<span style={{font: 'caption'}}>*</span>);
    }

    function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	}

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">My Profile</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/cleaner/dashboard">Overview</Link></li>
                                    <li className="breadcrumb-item"><Link to="/cleaner/settings">Settings</Link></li>
                                    <li className="breadcrumb-item active">My Profile</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="flex-grow-1">
                                        <h5 className="card-title mb-0">Complete Your Profile</h5>
                                    </div>
                                </div>
                                <div className="progress animated-progress custom-progress progress-label">
                                    <div className="progress-bar bg-primary" role="progressbar" style={{"width": (percentage) + "%"}}
                                        aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                                        <div className="label">{percentage}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <form 
                        onSubmit={submitIdentityProofData} 
                        id="submit-identity-proof-data" 
                        method="post" 
                        encType="multipart/form-data"> */}

                        <div className="col-lg-12">

                            <div className="card" key={stepKey}>

                                <div className="card-header mb-2">
                                    <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 profile-list"
                                        role="tablist">
                                        <li className="nav-item">
                                            <a className={'nav-link ' +  ((step1 === 'active') ? 'active' : '' )} data-bs-toggle="tab" href="#pills-basic-info"
                                                role="tab">
                                                <i className="fas fa-home"></i> Personal Details
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={'nav-link ' +  ((step2 === 'active') ? 'active' : '' )}  data-bs-toggle="tab" href="#pills-address" role="tab">
                                                <i className="far fa-envelope"></i> Address
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={'nav-link ' +  ((step3 === 'active') ? 'active' : '' )}   data-bs-toggle="tab" href="#pills-identity-proof" role="tab">
                                                <i className="far fa-envelope"></i> Document Verification
                                                {documentVerificationError && <i className="fs-10 badge rounded-pill" style={errorDot}><span className='d-none'>0</span></i>}
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="card-body" id="verification">
                                    <div className="tab-content">
                                        <div className={'tab-pane  ' +  ((step1 === 'active') ? 'active' : '' )}  id="pills-basic-info" role="tabpanel">

                                            <form 
                                                onSubmit={submitPersonalData} 
                                                method="post" 
                                                encType="multipart/form-data"
                                            >
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="text-center">
                                                            <div className="profile-user position-relative d-inline-block mx-auto  mb-2" style={{height: "140px", width: "140px"}}>
                                                                <img src={authData.image}
                                                                    className="rounded-circle avatar-xl img-thumbnail"
                                                                    alt="user-profile"
                                                                    style={{height: "100%", width: "100%", objectFit: "cover"}}
                                                                />
                                                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                                    <input id="profile-img-file-input" 
                                                                        type="file"
                                                                        name="image"
                                                                        accept="image/png, image/jpeg"
                                                                        className="profile-img-file-input"
                                                                        onChange={(e) => handleFileChange(e,'0')}
                                                                    />
                                                                    <label htmlFor="profile-img-file-input"
                                                                        className="profile-photo-edit avatar-xs">
                                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                                            <i className="ri-camera-fill"></i>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <h5 className="fs-16 mb-1">{authData.first_name} {authData.last_name}</h5>
                                                            {authValidations.image && <div className="text-danger"> {authValidations.image} </div>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <div className='row'>
                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">First Name{asteriskMarkComponent()}</label>
                                                            
                                                                <input type="text" 
                                                                    className="form-control" 
                                                                    name='first_name'
                                                                    onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)}
                                                                    onChange={manageAuthState}
                                                                    placeholder='Enter First Name'
                                                                    value={authData.first_name}
                                                                    maxLength={30} />
                                                                {authValidations.first_name && <div className="text-danger"> {authValidations.first_name} </div>}
                                                            
                                                            </div>

                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Last Name{asteriskMarkComponent()}</label>
                                                            
                                                                <input type="text" 
                                                                    className="form-control" 
                                                                    placeholder='Enter Last Name'
                                                                    value={authData.last_name}
                                                                    name='last_name'
                                                                    onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)}
                                                                    onChange={manageAuthState}
                                                                    maxLength={30} />
                                                                {authValidations.last_name && <div className="text-danger"> {authValidations.last_name} </div>}
                                                                
                                                            </div>

                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Email{asteriskMarkComponent()}
                                                                    {
                                                                        (authData.isEmailVerify === 0) ? 
                                                                        <span className="btn btn-sm bg-primary btn-outline-list text-white fw-6 ms-2" onClick={(e) => sendAccountVerifyOtp('email')}>Verify</span>
                                                                        :
                                                                        <span className="badge completed p-1 ms-2">Verified</span>
                                                                    }
                                                                </label>
                                                                <input type="text" 
                                                                    className="form-control" 
                                                                    placeholder='Enter Email'
                                                                    value={helper.obscureEmail(authData.email)}
                                                                    name='email'
                                                                    // onChange={manageAuthState}
                                                                    maxLength={50} 
                                                                    readOnly 
                                                                    style={{backgroundColor: '#cdccc747'}} />
                                                                    
                                                                {authValidations.email && <div className="text-danger"> {authValidations.email} </div>}
                                                                
                                                            </div>

                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">Phone Number{asteriskMarkComponent()}
                                                                {
                                                                    (authData.isPhoneVerify === 0) ? 
                                                                    <span className="btn btn-sm bg-primary btn-outline-list text-white fw-6 ms-2" onClick={(e) => sendAccountVerifyOtp('phone')}>Verify</span>
                                                                    :
                                                                    <span className="badge completed p-1 ms-2">Verified</span>
                                                                }
                                                                </label>
                                                                <div className="position-relative auth-pass-inputgroup">
                                                                    <input type="text" 
                                                                        className="form-control" 
                                                                        placeholder='Enter Phone Number'
                                                                        // value={authData.phone_number}
                                                                        value={helper.obscurePhone(authData.phone_number)}
                                                                        name='phone_number'
                                                                        // onChange={managePhone}
                                                                        maxLength={10} 
                                                                        readOnly 
                                                                        style={{backgroundColor: '#cdccc747'}} />
                                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon edit-icon-btn" type="button" id="edit-phone" onClick={togglePhoneModal} ><i className='bx bxs-edit edit-icon-lg'></i></button>
                                                                </div>
                                                                {authValidations.phone_number && <div className="text-danger"> {authValidations.phone_number} </div>}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label">Languages{asteriskMarkComponent()}</label>
                                                        
                                                        <input type="text" 
                                                            className="form-control" 
                                                            placeholder='Enter Languages'
                                                            name='languages'
                                                            value={authData.languages}
                                                            onKeyDown={(e) => helper.checkAlphabetInputWithComma(e)}
                                                            onChange={manageAuthState}
                                                            maxLength={50} />
                                                        {authValidations.languages && <div className="text-danger"> {authValidations.languages} </div>}
                                                    
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label">Date Of Birth{asteriskMarkComponent()}</label>
                                                        
                                                        <DatePicker 
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled,
                                                            }) => (
                                                                <div
                                                                style={{
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                                >
                                                                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                                    {"<"}
                                                                </button>
                                                                <select
                                                                    value={getYear(date)}
                                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                                >
                                                                    {years.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                    ))}
                                                                </select>

                                                                <select
                                                                    value={months[getMonth(date)]}
                                                                    onChange={({ target: { value } }) =>
                                                                    changeMonth(months.indexOf(value))
                                                                    }
                                                                >
                                                                    {months.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                    ))}
                                                                </select>

                                                                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                                    {">"}
                                                                </button>
                                                                </div>
                                                            )}

                                                            selected={authData.dob}
                                                            className='form-control'
                                                            maxDate={new Date()}
                                                            name='dob'
                                                            value={authData.dob}
                                                            placeholderText="Select Date Of Birth"
                                                            onChange={(date) => {manageTabData('dob',date)}} />
                                                            {authValidations.dob && <div className="text-danger"> {authValidations.dob} </div>}
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label">What describes you best?{asteriskMarkComponent()}</label>                                                     
                                                        <div className="list-group">
                                                            <RadioInput 
                                                                value="I am an independent professional cleaner" />
                                                            <RadioInput 
                                                                value="I run a cleaning company with employees" />
                                                            <RadioInput 
                                                                value="I am cleaner for a company" />
                                                        </div>
                                                        {authValidations.describeBest && <div className="text-danger"> {authValidations.describeBest} </div>}
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label">Bio (Optional)</label>
                                                        <textarea
                                                            type="text" 
                                                            className="form-control ptextBoxHeight" 
                                                            name='bio'
                                                            placeholder='Enter Bio'
                                                            value={authData.bio}
                                                            onChange={(e) => {manageTabData('bio', e.target.value)}} 
                                                            maxLength={250} ></textarea>
                                                            <div className="float-end me-1 word-limit-count">{characterCount}/250 Characters</div>
                                                        {authValidations.bio && <div className="text-danger"> {authValidations.bio} </div>}
                                                    </div>
                                                    
                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label">School (Optional)</label>
                                                        <div className="mb-4">
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder='Enter School'
                                                                name='school'
                                                                value={authData.school}
                                                                onKeyDown={(e) => helper.checkAlphabetInput(e)}
                                                                onChange={(e) => manageTabData('school', e.target.value)}
                                                                maxLength={50} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label">Years of experiene as a cleaner?{asteriskMarkComponent()}</label>
                                                        <select 
                                                            className="form-select"
                                                            name='experience'
                                                            value={authData.experience}
                                                            onChange={(e) => {manageTabData('experience', e.target.value)}}
                                                            aria-label="Default select example">
                                                            <option value="">Select your experience</option>
                                                            <option value="0">Less than 1 year</option>
                                                            <option value="1">1 year</option>
                                                            <option value="2">2 years</option>
                                                            <option value="3">3 years</option>
                                                            <option value="4">4 years</option>
                                                            <option value="5">5 years</option>
                                                            <option value="6">More than 5+ years</option>
                                                        </select>
                                                        {authValidations.experience && <div className="text-danger"> {authValidations.experience} </div>}
                                                    </div>
                                            

                                                    <div className="col-md-4 mb-3">
                                                        <label className="form-label">Have you cleaned short term rental</label>
                                                        <div className="form-check form-switch">
                                                            <label htmlFor="withradios-showcode" className="form-label text-muted">Yes</label>
                                                            <input 
                                                                className="form-check-input code-switcher" 
                                                                type="checkbox" 
                                                                role="switch" 
                                                                id="termRental"
                                                                onChange={manageCheckBox}
                                                                checked={authData.shortTermRental === "yes"}
                                                                value={authData.shortTermRental} />    

                                                            {authValidations.shortTermRental && <div className="text-danger"> {authValidations.shortTermRental} </div>}                                                              
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-3">
                                                        <div className="d-flex align-items-start gap-3 mt-4">
                                                            <button type="submit" className="btn btn-add ms-auto">Update</button>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </form>
                                            
                                        </div>
                                        <div className={'tab-pane  ' +  ((step2 === 'active') ? 'active' : '' )}   id="pills-address" role="tabpanel">
                                            <Address 
                                                addressArray={addressArray}
                                                parentCallback={handleAddressCallback}
                                                submitAddressData={submitAddressData}
                                                validation={validateAddress}
                                            />
                                        </div>
                                        <div className={'tab-pane  ' +  ((step3 === 'active') ? 'active' : '' )}   id="pills-identity-proof" role="tabpanel">
                                            <form 
                                                onSubmit={submitIdentityProofData} 
                                                method="post" 
                                                encType="multipart/form-data"
                                            >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="form-label">SSN{asteriskMarkComponent()}</label>
                                                        <div className="mb-4">
                                                            <div className="position-relative auth-pass-inputgroup">
                                                            { (social_security_number === "") ?
                                                                <input type="text"
                                                                    className="form-control"
                                                                    placeholder='Enter SSN'
                                                                    name='social_security_number'
                                                                    value={SSNValue}
                                                                    onKeyDown={(e) => helper.checkNumericInput(e)}
                                                                    onChange={(e) => setSSNValue(e.target.value)}
                                                                    maxLength={9} />
                                                                :
                                                                <>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    placeholder='Enter SSN'
                                                                    name='social_security_number'
                                                                    // value={social_security_number}
                                                                    value={helper.obscureSSN(social_security_number)}
                                                                    // onChange={(e) => setSocialSecurityNumber(e.target.value)}
                                                                    maxLength={9} 
                                                                    readOnly 
                                                                    style={{backgroundColor: '#cdccc747', borderColor: SSNFocus && 'red'}} />
                                                                {(!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1)) &&
                                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon edit-icon-btn" type="button" id="edit-ssn" onClick={toggleSSNModal} ><i className='bx bxs-edit edit-icon-lg'></i></button>
                                                                }
                                                                </>
                                                            }
                                                            </div>
                                                                
                                                            {docValidation.social_security_number && <div className="text-danger"> {docValidation.social_security_number} </div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Website (Optional)</label>
                                                        <div className="mb-4">
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder='Enter Website URL'
                                                                name='website'
                                                                value={website}
                                                                onChange={(e) => setWebsite(e.target.value)}
                                                                maxLength={30}
                                                                readOnly={(profileCompleteStatus && profileCompleteStatus === 1) ? true : false}
                                                                style={{backgroundColor: (profileCompleteStatus && profileCompleteStatus === 1) && '#cdccc747'}}
                                                            />
                                                            {docValidation.website && <div className="text-danger"> {docValidation.website}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Business Tax ID (TIN) (Optional)</label>
                                                        <div className="mb-4">
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder='Business Tax ID (TIN)'
                                                                name='tax_payer_id'
                                                                value={tax_payer_id}
                                                                onKeyDown={(e) => helper.checkNumericInput(e)}
                                                                onChange={(e) => setTaxPayerId(e.target.value)}
                                                                maxLength={9}
                                                                style={{backgroundColor: (profileCompleteStatus && profileCompleteStatus === 1) && '#cdccc747', borderColor: (tax_payer_id !== "" && SSNFocus) && 'red'}} 
                                                                readOnly={(profileCompleteStatus && profileCompleteStatus === 1) ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Legal Name{asteriskMarkComponent()}</label>
                                                        <div className="mb-4">
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder='Legal Name'
                                                                name='tax_payer_name'
                                                                value={tax_payer_name}
                                                                onKeyDown={(e) => helper.checkAlphabetInput(e)}
                                                                onChange={(e) => setTaxPayerName(helper.capitalizeFirstLetter(e.target.value))}
                                                                maxLength={60} 
                                                                readOnly={(profileCompleteStatus && profileCompleteStatus === 1) ? true : false}
                                                                style={{backgroundColor: (profileCompleteStatus && profileCompleteStatus === 1) && '#cdccc747'}}
                                                            />

                                                            {docValidation.tax_payer_name && <div className="text-danger"> {docValidation.tax_payer_name}</div>}
                                                        </div>
                                                    </div>


                                                    <div className="com-md-12">
                                                        <h5 className="mb-3">Choose Document Type</h5>

                                                        <div className="d-flex gap-2">
                                                            { (isIdentityDocumentUploaded === 0 || (isIdentityDocumentUploaded === 1 && documentType === "passport")) ?
                                                            <div>
                                                                <input 
                                                                    type="radio" 
                                                                    className="btn-check" 
                                                                    id="passport"
                                                                    value="passport"
                                                                    name="documentType"
                                                                    // defaultChecked={documentType === "passport"}
                                                                    checked={documentType === "passport"}
                                                                    onChange={(e) => {manageDocType(e.target.value)}}
                                                                    {...setDocumentType}
                                                                />
                                                                <label className="btn btn-outline-list" htmlFor="passport">Passport</label>
                                                            </div>
                                                             : <></>
                                                            }
                                                            { (isIdentityDocumentUploaded === 0 || (isIdentityDocumentUploaded === 1 && documentType !== "passport")) ?
                                                            <div>
                                                                <input 
                                                                    type="radio" 
                                                                    className="btn-check" 
                                                                    id="driving-licence"
                                                                    name="documentType"
                                                                    value="driving_licence"
                                                                    // defaultChecked={documentType === "driving_licence"}
                                                                    checked={documentType === "driving_licence"}
                                                                    onChange={(e) => {manageDocType(e.target.value)}}
                                                                    {...setDocumentType}
                                                                />
                                                                <label className="btn btn-outline-list" htmlFor="driving-licence">Driving Licence </label>
                                                            </div>
                                                             : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                    <div className={"col-md-4 " + ((documentType !== "passport") ? " d-none" : "") }>
                                                        <div className="mb-2">
                                                            <h5>Passport Front Image{asteriskMarkComponent()}</h5>
                                                            { (isIdentityDocumentUploaded > 0 && (!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1))) && 
                                                                <div className='py-1'>
                                                                    <input type="file" name="passport[frontImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '1')} /> 
                                                                </div>
                                                            }
                                                            <div className="dropzone d-flex align-items-center">
                                                                { (isIdentityDocumentUploaded === 0) ? 
                                                                <div className="fallback">
                                                                    <input type="file" name="passport[frontImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '1')} />
                                                                </div>
                                                                  : <></>
                                                                }
                                                                <div className="dz-message needsclick text-center">
                                                                    <div className="d-image">
                                                                        <img src={passportFrontImage} alt="passportfrontimg" onClick={() => openLightboxOnSlide(1)} />
                                                                    </div>
                                                                    {(isIdentityDocumentUploaded === 0) && 
                                                                        <div className="h-image"> 
                                                                            <img src="/assets/images/placeholder/add-icon.png" alt="passportfrontimg" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {docValidation.front_image && <div className="text-danger"> {docValidation.front_image}</div>}
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-4 " + ((documentType !== "driving_licence") ? " d-none" : "") }>
                                                        <div className="mb-2">
                                                            <h5>Driving Licence Front Image{asteriskMarkComponent()}</h5>
                                                            { (isIdentityDocumentUploaded > 0 && (!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1))) && 
                                                                <div className='py-1'>
                                                                    <input type="file" name="drivingLicense[frontImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '3')} />
                                                                </div>
                                                            }
                                                            <div className="dropzone d-flex align-items-center">
                                                                { (isIdentityDocumentUploaded === 0) ?
                                                                    <div className="fallback">
                                                                        <input type="file" name="drivingLicense[frontImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '3')} />
                                                                    </div>
                                                                    : <></>
                                                                 }
                                                                <div className="dz-message needsclick text-center">
                                                                    <div className="d-image">
                                                                        <img src={drivingFrontImage} alt="drivingFrontImage" onClick={() => openLightboxOnSlide(1)} />
                                                                    </div>
                                                                    {(isIdentityDocumentUploaded === 0) && 
                                                                        <div className="h-image"> 
                                                                            <img src="/assets/images/placeholder/add-icon.png" alt="drivingFrontImage" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {docValidation.front_image && <div className="text-danger"> {docValidation.front_image}</div>}
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-4 " + ((documentType !== "driving_licence") ? " d-none" : "") }>
                                                        <div className="mb-2">
                                                            <h5>Driving Licence Back Image{asteriskMarkComponent()}</h5>
                                                            { (isIdentityDocumentUploaded > 0 && (!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1))) && 
                                                                <div className='py-1'>
                                                                    <input type="file" name="drivingLicense[backImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '4')} />
                                                                </div>
                                                            }
                                                            <div className="dropzone d-flex align-items-center">
                                                                { (isIdentityDocumentUploaded === 0) ?
                                                                <div className="fallback">
                                                                    <input type="file" name="drivingLicense[backImage]" accept="image/png, image/jpeg" onChange={(e) => handleFileChange(e, '4')} />
                                                                </div>
                                                                : <></>
                                                                }
                                                                <div className="dz-message needsclick text-center">
                                                                    <div className="d-image">
                                                                        <img src={drivingBackImage} alt="drivingBackImage" onClick={() => openLightboxOnSlide(2)} />
                                                                    </div>
                                                                    {(isIdentityDocumentUploaded === 0) && 
                                                                        <div className="h-image"> 
                                                                            <img src="/assets/images/placeholder/add-icon.png" alt="drivingBackImage" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {docValidation.back_image && <div className="text-danger"> {docValidation.back_image}</div>}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <FsLightbox
                                                            toggler={lightboxController.toggler}
                                                            slide={lightboxController.slide}
                                                            sources={documentImgArr}
                                                        />
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="d-flex align-items-start gap-3 mt-4">
                                                            {(!profileCompleteStatus || (profileCompleteStatus && profileCompleteStatus !== 1)) &&
                                                                <button type="submit" className="btn btn-add ms-auto">Update</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </form> */}
                  
                </div>

                <Modal isOpen={isPhoneModalOpen}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Update Phone Number</h5>
                                <button type="button" className="btn-close"  onClick={togglePhoneModal}></button>
                            </div>
                            <div className="modal-body">
                                {(phoneUpdateSection === true) &&
                                    <div className="d-flexx align-items-center justify-content-left gap-3 pt-3">
                                        <label className="form-label">Enter New Phone Number:</label>
                                        <div className="w-100">
                                            <input 
                                                type='text' 
                                                maxLength={10}
                                                className='form-control'
                                                onKeyDown={(e) => helper.checkNumericInput(e)}
                                                onChange={(e) => setUpdatedPhoneNumber(e.target.value)}
                                                value={(updatedPhoneNumber !== "") ? updatedPhoneNumber : '' } />

                                            {updatePhoneError && <div className="text-danger"> {updatePhoneError} </div>}
                                        </div>
                                    </div>
                                }

                                {(phoneOtpSection === true) &&
                                    <div className="d-flexx align-items-center justify-content-left gap-3 pt-3">
                                        <label className="form-label">Enter OTP:</label>
                                        {updatePhoneMsg && <div className="text-success"> {updatePhoneMsg} </div>}
                                        <div className="w-100">
                                            <input 
                                                type='text' 
                                                maxLength={6}
                                                className='form-control'
                                                onKeyDown={(e) => helper.checkNumericInput(e)}
                                                onChange={(e) => setEnteredOTP(e.target.value)}
                                                value={(enteredOTP !== "") ? enteredOTP : '' } 
                                                />
                                                
                                            {OTPError && <div className="text-danger"> {OTPError} </div>}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer justify-content-center gap-3 mt-2">
                                {(phoneUpdateSection === true) &&
                                    <button 
                                        type="button"
                                        onClick={verifyUpdatedPhone}
                                        className="btn btn-add added-btn  waves-effect waves-light">Verify</button>
                                }
                                {(phoneOtpSection === true) &&
                                    <button 
                                        type="button"
                                        onClick={submitPhoneUpdate}
                                        className="btn btn-add added-btn  waves-effect waves-light">Submit</button>
                                }
                                <button 
                                    type="button"
                                    onClick={togglePhoneModal}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                            </div>

                        </div>
                    </div>
			    </Modal>

                <Modal isOpen={isSSNModalOpen}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Update SSN Number</h5>
                                <button type="button" className="btn-close"  onClick={toggleSSNModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex align-items-center justify-content-left gap-3 pt-3">
                                    <label className="form-label">SSN:</label>
                                    <div className="w-100">
                                        <input 
                                            type='text' 
                                            maxLength={9}
                                            className='form-control'
                                            onKeyDown={(e) => helper.checkNumericInput(e)}
                                            onChange={(e) => setEnteredSSNNumber(e.target.value)}
                                            />

                                        {updatedSSNError && <div className="text-danger"> {updatedSSNError} </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center gap-3 mt-2">
                                <button 
                                    type="button"
                                    onClick={updateSsnNumber}
                                    className="btn btn-add added-btn  waves-effect waves-light">Submit</button>
                                <button 
                                    type="button"
                                    onClick={toggleSSNModal}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                            </div>

                        </div>
                    </div>
			    </Modal>

                <Modal isOpen={isVerificationModalOpen}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div>
                                    <h5 className="modal-title">
                                        {
                                            (verificationType === 'email') && 'Enter the OTP to Verify Your Email'
                                        }
                                        {
                                            (verificationType === 'phone') && 'Enter the OTP to Verify Your Phone Number'
                                        }
                                    </h5>
                                </div>
                                <button type="button" className="btn-close" onClick={toggleVerificationModal}></button>
                            </div>
                            <div>
                                {
                                    (verificationType === 'email') && '(We have sent you an OTP on your given email.)'
                                }
                                {
                                    (verificationType === 'phone') && '(We have sent you an OTP on your given phone number.)'
                                }
                            </div>
                            <div className="modal-body">
                                <div className="d-flex align-items-center justify-content-left gap-3 pt-3">
                                    <label className="form-label">OTP:</label>
                                    <div className="w-100">
                                        <input 
                                            type='text' 
                                            maxLength={6}
                                            className='form-control'
                                            onKeyDown={(e) => helper.checkNumericInput(e)}
                                            onChange={(e) => setVerificationOtp(e.target.value)}
                                            />

                                        {verificationOtpError && <div className="text-danger"> {verificationOtpError} </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center gap-3 mt-2">
                                <button 
                                    type="button"
                                    onClick={verifyAccountVerificationOTP}
                                    className="btn btn-add added-btn  waves-effect waves-light">Verify</button>
                                <button 
                                    type="button"
                                    onClick={toggleVerificationModal}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                            </div>

                        </div>
                    </div>
			    </Modal>
            </div>
        </div>
    )
}

export default Profile