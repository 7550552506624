import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import helper from '../../helper/index';

function WorkingHours(props) {  
    const params = useParams();
    const token = Cookies.get('token');

    // const [mondayTiming, setMondayTiming] = useState([{ startTime: "", endTime: "", }]);
    const [mondayTiming, setMondayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);
    const [tuesdayTiming, setTuesdayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);
    const [wednesdayTiming, setWednesdayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);
    const [thursdayTiming, setThursdayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);
    const [fridayTiming, setFridayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);
    const [saturdayTiming, setSaturdayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);
    const [sundayTiming, setSundayTiming] = useState([{ startTime: "", endTime: "", day: 0}]);

    const updateBasicDetail = (name, value, i, day) => {
        console.log(value);
        if(day === 1) {
            let newWeeklyTiming = [...mondayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setMondayTiming(newWeeklyTiming);
        } else if(day === 2) {
            let newWeeklyTiming = [...tuesdayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setTuesdayTiming(newWeeklyTiming);
        } else if(day === 3) {
            let newWeeklyTiming = [...wednesdayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setWednesdayTiming(newWeeklyTiming);
        } else if(day === 4) {
            let newWeeklyTiming = [...thursdayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setThursdayTiming(newWeeklyTiming);
        } else if(day === 5) {
            let newWeeklyTiming = [...fridayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setFridayTiming(newWeeklyTiming);
        } else if(day === 6) {
            let newWeeklyTiming = [...saturdayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setSaturdayTiming(newWeeklyTiming);
        } else if(day === 7) {
            let newWeeklyTiming = [...sundayTiming];
            newWeeklyTiming[i][name] = value;
            newWeeklyTiming[i]['day'] = day;
            setSundayTiming(newWeeklyTiming);
        }
    }

    const addNewRow = (e, day) => {
        e.preventDefault();
        console.log("Added");
        if(day === 1) {
            setMondayTiming([...mondayTiming, { startTime: "", endTime: "", day: 0 }]);
        } else if(day === 2) {
            setTuesdayTiming([...tuesdayTiming, { startTime: "", endTime: "", day: 0 }]);
        } else if(day === 3) {
            setWednesdayTiming([...wednesdayTiming, { startTime: "", endTime: "", day: 0 }]);
        } else if(day === 4) {
            setThursdayTiming([...thursdayTiming, { startTime: "", endTime: "", day: 0 }]);
        } else if(day === 5) {
            setFridayTiming([...fridayTiming, { startTime: "", endTime: "", day: 0 }]);
        } else if(day === 6) {
            setSaturdayTiming([...saturdayTiming, { startTime: "", endTime: "", day: 0 }]);
        } else if(day === 7) {
            setSundayTiming([...sundayTiming, { startTime: "", endTime: "", day: 0 }]);
        }
    }

    const deleteRow = (e, i, day) => {
        e.preventDefault();
        console.log("Deleted");
        if(day === 1) {
            let deleteTime = [...mondayTiming];
            deleteTime.splice(i, 1);
            setMondayTiming(deleteTime);
        } else if(day === 2) {
            let deleteTime = [...tuesdayTiming];
            deleteTime.splice(i, 1);
            setTuesdayTiming(deleteTime);
        } else if(day === 3) {
            let deleteTime = [...wednesdayTiming];
            deleteTime.splice(i, 1);
            setWednesdayTiming(deleteTime);
        } else if(day === 4) {
            let deleteTime = [...thursdayTiming];
            deleteTime.splice(i, 1);
            setThursdayTiming(deleteTime);
        } else if(day === 5) {
            let deleteTime = [...fridayTiming];
            deleteTime.splice(i, 1);
            setFridayTiming(deleteTime);
        } else if(day === 6) {
            let deleteTime = [...saturdayTiming];
            deleteTime.splice(i, 1);
            setSaturdayTiming(deleteTime);
        } else if(day === 7) {
            let deleteTime = [...sundayTiming];
            deleteTime.splice(i, 1);
            setSundayTiming(deleteTime);
        }
    }

    useEffect(() => {
        console.log(mondayTiming);
    }, [mondayTiming]);

    const fetchData = () => {
        axios.get(process.env.REACT_APP_BACKENDURL + 'api/cleaner/fetch-availability-detail',
                    {headers: {'Content-Type': 'application/json', 'token': token}}
                )
                .then(function (response) {
                    props.setLoading(false)
                    if (response.data.status === 1) {
                        if(response.data.data !== null) {
                            const unavailableData = response.data.data.unavailable;
                            if(unavailableData.length > 0) {
                                let newWeeklyTiming = [...mondayTiming];
                                unavailableData.map((data, i) => {
                                    newWeeklyTiming[i] = {
                                        startTime: new Date(Date.parse(data.fromTime)),
                                        endTime: new Date(Date.parse(data.toTime)),
                                        day: data.day
                                    };
                                })
                                setMondayTiming(newWeeklyTiming);
                            }
                        }                    
                    }
                });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const saveFormData = (e) => {
        e.preventDefault();
        props.setLoading(true);
        console.log('mondayTiming', mondayTiming);
        // var weeklyData = [...mondayTiming, ...mondayTiming];
        var weeklyData = [...mondayTiming, ...tuesdayTiming];
        axios.post(process.env.REACT_APP_BACKENDURL + 'api/cleaner/update-availability-detail',
                    weeklyData,
                    {headers: {'Content-Type': 'application/json', 'token': token}}
                )
                .then(function (response) {
                    props.setLoading(false)
                    console.log('api response', response);
                    if (response.data.status === 1) {
                        helper.Toast().fire({ icon: 'error', title: "Your availability timing updated successfully." });
                    } else {
                        helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
                    }
                });
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Working Hours</h4>

                            <div className="page-title-right">

                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Working Hours</li>
                                </ol>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">

                        <div className="card">
                            <div className="card-body pt-4">
                                <div className="row">
                                    <div className="col-8 more-detail">
                                        <div className="accordion custom-accordionwithicon custom-accordion-border accordion-border-box accordion-secondary" id="accordionBordered">
                                            {/* Monday Section */}
                                            <div className="accordion-item material-shadow">
                                                <h2 className="accordion-header" id="accordionborderedExample1">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_borderedExamplecollapse1" aria-expanded="true" aria-controls="accor_borderedExamplecollapse1">
                                                        <div className="flex-shrink-0 pl-3">
                                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                                <input className="form-check-input code-switcher" type="checkbox" value='1' id="monday" />
                                                            </div>
                                                            <span className='px-4' style={{bottom: '6px', position: 'relative'}}><strong>Monday</strong></span>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="accor_borderedExamplecollapse1" className="accordion-collapse collapse show" aria-labelledby="accordionborderedExample1" data-bs-parent="#accordionBordered">
                                                    <div className="accordion-body">
                                                        <div className="col-12 mt-4">
                                                            {mondayTiming.map((timing, i) => {

                                                                let currentStartTime = timing.startTime ? timing.startTime : null;
                                                                let currentEndTime = timing.endTime ? timing.endTime : null;
                                                                let nextStartTime = mondayTiming[i+1] ? mondayTiming[i+1].startTime : null;
                                                                let nextEndTime = mondayTiming[i+1] ? mondayTiming[i+1].endTime : null;
                                                                let previousStartTime = mondayTiming[i-1] ? mondayTiming[i-1].startTime : null;
                                                                let previousEndTime = mondayTiming[i-1] ? mondayTiming[i-1].endTime : null;
                                                                // console.log(previousEndTime);
                                                                
                                                                return (<div key={i} className="row time-selecter monday-row">
                                                                    <div className="col-md-5 number mb-4">
                                                                        <div className="input-group flex-nowrap">
                                                                            <span id="basic-addon1" className="input-group-text" ><i className="ri-time-fill"></i></span>
                                                                            <DatePicker
                                                                            className='form-control'
                                                                            selected={currentStartTime}
                                                                            onChange={(date) => updateBasicDetail('startTime', date, i, 1)}
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            minTime={setMinutes(previousEndTime, 40)}
                                                                            maxTime={setHours(setMinutes(previousEndTime, 45), 23)}
                                                                            timeIntervals={60}
                                                                            timeCaption="Time"
                                                                            dateFormat="h:mm aa"
                                                                            placeholderText="Start Time"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5 number end-time mb-4">
                                                                        <div className="input-group flex-nowrap">
                                                                            <span id="basic-addon1" className="input-group-text" ><i className="ri-time-fill"></i></span>
                                                                            <DatePicker
                                                                            className='form-control'
                                                                            selected={currentEndTime}
                                                                            onChange={(date) => updateBasicDetail('endTime', date, i, 1)}
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            minTime={setMinutes(currentStartTime, 40)}
                                                                            maxTime={setHours(setMinutes(currentStartTime, 45), 23) }
                                                                            timeIntervals={60}
                                                                            timeCaption="Time"
                                                                            dateFormat="h:mm aa"
                                                                            placeholderText="End Time"
                                                                            />

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="col-md-2 mb-4">
                                                                        <i className='bx bx-plus-circle w-add-btn' onClick={(e) => addNewRow(e, 1)}></i>
                                                                        &nbsp; &nbsp; &nbsp;
                                                                        {
                                                                            (i !== 0) &&
                                                                            <i className="ri-delete-bin-line w-delete-btn" onClick={(e) => deleteRow(e, i, 1)}></i>
                                                                        }
                                                                    </div>
                                                                </div>)
                                                            })}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Tuesday Section */}
                                            <div className="accordion-item material-shadow">
                                                <h2 className="accordion-header" id="accordionborderedExample2">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_borderedExamplecollapse2" aria-expanded="true" aria-controls="accor_borderedExamplecollapse2">
                                                        <div className="flex-shrink-0 pl-3">
                                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                                <input className="form-check-input code-switcher" type="checkbox" value='2' id="tuesday" />
                                                            </div>
                                                            <span className='px-4' style={{bottom: '6px', position: 'relative'}}><strong>Tuesday</strong></span>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="accor_borderedExamplecollapse2" className="accordion-collapse collapse show" aria-labelledby="accordionborderedExample2" data-bs-parent="#accordionBordered">
                                                    <div className="accordion-body">
                                                        <div className="col-12 mt-4">
                                                            {tuesdayTiming.map((timing, i) => {

                                                                let currentStartTime = timing.startTime ? timing.startTime : null;
                                                                let currentEndTime = timing.endTime ? timing.endTime : null;
                                                                let previousEndTime = tuesdayTiming[i-1] ? tuesdayTiming[i-1].endTime : null;
                                                                
                                                                return (<div key={i} className="row time-selecter monday-row">
                                                                    <div className="col-md-5 number mb-4">
                                                                        <div className="input-group flex-nowrap">
                                                                            <span id="basic-addon1" className="input-group-text" ><i className="ri-time-fill"></i></span>
                                                                            <DatePicker
                                                                            className='form-control'
                                                                            selected={currentStartTime}
                                                                            onChange={(date) => updateBasicDetail('startTime', date, i, 2)}
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            minTime={setMinutes(previousEndTime, 40)}
                                                                            maxTime={setHours(setMinutes(previousEndTime, 45), 23)}
                                                                            timeIntervals={60}
                                                                            timeCaption="Time"
                                                                            dateFormat="h:mm aa"
                                                                            placeholderText="Start Time"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5 number end-time mb-4">
                                                                        <div className="input-group flex-nowrap">
                                                                            <span id="basic-addon1" className="input-group-text" ><i className="ri-time-fill"></i></span>
                                                                            <DatePicker
                                                                            className='form-control'
                                                                            selected={currentEndTime}
                                                                            onChange={(date) => updateBasicDetail('endTime', date, i, 2)}
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            minTime={setMinutes(currentStartTime, 40)}
                                                                            maxTime={setHours(setMinutes(currentStartTime, 45), 23) }
                                                                            timeIntervals={60}
                                                                            timeCaption="Time"
                                                                            dateFormat="h:mm aa"
                                                                            placeholderText="End Time"
                                                                            />

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="col-md-2 mb-4">
                                                                        <i className='bx bx-plus-circle w-add-btn' onClick={(e) => addNewRow(e, 2)}></i>
                                                                        &nbsp; &nbsp; &nbsp;
                                                                        {
                                                                            (i !== 0) &&
                                                                            <i className="ri-delete-bin-line w-delete-btn" onClick={(e) => deleteRow(e, i, 2)}></i>
                                                                        }
                                                                    </div>
                                                                </div>)
                                                            })}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='float-end mt-2'>
                                                <button className='btn primary-btn' onClick={(e) => saveFormData(e)}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkingHours;