import React, {useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import Sidebar from '../hostSidebar';
import axios from 'axios';
import moment from 'moment';
import UnreadChatCount from '../common/UnreadChat.js';
import { socket } from '../../socket.js';
import Swal from 'sweetalert2';
import Modal from '../common/Modal.js';
import helper from '../../helper/index.js';

const HostLayout = () => {

    const inputRef = useRef(null);
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [unreadChatCount, setUnreadChatCount] = useState(0);
    const [notificationSetting, setNotificationSetting] = useState({
        sms: 0,
        email: 0,
        onApp: 0
    });
    const [userNotifications, setUserNotifications] = useState([]);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [enteredPassword, setEnteredPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const defaultProfile =  '/assets/images/image-placeholder.png';
    const filePath = process.env.REACT_APP_BACKENDURL+'images/';

    const { logout, deleteFirestoreAccount, clearAccountData } = useAuth();
    const [profileName, setProfileName] = useState((decoded && decoded.name) ? decoded.name : "");
    const [profileImg, setProfileImg] = useState((decoded && decoded.image) ? decoded.image : defaultProfile);
    function handleLogout(){
        logout();
    }

    const deleteAccount = async() => {
        Swal.fire({
            title: "Are you sure want to delete your account? You will not be able to retrieve your account once deleted.",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
          }).then( async(result) => {
            if (result.isConfirmed) {
                togglePasswordModal();
            }
        });
    }

    const handleDeleteAccount = async(userId) => {
        const res = await deleteFirestoreAccount(userId);
        if(res && res.status === 1) {
            await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/delete-account', {'role_id': 2}, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }).then((response) => {
                if(response.data.status === 1) {
                    helper.Toast().fire({ icon: 'success', title: response.data.message });
                    // deleteFirestoreAccount(response.data.data.userId);
                    clearAccountData();
                } else {
                    helper.Toast().fire({ icon: 'error', title: response.data.message });
                }
            })
            .catch((error) => {
                console.error("Error: Something went wrong", error);
            });
        } else {
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
        }
    }

    const handleToggleButton = (event) => {
        event.preventDefault();
        let currentClassName = inputRef.current.className;
        if(currentClassName.includes('open')){
            inputRef.current.classList.remove('open')
        }else{
            inputRef.current.classList.add('open')
        }
    }

    const fetchNotificationSettings = async() => {
        await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-notification-settings',
        {
            params: {
                "role_id": 2
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        }).then((response) => {
            if(response.data.status === 1) {
                setNotificationSetting(response.data.data.notificationSettings);
            }
        })
        .catch((error) => {
            console.error("Error: Something went wrong", error);
        });
    }
    
    const fetchNotifications = async() => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-notification',
                            {
                                params: {
                                    "role_id": 2
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'token': token
                                }
                            })
                            .catch(function (error) {
                                console.log('Something went wrong');
                            });

            if(data.status === 1){
                setUserNotifications(data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getProfile = async() => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/my-profile',
                            {
                                params: {
                                    "role_id": 2
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'token': token
                                }
                            })
                            .catch(function (error) {
                                console.log('Something went wrong');
                            });

            if(data.status === 1) {
                setProfileName(data.data.first_name +' '+data.data.last_name);
                if(data.data.image !== "") {
                    setProfileImg(data.data.image);
                }
            }
        } catch (err) {

        }
    }

    useEffect(() => {
        getProfile();
        fetchNotificationSettings();
    }, []);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }
    
        function onDisconnect() {
            setIsConnected(false);
        }
          
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        
        if(notificationSetting.onApp === 1) {
            fetchNotifications();
        }
        socket.on('recieve-notification-signal-from-cleaner', function(){
            if(notificationSetting.onApp === 1) {
                fetchNotifications();
            }
        })

        socket.on('recieve-notification-update-signal', function() {
            if(notificationSetting.onApp === 1) {
                fetchNotifications();
            }
        })

        socket.on('receive-profile-update-signal', function() {
            getProfile();
        })
        
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('recieve-notification-signal-from-cleaner', function(){
                /**
                 * TODO
                 * */ 
            });
          };
    }, [notificationSetting]);

    function togglePasswordModal() {
        setEnteredPassword("");
        setPasswordError("");
        setPasswordType("password");
        setIsPasswordModalOpen(!isPasswordModalOpen);
    }

    const handlePasswordToggle = () => {
        if (passwordType === 'password'){
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const verifyPassword = async() => {
        setPasswordError("");
        if(enteredPassword.trim().length < 1) {
            setPasswordError("Password is required");
            return;
        }

        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/verify-password', {
                    'role_id': 2,
                    'password': enteredPassword
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }).then((response) => {
                    if(response.data.status === 1) {
                        togglePasswordModal();
                        handleDeleteAccount(response.data.data.userId);
                    } else {
                        setPasswordError(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error("Error: Something went wrong", error);
                });
    }

    return (
        <>
        <header id="page-topbar">
            <UnreadChatCount
                setUnreadChatCount = {setUnreadChatCount}
            />
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                        
                        <div className="navbar-brand-box horizontal-logo">
                            <a href="index.html" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={`/assets/images/logo-sm.png`} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={`/assets/images/logo-sm.png`} alt="" height="17" />
                                </span>
                            </a>

                            <a href="index.html" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={`/assets/images/logo-sm.png`} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={`/assets/images/logo-sm.png`} alt="" height="17" />
                                </span>
                            </a>
                        </div>

                        {/* <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                            id="topnav-hamburger-icon" onClick={handleToggleButton}>
                            <span className="hamburger-icon" ref={inputRef}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button> */}

                    
                    </div>

                    <div className="d-flex align-items-center">

                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-search fs-22"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..."
                                                aria-label="Recipient's username" />
                                            <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="ms-1 header-item">
                            <Link to={'/host/my-profile'} className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                title={`View public profile`}>
                                <i className='bx bx-user fs-22'></i>
                                
                            </Link>
                        </div>
                        <div className="ms-1 header-item">
                            <Link to={'/host/messages'} className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                    id="page-header-chat-notifications" title={`${ unreadChatCount > 0 ? unreadChatCount : 0 } Unread Message`}>
                                    <i className='bx bx-chat fs-22'></i>
                                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                        { unreadChatCount > 0 ? unreadChatCount : 0 }
                                        <span className="visually-hidden">unread messages</span>
                                    </span>
                                </button>
                            </Link>
                        </div>
                        <div className="dropdown topbar-head-dropdown ms-1 header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className='bx bx-bell fs-22'></i>
                                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                    { userNotifications ? userNotifications.filter(data =>  data.is_read === false).length : 0 }
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-notifications-dropdown">

                                <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                    <div className="p-3">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                            </div>
                                            <div className="col-auto dropdown-tabs">
                                                <span className="badge badge-soft-light fs-13"> { userNotifications ? userNotifications.filter(data =>  data.is_read === false).length : 0 } New</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tab-content" id="notificationItemsTabContent">
                                    <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                        <div data-simplebar style={{"maxHeight":"356px"}} className="pe-2">
                                            {(
                                            (userNotifications.length === 0) ? <div className="text-reset notification-item d-block dropdown-item position-relative">
                                                    <div className="text-center">
                                                        No new notification
                                                    </div>
                                                </div> 
                                            :
                                            (
                                            <div>    
                                                {userNotifications.map((value, index) => {
                                                    const timestamp = moment(value.createdAt).fromNow();
                                                    const notificationProfileImg = value.userProfile.image ? filePath+value.userProfile.image.filename : defaultProfile;
                                                    let linkTo = "";
                                                    if(value.type === "Assignment" || value.type === "Payment") {
                                                        linkTo = `/host/assignment/detail/${value.redirect_id}`;
                                                    }
                                                    return (
                                                    <div key={"key" + index + 1} >
                                                        {(index < 3) && 
                                                        <div className={`text-reset notification-item d-block dropdown-item position-relative nmh ${value.is_read === false ? 'active' : ''}`} >
                                                            <div className="d-flex">
                                                                <img src={notificationProfileImg} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                                <div className="flex-1">
                                                                    <Link to={linkTo}>
                                                                        <div className="stretched-link">
                                                                            <h6 className="mt-0 mb-1 fs-13 fw-semibold">{value.userDetail.name}</h6>
                                                                        </div>
                                                                        <div className="fs-13 text-muted">
                                                                            <p className="mb-1">{value.message}</p>
                                                                        </div>
                                                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                            <span><i className="mdi mdi-clock-outline"></i> {timestamp}</span>
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    );

                                                })}
                                                
                                                <div className="my-2 text-center">
                                                    <Link to={`host/all-notification`}>
                                                        <button type="button" className="btn btn-soft-success waves-effect waves-light">
                                                            View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                            )
                                            )}
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="dropdown ms-sm-1 header-item">
                            <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span className="d-flex align-items-center" style={{'width':'32px', 'height':'32px'}}>
                                    <img className="rounded-circle header-profile-user" src={profileImg} alt="Header Avatar" style={{'width':'100%', 'height':'100%', 'objectFit': 'cover'}} />
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <h6 className="dropdown-header">Welcome {profileName}!</h6>
                                <Link to="/host/profile" className="dropdown-item">
                                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> 
                                    <span className="align-middle">Profile</span>
                                </Link>
                                <Link to="/host/update-password" className="dropdown-item">
                                    <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> 
                                    <span className="align-middle">Change password</span>
                                </Link>
                                <Link to="/host/settings" className="dropdown-item">
                                    <span
                                        className="badge bg-soft-success text-success mt-1 float-end">New</span><i
                                        className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                        className="align-middle">Settings</span>
                                </Link>

                                <span className="dropdown-item cursor-pointer" onClick={deleteAccount}>
                                    <i className="mdi mdi-delete-forever text-muted fs-16 align-middle me-1"></i> 
                                    <span className="align-middle" data-key="t-logout">Delete Account</span>
                                </span>
                                
                                <Link to="/host/feedback" className="dropdown-item">
                                    <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                                    <span className="align-middle">Help</span>
                                </Link>

                                <span className="dropdown-item cursor-pointer" onClick={handleLogout}>
                                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                                    <span className="align-middle" data-key="t-logout">Logout</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isPasswordModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter Password To Delete Account</h5>
                            <button type="button" className="btn-close" onClick={togglePasswordModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center justify-content-left gap-3 pt-3">
                                <label className="form-label">Password:</label>
                                <div className='w-100'>
                                    <div className="position-relative auth-pass-inputgroup">
                                        <input 
                                            type={passwordType} 
                                            className="form-control pe-5 password-input" 
                                            placeholder="Enter password" 
                                            id="password-input"
                                            maxLength={50} 
                                            onChange={(e) => setEnteredPassword(e.target.value)} />

                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={handlePasswordToggle} ><i className="ri-eye-fill align-middle"></i></button>
                                    </div>
                                    {passwordError && <div className="text-danger"> {passwordError} </div>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center gap-3 mt-2">
                            <button 
                                type="button"
                                onClick={verifyPassword}
                                className="btn btn-add added-btn  waves-effect waves-light">Submit</button>
                            <button 
                                type="button"
                                onClick={togglePasswordModal}
                                className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </header>

        <Sidebar />
        </>
    )
}

export default HostLayout;
