import React, {  useEffect, useState } from 'react'
import Footer from "../footer";
import HostLayout from './host';
import CleanerLayout from './cleaner';
import { Outlet, useLocation, useBeforeUnload  } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import BeatLoader from "react-spinners/ClipLoader";
// import { useAuth } from '../../hooks/auth';
import { decodeToken  } from "react-jwt";
import PageContextProvider from '../../Contexts/HostContext';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BroadcastChannel } from 'broadcast-channel';

const tabLogoutChannel = new BroadcastChannel('handleTabsLogout');

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#8f368dc7",
  position: "fixed",
  top: "40%",
  left: "50%",
  zIndex: "99999",
  transform: "translate(-50%, -40%)",
  border: "3px solid #8f368dc7"
};

const loderWrap  = {
  position: "absolute",
  width: "100%",
  background: "rgba(0,0,0,0.5)",
  height: "100%",
  zIndex: "99999999"
};

const Layout = (props) => {
  const handleTabLogout = (token) => {
    if(Cookies.get('token') === undefined || Cookies.get('token') === token) {
      navigate('/login');
    }
  }

  tabLogoutChannel.onmessage = msg => handleTabLogout(msg);
  const navigate = useNavigate();
  
  const location = useLocation();
  var userlevel = 0;
  var token = Cookies.get('token');
  var not_allowed_routes = [
                        '/host/assignment/list', '/host/assignment/add', '/cleaner/assignment/list'
                      ] ;
  if(token !== undefined && token !== ""){
      // get user data from token in cookie
      const decoded = decodeToken(token);
      userlevel = decoded.userlevel;
  }
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = React.useState(null);
  const [is_profile_completed, setIsProfileCompleted] = useState({});
  const [profileErrorFields, setProfileErrorFields] = useState("");
  const [profileCompleteStatus, setProfileCompleteStatus] = useState(0);

  // eslint-disable-next-line no-unused-vars
  let [color, setColor] = useState("#8f368dc7");

  // save it off before users navigate away
  useBeforeUnload(
    React.useCallback(() => {
      localStorage.stuff = state;
    }, [state])
  );

  useEffect(() => {
    props.setProgress(100)
    getProfileStatus()
  }, [location]);

  const getProfileStatus = async () => {
    
    axios.post(process.env.REACT_APP_BACKENDURL+'api/common/profile-status',
    { role_id: userlevel},
    {
      headers: {
          'token': token,
      }
    })
    .then(function (response) {
      if(response.data.status === 1){
        setProfileCompleteStatus(response.data.data.is_profile_completed);
        setIsProfileCompleted(response.data.data);
        setProfileErrorFields(response.data.data.error_fields);
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const updateProfileCompletedStatus = async(e) => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-profile-read-status',
    { 
      role_id: userlevel,
      readStatus: 1
    },
    {
      headers: {
          'token': token,
      }
    })
    .then(function (response) {
      if(response.data.status === 1){
        getProfileStatus()
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  return (
    <div id="layout-wrapper">
      {props.loading && 
      <div style={loderWrap}>
       <BeatLoader
          color={color}
          loading={props.loading}
          cssOverride={override}
          size={125}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      }
       <LoadingBar
        color='#8f368d'
        progress={props.progress}
        waitingTime={300}
        height={3}
        onLoaderFinished={() => props.setProgress(0)}
      />
    {/* <Navbar /> */}
      {(userlevel === 2) ?
        <HostLayout />
        : <CleanerLayout />
      }
      <div className="main-content">
        {/* {(is_profile_completed && is_profile_completed.is_profile_completed === 0) ?
          <div className="alert alert-success alert-solid alert-dismissible fade show text-white text-center"
              role="alert">
              <b>{is_profile_completed.is_profile_completed_msg}</b> <Link to={is_profile_completed.is_profile_completed_url}>Click Here</Link>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:<></>
        } */}

          {(is_profile_completed && is_profile_completed.is_profile_completed === 0) ?
            <div className="alert alert-message-box align-items-center d-flex justify-content-between">
                <div className="messagr-text align-items-center d-flex gap-2">
                    <i className="ri-home-fill"></i>
                    <h6 className="mb-0 p-1">{is_profile_completed.is_profile_completed_msg}</h6>
                </div>

                {(is_profile_completed.is_profile_completed_url !== '') ?
                  <Link className="btn white-btn waves-effect waves-light" to={is_profile_completed.is_profile_completed_url}> Click here </Link>
                : <></>}
            </div>
            :
            <>
              {(is_profile_completed && is_profile_completed.profileReadStatus === 0 && is_profile_completed.isAdminVerificationPending === 1) &&
              <div className="alert alert-message-box align-items-center d-flex justify-content-between">
                  <div className="messagr-text align-items-center d-flex gap-2">
                      <i className="ri-home-fill"></i>
                      <h6 className="mb-0 p-1">Congratulations, Your account is verified successfully.</h6>
                  </div>
                  
                  <button type="button" onClick={(e) => updateProfileCompletedStatus(e) } style={{background: 'transparent', border: '0px'}}>
                    <i className="ri-close-fill" style={{color: 'white', fontSize: 'x-large'}}></i>
                  </button>
              </div>}

              {(is_profile_completed && is_profile_completed.profileReadStatus === 0 && is_profile_completed.isAdminVerificationPending === 0) &&
                <div className="alert alert-message-box align-items-center d-flex justify-content-between">
                    <div className="messagr-text align-items-center d-flex gap-2">
                        <i className="ri-home-fill"></i>
                        <h6 className="mb-0 p-1">{is_profile_completed.is_profile_completed_msg}</h6>
                    </div>
                </div>
              }
            </>

          }

          {(is_profile_completed && is_profile_completed.is_profile_completed === 0 && (not_allowed_routes.filter((val) => val.includes(location.pathname)).length <= 0 ? 0 : 1)) ?
            <div className="page-content">
              <div className="container-fluid">
                <div className="col-xl-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="default-item">
                                <img src="/assets/images/deafult-logo.png" alt='' />
                                <h6 className="default-dis py-2"> You don't have permission to access this page.</h6>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            :<></>
          }



        <PageContextProvider>
          {( (is_profile_completed && is_profile_completed.is_profile_completed === 1) || (is_profile_completed && is_profile_completed.is_profile_completed === 0 && not_allowed_routes.filter((val) => val.includes(location.pathname)).length <= 0 ? 1 : 0 ) ) ?
            <Outlet context={[getProfileStatus, profileErrorFields, profileCompleteStatus]} />
            :<></>
          }
        </PageContextProvider>
       </div>
      <Footer />
    </div>
      
  );
};

export default Layout;