import React, {useState} from 'react'
import { useWizard } from 'react-use-wizard';
import ProgressBar from '../../../../components/common/ProgressBar'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useContext } from 'react';
import { PropertyContext } from '../../../../Contexts/PropertyContext';
import helper from '../../../../helper';
import { useDropzone } from 'react-dropzone'

// const thumb = {
//   display: 'inline-flex',
//   borderRadius: 2,
//   border: '1px solid #eaeaea',
//   marginBottom: 8,
//   marginRight: 8,
//   width: 100,
//   height: 100,
//   padding: 4,
//   boxSizing: 'border-box'
// };

const thumbInner = {
  display: 'flex',
  overflow: 'hidden',
  width: '150px'
};

const imgStyle = {
  width: '100%',
  height: '100px',
  objectFit: 'cover'
};

const BasicDetail = (props) => {
  const { nextStep } = useWizard(); 
  const [descriptionError, setdescriptionError] = useState('')
  const {types, subTypes, hostId} = useContext(PropertyContext);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpeg', '.jpg'],
    },
    maxFiles: 6,
    onDrop: (acceptedFiles, fileRejections) => {
      // Manage rejected files
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            helper.Toast().fire({ icon: 'error', title: "File is too large to upload" })
          }
          if (err.code === "file-invalid-type") {
            helper.Toast().fire({ icon: 'error', title: "Only .png and .jpg files are allowed" })
          }
          if(err.code === "too-many-files") {
            helper.Toast().fire({ icon: 'error', title: "You can upload max 6 files" })
          }
        });
      });
      // rejected files logic ends
      if ((props.uploadedInstructionImages.length + props.instructionImages.length + acceptedFiles.length) > 6) {
        return helper.Toast().fire({ icon: 'error', title: "You can upload max 6 files" })
      }

      var newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));

      props.setInstructionImagesPreview(prevState => [...prevState, ...newFiles]);
      props.setInstructionImages(prevState => [...prevState, ...acceptedFiles]);
    }
  });

  const handleDeleteImage = (index) => {
    props.setInstructionImages(crt => {
      return crt.filter((_el, ind) => (ind !== index))
    })
    
    props.setInstructionImagesPreview(crt => {
      return crt.filter((_el, ind) => (ind !== index))
    })
  }

  return (
      <>
      <ProgressBar/>

      <div className="card">
        <div className="card-body">
            <div className="live-preview">
              <div id="basic-form" className="row p-1">

              {/* <div className="col-xxl-3 col-md-6">
                  <label className="form-label">Select Host</label>
                  <select className="form-control" 
                      name='type' 
                      disabled={hostId !== ""}
                      onChange={(e) => props.parentCallback('hostId', e.target.value)}
                      value={props.basicDetail.hostId}>
                         <option  value="" >Select Host</option>
                        {props.hostList.map(item => {
                            return (
                                <option key={item._id} value={item._id} >{item.first_name} {item.last_name}</option>
                            );
                        })}
                  </select>
                </div> */}
                

                <div className="col-xxl-4 col-md-4">
                    <div className="input-group mb-4">
                      <span className="input-group-text" id="basic-addon1"><i className="ri-user-fill "></i></span>
                      <input type="text" 
                        onChange={(e) => props.parentCallback('propertyName', helper.capitalizeFirstLetter(e.target.value))}
                        className="form-control" 
                        value={props.basicDetail.propertyName}
                        placeholder='Enter Property Name'
                        maxLength={50} />
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="input-group mb-4">
                      <span className="input-group-text" id="basic-addon1"><i className=" ri-home-5-line "></i></span>
                      <select className="form-select" 
                        name='type' 
                        onChange={(e) => props.parentCallback('type', e.target.value)}
                        value={props.basicDetail.type}>
                          {types.map(item => {
                              return (
                                  <option key={item.id} value={item.id} >{item.name}</option>
                              );
                          })}
                    </select>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="input-group mb-4">
                      <span className="input-group-text" id="basic-addon1"><i className=" ri-building-2-line "></i></span>
                      <select className="form-select" 
                        name='subType' 
                        onChange={(e) => props.parentCallback('subType', e.target.value)}
                        value={props.basicDetail.subType}>
                          {subTypes.map(subItem => {
                              return (
                                  <option key={subItem.id} value={subItem.id} >{subItem.name}</option>
                              );
                          })}
                    </select>
                  </div>
                </div>

                <div className="col-xxl-12 col-md-12 mb-3">
                  <CKEditor
                    editor={ ClassicEditor }
                    data={(props.basicDetail.description) ? props.basicDetail.description : ''}
                    config={ {
                      height: '25rem',
                      toolbar: [  'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' , 'link' ],
                      ui: {
                        poweredBy: {
                            forceVisible: false
                        }
                      }
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        let a = helper.removeTag(data)
                        if(a.length > 500){
                          let error = `Character limit exceeded. Allowed 500 characters, you have entered ${a.length}.`;
                          setdescriptionError(error)
                          return;
                        }else{
                          setdescriptionError('')
                          props.parentCallback('description', data)
                        }
                    } }
                  />
                  {descriptionError && <div className="text-danger"> {descriptionError} </div>}

                  
                  {/* <textarea className='form-control' 
                    defaultValue={props.basicDetail.description}
                    onChange={(e) => props.parentCallback('description', e.target.value)}
                    placeholder='Enter Brief Summary describing your place'></textarea> */}
                </div>

                <div className="col-xxl-6 col-md-6 mt-4">
                   
                    <div className="input-group mb-4">
                        <span className="input-group-text" id="basic-addon1"><i className="ri-pencil-fill"></i></span>                        
                        <textarea maxLength={200} 
                          className="form-control" 
                          defaultValue={props.basicDetail.accessibility}
                          onChange={(e) => props.parentCallback('accessibility', e.target.value)}
                          rows="4" 
                          placeholder="Enter instructions on how cleaners can access your property">
                        </textarea>
                    </div>
                </div>

                
                
                <div className="col-xxl-6 col-md-6">
                  <small>You can upload photos for instruction to cleaner to access your property (max 6 photos allowed*)</small>
                  {/* <div className="dropzone d-flex align-items-center" style={{'minHeight': 'auto'}}>
                    <div className="fallback">
                        <input 
                          name="accessibilityImages" 
                          type="file" 
                          multiple={true}
                          onChange={(e) => handleFileChange(e,'0')}
                        />
                    </div>
                    <div className="dz-message needsclick text-center">
                        <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                        </div>
                        <h4 className="fs-10">Click to upload instruction photos</h4>
                    </div>
                  </div> */}

                  <div {...getRootProps({ className: 'dropzone' })} style={{'minHeight': '100px'}}>
                    <div className='file-uplod-text'>
                      <input {...getInputProps()} />
                      <i className="display-6 text-muted ri-upload-cloud-2-fill"></i>
                      <h6>Drag 'n' drop some files here, or click to upload instruction photos</h6>
                    </div>
                  </div>

                  <div className="row border border-dashed rounded gx-2 my-2">
                    {/* {(props.instructionImagesPreview.length > 0 ) && 
                        props.instructionImagesPreview.map((img, index) => (
                          <React.Fragment key={index}>
                            <div className="col-4 mb-2">
                              <div className='icon-container' style={{'width': '100', 'height': '100'}}>
                                <img src={img} alt="" className="img-fluid rounded" />
                              </div>
                            </div>
                          </React.Fragment>
                        ))
                    } */}

                    {props.instructionImagesPreview.length > 0 &&
                      props.instructionImagesPreview.map((image, index) => (
                        // <div style={thumb} key={image.name + index}>
                        <div className="col-4 mb-2" key={image.name + index}>
                          <div style={thumbInner} >
                            <span style={thumbInner}
                              className="image-wrap"
                              onClick={(e) => handleDeleteImage(index)}
                            >
                              <img src={`${URL.createObjectURL(image)}`} style={imgStyle} key={index} alt={image.name} />
                            </span>
                          </div>
                        </div>
                      ))
                    }

                    {/* Render already uploaded images */}
                    {(props.pageName === "edit property" && props.uploadedInstructionImages && props.uploadedInstructionImages.length > 0) &&
                      props.uploadedInstructionImages.map((img, i) => (
                        img && img.filename ? (
                          // <div style={thumb} key={img.filename}>
                          <div className="col-4 mb-2" key={img.filename}>
                            <div
                              style={thumbInner}
                              className="image-wrap"
                              onClick={(e) => props.setUploadedInstructionImages(crt => {
                                return crt.filter((_el, index) => (index !== i))
                              })}
                            >
                              {/* <span className="close-btn">&times;</span> */}
                              <img src={process.env.REACT_APP_BACKENDURL + 'images/' + img.filename} style={imgStyle} key={i} alt={img.filename} />
                            </div>
                          </div>
                        ) : null
                      ))}
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex align-items-start gap-3 mt-4">
                      <button type="button" className="btn btn-add btn-label right ms-auto" disabled={ !props.step1Validation || descriptionError.length > 0 ? true : false} onClick={() => nextStep()} ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to additional info</button>
                  </div>

                </div>
                
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default BasicDetail;
