import React , { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Cookies from 'js-cookie';
import moment from 'moment';
import { decodeToken } from "react-jwt";


let limit = 10;
function Tabs(props) {
    const token = Cookies.get('token');
    const decode = decodeToken(token);
    const authUserId = decode.user_id;
    
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [activetype, setActivetype] = useState('running');
    
    const [filterRecords, setFilterRecords] = useState({
        propertyId: "",
        sortBy: "asc",
        searchText: ""
    });

    useEffect(() => {
        getData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRecords]);

    useEffect(() => {
        // getProperties()
        // getData([], 'running', true);
        const step = props.step;
        getData([], step, true);
    }, [])

    const getData = (datas, assignmentType = 'running', isInit = false) => {
        let assTypeV = assignmentType;

        if(activetype === assignmentType && isInit === false){ return false }
        props.setLoading(true);
        let pageNo = (datas) ? datas.selected : 0;

        if(typeof datas.selected === "undefined"){
            // When tab change, update new tab
            setActivetype(assTypeV);
        }else{
            // When page no is selected, acive current tab
            assTypeV =  activetype;
        }

        let type = '';
        if(assTypeV === 'running') {
            type = 'today';
        } else if (assTypeV === 'upcoming') {
            type = 'upcoming';
        }

        axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/assignment-list',
        {
            // type:"upcoming",
            type: type,
            page: pageNo,
            limit: limit,
            assignmentType: assTypeV
        },
        {
            headers: {
                'token': token
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            console.log(error);
        });
    }


    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

  return (
    <div className="card-body">
        <ul className="nav nav-pills nav-justified mb-3 assignment-tab" id="pills-tab"
            role="tablist">
                
            <li className="nav-item" role="presentation">
                <button className={'nav-link running ' +  ((activetype === 'running') ? 'active' : '' )} type="button" onClick={(e) => getData([],'running')} >Running</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={'nav-link pending ' +  ((activetype === 'pending') ? 'active' : '' )}  type="button" onClick={(e) => getData([],'pending')} >Pending</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={'nav-link upcoming ' +  ((activetype === 'upcoming') ? 'active' : '' )} type="button" onClick={(e) => getData([],'upcoming')}  >Upcoming</button>
            </li>
            <li className="nav-item" role="presentation" type="button">
                <button className={'nav-link completed ' +  ((activetype === 'completed') ? 'active' : '' )} type="button" onClick={(e) => getData([],'completed')}>Completed</button>
            </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-run" role="tabpanel" aria-labelledby="pills-run-tab">
                    {data.length > 0 ?
                        <>
                            <div className="table-responsive table-card data-table">
                                <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                    <thead className="">
                                        <tr className="text-muted">
                                            <th scope="col">Property</th>
                                            <th scope="col">Host Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Total Price</th>
                                            {
                                                (activetype === 'completed') ? 
                                                    <th scope="col">Status</th>
                                                : <></> 
                                            }
                                            <th scope="col">&nbsp;</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.map((item, i) => { 
                                            const cleanerData = item.cleaners.filter((data) => data.value === authUserId);
                                            console.log('Property image',item.propertyDetail);
                                            return ( 
                                                <tr key={i}>
                                                    <td>
                                                        <div className="d-flex gap-2 align-items-center">
                                                        <img src={(item.propertyDetail.images[0]) ? process.env.REACT_APP_BACKENDURL+'images/'+item.propertyDetail.images[0].filename : "/assets/images/propery.png"} alt=""
                                                            className="rounded avatar-sm me-2" />
                                                            <div className='d-flex'>
                                                                <a href="#javascript" className="text-body fw-medium">{item.title}</a>
                                                                <p className="mb-0 pt-1 number"></p>
                                                                {
                                                                    (item.status < 4 && cleanerData[0]?.isRead === 0) &&
                                                                    <i className="fs-10 badge rounded-pill assignment-list-unread"><span className='d-none'>{cleanerData[0]?.isRead}</span></i>
                                                                }
                                                            </div>

                                                        </div>

                                                    </td>
                                                    <td>{item.hostDetail.first_name} {item.hostDetail.last_name}</td>
                                                    {/* <td className="number"> {moment(item.date).format('MMM Do YYYY, hh:mm A') }</td> */}
                                                    <td className="number"> {moment(item.date).format('MMM Do YYYY') }</td>
                                                    <td className="number">${parseFloat(item.price).toFixed(2)}</td>
                                                    {
                                                        (activetype === 'completed') ? 
                                                            (item.status === 4) ?
                                                                <td>
                                                                    <span className="badge completed p-2 text-capitalize">Completed</span>
                                                                </td>
                                                            :
                                                                <td>
                                                                    <span className="badge cancelled bg-danger p-2 text-capitalize">Cancelled</span>
                                                                </td>
                                                        : <></> 
                                                    }
                                                    <td className="note-icon">
                                                        <div className="hstack gap-3 flex-wrap">
                                                            <Link to={`/cleaner/assignment/detail/${item._id}`} className="btn btn-add waves-effect waves-light">
                                                                View
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <ReactPaginate
                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                breakLabel="..."
                                pageCount={PageCount}
                                pageRangeDisplayed={3}
                                onPageChange={getData}
                                containerClassName={'pagination pagination-rounded mt-3 mb-3 justify-content-center'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                renderOnZeroPageCount={null}
                                activeClassName={'active'}
                            />
                        </>

                    :
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="alert alert-danger" role="alert">No assignment yet.</div>
                            </div>
                        </div>
                    }
            </div>
            
        </div>
        
    </div>
  )
}

export default Tabs