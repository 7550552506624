import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import Tabs from './assignment/Tabs';
import axios from 'axios';
import Cookies from 'js-cookie';


const Home = (props) => {
    const token = Cookies.get('token');

    const [todayAssignments, setTodayAssignments] = useState([]);
    const getTodayAssignmentsData = () => {

        axios.get(process.env.REACT_APP_BACKENDURL+'api/cleaner/assignment/today-assignments',
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                setTodayAssignments(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getTodayAssignmentsData()
    }, [])
    
  return (
    <div className="page-content">
        <div className="container-fluid">
            
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Dashboard</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link href="#javascript">Overview</Link>
                                </li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-start flex-column ">
                                <h4 className="card-title mb-0 flex-grow-1">Today</h4>
                                <p className="ass-number">You have {todayAssignments.length} {(todayAssignments.length > 1) ? 'assignments' : 'assignment'} scheduled for completion</p>
                            </div>

                        </div>
                        <div className="card-body">
                        {(todayAssignments.length > 0) ?
                            <div className="row assignment-list">
                                {todayAssignments.map((item, i) => (
                                    <React.Fragment key={i}>
                                        <div key={i} className="col-xxl-4 col-md-4 p-3">
                                            <Link to={`/cleaner/assignment/detail/${item._id}`}>
                                                <div className="card p-lg-3 assigment-card-custom">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="image-box d-flex align-items-center gap-3">
                                                            <div className="d-flex align-items-center" style={{'width': '4.3rem', 'height': '4rem'}}>
                                                                        {
                                                                            (item.propertyDetail.images && item.propertyDetail.images.length > 0) ? 
                                                                                <img className="img-thumbnail rounded-circle" 
                                                                                        style={{'width': '100%', 'height': '100%', 'objectFit': 'cover'}}
                                                                                    src={process.env.REACT_APP_BACKENDURL+'images/'+item.propertyDetail.images[0].filename} key={i} alt={item.propertyDetail.images[0].filename} />
                                                                            : 
                                                                                <img className="img-thumbnail rounded-circle avatar-custom" src='/assets/images/propery.png' alt="Header Avatar" />
                                                                            }
                                                                        
                                                            </div>
                                                            
                                                            <div className="image-box-content">
                                                                <span className="img-title">Assigned by:</span>
                                                                <br />
                                                                <span className="autr-name">
                                                                    {item.hostDetail.first_name} {item.hostDetail.last_name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="image-box-content">
                                                            {(item.status <= 1) && <span className="badge bg-pending p-2 text-capitalize">Pending</span>}
                                                            {(item.status === 2) && <span className="badge bg-upcoming p-2 text-capitalize">Upcoming</span>}
                                                            {/* {(item.status > 1 && item.status <= 3) && <span className="badge bg-primary p-2 text-capitalize">Running</span>} */}
                                                            {(item.status === 3) && <span className="badge bg-running p-2 text-capitalize">Running</span>}
                                                            {(item.status === 4) && <span className="badge bg-completed p-2 text-capitalize">Completed</span>}
                                                            {(item.status === 5) && <span className="badge bg-danger p-2 text-capitalize">Cancelled</span>}
                                                        </div>
                                                    </div>
                                                    <h4 className="assigment-name">{item.title}</h4>
                                                    <p className="assign-disc  number">{item.propertyDetail.fullAddress}</p>
                                                </div>
                                            </Link>

                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                            : 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-danger" role="alert">No assignment yet.</div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <h4 className="card-title mb-0 ">Assignments</h4>
                        </div>

                        <Tabs setLoading={props.setLoading}/>
                    </div>
                </div>
            </div>
            
        </div>
    </div>    
  )
}

export default Home;
