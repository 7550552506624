import React from 'react'
import { useWizard } from 'react-use-wizard';
import ProgressBar from '../../../../components/common/ProgressBar'
import helper from '../../../../helper';

const Additional = (props) => {
  const { nextStep, previousStep } = useWizard();

  return (
      <>
      <ProgressBar />
      <div className="card">
        <div className="card-body">
            <div className="live-preview">
          {/* Bedrooms */}
          <div className="row p-1">

            <h3 className="mb-3">Rooms and Spaces</h3>

            { props.additionlFields.map((item) => 

              <div key={item} className="col-md-4 mb-4">
                  <div className="quantity d-flex justify-content-between align-items-center">
                      <label className="form-label">{helper.captilizeString(item)}</label>
                      <div className="input-step step-success">
                          <button type="button" className="minus" onClick={(e) => props.handleAdditionalItemsValue(item, parseInt(props.formField[item]) - 1)}>–</button>
                          <input type="number" className="product-quantity" value={props.formField[item]} min="0" max="100" readOnly />
                          <button type="button" className="plus" onClick={(e) => props.handleAdditionalItemsValue(item, parseInt(props.formField[item]) + 1)}>+</button>
                      </div>
                  </div>
              </div>
            )}


              <h3 className="mb-3">Size</h3>
              <p>Approximately how big is your property when measuerd</p>

              <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-between gap-3"
                      id="size">
                      <div className="input-group ">
                          <span className="input-group-text" id="basic-addon1"><i className="ri-user-fill "></i></span>
                            <input type="text" className="form-control"
                              value={props.propertySize} 
                              onChange={(e) => props.setPropertySize(e.target.value)}
                              placeholder="Enter Property Name"
                            />
                      </div>
                      <div className="group d-flex gap-3">
                          <button type="button" 
                            className={`btn btn-label ${props.selectedSizeType === 'ft' ? 'btn-add' : 'btn-outline-list'}`} 
                            onClick={(e) => props.setSelectedSizeType('ft')}>ft²</button>
                          <button type="button" 
                            className={`btn btn-label ${props.selectedSizeType === 'm' ? 'btn-add' : 'btn-outline-list'}`}
                            onClick={() => props.setSelectedSizeType('m')}>m²</button>
                      </div>
                  </div>
              </div>

              <div className="d-flex align-items-start gap-3 mt-4">
                  <button type="button" className="btn btn-outline-list btn-label"  onClick={() => previousStep()} ><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                      Previous</button>
                  <button type="button" className="btn btn-add btn-label right ms-auto" disabled={ !props.step2Validation ? true : false} onClick={() => nextStep()} ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next</button>
              </div>


          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Additional;