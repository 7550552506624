import React,{useState, useEffect} from 'react'
import {useParams } from 'react-router-dom';
import moment from 'moment';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import axios from 'axios';
import Cookies from 'js-cookie';

const TransactionInvoicePdf = () => {
    const token = Cookies.get('token');
	const params = useParams();
	const [TransData, setTransData] = useState({});
	const getTargetElement = () => document.getElementById('pdf-main-wrapper');

	const options = {
		method: 'open',
		resolution: Resolution.HIGH,
		page: {
		   margin: Margin.SMALL,
		   format: 'A4',
		   orientation: 'portrait',
		},
		canvas: {
		   mimeType: 'image/png',
		   qualityRatio: 1
		},
		overrides: {
		   pdf: {
			  compress: true
		   },
		   canvas: {
			  useCORS: true
		   }
		},
	 }

	const fetchTranasctionDetail = async () => {
        axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/fetch-transaction-detail',
                    { 
                        role_id: 2,
						transId: params.tranasctionId
                    },
                    { headers: { 'Content-Type': 'application/json', 'token': token } }
                )
            .then(function (response) {
                if(response.data.status === 1){
					setTransData({...response.data.data});
				}else{
					setTransData({});
				}
            })
            .catch(function (error) {
                console.log('successmy',error);
            });
    };

	useEffect(() => {
        fetchTranasctionDetail();
		setTimeout(()=>{
			generatePDF(getTargetElement, options)
		},1000);
    }, [])

	return (
		<>

			<div className="page-content">
                <div className="container-fluid">
					{
						Object.keys(TransData).length > 0 ? 
							<div className="card mb-0">
								<div className="card-body">
									<div className="d-flex align-items-center mb-2">
										<div className="flex-grow-1">
										<p className="fw-medium text-muted mb-0 number">
											Transaction ID: {TransData.paymentId}<br></br>
											Transaction Date: {moment.unix(TransData.createdDate).format('MMM Do YYYY, hh:mm A') }
											
										</p>
										</div>
										<div> 
										<h4 className="fs-20 fw-semibold ff-secondary">
											<span className="badge completed p-2 text-capitalize">{TransData.status}</span>
										</h4>
										</div>
									</div> 
									<div className="d-flex align-items-center pt-2 mb-2 border-top">
										<div className="flex-grow-1">
											<p className="text-uppercase fw-semibold text-muted mb-0 number ">Assigment Name</p>
										</div>
										<div>
											<p className="fw-medium ff-secondary mb-0">{TransData.assignmentTitle}</p>
										</div>
									</div>
									<div className="d-flex align-items-center pt-2 border-top">
										<div className="flex-grow-1">
											<p className="text-uppercase  fw-semibold text-muted mb-0 number ">Payment Method</p>
											<p className="text-uppercase  fw-semibold text-muted mb-0 number ">Type</p>
											<p className="text-uppercase  fw-semibold text-muted mb-0 number ">Last 4 Digits</p>
										</div>
										<div>
											<p className="fw-medium ff-secondary mb-1 text-end">Card</p>
											<p className="fw-medium ff-secondary mb-1 text-end">{TransData.paymentMethod.card}</p>
											<p className="fw-medium ff-secondary mb-1 text-end">{TransData.paymentMethod.last4}</p>
										</div>
									</div>
									<div className="d-flex align-items-center pt-2 border-top">
										<div className="flex-grow-1">
											<p className="text-uppercase  fw-semibold text-muted mb-0 number ">Sub Total</p>
											<p className="text-uppercase  fw-semibold text-muted mb-0 number ">Transaction Fee ({TransData.currentTrans.transFeePerct}%)</p>
										</div>
										<div>
											<p className="fw-medium ff-secondary mb-0 text-end">${TransData.currentTrans.amount}</p>
											<p className="fw-medium ff-secondary mb-0 text-end">${TransData.currentTrans.transactionFee}</p>
										</div>
									</div>
									<div className="d-flex align-items-center pt-2 border-top">
										<div className="flex-grow-1">
											<h5 className="text-uppercase  fw-semibold text-muted mb-0 number ">Total</h5>
										</div>
										<div>
											<h4 className="fs-20 fw-medium ff-secondary">${TransData.currentTrans.totalAmount}</h4>
										</div>
									</div>
								</div>
							</div>
						:<></>
					}
				</div>
			</div>
		</>
	);
};

export default TransactionInvoicePdf;
