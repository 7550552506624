import React,{useMemo, useEffect, useState} from 'react'
// import GoogleMapReact from 'google-map-react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import AutoComplete from '../../../components/common/AutoComplete';
import helper from '../../../helper'; 

const Address = (props) => {
  const [ mapCenter, setMapCenter ] = useState({ lat: 37.09024, lng: -95.712891 });
  const center = useMemo(() => ({ lat: parseFloat(props.addressArray.lat), lng: parseFloat(props.addressArray.lng) }), [props.addressArray.lat, props.addressArray.lng]);
  const containerStyle = {
    width: '100%',
    // height: '50vh'
    height: 'calc(222px)'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  })

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null)
  const [place, setPlace] = React.useState(null)

  useEffect(() => {
    if(place !== null && place !== undefined && place.geometry !== undefined){
      getAddressFromPlace(place)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[place])

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    var listener = new window.google.maps.event.addListener(map, "idle", function() { 
      if (map.getZoom() > 17) map.setZoom(17);
      new window.google.maps.event.removeListener(listener); 
    });

    setMap(map)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    setMapCenter(center);
    if(map !== null) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      var listener = new window.google.maps.event.addListener(map, "idle", function() { 
        if (map.getZoom() > 17) map.setZoom(17);
        new window.google.maps.event.removeListener(listener); 
      });
    }
  }, [center, map])

  const getAddressFromPlace = (place) => {
    props.parentCallback('lat',place.geometry.location.lat())
    props.parentCallback('lng',place.geometry.location.lng())
    props.parentCallback('fullAddress',place.name);
    props.parentCallback('streetAddress',place.name);
    for (var j = 0; j < place.address_components.length; j++) {
        for (var k = 0; k < place.address_components[j].types.length; k++) {

            if (place.address_components[j].types[k] === "locality") {
                props.parentCallback('city',place.address_components[j].short_name);
            }
            if (place.address_components[j].types[k] === "postal_code") {
                props.parentCallback('pincode',place.address_components[j].short_name);
            }
            if (place.address_components[j].types[k] === "country") {
                props.parentCallback('country',place.address_components[j].long_name);
            }
            if (place.address_components[j].types[k] === "administrative_area_level_1") {
                props.parentCallback('state',place.address_components[j].long_name);
            }
        }
    }
  }

  return (
    <>
    <div className="card">
        <div className="card-body">
            <div className="live-preview">
              <form onSubmit={props.submitAddressData} method="post" encType="multipart/form-data">
                <div className="row p-1" id="address-bar">
                        
                  <div className="col-md-6">
                        <div className="col-md-12">
                          <AutoComplete setPlace={setPlace} addressArray={props.addressArray} parentCallback={props.parentCallback} />

                          { isLoaded ? (
                                <GoogleMap
                                  mapContainerStyle={containerStyle}
                                  center={mapCenter}
                                  zoom={17}
                                  onLoad={onLoad}
                                  onUnmount={onUnmount}
                                >
                                  <MarkerF position={mapCenter} />
                                </GoogleMap>
                            ) : <></>
                          }
                        </div>  
                  </div>

                  {/*  *********************************************** */}
                  
                  <div className="col-md-6">
                    <div className="row">                      
                      <div className="col-md-12 mb-4">
                        <div className="input-group">
                            {/* <span className="input-group-text" id="basic-addon1"><i className=" ri-home-7-fill "></i></span> */}
                            <input type="text" 
                              className="form-control" 
                              placeholder="Enter Country"
                              value={props.addressArray.country}
                              name='country'
                              onChange={(e) => props.parentCallback('country', e.target.value)}
                              maxLength={50} />
                        </div>
                        {props.validation.country && <div className="text-danger">{props.validation.country} </div>}
                      </div>

                      <div className="col-md-12 mb-4">
                          <div className="input-group">
                              {/* <span className="input-group-text" id="basic-addon1"><i className=" ri-home-7-fill "></i></span> */}
                              <input type="text" 
                                className="form-control" 
                                value={props.addressArray.streetAddress}
                                name='streetAddress'
                                onChange={(e) => props.parentCallback('streetAddress', e.target.value)}
                                placeholder='Enter Street Address'
                                maxLength={50} />
                          </div>
                          {props.validation.streetAddress && <div className="text-danger">{props.validation.streetAddress} </div>}
                      </div>

                      <div className="col-md-12 mb-4">
                        <div className="input-group">
                            {/* <span className="input-group-text" id="basic-addon1"><i className="ri- ri-home-7-fill "></i></span> */}
                              <input type="text" 
                                className="form-control" 
                                placeholder='Enter City'
                                value={props.addressArray.city}
                                name='city'
                                onChange={(e) => props.parentCallback('city', e.target.value)}
                                maxLength={50} />
                        </div>
                        {props.validation.city && <div className="text-danger">{props.validation.city} </div>}
                      </div>

                      <div className="col-md-12 mb-4">
                          <div className="input-group">
                            {/* <span className="input-group-text" id="basic-addon1"><i className="ri- ri-home-7-fill"></i></span> */}
                            <input type="text" 
                              className="form-control" 
                              placeholder='Enter State'
                              value={props.addressArray.state}
                              name='state'
                              onChange={(e) => props.parentCallback('state', e.target.value)}
                              maxLength={50} />
                          </div>
                          {props.validation.state && <div className="text-danger">{props.validation.state} </div>}
                      </div>

                      <div className="col-md-12 mb-4">
                          <div className="input-group">
                              {/* <span className="input-group-text" id="basic-addon1"><i className="bx bxs-map-pin"></i></span> */}
                              <input type="text" 
                                className="form-control" 
                                placeholder='Enter Zipcode'
                                value={props.addressArray.pincode}
                                name='pincode'
                                onKeyDown={(e) => helper.checkNumericInput(e)}
                                onChange={(e) => props.parentCallback('pincode', e.target.value)}
                                maxLength={5} />
                          </div>
                          {props.validation.pincode && <div className="text-danger">{props.validation.pincode} </div>}
                      </div>

                    </div>
                  </div>

                  <div className="col-12">
                      <div className="d-flex align-items-start gap-3 mt-4">
                          <button type="submit" className="btn btn-add ms-auto">Update</button>
                      </div>
                  </div>

                </div>
              </form>
            </div>
        </div>
      </div>
    </>
  )
}

export default Address;