import React,{useState, useEffect, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Wizard } from 'react-use-wizard';
import BasicDetail from './steps/BasicDetail'
import AdditionalInformation from './steps/AdditionalInformation'
import Address from './steps/Address'
import Images from './steps/Images'
// import Checklist from './steps/Checklist'
import Preview from './steps/Preview'
import helper from '../../../helper/index.js';
import { PropertyContext } from '../../../Contexts/PropertyContext';
import { HostContext } from '../../../Contexts/HostContext';
import '../../../styles/incrementButton.css';
import { socket } from '../../../socket'
import Cookies from 'js-cookie';

const AddProperty = (props) => {
    const token = Cookies.get('token');
    // get user data from token in cookie
    const {decoded} = useContext(HostContext);    
    const hostId = decoded.user_id
    const [selectedFiles, setSelectedFiles] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [pageName, setPageName] = useState('Add property');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedSizeType, setSelectedSizeType] = useState('m');
    const [propertySize, setPropertySize] = useState(100);
    const [instructionImages, setInstructionImages] = useState([]);
    const [instructionImagesPreview, setInstructionImagesPreview] = useState([]);
    const [uploadedInstructionImages, setUploadedInstructionImages] = useState([]);

    // const [title, setTitle] = useState("");
    // const [checkListArray, setCheckList] = useState([]);
    // const [errorTitle, setFNErrorMessage] = useState("");
    // const [updateState, setUpdateState] = useState({index:-1, title: "", currentState: "add"})


    const stepNames = helper.propertyWizardSteps;
    const navigate = useNavigate();
    const types = helper.types;

    const subTypes = helper.subTypes;

    const [ basicDetailArray, setBasicDetailArray ] = useState({
            hostId: hostId,
            type: 1,
            subType: 1,
            propertyName: '',
            description: '',
            accessibility: ''
        })

    const [ addressArray, setAddressArray ] = useState({
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        streetAddress: "",
        lat: 37.09024,
        lng: -95.712891
    })

    const [additionalArray, setAdditionalArray] = useState({ bedrooms: 0, bed: 0, bathroom: 0, kitchen: 0, garage: 0 })

    const [files, setFiles] = useState([]);
    // Step 2, Manage Additional information state
    // ****************************************************
    const additionlFields = helper.additionlFields;
    

    const handleOnChange = e => {
        const { name, value } = e.target;
        setAdditionalArray({ [name]: parseInt(value) });
    };

    // const DecreaseItem = (name) => {
    //     setAdditionalArray(prev => ({ ... formField, [name]: parseInt(prev[name]) - 1 }));
    // }

    const handleAdditionalItemsValue = (name, value) => {
        if(value >= 0 && value <= 20){
            additionalArray[name] = value;
            setAdditionalArray({ ...additionalArray });
        }
    }

    // ****************************************************
    // Need to check all is required
    const [step1Validation, setStep1Validation] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [step2Validation, setStep2Validation] = useState(true);
    const [step3Validation, setStep3Validation] = useState(false);
    const [step4Validation, setStep4Validation] = useState(false);
    // const [step5Validation, setStep5Validation] = useState(false);

    useEffect(() => {
        setStep2Validation(true);
        if(propertySize <= 0 || propertySize === ""){
            setStep2Validation(false);
        }
        if(selectedSizeType === ""){
            setStep2Validation(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertySize,selectedSizeType])

    // Handle basic detail form state callback
    const handleBasicDetailCallback = ( keyValue, value) => {
        setStep1Validation(true)
        basicDetailArray[keyValue] = value
        setBasicDetailArray({ ...basicDetailArray })
        Object.keys(basicDetailArray).map(key => {
            if(basicDetailArray[key] === ""){
                setStep1Validation(false)
            }
        })        
    }
    // Handle Address form state callback
    const handleAddressCallback = ( keyValue, value) => {
        setStep3Validation(true)
        addressArray[keyValue] = value
        setAddressArray({ ...addressArray })
        Object.keys(addressArray).map(key => {
            if(addressArray[key] === "" ){
                setStep3Validation(false)
            }
        })
    }

    const collectData = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        Object.keys(additionalArray).map((x,i) => {
            formData.append(x, additionalArray[x]);
        })
        Object.keys(basicDetailArray).map((x,i) => {
            formData.append(x, basicDetailArray[x]);
        })
        Object.keys(addressArray).map((x,i) => {
            formData.append(x, addressArray[x]);
        })
        selectedFiles.forEach((images, index) => {
            formData.append(`images`, images);
        });

        instructionImages.forEach((accessibilityImages, index) => {
            formData.append(`accessibilityImages`, accessibilityImages);
        });
        formData.append(`sizeType`,selectedSizeType)
        formData.append(`size`,propertySize)
        formData.append(`addedBy`,decoded.user_id)
        // checkListArray.forEach((checklist, index) => {
        //     formData.append(`checklist`, checklist);
        // });
        props.setLoading(true)
        // axios.post(process.env.REACT_APP_BACKENDURL+'property/create-property',formData)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/host/add-property',formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': token
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                socket.emit('send-notification-signal-to-admin',1)
                props.setLoading(false)
                navigate('/host/property/list');
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    };

    return (
        <div className="page-content">
            <div className="container-fluid">


                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Add New Property</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/host/dashboard">Overview</Link></li>
                                    <li className="breadcrumb-item"><Link to="/host/property/list">Property List</Link></li>
                                    <li className="breadcrumb-item active">Add New Property</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={collectData} method="post" encType="multipart/form-data">
                        <PropertyContext.Provider value={{types, subTypes, stepNames, hostId}}>
                        <Wizard >
                            
                            <BasicDetail 
                                parentCallback={handleBasicDetailCallback}
                                basicDetail={basicDetailArray}
                                step1Validation={step1Validation}
                                // hostList={hostList}
                                currentStep="Basic Details"
                                setInstructionImages={setInstructionImages}
                                setInstructionImagesPreview={setInstructionImagesPreview}
                                instructionImagesPreview={instructionImagesPreview}
                                instructionImages={instructionImages} 
                                uploadedInstructionImages={uploadedInstructionImages}
                            />

                            <AdditionalInformation 
                                selectedSizeType={selectedSizeType}
                                setSelectedSizeType={setSelectedSizeType}
                                propertySize={propertySize}
                                setPropertySize={setPropertySize}
                                handleAdditionalItemsValue={handleAdditionalItemsValue}
                                handleOnChange={handleOnChange}
                                formField={additionalArray}
                                step2Validation={step2Validation}
                                additionlFields={additionlFields}/>

                            <Address 
                                addressArray={addressArray}
                                step3Validation={step3Validation}
                                parentCallback={handleAddressCallback}/>

                            <Images 
                                setFiles={setFiles}
                                files={files}
                                selectedFiles={selectedFiles} 
                                setSelectedFiles={setSelectedFiles}
                                setStep4Validation={setStep4Validation}
                                pageName={pageName}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                step4Validation={step4Validation} />

                            {/* <Checklist 
                                title={title}
                                setTitle={setTitle}
                                checkListArray={checkListArray}
                                setCheckList={setCheckList}
                                errorTitle={errorTitle}
                                setFNErrorMessage={setFNErrorMessage}
                                updateState={updateState}
                                setUpdateState={setUpdateState}
                                step5Validation={step5Validation}
                                setStep5Validation={setStep5Validation}
                                pageName={pageName} /> */}

                            <Preview 
                                // checkListArray={checkListArray}
                                additionalArray={additionalArray}
                                basicDetailArray={basicDetailArray}
                                addressArray={addressArray}
                                pageName={pageName}
                                selectedSizeType={selectedSizeType}
                                propertySize={propertySize}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                files={files}
                                uploadedInstructionImages={uploadedInstructionImages}
                                instructionImages={instructionImages}
                            />
                        </Wizard>
                        </PropertyContext.Provider>
                        </form>
                    </div>
                    
                </div>


                
            </div>
        </div>
    )
}

export default AddProperty;
