import React, { useMemo, useContext, useState } from 'react'
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import helper from '../../../../helper/index.js';
import Cookies from 'js-cookie';
import CardsList from './CardsList.js';
import Swal from 'sweetalert2';
import {CountryList} from '../../../../../src/components/common/CountryList';

const Cards = (props) => {
  const token = Cookies.get('token');
  const [getProfileStatus] = useOutletContext();

  const [cardsData, setCardsData] = useState([])
  const [ActiveCard, setActiveCard] = useState({});

  const [card_full_name, setCardFullName] = useState("");
  const [card_number, setCardNumber] = useState("");
  const [card_exp_month, setCardExpMonth] = useState("");
  const [card_exp_year, setCardExpYear] = useState("");
  const [card_cvc, setCardCvc] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");

  const [errorCcardFullName, setErrorCcardFullName] = useState("");
  const [errorCnumber, setErrorCnumber] = useState("");
  const [errorCexpMonth, setErrorCexpMonth] = useState("");
  const [errorCexpYear, setErrorCexpYear] = useState("");
  const [errorCcvc, setErrorCcvc] = useState("");
  const [errorCzipcode, setErrorCzipcode] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [yearsList, setYearsList] = useState([]);
  const [accountName, setAccountName] = useState("");

  useEffect(() => {
    fetchUserDetails();
    fetchCardsList();
    fetchStripeCustomerActiveCard();
    const currentYear = new Date().getFullYear();
    const next15Years = Array.from(new Array(15), (v, i) => currentYear + i);
    setYearsList(next15Years);
  }, [])

  // Fetch user card list
  const fetchCardsList = async () => {
    const { data } = await axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/fetch-card-list', {}, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    });
    if (data.status === 1) {
      setCardsData(data.data)
    }
  };

  // Fetch customer profile data
  const fetchStripeCustomerActiveCard = async () => {
    const { data } = await axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/get-customer-active-card', {}, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    });
    if (data.status === 1) {
      setActiveCard({...data.data});
    }
  };

  const fetchUserDetails = async () => {
    const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/my-profile',{
      headers: {
          'Content-Type': 'application/json',
          'token': token
      },
      params: {
          role_id: 2
      }
    })
    .catch(function (error) {
      console.log(error);
      props.setLoading(false);
    });
    
    if(data.status === 1){
      let name = data.data.first_name +' '+ data.data.last_name;
      setAccountName(name);
    }
  };

  /* Submit Account details code start */
  const submitCardDetailsData = async (event) => {
    event.preventDefault();

    setErrorCnumber("");
    setErrorCexpMonth("");
    setErrorCexpYear("");
    setErrorCcvc("");
    setErrorCzipcode("");
    setErrorCountry("");
    setErrorCcardFullName("");

    let error = 0;
    if (card_number.trim().length === 0) {
      setErrorCnumber('Card Number field is required.');
      error = 1;
    }
    if (card_exp_month.trim().length === 0) {
      setErrorCexpMonth('Expiry Month field is required.');
      error = 1;
    }
    if (card_exp_year.trim().length === 0) {
      setErrorCexpYear('Expiry Year field is required.');
      error = 1;
    }
    if (card_cvc.trim().length === 0) {
      setErrorCcvc('CVC field is required.');
      error = 1;
    }
    if (card_full_name.trim().length === 0) {
      setErrorCcardFullName('Full name field is required.');
      error = 1;
    } 
    // else if(card_full_name.toLowerCase() !== accountName.toLowerCase()) {
    //   setErrorCcardFullName('Full name should matched with registered name.');
    //   error = 1;
    // }
    
    if (zipcode.trim().length === 0) {
      setErrorCzipcode('Zipcode/Postal Code is required.');
      error = 1;
    } else if(zipcode.trim().length !== 5) {
      setErrorCzipcode('Zipcode/Postal Code is not correct.');
      error = 1;
    }
    if (country.trim().length === 0) {
      setErrorCountry('Select Country is required.');
      error = 1;
    }

    if (error === 1) {
      return;
    }

    const formData = new FormData(event.target);
    props.setLoading(true)
    axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/add-card', formData, {
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 1) {
          fetchCardsList()
          fetchStripeCustomerActiveCard()
          getProfileStatus();
          props.setLoading(false)
          helper.Toast().fire({
            icon: 'success',
            title: response.data.message
          })
        } else {
          props.setLoading(false)
          helper.Toast().fire({
            icon: 'error',
            title: response.data.message
          })
        }
      })
      .catch(function (error) {
        props.setLoading(false)
      });
  };

  /** Delete card code starts */
  const deleteCard = async (card_id) => {
    Swal.fire({
      text: 'Are you sure you want to delete this card?',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Cancel`,
      customClass: {
        confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
        cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
        denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
    },
    buttonsStyling: false // Set to false if you want to fully control button styling
  }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append(`card_id`,card_id);
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/delete-card', formData, {
          headers: {
            'token': token,
            'Content-Type': 'application/json'
          }
        })
          .then(function (response) {
            if (response.data.status === 1) {
              fetchCardsList()
              fetchStripeCustomerActiveCard()
              getProfileStatus();
              props.setLoading(false)
              helper.Toast().fire({ icon: 'success', title: response.data.message })
            } else {
              props.setLoading(false)
              helper.Toast().fire({ icon: 'error', title: response.data.message })
            }
          })
          .catch(function (error) {
            props.setLoading(false)
            helper.Toast().fire({ icon: 'error', title: "Something went wrong. please try again." })
          });
      }
    });
  };

  /** Update default card code starts */
  const updateDefaultCard = async (card_id) => {
    const formData = new FormData();
    formData.append(`card_id`,card_id);
    props.setLoading(true)
    axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/update-default-card', formData, {
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 1) {
          fetchStripeCustomerActiveCard()
          fetchCardsList()
          props.setLoading(false)
          helper.Toast().fire({
            icon: 'success',
            title: response.data.message
          })
        } else {
          props.setLoading(false)
          helper.Toast().fire({
            icon: 'error',
            title: response.data.message
          })
        }
      })
      .catch(function (error) {
        props.setLoading(false)
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-7">
          <div className="p-2 shadow-none mb-2">
            <h5 className="text-uppercase">Credit/Debit Card</h5>
            <form onSubmit={submitCardDetailsData} method="post" encType="multipart/form-data">
              <div className="row gy-3">
                <div className="col-md-12">
                  <label htmlFor="cc-name" className="form-label">Name on card</label>
                  <input type="text"
                    id="cc-name"
                    className="form-control"
                    name='card_full_name'
                    value={card_full_name}
                    onKeyDown={(e) => helper.checkAlphabetInput(e)}
                    onChange={(e) => setCardFullName(e.target.value)}
                    placeholder='Enter Full Name'
                    maxLength={60} />
                  <small className="text-muted">Full name as displayed on card</small>
                  {errorCcardFullName && <div className="text-danger"> {errorCcardFullName} </div>}
                </div>

                <div className="col-md-12">
                  <label htmlFor="cc-number" className="form-label">Credit card number</label>
                  <input type="text"
                    className="form-control"
                    id="cc-number"
                    name='card_number'
                    value={card_number}
                    onKeyDown={(e) => helper.checkNumericInput(e)}
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder='XXXX XXXX XXXX XXXX'
                    maxLength={16} />
                  {errorCnumber && <div className="text-danger"> {errorCnumber} </div>}
                </div>

                <div className="col-md-3">
                  <label htmlFor="cc-expiration" className="form-label">Expiry Month</label>
                  <input type="text"
                    className="form-control"
                    id="cc-expiration"
                    name='card_exp_month'
                    value={card_exp_month}
                    onKeyDown={(e) => helper.checkNumericInput(e)}
                    onChange={(e) => setCardExpMonth(e.target.value)}
                    placeholder='Month'
                    maxLength={2} />
                  {errorCexpMonth && <div className="text-danger"> {errorCexpMonth} </div>}
                </div>

                <div className="col-md-3">
                  <label htmlFor="cc-expiration-year" className="form-label">Expiry Year</label>
                  <div className="input-group">
                    <select id="cc-expiration-year" name="card_exp_year" className="form-control" onChange={(e) => setCardExpYear(e.target.value)}>
                      <option value="">Select Year</option>
                      {
                        yearsList.map((year) => {
                          return ( <option key={year} value={year}>{year}</option> )
                        })
                      }
                    </select>
                  </div>
                  {errorCexpYear && <div className="text-danger"> {errorCexpYear} </div>}
                </div>

                <div className="col-md-6">
                  <label htmlFor="cc-cvv" className="form-label">CVC</label>
                  <input type="text"
                    id="cc-cvv"
                    className="form-control"
                    name='card_cvc'
                    value={card_cvc}
                    onKeyDown={(e) => helper.checkNumericInput(e)}
                    onChange={(e) => setCardCvc(e.target.value)}
                    placeholder='CVC'
                    maxLength={4} />
                  {errorCcvc && <div className="text-danger"> {errorCcvc} </div>}
                </div>

                <div className="col-md-6">
                  <label htmlFor="zipcode" className="form-label">Zipcode</label>
                  <input type="text"
                    id="zipcode"
                    className="form-control"
                    name='zipcode'
                    value={zipcode}
                    onKeyDown={(e) => helper.checkNumericInput(e)}
                    onChange={(e) => setZipcode(e.target.value)}
                    placeholder='Zipcode'
                    maxLength={5} />
                  {errorCzipcode && <div className="text-danger"> {errorCzipcode} </div>}
                </div>

                <div className="col-md-6">
                  <label htmlFor="country" className="form-label">Country</label>
                  <div className="input-group">
                    <select id="country" name="country" className="form-control" onChange={(e) => setCountry(e.target.value)}>
                      <option value="">Select Country</option>
                      {
                        CountryList.map((country) => {
                          return ( <option key={country.code} value={country.code}>{country.name}</option> )
                        })
                      }
                    </select>
                  </div>

                  {errorCountry && <div className="text-danger"> {errorCountry} </div>}
                </div>

                <div className="col-md-12">
                  <div className="align-items-start gap-3">
                    <button type="submit" className="btn btn-add ms-auto">Add New Card</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
        <div className="col-5">
          <div className="card h-100">
            <div className="card-body">
              <div className="save-card">
                <h5>Saved Cards</h5>
                <CardsList 
                  setLoading={props.setLoading} 
                  cardsData={cardsData}
                  ActiveCard={ActiveCard}
                  deleteCard={deleteCard}
                  updateDefaultCard={updateDefaultCard}/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Cards;