import React , { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import helper from '../../../helper/index.js';
import { HostContext } from '../../../Contexts/HostContext';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

let limit = 10;
const Home = (props) => {
    const token = Cookies.get('token');
    const types = helper.types;
    const subTypes = helper.subTypes;
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const {decoded} = useContext(HostContext);    
    const hostId = (decoded.user_id) ? decoded.user_id : null; 
    const [filterRecords, setFilterRecords] = useState({
                                                hostId: "",
                                                sortBy: "",
                                                search_string: "",
                                                type: 0,
                                                subType: 0,
                                            });

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRecords]);
    

    const getSize = (size, sizeType) => {
        var sizeTData =  (sizeType === "m") ? "m²" : "ft²";
        return size + ' ' + sizeTData;
    }

    // const getTypeName = (id) => {
    //     var foundValue = types.filter(obj => obj.id === id);
    //     return foundValue[0].name;
    // }

    const getSubTypeName = (id) => {
        var foundValue = subTypes.filter(obj => obj.id === id);
        return foundValue[0].name;
    }

    const getData = (datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        let formdata = filterRecords;
        formdata.limit = limit;
        formdata.page = pageNo;
        props.setLoading(true);
        axios.get(process.env.REACT_APP_BACKENDURL+'api/host/get-properties',{
            params: formdata,
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .then(function (response) {
            props.setLoading(false);
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            props.setLoading(false);
            console.log(error);
        });
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }
    const handleRemove = (id) => {
        Swal.fire({
            text: 'Are you sure want to remove this property?',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            if (result.isConfirmed) {
                props.setLoading(true);
                axios.post(process.env.REACT_APP_BACKENDURL+'api/host/remove-property',{id: id},{
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                })
                .then(function (response) {
                    props.setLoading(false);
                    if(response.data.status === 1){
                        helper.Toast().fire({ icon: 'success', title: response.data.message }).then(() => {
                            getData();
                        });
                    } else if(response.data.status === 2) {
                        helper.Toast().fire({ icon: 'error', title: response.data.message })
                    }
                })
                .catch(function (error) {
                    props.setLoading(false);
                    console.log(error);
                });
            }
        })
    }

    return (
        <>
        <div className="page-content">
        <div className="container-fluid">


            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Property Listing</h4>

                        <div className="page-title-right">
                            {hostId === null ?
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/host/dashboard">Overview</Link>
                                </li>
                                <li className="breadcrumb-item active">Property Listing</li>
                            </ol>
                            :
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/host/dashboard">Overview</Link>
                                </li>
                                <li className="breadcrumb-item active">Property Listing</li>
                            </ol>
                            }
                        </div>

                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-xl-12">
                            <div className="gap-3 icon-btn mb-3 text-end">
                                <Link to={"/host/property/add/"} className="btn btn-outline-list list-btn waves-effect waves-light me-2">Add New Property</Link>
                                <Link to={"/host/checklist"} className="btn btn-add list-btn waves-effect waves-light">Checklist</Link>
                            </div>
                
                                <div className="accordion mb-4" id="default-accordion-example">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Filter properties by using search
                                        </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#default-accordion-example">
                                            <div className="accordion-body">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input type="text" className="form-control" name="search_string" placeholder="Search by name" onChange={(e) => handleFilters('search_string', e.target.value)} value={filterRecords.search_string}/>
                                                        </div>
                                                        <div className="col-3">
                                                            <select className="form-select" 
                                                                name="type" 
                                                                onChange={(e) => handleFilters('type', e.target.value)}>
                                                                <option value="">Filter by type</option>
                                                                {types.map((item, i) => {
                                                                    return(
                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-3">
                                                            <select className="form-select" 
                                                                name="type" 
                                                                onChange={(e) => handleFilters('subType', e.target.value)}>
                                                                <option value="">Filter by sub type</option>
                                                                {subTypes.map((item, i) => {
                                                                    return(
                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-3">
                                                            <select className="form-select" name="sortBy" 
                                                                    value={filterRecords.sortBy}
                                                                    onChange={(e) => handleFilters('sortBy', e.target.value)}>
                                                                <option value="">Order by property title</option>
                                                                <option value="desc">Descending </option>
                                                                <option value="asc">Ascending</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                {data.length > 0 ?
                    <>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive table-card data-table" id="property-detail">
                                <table className="table table-borderless table-nowrapp align-middle mb-0 ">
                                    <thead className="">
                                        <tr className="text-muted">
                                            <th scope="col" width="30%">Property</th>
                                            <th scope="col" width="30%">location</th>
                                            <th scope="col">Range</th>
                                            <th scope="col">Property Type</th>
                                            {/* <th scope="col">Status</th> */}
                                            <th scope="col" style={{"width":"12%"}}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.map((item) => {
                                            return (
                                                <tr key={item._id}>
                                                    <td>
                                                        {(item.images && item.images.length > 0 && item.images[0] && item.images[0].filename) ?
                                                            <img src={ process.env.REACT_APP_BACKENDURL+'images/' + item.images[0].filename} alt="" className="rounded avatar-sm me-2" />
                                                            :
                                                            <img src="/assets/images/image-placeholder.png" alt="" className="rounded avatar-sm me-2" />
                                                        }

                                                        <a href="#javascript" className="text-body fw-medium">{item.propertyName}</a>
                                                    </td>
                                                    <td className="number">{item.fullAddress}</td>
                                                    <td className="number">{getSize(item.size, item.sizeType)}</td>
                                                    <td>
                                                        <div className="text-nowrap">{getSubTypeName(item.subType)}</div>
                                                    </td>
                                                    <td>
                                                        <div className="hstack gap-3 flex-wrap">
                                                            <Link to={`/host/property-edit/${item._id}`} className="edit-icon fs-18">
                                                                <img src="/assets/images/sidebar-icon/edit.svg" alt="" height="30" />
                                                            </Link>
                                                            <a href="#javascript"
                                                                onClick={(e) => handleRemove(item._id)}
                                                                className="link-danger delete-icon fs-18">
                                                                <img src="/assets/images/sidebar-icon/Delete.svg" alt="" height="30" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>

                    
                    <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel="..."
                        pageCount={PageCount}
                        pageRangeDisplayed={3}
                        onPageChange={getData}
                        containerClassName={'pagination pagination-rounded mt-5 mb-5 justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        renderOnZeroPageCount={null}
                        activeClassName={'active'}
                    />

                    </>


                :
                
                    <div className="card">
                        <div className="card-body">
                            <div className="default-item">
                                <img src="/assets/images/deafult-logo.png" alt="defaultimage"/>
                                <h6 className="default-dis py-2"> You don't have any Properties.</h6>
                                <Link to={"/host/property/add/"} className="btn btn-add waves-effect waves-light w-25">Add New Property</Link>
                            </div>
                        </div>
                    </div>

                }   
                </div>
            </div>
        </div>
    </div>
      
    </>
    )
}

export default Home;
