import { useEffect, useState } from "react";
import { db } from '../../fireabse';
import Cookies from 'js-cookie';
import {
    collection,
    onSnapshot,
    query,
    where,
    and
} from "firebase/firestore";

const UnreadChatCount = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const snapshotUsersQuery = query(collection(db, "users"), where("role_id", "in", [1,4]));
        onSnapshot(snapshotUsersQuery, (snapshot) => {
            setUsers(snapshot.docs.map((doc) => doc.id ));
        },
        (error) => {

        });
    }, []);

    useEffect(() => {
        const userId = Cookies.get('auth_user');
        if (users.length > 0 && userId) {
            const snapshotQuery = query(collection(db, "chats"), and (
                    where("senderId", "not-in", users),
                    where("receiverId", "==", userId),
                    where("isRead", "==", false)
                ));

            onSnapshot(snapshotQuery, (snapshot) => {
                props.setUnreadChatCount(snapshot.docs.length);
            },
            (error) => {

            });
        }
    }, [users]);
};

export default UnreadChatCount;