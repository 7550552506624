import React, { useState, useRef, useEffect } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import helper from '../helper/index.js';

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [one, setOne] = useState('');
    const [two, setTwo] = useState('');
    const [three, setThree] = useState('');
    const [four, setFour] = useState('');
    const [five, setFive] = useState('');
    const [six, setSix] = useState('');
    const [pressedCode, setPressedCode] = useState(0);
    // const [time, setTime] = useState(60);
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorCPassword, setErrorCPassword] = useState('');
    const [type, setType] = useState('password');
    const [ctype, setCType] = useState('password');

    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setEErrorMessage] = useState("");
    const [errorPhone, setPErrorMessage] = useState("");
    const [OTPError, setOTPError] = useState("");

    const [phoneSection, setPhoneSection] = useState(true);
    const [otpSection, setOtpSection] = useState(false);
    const [showPasswordSection, setShowPasswordSection] = useState(false);
    const [recoverUserId, setRecoverUserId] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);

    const loderWrap  = {
        position: "absolute",
        width: "100%",
        background: "rgba(237, 232, 232, 0)",
        height: "100%",
        zIndex: "99999999"
    };

    const override = {
        display: "block",
        position: "fixed",
        top: "47%",
        left: "47%",
        zIndex: "99999",
    };

    let ElementOne = useRef();
    let ElementTwo = useRef();
    let ElementThree = useRef();
    let ElementFour = useRef();
    let ElementFive = useRef();
    let ElementSix = useRef();

    const handleKeyDown = e => {
        if(e.target.value.length === 1 && e.target.value.match(/[a-z]/i)){
          return false;
        }
        let result = e.target.value.replace(/\D/g, '');
        let name = e.target.name;
        let type = "forward";
        if(pressedCode === 8){
          type = "back";
        }
        if(type === "back"){
          result = "";
        }
        switch (name) {
          case 'one':
            if(type === "forward"){
              setOne(result);
              ElementTwo.current.focus()
            }else{
              setOne('');
            }
          break;
          case 'two':
            if(type === "forward"){
              setTwo(result);
              ElementThree.current.focus()
            }else{
              setTwo('');
            }
          break;
          case 'three':
            if(type === "forward"){
              setThree(result);
              ElementFour.current.focus()
            }else{
              setThree('');
            }
          break;
          case 'four':
            if(type === "forward"){
              setFour(result);
              ElementFive.current.focus()        
            }else{
              setFour('');
            }
          break;
          case 'five':
            if(type === "forward"){
              setFive(result);
              ElementSix.current.focus()
            }else{
              setFive('');
            }
          break;
          case 'six':
            if(type === "back"){
              setSix('');
            }else{
              setSix(result);
            }
          break;
    
          default:
          break;
        }
    };

    const checkKey = (e) => {
        setPressedCode(e.keyCode)
        if(e.keyCode === 8 && e.target.value === ""){
          switch (e.target.name) {
            case 'two':
              ElementOne.current.focus()  
            break;
            case 'three':
              ElementTwo.current.focus()  
            break;
            case 'four':
              ElementThree.current.focus()  
            break;
            case 'five':
              ElementFour.current.focus()  
            break;
            case 'six':
              ElementFive.current.focus()  
            break;
          
            default:
            break;
          }
        }
    }
    
    useEffect(() => {
      if(one !== "" && two !== "" && three !== "" && four !== "" && five !== "" && six !== ""){
        setOtp(one+''+two+''+three+''+four+''+five+''+six)
      }
    },[one, two, three, four, five, six])

    const handlePassword = () => {
      if (type === 'password') {
          setType('text')
      } else {
          setType('password')
      }
    }

    const handleCPassword = () => {
      if (ctype === 'password') {
        setCType('text')
      } else {
        setCType('password')
      }
    }
    
    const resetPassword = async() => {
      const response = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-password', {
                          password: password,
                          otpCode: otp,
                          userId: recoverUserId
                      },{
                          headers: {
                              'Content-Type': 'application/json'
                          }
                      });
      return response.data;
    }

    const updateRecoverPassword = async (event) => {
        event.preventDefault();
        setErrorPassword('');
        setErrorCPassword('');

        let error = 0;
        if(password.trim().length === 0) {
          setErrorPassword('Password field is required.');
          error = 1;
        }

        if(cpassword.trim().length === 0) {
          setErrorCPassword('Confirm password field is required.');
          error = 1;
        } else {
          if(password !== cpassword) {
            setErrorCPassword('Confirm password should be same as password.');
            error = 1;
          }
        }

        if(error === 1){
          return;
        }

        if(error === 0){
          setLoading(true)
          let res = await resetPassword();
          setLoading(false)
          if(res.status === 1){
            helper.Toast().fire({ icon: 'success', title: res.message }).then(() => { navigate(`/login`); });
          }else if(res.status === 2){
            helper.Toast().fire({ icon: 'success', title: res.message }).then(() => { navigate(`/login`); });
          }else{
            helper.Toast().fire({ icon: 'error', title: res.message });
          }
        } else {
          helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please check and try again." });
        }
        
    };

    const sentRecoverOtp = async(email, phone) => {
      const response = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/send-reset-password-otp', {
          email: email,
          phone: phone
      },{
          headers: {
              'Content-Type': 'application/json'
          }
      });
      return response.data;
    }

    const sendOtp = async(event) => {
      event.preventDefault();
      setEErrorMessage("");
      setPErrorMessage("");

      let error = 0;
      if(email.trim().length === 0 && phone.trim().length === 0) {
          setEErrorMessage('Please enter one of the above information to get OTP for reset password.');
          error = 1;
      }

      if(email.trim().length !== 0 ) {
          // check email address in valid
          let vEmail = String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          
          if(vEmail === null){
            setEErrorMessage('Email Address is invalid.');
            error = 1;
          }
      }

      if(error === 1){
        return;
      }

      setLoading(true);
      let res = await sentRecoverOtp(email, phone);
      setLoading(false);
      if(res.status === 1){
        setRecoverUserId(res.data);
        setPhoneSection(false);
        setOtpSection(true);
        setShowPasswordSection(false);
      } else {
        setEErrorMessage(res.message);
      }
    }

    const verifyOTP = async(event) => {
      event.preventDefault();
      setOTPError("");
      setLoading(true);
      const {data} = await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/verify-reset-password-otp', {
                            otp: otp,
                            userId: recoverUserId
                        },{
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
      setLoading(false);
      if(data.status === 1) {
        setPhoneSection(false);
        setOtpSection(false);
        setShowPasswordSection(true);
      } else {
        setOTPError(data.message)
      }
    }

    const handleChange = (event) => {
      setPhone('');
      setEmail('');
      setEErrorMessage('');
      setPErrorMessage('');
      setSelectedOption(event.target.value);
    };

  return (    
    <>
     {loading &&  <div style={loderWrap}>
        <ClipLoader
            color="#8F368D"
            cssOverride={override}
            loading
            size={45}
            speedMultiplier={1}
            />
        </div>
    }
    
    <div id="login-bg"  className="auth-page-wrapper auth-bg-cover py-3 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card overflow-hidden">
                            <div className="row g-0">
                                <div className="col-lg-6">
                                    <div className="p-lg-5 p-4 left-icon h-100">
                                        <div className="bg-overlay"></div>
                                        <div className="position-relative h-100 d-flex justify-content-center align-items-center flex-column">
                                            <div className="mx-auto">
                                              <img src="../assets/images/logo-hk.png" alt="Housekeeper-Logo" />
                                            </div>
                                            <div className="mt-2">
                                                <p>Simplified Cleaning</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="px-lg-5 p-4">
                                        <div className="contant-area">
                                          { (otpSection === true) ? <h1>OTP Verification</h1> : <h1>Recover your account</h1> }
                                          
                                          { (phoneSection === true) && 
                                            <div className="mb-3">
                                                <p className="form-sub-title">Please select how you want to recover your account. The email address or phone number should match that used in your account.</p>
                                                <div className="mt-4">
                                                    <input
                                                        type="radio"
                                                        name="framework"
                                                        value="Phone"
                                                        checked={selectedOption === "Phone"}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='ms-2 me-3'> Phone </span>

                                                    <input
                                                        type="radio"
                                                        name="framework"
                                                        value="Email"
                                                        checked={selectedOption === "Email"}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='ms-2 me-3'> Email </span>
                                                </div>
                                            </div>
                                          }

                                          {
                                            (showPasswordSection === true) && <p className="form-sub-title">Enter new password to update into your account.</p>
                                          }
                                        </div>

                                        <div className="mt-4">
                                          {
                                            (phoneSection === true) && 
                                            <form onSubmit={sendOtp} autoComplete="off">
                                              <div className="mb-3">
                                                {
                                                    (selectedOption === "Phone") &&
                                                    <>
                                                        <label>Enter phone number to receive OTP</label>
                                                        <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))} maxLength={10} />
                                                        {errorPhone && <div className="text-danger"> {errorPhone} </div>}
                                                    </>
                                                }
                                                {
                                                    (selectedOption === "Email") &&
                                                    <>
                                                        <label>Enter your email to receive OTP</label>
                                                        <input type='text' className='form-control' name='email' value={email} maxLength={50} onChange={(e) => setEmail(e.target.value.trim())} />
                                                        {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                                    </>
                                                }
                                              </div>
                                              {
                                                (phoneSection === true && selectedOption !== null) &&
                                                <div className="mt-4">
                                                  <button className="btn primary-btn btn-shadow w-100" type="submit">Verify</button>
                                                </div>
                                              }
                                            </form>
                                          }

                                          {
                                            (otpSection === true) && 
                                            <div>
                                              <div className="mb-3">
                                                <label className="form-label">Enter your received OTP for verification.</label>
                                                <div className="d-flex align-items-center gap-3 otp-filed">
                                                  <input  
                                                    onChange={handleKeyDown}
                                                    onKeyDown={checkKey}
                                                    className="form-control"
                                                    maxLength="1"
                                                    name='one'
                                                    value={one}
                                                    autoFocus={true}
                                                    ref={ElementOne} />
                                                  <input  
                                                    onChange={handleKeyDown}
                                                    onKeyDown={checkKey}
                                                    className="form-control"
                                                    maxLength="1"
                                                    name='two'
                                                    value={two}
                                                    ref={ElementTwo} />
                                                  <input  
                                                    onChange={handleKeyDown}
                                                    onKeyDown={checkKey}
                                                    className="form-control"
                                                    maxLength="1"
                                                    name='three'
                                                    value={three}
                                                    ref={ElementThree} />
                                                  <input  
                                                    onChange={handleKeyDown}
                                                    onKeyDown={checkKey}
                                                    className="form-control"
                                                    maxLength="1"
                                                    name='four'
                                                    value={four} 
                                                    ref={ElementFour} />
                                                  <input  
                                                    onChange={handleKeyDown}
                                                    onKeyDown={checkKey}
                                                    className="form-control"
                                                    maxLength="1"
                                                    name='five'
                                                    value={five}
                                                    ref={ElementFive} />
                                                  <input  
                                                    onChange={handleKeyDown}
                                                    onKeyDown={checkKey}
                                                    className="form-control"
                                                    maxLength="1"
                                                    name='six'
                                                    value={six}
                                                    ref={ElementSix} />                                                    
                                                </div>
                                                {OTPError && <div className="text-danger"> {OTPError} </div>}
                                              </div>
                                              <div className="mt-4">
                                                <button className="btn primary-btn btn-shadow w-100" onClick={verifyOTP} type="submit">Verify OTP</button>
                                              </div>
                                            </div>
                                          }

                                          {
                                            (showPasswordSection === true) && 
                                            <form onSubmit={updateRecoverPassword} autoComplete="off" >
                                              <div className="mb-3">
                                                  <label htmlFor="password-addon" className="form-label">Password</label>
                                                  <div className="position-relative auth-pass-inputgroup">
                                                      <input type={type} className="form-control" name="password" defaultValue={password} onBlur={(e) => setPassword(e.target.value.trim())} maxLength={50} autoComplete="off" />
                                                      {errorPassword && <div className="text-danger"> {errorPassword} </div>}
                                                      <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={handlePassword} ><i className="ri-eye-fill align-middle"></i></button>
                                                  </div>
                                              </div>

                                              <div className="mb-3">
                                                  <label htmlFor="cpassword-addon" className="form-label">Confirm Password</label>
                                                  <div className="position-relative auth-pass-inputgroup">
                                                      <input type={ctype} className="form-control" name="confirm-password" value={cpassword} onChange={(e) => setCPassword(e.target.value)} maxLength={50} autoComplete="off" />
                                                      {errorCPassword && <div className="text-danger"> {errorCPassword} </div>}
                                                      <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted cpassword-addon" type="button" id="cpassword-addon" onClick={handleCPassword} ><i className="ri-eye-fill align-middle"></i></button>
                                                  </div>
                                              </div>

                                              <div className="mt-4">
                                                  <button className="btn primary-btn btn-shadow w-100" type="submit">Reset</button>
                                              </div>
                                            </form>
                                          }

                                        </div>

                                        <div className="mt-5 text-center singup-text">
                                            <p className="mb-0">Already have an account? 
                                                <Link to="/login" className="fw-semibold primary-text" > Login Here</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    
                    </div>

                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ForgotPasswordPage;
