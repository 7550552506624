import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import { ResponsiveBar } from '@nivo/bar'
import axios from 'axios';
import { Link } from 'react-router-dom';
// import { BarChart } from '@mui/x-charts/BarChart';

const Project = (props) => {
    const token = Cookies.get('token');

    const [customerData, setCustomerData] = useState({
      "totalCustomerByMonth": [],
      "repeatCustomerRatio": [],
      "totalUniqueCustomers": 0,
      "totalFiveStarsReview": 0,
      "year": 0
  })

  const collectData = async() => {
      try {
          await axios.post(process.env.REACT_APP_BACKENDURL+'api/host/insight/customer', {},
          {
              headers: {
                  'Content-Type': 'application/json',
                  'token': token
              }
          }).then((response) => {
              if(response.data.status === 1) {
                  setCustomerData(response.data.data)
              }
          })
          .catch((error) => {
              console.error("Error: Something went wrong", error);
          });

      } catch (error) {
          
      }
  }

  useEffect(() => {
      collectData()
  }, [])

  return (
    <div className="page-content">
        <div className="container-fluid">

            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">Cleaners</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/host/dashboard">Overview</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={'/host/insight'}>Insight</Link>
                  </li>
                  <li className="breadcrumb-item active">Cleaners</li>
                </ol>
              </div>
            </div>

            <div className="card-body">
                <div className="row earning-detail-boxs">
                    {/* Current earning available in wallet */}
                    <div className="col-xl-4 col-md-6">
                    
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total cleaners</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                        <span className="counter-value" data-target="119.25">{customerData.totalUniqueCustomers}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* number of 5 stars rating given by Cleaners */}
                    <div className="col-xl-4 col-md-6">
                    
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">5 stars ratings</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                        <span className="counter-value" data-target="119.25">{customerData.totalFiveStarsReview}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Repeated Customer Ration In Last 6 Months */}
                    <div className="col-xl-4 col-md-6">
                    
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Repeated Cleaners</p>
                                        <h6 className="earning-time">In 6 Months</h6>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                        <span className="counter-value" data-target="119.25">{customerData.repeatCustomerRatio}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            
              <div className="row">
                <div className="col-xxl-12 col-md-12">
                    <div className="card">
                    {/* `Total Cleaners by month in ${customerData.year}` */}
                        <div className="col-md-12">
                            <div className="card card-animate">
                                <div className='card-header'>
                                    <h4>Total Cleaners by month in year {customerData.year}</h4>
                                </div>
                                <div className="card-body" style={{'height': '50vh'}}>
                                    <ResponsiveBar
                                        data={customerData.totalCustomerByMonth}
                                        keys={[
                                            'total'
                                        ]}
                                        indexBy="month"
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        padding={0.3}
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        // colors={{ scheme: 'blueColor' }}
                                        colors={['#8f368d','#000080']}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: ``,
                                            legendPosition: 'middle',
                                            legendOffset: 32,
                                            truncateTickAt: 0
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: -40,
                                            truncateTickAt: 0,
                                            format: (e) => (Math.floor(e) === e ? e : "")
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor="white"
                                        legends={[
                                            {
                                                dataFrom: 'keys',
                                                anchor: 'bottom-right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 120,
                                                translateY: 0,
                                                itemsSpacing: 2,
                                                itemWidth: 100,
                                                itemHeight: 20,
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 0.85,
                                                symbolSize: 20,
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    </div>    
  )
}

export default Project;
