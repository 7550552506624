import React,{useMemo, useEffect, useState} from 'react'
import {  useWizard } from 'react-use-wizard';
import ProgressBar from '../../../../components/common/ProgressBar'
// import GoogleMapReact from 'google-map-react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import AutoComplete from '../../../../components/common/AutoComplete';
import helper from '../../../../helper/index';

const Step2 = (props) => {
  const [ mapCenter, setMapCenter ] = useState({ lat: 37.09024, lng: -95.712891 });
  const center = useMemo(() => ({ lat: parseFloat(props.addressArray.lat), lng: parseFloat(props.addressArray.lng) }), [props.addressArray.lat, props.addressArray.lng]);
  const { previousStep, nextStep } = useWizard();

  const containerStyle = {
    width: '100%',
    // height: '50vh'
    height: 'calc(222px)'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  })

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null)
  const [place, setPlace] = React.useState(null)

  useEffect(() => {
    if(place !== null && place !== undefined && place.geometry !== undefined){
      getAddressFromPlace(place)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[place])

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    var listener = new window.google.maps.event.addListener(map, "idle", function() { 
      if (map.getZoom() > 17) map.setZoom(17);
      new window.google.maps.event.removeListener(listener); 
    });

    setMap(map)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {
    setMapCenter(center);
  }, [center, map])

  const getAddressFromPlace = (place) => {
    props.parentCallback('lat',place.geometry.location.lat())
    props.parentCallback('lng',place.geometry.location.lng())
    props.parentCallback('fullAddress',place.name);
    props.parentCallback('streetAddress',place.name);
    for (var j = 0; j < place.address_components.length; j++) {
        for (var k = 0; k < place.address_components[j].types.length; k++) {

            if (place.address_components[j].types[k] === "locality") {
                props.parentCallback('city',place.address_components[j].short_name);
            }
            if (place.address_components[j].types[k] === "postal_code") {
                props.parentCallback('pincode',place.address_components[j].short_name);
            }
            if (place.address_components[j].types[k] === "country") {
                props.parentCallback('country',place.address_components[j].long_name);
            }
            if (place.address_components[j].types[k] === "administrative_area_level_1") {
                props.parentCallback('state',place.address_components[j].long_name);
            }
        }
    }
}

  return (
    <>
    <ProgressBar stepNames={props.stepNames}/>
    <div className="card">
        <div className="card-body">
            <div className="live-preview">
              <div className="row p-1" id="address-bar">
                       
                <div className="col-md-6">
                  <h3 className="mb-3">Where is your place located</h3>
                      <div className="col-md-12">
                        <AutoComplete setPlace={setPlace}/>

                        { isLoaded ? (
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={mapCenter}
                                zoom={17}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                              >
                                <MarkerF position={mapCenter} />
                              </GoogleMap>
                          ) : <></>
                        }
                      </div>  
                </div>

                {/*  *********************************************** */}
                
                <div className="col-md-6">
                  <div className="row">
                    <h3 className="mb-3">Confirm Your Address</h3>
                    
                    <div className="col-md-12 mb-4">
                      <div className="input-group">
                          {/* <span className="input-group-text" id="basic-addon1"><i className=" ri-home-7-fill "></i></span> */}
                           <input type="text" 
                            className="form-control" 
                            placeholder="Enter Country"
                            value={props.addressArray.country}
                            onChange={(e) => props.parentCallback('country', e.target.value)}
                            maxLength={50} />
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                        <div className="input-group">
                            {/* <span className="input-group-text" id="basic-addon1"><i className=" ri-home-7-fill "></i></span> */}
                            <input type="text" 
                              className="form-control" 
                              value={props.addressArray.streetAddress}
                              onChange={(e) => props.parentCallback('streetAddress', e.target.value)}
                              placeholder='Enter Street Address'
                              maxLength={50} />
                        </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <div className="input-group">
                          {/* <span className="input-group-text" id="basic-addon1"><i
                                  className="ri- ri-home-7-fill "></i></span> */}
                            <input type="text" 
                              className="form-control" 
                              placeholder='Enter City'
                              value={props.addressArray.city}
                              onChange={(e) => props.parentCallback('city', e.target.value)}
                              maxLength={50} />
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                        <div className="input-group">
                          {/* <span className="input-group-text" id="basic-addon1"><i
                                  className="ri- ri-home-7-fill"></i></span> */}
                          <input type="text" 
                            className="form-control" 
                            placeholder='Enter State'
                            value={props.addressArray.state}
                            onChange={(e) => props.parentCallback('state', e.target.value)}
                            maxLength={50} />
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                        <div className="input-group">
                            {/* <span className="input-group-text" id="basic-addon1"><i
                                    className="bx bxs-map-pin"></i></span> */}
                            <input type="text" 
                              className="form-control" 
                              placeholder='Enter Zipcode'
                              value={props.addressArray.pincode}
                              onKeyDown={(e) => helper.checkNumericInput(e)}
                              onChange={(e) => props.parentCallback('pincode', e.target.value)}
                              maxLength={5} />
                        </div>
                    </div>

                  </div>
                </div>

                <div className="d-flex align-items-start gap-3 mt-4">
                    <button type="button" className="btn btn-outline-list btn-label" onClick={() => previousStep()}><i
                            className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                        Previous</button>
                    <button type="button" className="btn btn-add btn-label right ms-auto"disabled={ !props.step3Validation ? true : false} 
                            onClick={() => nextStep()}><i
                            className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next</button>
                </div>


              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Step2;