/* eslint-disable jsx-a11y/anchor-is-valid */
import React,  { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from "../hooks/auth";
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import axios from 'axios';
import 'simplebar-react/dist/simplebar.min.css';
import UnreadChatCount from './common/UnreadChat.js';
import { socket } from '../socket.js';

const Sidebar = () => {
    const token = Cookies.get('token');
    // get user data from token in cookie
    const decoded = decodeToken(token);
    const [unreadChatCount, setUnreadChatCount] = useState(0);
    const [newAssignmentCount, setNewAssignmentCount] = useState(0);
    const { logout } = useAuth();
    function handleLogout(){
        logout();
    }

    const getHostUnreadAssignmentCount = async() => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/get-unread-assignment-count',
                            {
                                params: {
                                    "role_id": 2
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'token': token
                                }
                            })
                            .catch(function (error) {

                            });

            if(data.status === 1){
                setNewAssignmentCount(data.count);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    useEffect(() => {
        getHostUnreadAssignmentCount();
        socket.on("accept-assignment-invitation-from-cleaner", function() {
            getHostUnreadAssignmentCount();
        })
    }, []);

    return (
        <>

        <div id="sidebar" className="app-menu navbar-menu">
            <UnreadChatCount
                setUnreadChatCount = {setUnreadChatCount}
            />
            <div className="navbar-brand-box">

                <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                        <img src={`/assets/images/das-logo.png`} alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                        <img src={`/assets/images/das-logo.png`} alt="" height="45" />
                    </span>
                </Link>
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                    id="vertical-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>
            <SimpleBar forceVisible="y" autoHide={true} style={{ maxHeight: '80vh' }}>
                <div id="scrollbar">
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">Main</span></li>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/host/dashboard" activeclassname="active" >
                                    <div  className="overview-menu">
                                        <span data-key="t-dashboards">Overview</span>
                                    </div>                               
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'/host/calendar'} className="nav-link menu-link" activeclassname="active" >
                                    <div className="calendar-icon">
                                        <span data-key="t-advance-ui">Calendar</span>
                                    </div>   
                                </NavLink> 
                            </li>

                            <li className="nav-item">
                                <NavLink to="host/property/list" className="nav-link menu-link" activeclassname="active">
                                    <div className="property-icon">
                                        <span data-key="t-apps">Properties</span>
                                    </div>
                                    
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/host/partner/list" className="nav-link menu-link" activeclassname="active">
                                    <div className="cleaner-icon">
                                        <span data-key="t-layouts">Cleaners</span>
                                    </div>
                                    
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link menu-link" to="/host/insight" activeclassname="active">
                                    <div className="insight-icon">
                                        <span data-key="t-apps">Insight</span>
                                    </div>
                                    
                                </NavLink>
                            </li>

                            {/* <li className="nav-item">
                                <NavLink to="/host/checklist" className="nav-link menu-link">
                                    <div className="checklist-icon">
                                        <span data-key="t-layouts">Checklist</span>
                                    </div>
                                    
                                </NavLink>
                            </li>  */}

                            <li className="nav-item d-flex">
                                <NavLink to="host/assignment/list" className="nav-link menu-link" activeclassname="active">
                                    <div className="turnovers-icon">
                                        <span data-key="t-authentication">Assignment</span>
                                    </div>
                                </NavLink>
                                {
                                    (newAssignmentCount > 0) &&
                                    <i className="fs-10 badge rounded-pill unread-msg"><span className='d-none'>{newAssignmentCount}</span></i>
                                }
                            </li>

                            <li className="nav-item d-flex active">
                                <NavLink to="/host/messages" className="nav-link menu-link" activeclassname="active">
                                    <div className="chat-icon">
                                        <span data-key="t-landing">Messages</span>
                                    </div>
                                </NavLink>
                                {
                                    (unreadChatCount > 0) &&
                                    <i className="fs-10 badge rounded-pill unread-msg"><span className='d-none'>{unreadChatCount}</span></i>
                                }
                            </li>

                            <li className="menu-title"><span data-key="t-menu">Main</span></li>
                        
                            <div className="footer-sidebar">
                                <li className="nav-item">
                                    <NavLink to="host/settings" className="nav-link menu-link" activeclassname="active">
                                        <div className="setting-icon">
                                            <span data-key="t-widgets">Settings</span>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link menu-link cursor-pointer" onClick={handleLogout}>
                                        <div className="log-icon">
                                            <span data-key="t-forms">Logout</span>
                                        </div>
                                        
                                    </a>

                                </li>
                            </div>
                            
                        </ul>                    
                    </div>
                    
                </div>
            </SimpleBar>
            <div className="sidebar-background"></div>
        </div>
        
        </>
    )
}

export default Sidebar;
