/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate,Link  } from 'react-router-dom';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import axios from 'axios';
// import OneSignal from 'react-onesignal';
import helper from '../helper/index.js';

const VerifyOtp = () => {
  // eslint-disable-next-line no-unused-vars
  const token = Cookies.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if(token === "" || token === undefined) {
      navigate('/login');
    }

    if(Object.keys(decoded).length === 0){
      navigate('/login')
    }
  }, [])

  const decoded = typeof token !== "undefined" ? decodeToken(token) : {};

  // eslint-disable-next-line no-unused-vars
  const [otp, setOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [otpCode, setOtpCode] = useState(decoded.optCode);
  // eslint-disable-next-line no-unused-vars
  let [loading, setLoading] = useState(false)

  let ElementOne = useRef();
  let ElementTwo = useRef();
  let ElementThree = useRef();
  let ElementFour = useRef();
  let ElementFive = useRef();
  let ElementSix = useRef();

  let ElementOneE = useRef();
  let ElementTwoE = useRef();
  let ElementThreeE = useRef();
  let ElementFourE = useRef();
  let ElementFiveE = useRef();
  let ElementSixE = useRef();

  let ElementOneP = useRef();
  let ElementTwoP = useRef();
  let ElementThreeP = useRef();
  let ElementFourP = useRef();
  let ElementFiveP = useRef();
  let ElementSixP = useRef();

  const [one, setOne] = useState('');
  const [two, setTwo] = useState('');
  const [three, setThree] = useState('');
  const [four, setFour] = useState('');
  const [five, setFive] = useState('');
  const [six, setSix] = useState('');
  const [pressedCode, setPressedCode] = useState(0);
  const [time, setTime] = useState(60);

  const [oneE, setOneE] = useState('');
  const [twoE, setTwoE] = useState('');
  const [threeE, setThreeE] = useState('');
  const [fourE, setFourE] = useState('');
  const [fiveE, setFiveE] = useState('');
  const [sixE, setSixE] = useState('');

  const [oneP, setOneP] = useState('');
  const [twoP, setTwoP] = useState('');
  const [threeP, setThreeP] = useState('');
  const [fourP, setFourP] = useState('');
  const [fiveP, setFiveP] = useState('');
  const [sixP, setSixP] = useState('');

  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);

  function Timer() {

    useEffect(() => {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);

    }, [time > 0]);  

    return <div>Resend code available in 00:{time}</div>;
  }

  useEffect(() => {
    if(one !== "" && two !== "" && three !== "" && four !== "" && five !== "" && six !== ""){
      setOtp(one+''+two+''+three+''+four+''+five+''+six)
    }
  },[one, two, three, four, five, six])

  useEffect(() => {
    if(oneE !== "" && twoE !== "" && threeE !== "" && fourE !== "" && fiveE !== "" && sixE !== ""){
      setEmailOtp(oneE+''+twoE+''+threeE+''+fourE+''+fiveE+''+sixE)
    }
  },[oneE, twoE, threeE, fourE, fiveE, sixE])

  useEffect(() => {
    if(oneP !== "" && twoP !== "" && threeP !== "" && fourP !== "" && fiveP !== "" && sixP !== ""){
      setPhoneOtp(oneP+''+twoP+''+threeP+''+fourP+''+fiveP+''+sixP)
    }
  },[oneP, twoP, threeP, fourP, fiveP, sixP])

  const checkUserVerify = () => {
    if(decoded.isLoginVerify === 1){
      if(decoded.userlevel === 2){
        navigate('/host/dashboard')
      }else if(decoded.userlevel === 3){
        navigate('/host/dashboard')
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      // const oneSignalId = OneSignal.User.PushSubscription.id;
      if(otp.length === 6 || emailOtp.length === 6 || phoneOtp.length === 6 ){
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_BACKENDURL+'general/verifyOTP', {
          otp: otp,
          emailOtp: emailOtp,
          phoneOtp: phoneOtp,
          _id: decoded.user_id
        },{
          headers: {
              'Content-Type': 'application/json'
          }
        });
        if(response.data.status === 1){
          let token = response.data.token;
          Cookies.remove('token');
          Cookies.set('token', token);

            if(decoded.userlevel === 2){
              navigate('/host/dashboard')
            }else if(decoded.userlevel === 3){
              navigate('/cleaner/dashboard')
            }
            // Set Token in cookie
            // setCookies('isLoginVerify', 1);
          }else{
            helper.Toast().fire({ icon: 'error', title: response.data.message });
            return {status: 0, token: ""}
          // return false
        }
      }else{
        alert('Invalid OTP');
      }
      
    } catch (error) {
      console.log(error);
    } 
    setLoading(false)
    
  };

  const handleKeyDown = e => {
    if(e.target.value.length === 1 && e.target.value.match(/[a-z]/i)){
      return false;
    }
    let result = e.target.value.replace(/\D/g, '');
    let name = e.target.name;
    let type = "forward";
    if(pressedCode === 8){
      type = "back";
    }
    if(type === "back"){
      result = "";
    }
    switch (name) {
      case 'one':
        if(type === "forward"){
          setOne(result);
          ElementTwo.current.focus()
        }else{
          setOne('');
        }
      break;
      case 'two':
        if(type === "forward"){
          setTwo(result);
          ElementThree.current.focus()
        }else{
          setTwo('');
        }
      break;
      case 'three':
        if(type === "forward"){
          setThree(result);
          ElementFour.current.focus()
        }else{
          setThree('');
        }
      break;
      case 'four':
        if(type === "forward"){
          setFour(result);
          ElementFive.current.focus()        
        }else{
          setFour('');
        }
      break;
      case 'five':
        if(type === "forward"){
          setFive(result);
          ElementSix.current.focus()
        }else{
          setFive('');
        }
      break;
      case 'six':
        if(type === "back"){
          setSix('');
        }else{
          setSix(result);
        }
      break;

      default:
      break;
    }
  };

  const handleOtpKeyDown = (e) => {
    if(e.target.value.length === 1 && e.target.value.match(/[a-z]/i)){
      return false;
    }
    
    let result = e.target.value.replace(/\D/g, '');
    let name = e.target.name;
    let type = "forward";
    if(pressedCode === 8){
      type = "back";
    }
    if(type === "back"){
      result = "";
    }
  
    switch (name) {
      case 'oneE':
        if(type === "forward"){
          setOneE(result);
          ElementTwoE.current.focus()
        }else{
          setOneE('');
        }
      break;
      case 'twoE':
        if(type === "forward"){
          setTwoE(result);
          ElementThreeE.current.focus()
        }else{
          setTwoE('');
          ElementOneE.current.focus()
        }
      break;
      case 'threeE':
        if(type === "forward"){
          setThreeE(result);
          ElementFourE.current.focus()
        }else{
          setThreeE('');
          ElementTwoE.current.focus()
        }
      break;
      case 'fourE':
        if(type === "forward"){
          setFourE(result);
          ElementFiveE.current.focus()        
        }else{
          setFourE('');
          ElementThreeE.current.focus()
        }
      break;
      case 'fiveE':
        if(type === "forward"){
          setFiveE(result);
          ElementSixE.current.focus()
        }else{
          setFiveE('');
          ElementFourE.current.focus()
        }
      break;
      case 'sixE':
        if(type === "back"){
          setSixE('');
          ElementFiveE.current.focus()
        }else{
          setSixE(result);
        }
      break;
      case 'oneP':
        if(type === "forward"){
          setOneP(result);
          ElementTwoP.current.focus()
        }else{
          setOneP('');
        }
      break;
      case 'twoP':
        if(type === "forward"){
          setTwoP(result);
          ElementThreeP.current.focus()
        }else{
          setTwoP('');
          ElementOneP.current.focus()
        }
      break;
      case 'threeP':
        if(type === "forward"){
          setThreeP(result);
          ElementFourP.current.focus()
        }else{
          setThreeP('');
          ElementTwoP.current.focus()
        }
      break;
      case 'fourP':
        if(type === "forward"){
          setFourP(result);
          ElementFiveP.current.focus()        
        }else{
          setFourP('');
          ElementThreeP.current.focus()
        }
      break;
      case 'fiveP':
        if(type === "forward"){
          setFiveP(result);
          ElementSixP.current.focus()
        }else{
          setFiveP('');
          ElementFourP.current.focus()
        }
      break;
      case 'sixP':
        if(type === "back"){
          setSixP('');
          ElementFiveP.current.focus()
        }else{
          setSixP(result);
        }
      break;

      default:
      break;
    }
  };

  const handleResendCode = async (e, type = "") => {
    e.preventDefault()

    let resendType = "";
    if(type !== "") {
      resendType = type;
    }
    try {
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_BACKENDURL+'general/generate-code', {
          _id: decoded.user_id,
          resendType: resendType
        },{
          headers: {
              'Content-Type': 'application/json'
          }
        });
        if(response.data.status === 1){
          let token = response.data.token;
          Cookies.remove('token');
          let deco = decodeToken(token)
          setOtpCode(deco.optCode)
          Cookies.set('token', token);
        }else{
          helper.Toast().fire({ icon: 'error', title: response.data.message });
          return {status: 0, token: ""}
        // return false
        }
    } catch (error) {
      console.log(error);
    } 
    setLoading(false)
  }

  const checkKey = (e) => {
    setPressedCode(e.keyCode)
    if(e.keyCode === 8 && e.target.value === ""){
      switch (e.target.name) {
        case 'two':
          ElementOne.current.focus()  
        break;
        case 'three':
          ElementTwo.current.focus()  
        break;
        case 'four':
          ElementThree.current.focus()  
        break;
        case 'five':
          ElementFour.current.focus()  
        break;
        case 'six':
          ElementFive.current.focus()  
        break;
        case 'twoE':
          ElementOneE.current.focus()
        break;
        case 'threeE':
          ElementTwoE.current.focus()
        break;
        case 'fourE':
          ElementThreeE.current.focus()
        break;
        case 'fiveE':
          ElementFourE.current.focus()
        break;
        case 'sixE':
          ElementFiveE.current.focus()
        break;
        case 'twoP':
          ElementOneP.current.focus()
        break;
        case 'threeP':
          ElementTwoP.current.focus()
        break;
        case 'fourP':
          ElementThreeP.current.focus()
        break;
        case 'fiveP':
          ElementFourP.current.focus()
        break;
        case 'sixP':
          ElementFiveP.current.focus()
        break;

        default:
        break;
      }
    }
  }

  const obsecureEmail = (email) => {
    if(email !== undefined && email.length > 0) {
      const [name, domain] = email.split('@');
      return `${name[0]}${new Array(name.length-1).join('x')}${new Array(name[name.length-1])}@${domain}`;
    }
  }

  useEffect(() => {
    setTimeout(() => {      
      checkUserVerify()
    }, 1000);
  }, [])
  
  const handleCheckState = (type) => {
    if(type === "email" && isPhoneChecked) {
      setIsEmailChecked(!isEmailChecked)
    } else if(type === "phone" && isEmailChecked) {
      setIsPhoneChecked(!isPhoneChecked)
    }
  }

  return (
    <div id="login-bg" className="auth-page-wrapper auth-bg-cover py-3 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card overflow-hidden">
                            <div className="row g-0 justify-content-center align-items-center">
                                <div className="col-lg-6">
                                    <div className="p-lg-5 p-4 left-icon h-100">
                                        <div className="bg-overlay"></div>
                                        <div className="position-relative h-100 d-flex justify-content-center align-items-center flex-column">
                                            <div className="mx-auto">
                                                <Link to="/" className="d-block">
                                                    <img src="../assets/images/logo-hk.png" alt="Housekeeper-Logo" />
                                                </Link>
                                            </div>
                                            <div className="mt-2">
                                                <p>simplified cleaning</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="px-lg-5  p-4">
                                        <div className="contant-area">
                                            <h1>OTP Verification</h1>
                                            {(decoded.phone_number !== undefined) &&
                                              <p className="form-sub-title">Enter your OTP sent to {obsecureEmail(decoded.email)} and XXXXXX{decoded.phone_number.substring(decoded.phone_number.length - 4)}.
                                               {/* OTP is:-{otpCode} */}
                                              </p>
                                            }
                                        </div>
                                        {((decoded.emailOtpCode !== undefined && decoded.phoneOtpCode !== undefined) && decoded.emailOtpCode !== "" && decoded.phoneOtpCode !== "") &&
                                          <div className="contant-area mt-3">
                                            <span>
                                              <input type='checkbox' checked={isEmailChecked} onChange={(e) => handleCheckState('email')} />
                                              <span style={{fontSize: "16px"}}> Email </span>
                                            </span>
                                            <span className="ms-4">
                                              <input type='checkbox' checked={isPhoneChecked} onChange={(e) => handleCheckState('phone')} />
                                              <span style={{fontSize: "16px"}}> Phone </span>
                                            </span>
                                          </div>
                                        }

                                        <div className="mt-4">
                                            <form className="login-form" onSubmit={handleSubmit}>
                                              {((decoded.emailOtpCode === undefined && decoded.phoneOtpCode === undefined) || (decoded.emailOtpCode === "" && decoded.phoneOtpCode === "")) &&
                                                <div id="loginOtpSection"> 
                                                  <div className="mb-3 d-flex align-items-center gap-3 otp-filed">
                                                    <input  
                                                      onChange={handleKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='one'
                                                      value={one}
                                                      autoFocus={true}
                                                      ref={ElementOne} />
                                                    <input  
                                                      onChange={handleKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='two'
                                                      value={two}
                                                      ref={ElementTwo} />
                                                    <input  
                                                      onChange={handleKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='three'
                                                      value={three}
                                                      ref={ElementThree} />
                                                    <input  
                                                      onChange={handleKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='four'
                                                      value={four} 
                                                      ref={ElementFour} />
                                                    <input  
                                                      onChange={handleKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='five'
                                                      value={five}
                                                      ref={ElementFive} />
                                                    <input  
                                                      onChange={handleKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='six'
                                                      value={six}
                                                      ref={ElementSix} />                                                    
                                                  </div>
                                                  {(time > 0) ? <Timer></Timer> : 
                                                  <label 
                                                    className="form-check-label primary-text" 
                                                    htmlFor="auth-remember-check"
                                                    onClick={(e) => handleResendCode(e)}>Resend Code
                                                  </label>
                                                  }
                                                </div>
                                              }

                                              {(decoded.emailOtpCode && decoded.emailOtpCode !== "" && isEmailChecked) &&
                                                <div id="emailOtpSection">
                                                  <label htmlFor="username" className="form-label">Enter Email OTP</label> 
                                                  <div className="mb-3 d-flex align-items-center gap-3 otp-filed">
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='oneE'
                                                      value={oneE}
                                                      autoFocus={true}
                                                      ref={ElementOneE} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='twoE'
                                                      value={twoE}
                                                      ref={ElementTwoE} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='threeE'
                                                      value={threeE}
                                                      ref={ElementThreeE} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='fourE'
                                                      value={fourE} 
                                                      ref={ElementFourE} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='fiveE'
                                                      value={fiveE}
                                                      ref={ElementFiveE} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='sixE'
                                                      value={sixE}
                                                      ref={ElementSixE} />                                                    
                                                  </div>
                                                  {(time > 0) ? <Timer></Timer> : 
                                                  <label 
                                                    className="form-check-label primary-text" 
                                                    htmlFor="auth-remember-check"
                                                    onClick={(e) => handleResendCode(e, 'email')}>Resend Code
                                                  </label>
                                                  }
                                                </div>
                                              }

                                              {(decoded.phoneOtpCode && decoded.phoneOtpCode !== "" && isPhoneChecked) &&
                                                <div id="phoneOtpSection"> 
                                                  <label htmlFor="username" className="form-label">Enter Phone OTP</label> 
                                                  <div className="mb-3 d-flex align-items-center gap-3 otp-filed">
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='oneP'
                                                      value={oneP}
                                                      autoFocus={true}
                                                      ref={ElementOneP} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='twoP'
                                                      value={twoP}
                                                      ref={ElementTwoP} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='threeP'
                                                      value={threeP}
                                                      ref={ElementThreeP} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='fourP'
                                                      value={fourP} 
                                                      ref={ElementFourP} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='fiveP'
                                                      value={fiveP}
                                                      ref={ElementFiveP} />
                                                    <input  
                                                      onChange={handleOtpKeyDown}
                                                      onKeyDown={checkKey}
                                                      className="form-control"
                                                      maxLength="1"
                                                      name='sixP'
                                                      value={sixP}
                                                      ref={ElementSixP} />                                                    
                                                  </div>
                                                  {(time > 0) ? <Timer></Timer> : 
                                                  <label 
                                                    className="form-check-label primary-text" 
                                                    htmlFor="auth-remember-check"
                                                    onClick={(e) => handleResendCode(e, 'phone')}>Resend Code
                                                  </label>
                                                  }
                                                </div>
                                              }

                                              <div className="mt-4">
                                                  <button className="btn primary-btn btn-shadow w-100" type="submit">Verify</button>
                                              </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
  )
}

export default VerifyOtp