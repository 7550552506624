import { useRef, useEffect, useState } from "react";
const AutoComplete = (props) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["address"]
  };

  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    setDefaultValue(props?.defaultValue)
  },[props?.defaultValue])

  useEffect(() => {
    setTimeout(() => {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        props.setPlace(place);
      });
    }, 2000);
  }, []);

  return (
    <>
      <div className="input-group mb-4">
          <span className="input-group-text" id="basic-addon1"><i className="ri-map-pin-fill"></i></span>
          <input 
            type="text" 
            className="form-control" 
            placeholder="Enter a location" 
            ref={inputRef} 
            // onChange={(e) => (defaultValue) ? setDefaultValue(e.target.value) : ''}
            onChange={(e) => setDefaultValue(e.target.value)}
            value={defaultValue}
          />
      </div>
    </>
  );
};
export default AutoComplete;