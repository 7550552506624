import { createContext, useContext, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// import api from '../../services/api';
import axios from 'axios';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { db, auth } from '../../fireabse';
import { decodeToken  } from "react-jwt";
import { doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
// import OneSignal from 'react-onesignal';
import helper from '../../helper/index';
import { BroadcastChannel } from 'broadcast-channel';

const tabLogoutChannel = new BroadcastChannel('handleTabsLogout');
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    // const [cookies, setCookies, removeCookie] = useCookies();
    const [roleId, setRoleId] = useState(0);
    const [permission, setPermission] = useState([]);
    const login = async ({ email, password = '', socialLogin = false, token = '' }) => {
        try {
          Cookies.remove('token');
          Cookies.remove('auth_user');
          const response = await axios.post(process.env.REACT_APP_BACKENDURL+'general/webLogin', {
            email: email,
            password: password,
            socialLogin: socialLogin,
            gToken:token

          },{
            headers: {
                'Content-Type': 'application/json'
            }
          });
          
          if(response.data.status === 1){
            const firebase_uid_exist = response.data.data.firebase_uid ? true : false;
            if(!firebase_uid_exist) {
              const firstName = response.data.data.first_name;
              const lastName = response.data.data.last_name;
              const user_id = response.data.data._id;
              const role_id = response.data.data.role_id;
              const userCredential =  await createUserWithEmailAndPassword(auth, email, user_id);
              const user = userCredential.user;
              // Save firebase uid in the database
              const resp = await axios.post(process.env.REACT_APP_BACKENDURL+'user/update-firebase-uid', {
                uid: user.uid,
                user_id: user_id,
              },{
                  headers: {
                      'Content-Type': 'application/json'
                  }
              });

              if(resp.data.status === 1){
                // Save name in firebase user
                const update = await updateProfile(user, {
                  displayName: firstName + ' ' + lastName,
                });

                setDoc(doc(db, "users", user.uid), {
                  uid: user.uid,
                  name: firstName + ' ' + lastName,
                  email: email,
                  role_id: parseInt(role_id)
                });
              }
              Cookies.set('auth_user', user.uid);
            } else {
              Cookies.set('auth_user', response.data.data.firebase_uid);
            }
            // Set Token in cookie
            Cookies.set('token', response.data.data.token);
            // get user data from token in cookie
            // eslint-disable-next-line no-unused-vars
            const decoded = decodeToken(response.data.data.token);

            //firebase sign in
            // eslint-disable-next-line no-unused-vars
            var FData = await signInWithEmailAndPassword(auth, email, response.data.data._id);
            await updateUserActiveStatus(auth.currentUser.uid, true);
            setRoleId(response.data.data.role_id);
            setPermission(response.data.permissions);
            return {status: 1, token: response.data.data.token}
            // return true;
          }else{
            helper.Toast().fire({ icon: 'error', title: response.data.message });
            return {status: 0, token: ""}
            // return false
          }
          
        } catch (error) {
          return {status: 0, token: "", message: error.message}
        } 
    };

    // const updatePushNotification = async(token) => {
    //   try {
    //     const oneSignalId = OneSignal.User.PushSubscription.id;
    //     if(oneSignalId !== undefined) {
    //       await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-pushNotification', {
    //                         oneSignalId: oneSignalId
    //                       },{
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             'token': token
    //                         }
    //                       });
    //     }
    //     return;
    //   } catch(err) {
    //     console.log("logout error: ", err);
    //     return;
    //   }
    // } 

    const logout = async () => {
        const lastActiveToken = Cookies.get('token');
        // ['token', 'name'].forEach(obj => removeCookie(obj, { path: '/', domain: process.env.REACT_APP_DOMAIN })); // remove data save in cookies
        // await updatePushNotification(cookies.token);
        Cookies.remove('token');
        // logout from firebase
        const auth_user = Cookies.get('auth_user');
        await updateUserActiveStatus(auth_user, false);
        Cookies.remove('auth_user');
        try {
          signOut(auth);
        } catch (err) {
        }
        setTimeout(() => {
          tabLogoutChannel.postMessage(lastActiveToken);
          navigate('/login');
        }, 1000);
    };

    // const _deleteFirestoreAccount = async(userId) => {
    //   const user = auth.currentUser;
    //   await deleteDoc(doc(db, "users", user.uid));

    //   const credential = EmailAuthProvider.credential(user.email, userId);
    //   try {
    //     await reauthenticateWithCredential(user, credential);
    //   } catch (error) {
    //     // Cookies.remove('token');
    //     // Cookies.remove('auth_user');
    //     // navigate('/login');
    //     let data = {status: 0};
    //     console.log('data1', data);
    //     return (data);
    //   }

    //   await deleteUser(user).then(() => {  
    //     // logout();
    //     // Cookies.remove('token');
    //     // Cookies.remove('auth_user');
    //     // navigate('/login');
    //     let data = {status: 1};
    //     console.log('data2', data);
    //     return (data);

    //   }).catch((error) => {
    //     // Cookies.remove('token');
    //     // Cookies.remove('auth_user');
    //     // navigate('/login');
    //     let data = {status: 0};
    //     console.log('data3', data);
    //     return (data);
    //   });
    // }
    
    const deleteFirestoreAccount = async(userId) => {
      return new Promise(async(resolve) => {
        let data = {};
        const user = auth.currentUser;
        await deleteDoc(doc(db, "users", user.uid));

        const credential = EmailAuthProvider.credential(user.email, userId);
        try {
          await reauthenticateWithCredential(user, credential);
        } catch (error) {
          data = {status: 0};
        }

        await deleteUser(user).then(() => {  
          data = {status: 1};
        }).catch((error) => {
          data = {status: 0};
        });

        resolve(data);
      });
    }

    const updateUserActiveStatus = (userId, status) => {
      return new Promise((resolve) => {
        const result = updateDoc(doc(db, "users", userId), {
                          status: status
                        }).catch(err => {

                        });
        resolve(result);
      });
    }

    const clearAccountData = () => {
      Cookies.remove('token');
      Cookies.remove('auth_user');
      navigate('/login');
    } 

    const value = useMemo(
        () => ({
            login,
            logout,
            roleId,
            permission,
            deleteFirestoreAccount,
            clearAccountData
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [roleId, permission]
    );

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
};

export const useAuth = () => {
    return useContext(UserContext)
};