import React,{useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { decodeToken  } from "react-jwt";
import Cookies from 'js-cookie';
import moment from 'moment';
import helper from '../../../helper/index';
import Swal from 'sweetalert2';


const AssignmentDetail = (props) => {
    const token = Cookies.get('token');
    console.log(token)
    const decoded = decodeToken(token);
    const navigate = useNavigate();
    const params = useParams();
    const assignmentId = params.assignmentId;
    
    const [ assDetail, setAssDetail ] = useState({
        propertyDetail : {},
    });

    const fetchAssessmentDetail = async () => {
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/host/get-assignment-detail?id=' + assignmentId, 
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log(error);
        });
       
        if(data.status === 1){
            console.log(data.data);
            setAssDetail(data.data);
        }else{
            navigate('/host/assignment/list');
        }
    };

    const hireJob = async (cleanerId, cleanerName) => {
        Swal.fire({
            text: `Are you sure you want to hire ${cleanerName} for this job?`,
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                 // update-invitation-status
                axios.post(
                process.env.REACT_APP_BACKENDURL+'api/host/assignment/accept-invitation', 
                {
                    cleanerId: cleanerId,
                    id: assignmentId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }
                )
                .then(() => {
                    helper.Toast().fire({ icon: 'success', title: `Assigment has been successfully assigned to ${cleanerName}` }).then(() => {
                        navigate("/host/assignment/detail/" + assignmentId);
                    });
                    
                })
                .catch((err) => {
                    console.log('err', err)
                });
            }
        })
       
    }

    useEffect(() => {
        fetchAssessmentDetail();
    }, []);


    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Invited Cleaners List</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/host/assignment/list">Assignments</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/host/assignment/detail/" + assignmentId}>Assignment Detail</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Invited Cleaners List</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                        
                                {(typeof assDetail.cleaners !=="undefined") && assDetail.cleaners.length > 0 ?
                                    <>
                                        <div className="table-responsive table-card data-table">
                                            <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                                <thead className="">
                                                    <tr className="text-muted">
                                                        <th scope="col">Cleaner Name</th>
                                                        <th scope="col">Assignment Name</th>
                                                        {/* <th scope="col">Property Name</th> */}
                                                        <th scope="col">Assignment Date</th>
                                                        <th scope="col">Assignment Time</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Action
                                                        </th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {assDetail.cleaners.map((item, i) => { 
                                                        return ( 
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <img src="/assets/images/users/avatar-2.jpg" alt=""
                                                                            className="rounded-circle avatar-sm me-2" />
                                                                        <div>
                                                                            <a href="#javascript: void(0);"className="text-body fw-medium"> {item.label}</a>
                                                                        </div>

                                                                    </div>
                                                                </td>
                                                                <td>{assDetail.title}</td>
                                                                {/* <td>{assDetail.propertyDetail.propertyName}</td> */}
                                                                <td className="number"> { (assDetail.date) ? moment(assDetail.date).format('MMM Do YYYY') : "" }</td>
                                                                <td>{assDetail.startTime} - {assDetail.endTime}</td>
                                                                <td className="number">${assDetail.price}</td>
                                                             
                                                                <td className="note-icon">
                                                                    <div className="hstack gap-3 flex-wrap">
                                                                        { ((typeof assDetail.cleanerId === "undefined") || (assDetail.cleanerId === "")) ?
                                                                            <button 
                                                                                type="button" 
                                                                                className="btn added-btn btn-add w-100 waves-effect waves-light" 
                                                                                value={item.label}
                                                                                onClick={(e) => hireJob(item.value, item.label) }> Hire</button>
                                                                        :
                                                                            ((item.value === assDetail.cleanerId) ? 
                                                                            <button type="button" className="btn added-btn btn-add w-100 waves-effect waves-light">Hired</button>
                                                                            : "")
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        
                                    </>

                                :
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="alert alert-danger" role="alert">No assignment yet.</div>
                                        </div>
                                    </div>
                                }
                        
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    )
}

export default AssignmentDetail;
