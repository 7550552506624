import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import helper from '../../helper/index';
import Cookies from 'js-cookie';
import moment from 'moment';
let limit = 10;

const FeedbackList = () => {
    const token = Cookies.get('token');
    const fullurl =  window.location.href;
    const segment = fullurl.split("/"); 
    let role_id = 0;
    let role_name = '';
    if(segment[3] !== "undefined" && segment[3] === "host"){
        role_id = 2;
        role_name = "host";
    }else if(segment[3] !== "undefined" && segment[3] === "cleaner"){
        role_id = 3;
        role_name = "cleaner";
    }

    const buttonRef = useRef(null);

    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [formType, setFormType] = useState('');
    const [titleError, setTitleError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [typeError, setTypeError] = useState('');

    const [titleText, setTitleText] = useState('');
    const [messageText, setMessageText] = useState('');
    

    const addContact = (event) => {

        event.preventDefault();
        
        setTitleError("");
        setTypeError("");
        setMessageError("");

        let error = 0;
        if(title.trim().length === 0) {
            setTitleError('Title field is required.');
            error = 1;
        }
        if(formType.trim().length === 0) {
            setTypeError("Please select type of submission");
        }
        if(message.trim().length === 0) {
            setMessageError('Subject field is required.');
            error = 1;
        }

        if(error === 1){
            return;
        }

        axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/submit-contact-form',
            { 
                role_id: role_id,
                title: title,
                form_type: formType,
                message: message
            },
            { headers: { 'Content-Type': 'application/json', 'token': token } }
        )
            .then(function (response) {
                if (response.data.status === 1) {
                    // /TODO
                    helper.Toast().fire({ icon: 'success', title: "Feedback submitted successfully." });
                    getData([]);
                    setTitle('');
                    setMessage('');
                    buttonRef.current.click();
                } else {
                    helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL + 'api/common/fetch-contact-forms', {
            params: {
                page: pageNo,
                limit: limit,
                role_id: role_id,
                sortBy: 'desc',
            },
            headers: { 'Content-Type': 'application/json', 'token': token }
        }).then(function (response) {
            if (response.data.status === 1) {
                setPageCount(response.data.total_pages);
                setData(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const renderContect = (title, message) => {
        setTitleText(title);
        setMessageText(message);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Feedback Submission</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to={`/${role_name}/dashboard`}>Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={`/${role_name}/settings`}>Settings</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Feedback Submission</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className='text-end'>
                            <button className="btn btn-add add-btn mb-3" data-bs-toggle="modal" data-bs-target="#contactModal"><i className="ri-add-line align-bottom me-1"></i> Submit Feedback</button>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="live-preview">
                                    {(data.length > 0) ?
                                        <>
                                            <div className="table-responsive mb-3">
                                                <table className="table table-striped table-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Message</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>
                                                                        {(item.title.length > 30) ?
                                                                           
                                                                           item.title.substring(0, 30)
                                                                           
                                                                        : 
                                                                            item.title
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {(item.message.length > 30) ?
                                                                           
                                                                           item.message.substring(0, 30)
                                                                           
                                                                        : 
                                                                            item.message
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {(item.type === 1) && 'Feedback'}
                                                                        {(item.type === 2) && 'Query'}
                                                                    </td>
                                                                    <td>
                                                                    {moment(item.createdAt).format('MMM Do YYYY, hh:mm A')}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex gap-2">
                                                                            <div className="edit">
                                                                                <button type="button" onClick={(e) => {renderContect(item.title, item.message)}} className="btn btn-sm btn-success edit-item-btn"  data-bs-toggle="modal" data-bs-target="#viewContact" >View Details</button>
                                                                            </div>                                                                        
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ReactPaginate
                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                breakLabel="..."
                                                pageCount={PageCount}
                                                pageRangeDisplayed={3}
                                                onPageChange={getData}
                                                containerClassName={'pagination pagination-rounded mt-3 mb-3 justify-content-center'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                renderOnZeroPageCount={null}
                                                activeClassName={'active'}
                                            />
                                        </>
                                        :
                                        <>
                                            <h5>No feedback submission found</h5>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade zoomIn" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content border-0">
                            <div className="modal-header p-3 bg-soft-info">
                                <h5 className="modal-title" id="contactModalLabel">Feedback Submission</h5>
                                <button  ref={buttonRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    id="close-modal"></button>
                            </div>
                            <form onSubmit={addContact} >
                                <div className="modal-body">
                                    <div className="row g-3">
                                        
                                        <div className="col-lg-12">
                                            <div>
                                                <label className="form-label">Subject</label>
                                                <input type="text" className="form-control" placeholder="Subject"  value={title} onChange={(e) => setTitle(e.target.value)} maxLength={100} />
                                                {titleError && <div className="text-danger"> {titleError} </div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <label className="form-label">Submission Type</label>
                                                <select id="submission_type" name="submission_type" className="form-control" onChange={(e) => setFormType(e.target.value)}>
                                                    <option>Please Select Type</option>
                                                    <option value="1">Submitting a Feedback</option>
                                                    <option value="2">Submitting a Query</option>
                                                </select>
                                                {typeError && <div className="text-danger"> {typeError} </div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <label htmlFor="client_nameName-field" className="form-label">Message</label>
                                                <textarea  className="form-control" rows={5} value={message} onChange={(e) => setMessage(e.target.value)} maxLength={500} ></textarea>
                                                <div className="float-end me-1 word-limit-count">{message.length}/500 Characters</div>
                                                {messageError && <div className="text-danger"> {messageError} </div>}
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit" className="btn btn-success" id="add-btn">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade zoomIn" id="viewContact" tabIndex="-1" aria-labelledby="viewContactLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content border-0">
                            <div className="modal-header p-3 bg-soft-info">
                                <h5 className="modal-title" id="viewContactLabel">View Detail</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    id="close-modal"></button>
                            </div>
                            
                            <div className="modal-body">
                                <div className="row g-3">
                                    
                                    <div className="col-lg-12">
                                        <div>
                                            <label className="form-label">Subject</label>
                                            <p>{titleText}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div>
                                            <label className="form-label">Message</label>
                                            <p>{messageText}</p>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FeedbackList;
