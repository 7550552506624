import React from "react";

const containerStyle = {
	position: "fixed",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
	background: "rgba(0, 0, 0, 0.5)",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	zIndex: 9
}

const innerContainerStyle = {
	background: "white",
	height: "auto",
	width: "550px",
	margin: "auto",
	padding: "2%",
	border: "2px solid #000",
	borderRadius: "10px",
	boxShadow: "2px solid black",
	// height: "calc(100% - 10%)"
}

const innerContainerStyle2 = {
	background: "white",
	// height: "auto",
	width: "720px",
	margin: "auto",
	padding: "2%",
	border: "2px solid #000",
	borderRadius: "10px",
	boxShadow: "2px solid black",
	height: "calc(100% - 10%)"
}


const Modal = ({ isOpen, onClose, children, isName }) => {
	if (!isOpen) return null;

	return (
		<div onClick={onClose} style={containerStyle}>
			<div style={(isName==="privacyPolicy") ? innerContainerStyle2 : innerContainerStyle}>
				{children}
			</div>
		</div>
	);
};

export default Modal;
