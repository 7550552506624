import React from 'react';
import { useWizard } from 'react-use-wizard';
import { useContext } from 'react';
import { PropertyContext } from '../../Contexts/PropertyContext';

const ProgressBar = () => {
  const {stepNames} = useContext(PropertyContext);
  const { activeStep, stepCount } = useWizard(); 

  return (
        <div className="step-arrow-nav mb-4">
              {/* <div className="progress" style={{ 'height': '1px' }}>
                  <div className="progress-bar" 
                    role="progressbar" 
                    style={{ 'width': '0%' }} 
                    aria-valuenow="0" 
                    aria-valuemin="0" 
                    aria-valuemax="100"></div>
              </div> */}

              <ul id="propert-step" className="nav nav-pills custom-nav nav-justified" role="tablist">
              {[...Array(stepCount)].map((x, i) =>
                <li className="nav-item" role="presentation" key={i}>
                  <button 
                        className={`nav-link ${ ( (i===0 && activeStep === 0 ) ? 'active' : ( (activeStep === i) ? 'active' : (activeStep > i ? 'done' : '')) )} `}
                        id={`pills-${i}-tab`}
                        data-bs-target={`#pills-${i}`}
                        type="button"
                        // onClick={() => {((isLastStep || i+1 == activeStep) ? previousStep() : nextStep())} }
                        // onClick={() => goToStep(i)}
                        {...(i !== 0 ? {tabIndex: '-1'} : {})}
                        >{stepNames[i]}</button>
                  </li>
                )}
              </ul>
    </div>
  )
}
export default ProgressBar;