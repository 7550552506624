import React,{ useEffect, useState, useContext, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import helper from '../../../helper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation, Pagination, Scrollbar, A11y  } from 'swiper/modules';
import moment from 'moment';
import FsLightbox from "fslightbox-react";
import Modal from '../../../components/common/Modal.js';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Swal from 'sweetalert2';
import { HostContext } from '../../../Contexts/HostContext';
import { socket } from '../../../socket';

const inputStyle = {
    "border": "2px solid #8f368d",
    "padding": "10px",
    "width": "100%",
    "fontSize": "16px",
    "fontFamily": "'Inter'",
    "borderRadius": "8px",
    "fontWeight": "700",
    "marginBottom": "0",
}

const readonlyInputStyle = {
    "border": "2px solid #8f368d",
    "padding": "10px",
    "width": "100%",
    "fontSize": "16px",
    "fontFamily": "'Inter'",
    "borderRadius": "8px",
    "fontWeight": "700",
    "marginBottom": "0",
    "backgroundColor": "#cdccc747"
}

const imgContainer = {
    height: '80px'
}

const imgStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
}

const thumbInner = {
    display: 'flex',
    overflow: 'hidden',
    // width: '150px'
};


const AssignmentDetail = (props) => {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const closeModalButton = useRef(null);
    const {decoded} = useContext(HostContext);  
    const cleanerId = decoded.user_id
    const token = Cookies.get('token');
    const params = useParams();
    const [data, setData] = useState({
        propertyDetail: {},
        procedure: {
            gettingThere: {},
            gettingInside: {},
            arrivalConfirm: {},
            checkList: [],
            images: []
        },
        checklist: [],
        cleaners: [],
        dataSwitch:0,
        hostDetail: {},
        hostProfile: {},
        hostReviews: {},
        review: {},
        hostReview: {}
    })
    const [checkedList, setCheckedList] = useState([])
    const [procedureImages, setProcedureImages] = useState([])
    const [procedureImagesToUpload, setProcedureImagesToUpload] = useState([])
    const [allChecked, setAllChecked] = useState(false);
    const [isImageDecline, setIsImageDecline] = useState(false);
    const [barganingPrice, setBarganingPrice] = useState(0)
    const [reason, setReason] = useState("")
    const [isButtonsNeed, setIsButtonsNeed] = useState(false)
    const [imageStat, setImageStat] = useState({
        totalImagesCount: 0,
        declineImagesCount: 0,
        imagesAllowed: 5
    })
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [imagesComplete, setImagesCompleted] = useState(0);
    const [ comment, setComment ] = useState('');
    const [addReview, setAddReview] = useState(false);
    const [reviewError, setReviewError] = useState("");
    const assignmentId = params.assignmentId;
    const subTypes = helper.subTypes
    const date = moment(data.date, "M/D/YYYY").format("MM/DD/YYYY")
    const now = moment().format("MM/DD/YYYY")
    const [instructionImages, setInstructionImages] = useState([]);
    const [propertyImagesArr, setPropertyImagesArr] = useState([]);
    const [procedureImagesArray, setProcedureImagesArray] = useState([]);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const [lightboxController2, setLightboxController2] = useState({
        toggler: false,
        slide: 1
    });
    const [propertyImgController, setPropertyImgController] = useState({
		toggler: false,
		slide: 1
	});
    const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);

    const fetchAssessmentDetail = async () => {
        let isImagesComplete = false;
        let declineImageCount = 0;
        let totalImageCount = 0;
        let allwoedImages = imageStat.imagesAllowed;
        props.setLoading(true);
        const { data } = await axios.post(
                            process.env.REACT_APP_BACKENDURL+'api/cleaner/assignment-detail', 
                            {
                                assessment_id: assignmentId.trim(),
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'token': token
                                }
                            }
                        );
       
        if(data.status === 1){   
            setData(data.data)
            setCheckedList(data.data.procedure.checkList)

            if(data.data.propertyDetail.images && data.data.propertyDetail.images.length > 0) {
                let propertyImg = [];
                data.data.propertyDetail.images.map((img, i) => {
                    propertyImg.push(process.env.REACT_APP_BACKENDURL+'images/'+img.filename);
                });
                setPropertyImagesArr(propertyImg);
            }
            // let letbuttonStatus = (data.data.status === 0 || data.data.status === 1) ? true : false
            // setIsButtonsNeed(letbuttonStatus)
                // setIsImageDecline(true)

            if(typeof data.data.procedure.images !== "undefined" && data.data.procedure.images.length > 0){
                // eslint-disable-next-line array-callback-return
                let imagesComplete = 1;
                let pImg = [];
                data.data.procedure.images.map((img, i) => {
                    totalImageCount ++
                    if(img.status === "2" || img.status === "3"){
                        isImagesComplete = true;
                        declineImageCount ++
                    }
                    
                    if(img.status === "0" || img.status === "2"){
                        imagesComplete = 0;
                    }

                    if(img.status === "0" || img.status === "1") {
                        pImg.push(process.env.REACT_APP_BACKENDURL+'assignments/'+img.filename);
                    }
                });

                setProcedureImagesArray(pImg);

                setImagesCompleted(imagesComplete);
                imageStat['declineImagesCount'] = declineImageCount 
                imageStat['totalImagesCount'] = totalImageCount
                imageStat['imagesAllowed'] = (5 - (totalImageCount - declineImageCount))
                setImageStat({...imageStat})
            }
            setIsImageDecline(isImagesComplete)

            data.data.cleaners.map((item, i) => {
                if(cleanerId === item.value){
                    if(item.status === 0  && item.is_bargaining !== 1){
                        setIsButtonsNeed(true)
                    }
                }
            })

            if(Object.keys(data.data.review).length > 0){
                setRating(data.data.review.stars);
            }

            if(data.data.propertyDetail.accessibilityImages !== undefined && data.data.propertyDetail.accessibilityImages.length > 0) {
                let imgurl = [];
                let path = process.env.REACT_APP_BACKENDURL+'images/';
                data.data.propertyDetail.accessibilityImages.map((img, i) => (
                    imgurl.push(path+img.filename)
                ));
                setInstructionImages(imgurl);
            }
            props.setLoading(false);
            
        }else{
            props.setLoading(false);
            // navigate('/host-list');
        }
    };
    
    const manageStatus = (e) => {
        e.preventDefault();
        let status = e.target.value
        let title = (status === "1") ? "accept" : "decline"
        Swal.fire({
            text: `Are you sure you want to ${title} this invitation`,
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                 // update-invitation-status
                axios.post(
                process.env.REACT_APP_BACKENDURL+'api/cleaner/update-invitation-status', 
                {
                    assessment_id: assignmentId.trim(),
                    status: status
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }
                )
                .then(() => {
                    sendSignalToHost()
                    // Emit Sockect when cleaner accept the invitation
                    if(status === "1"){
                        sendUnreadAssignmentDot()
                    }
                    sendNotificationToHost()
                    fetchAssessmentDetail()
                    setIsButtonsNeed(false)
                })
                .catch((err) => {
                    console.log('err', err)
                });
            }
        })
       
    }

    const manageBarganing = (e) => {
        e.preventDefault();
        // Check some validations

        if(data.price >= barganingPrice){
            helper.Toast().fire({ icon: 'warning', title: `Please enter amount more than ${parseFloat(data.price).toFixed(2)}` });
            return;
        }else if(reason === "" || reason.length < 25){
            if(reason !== "" && reason.length < 25){
                helper.Toast().fire({ icon: 'warning', title: "The text you are entering is too short. It must be at least 25 characters in length" });
            }else{
                helper.Toast().fire({ icon: 'warning', title: "Please enter your reason." });
            }
            return 
        }
        props.setLoading(true);
        axios.post(
            process.env.REACT_APP_BACKENDURL+'api/cleaner/bargaining-invitation', 
            {
                assessment_id: assignmentId.trim(),
                price: barganingPrice,
                message: reason
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
            )
            .then(() => {
                props.setLoading(false);
                sendSignalToHost()
                sendNotificationToHost()
                closeModalButton.current.click()
                fetchAssessmentDetail();
                setIsButtonsNeed(false);
            })
            .catch((err) => {
                props.setLoading(false);
                console.log('err', err)
            });
    }

    const manageProcedureStatus = (e) => {
        e.preventDefault();
        if(now !== date){
            if(now < date){
                helper.Toast().fire({ icon: 'info', title: "You cannot start this assignment before "+data.date });
            }else{
                helper.Toast().fire({ icon: 'warning', title: "This assignment has been expired!" });
            }
            return false;
        }
        let status = e.target.value;
        axios.post(
            process.env.REACT_APP_BACKENDURL+'api/cleaner/start-procedure', 
            {
                assessment_id: assignmentId.trim(),
                status: status,
                checkedList
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
            )
            .then(() => {
                sendNotificationToHost()
                sendSignalToHost()
                fetchAssessmentDetail()
            })
            .catch((err) => {
                console.log('err', err)
            });
    }

    const submitCheckListImages = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);        
        formData.append(`assessment_id`,assignmentId.trim());
        if(procedureImagesArray.length + procedureImagesToUpload.length !== 5) {
            helper.Toast().fire({ icon: 'error', title: `Total ${imageStat.imagesAllowed} image(s) is required.` });
            return;
        }

        formData.delete('images');
        procedureImagesToUpload.forEach((imageToUpload, index) => {
            formData.append(`images`, imageToUpload);
        });
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/cleaner/upload-checklist-images',formData,{
            headers: {
                'token': token,
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                sendNotificationToHost()
                sendSignalToHost()
                props.setLoading(false)
                fetchAssessmentDetail()
                //navigate('/host/profile');
                setProcedureImages([])
                setProcedureImagesToUpload([])
                helper.Toast().fire({
                    icon: 'success',
                    title: response.data.message
                })
            }else{
                props.setLoading(false)
                helper.Toast().fire({
                    icon: 'error',
                    title: response.data.message
                })
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    };

    function handleFileChange(e,type) {
        let imagesArray = [];
        let imagesArrayToUpload = [];
        let totalImagesLength = Object.keys(e.target.files).length + procedureImagesToUpload.length;
        // if(Object.keys(e.target.files).length === imageStat.imagesAllowed){
        if(totalImagesLength === imageStat.imagesAllowed){
            Object.keys(e.target.files).map((imgKey) => {
                imagesArrayToUpload.push(e.target.files[imgKey]);
                imagesArray.push(URL.createObjectURL(e.target.files[imgKey]))
            })
            setProcedureImages(prevState => [...prevState, ...imagesArray]);
            setProcedureImagesToUpload(prevState => [...prevState, ...imagesArrayToUpload]);
        } else if(totalImagesLength < imageStat.imagesAllowed) {
            helper.Toast().fire({ icon: 'error', title: `Total ${imageStat.imagesAllowed} image(s) is required.` });
        }
        return;
    }

    const handleDeleteImage = (index) => {
        setProcedureImages(crt => {
          return crt.filter((_el, ind) => (ind !== index))
        })

        setProcedureImagesToUpload(crt => {
          return crt.filter((_el, ind) => (ind !== index))
        })
    }

    const Checkbox = ({ isChecked, checkHandler, index, readOnly }) => {
        return (
            <input
                className="form-check-input me-1"
                type="checkbox"
                disabled={readOnly}
                id={`checkbox-${index}`}
                checked={isChecked}
                onChange={checkHandler}
            />
        )
    }

    const updateCheckStatus = index => {
        setCheckedList(checkedList.map((list, currentIndex) =>
            currentIndex === index
            ? { ...list, status: (list.status === 0) ? 1 : 0, timestamp: new Date() }
            : list
        ))
    }

    const SubmitRating = async (e) => {
        e.preventDefault();    
        setReviewError("");
        let error = 0;
        if(rating < 1) {
            setReviewError("Please select some rating");
            error = 1;
        }

        if(comment.trim().length === 0) {
            setReviewError("Please enter some comment");
            error = 1;
        } else if(comment.trim().length > 250) {
            setReviewError("You can add maximum 250 characters in comment.");
            error = 1;
        }

        if(error === 1) {
            return;
        }
        props.setLoading(true);
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/give-review', 
            {
                "role_id": 3,
                "assignmentId":assignmentId,
                "reviewTo": data.hostId,
                "stars": rating,
                "comment": comment
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
        ).then((response) => {
            sendNotificationToHost()
            sendSignalToHost()
            fetchAssessmentDetail();
            props.setLoading(false);
            if(Object.keys(response.data.review).length > 0){
                setRating(response.data.review.stars);
                data['review'] = response.data.review;
                setData({...data});
            }
        })
        .catch((err) => {
            console.log('err', err)
            props.setLoading(false);
        });
            
    }

    useEffect(() => {
        validateCheckbox()
    }, [checkedList])
    

    const validateCheckbox = () => {
        let status = false;
        checkedList.map((list, currentIndex) => {
            if(list.status === 0){
                status = true
            }
        })        
        setAllChecked(status)
    }

    const viewMessage = async (message) => {
        Swal.fire({
            text: message,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        })
    }

    const updateNotificationStatus = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-notification-status',
        {
            role_id: 3,
            assessment_id: assignmentId.trim()
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        
        socket.emit('send-notification-update-signal');
    }

    const updateAssignmentReadStatus = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-assignment-read-status',
        {
            role_id: 3,
            assessment_id: assignmentId.trim(),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
    }

    useEffect(() => {
        socket.emit('send-notification-signal-to-cleaner', cleanerId);
        fetchAssessmentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        updateNotificationStatus();
        updateAssignmentReadStatus();
    }, [params.assignmentId]);

    useEffect(() => {
        function onConnect() {
          setIsConnected(true);
        }
    
        function onDisconnect() {
          setIsConnected(false);
        }
        
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        socket.on('recieve-assignment-signal-from-host', (resData) => {
            if(resData.assignmentId === assignmentId){
                fetchAssessmentDetail();
                // updateNotificationStatus();
                updateAssignmentReadStatus();
            }
        });

        socket.on('save-socket-id', (socketId) => {
            UpdateSocketId(socketId, decoded.userlevel)
        });
    
        return () => {
          socket.off('connect', onConnect);
          socket.off('disconnect', onDisconnect);
          socket.off('recieve-assignment-signal-from-host', fetchAssessmentDetail);
        };
    }, []);

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            var now = moment(new Date());
            var createdDate = moment(data.date, "M/D/YYYY");
            var duration = moment.duration(now.diff(createdDate));
            var days = duration.asDays();
            if(days >= 14) {
                setAddReview(false);
            } else if(days < 14) {
                setAddReview(true);
            }
        }
    }, [data])

    const UpdateSocketId = (socketId, roleId) => {
        axios.post(
                    process.env.REACT_APP_BACKENDURL+"api/common/update-socket", 
                    {
                        socketId: socketId,
                        role_id: roleId
                    },
                    {
                        headers: {'Content-Type': 'application/json', 'token': token}
                    }
                )
    }

    const sendSignalToHost = () => {
        // Connect Socket if not connected
        if(!isConnected){
            socket.connect();
        }
        socket.emit('send-assignment-signal-to-host', assignmentId);
    }

    const sendNotificationToHost = () => {
        // Connect Socket if not connected
        if(!isConnected){
            socket.connect();
        }
        socket.emit('send-notification-signal-to-host',data.hostId);
    }

    const sendUnreadAssignmentDot = () => {
        // Connect Socket if not connected
        if(!isConnected){
            socket.connect();
        }
        socket.emit('send-unread-assignment-host',data.hostId);
    }

    const DoubleTick = () => {
        return (
            <div className="flex-shrink-0 avatar-xs">
                <div className="avatar-title bg-primary rounded-circle">
                    <i className=" ri-check-double-fill "></i>
                </div>
            </div>
        )
    }

    const SingleTick = () => {
        return (
            <div className="flex-shrink-0 avatar-xs">
                <div className="avatar-title bg-light text-prime rounded-circle">
                    <i className=" ri-check-fill "></i>
                </div>
            </div>
        )
    }

    const AssignmentAlertMessage = (props) => {
        return (
            <div className="alert alert-message alert-dismissible alert-label-icon rounded-label fade show fs-18 text-center fw-bold text-capitalize"
                role="alert"
                key={props.keyIndex}>
                <i className="ri-check-line label-icon"></i>
                {props.message}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        )
    }

    const AccordionHeader = (props) => {
        return (
            <div className="accordion-header" id={props.keyName+'-header'}>
                <a className="accordion-button p-2 shadow-none" 
                    data-bs-toggle="collapse"
                    href={"#"+props.keyName} 
                    aria-expanded="false" 
                    aria-controls={props.keyName}>
                    <div className="d-flex align-items-center">
                        {props.isApproved === 0 ? <SingleTick /> : <DoubleTick />}
                        <div className="flex-grow-1 ms-3">
                            <h6 className="fs-14 mb-0 fw-semibold">{props.name}
                                {props.timestamp ? 
                                    <span className="fw-normal number"> - {moment(props.timestamp).format('MMM Do YYYY, hh:mm A')}</span>
                                    :
                                    <></>
                                }                                
                            </h6> 
                        </div>
                    </div>
                </a>
            </div>
        )
    }

    function openLightboxOnSlide(number) {
        setLightboxController({
          toggler: !lightboxController.toggler,
          slide: number
        });
    }

    function openLightboxOnSlide2(number) {
        setLightboxController2({
          toggler: !lightboxController2.toggler,
          slide: number
        });
    }

    function openPropertyImgLightbox(number) {
		setPropertyImgController({
			toggler: !propertyImgController.toggler,
			slide: number
		});
	}

    const ProcedureList = (props) => {
        return (
            <div className="accordion-item border-0">
                <AccordionHeader keyName={props.keyName} name={props.name} isApproved={props.isApproved} timestamp={props.timestamp}/>
                {(props.needCheck === 1) ?
                    <div id={props.keyName}
                        className={'accordion-collapse collapse show' }
                        aria-labelledby={props.keyName+'-header'} 
                        data-bs-parent="#accordionExample">
                        
                            {(props.isApproved === 0) ?
                                <>
                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                        {
                                            props.instructions && <div className='mb-2'> {props.instructions} </div>
                                        }
                                        
                                        {(props.instructionPhoto !== undefined && props.instructionPhoto.length > 0) &&
                                            <div className='row pb-1'>
                                                {props.instructionPhoto.map((img, i) => (
                                                    (img && img.filename) && (
                                                        <div className='col-4 pe-0 pb-1' style={imgContainer} key={i}>
                                                            <div className="message-img-link text-end" style={{marginBottom: '-27px', marginTop: '0px'}}>
                                                                <ul className="list-inline mb-0">
                                                                    <li className="list-inline-item dropdown">
                                                                        <span className="dropdown-item" onClick={() => openLightboxOnSlide(i + 1)}>
                                                                            <span className="ri-zoom-in-line mx-1 text-muted align-bottom color-white font-18"></span>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <img src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} alt={img.filename} style={imgStyle} onClick={() => openLightboxOnSlide(i+1)} />
                                                        </div>
                                                    )))
                                                }
                                            </div>
                                        }
                                        
                                        {!props.message ? <p>Click Here to Confirm Completion </p> : <p>{props.message}</p> }
                                        <div className="hstack gap-2">
                                            <button 
                                                onClick={manageProcedureStatus}
                                                disabled={props.isApproved}
                                                value={props.keyName}
                                                className="btn btn-add btn-sm w-100">{props.status ? props.status : 'Completed'}</button>
                                        </div> 
                                    </div>
                                </>: 
                                <>
                                    {(props.keyName === "getting_inside") ? 
                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                            {
                                                props.instructions && <div className='mb-2'> {props.instructions} </div>
                                            }
                                            
                                            {(props.instructionPhoto !== undefined && props.instructionPhoto.length > 0) &&
                                                <div className='row pb-1'>
                                                    {props.instructionPhoto.map((img, i) => (
                                                        (img && img.filename) && (
                                                            <div className='col-4 pe-0 pb-1' style={imgContainer} key={i}>
                                                                <div className="message-img-link text-end" style={{marginBottom: '-27px', marginTop: '0px'}}>
                                                                    <ul className="list-inline mb-0">
                                                                        <li className="list-inline-item dropdown">
                                                                            <span className="dropdown-item" onClick={() => openLightboxOnSlide(i + 1)}>
                                                                                <span className="ri-zoom-in-line mx-1 text-muted align-bottom color-white font-18"></span>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <img src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} alt={img.filename} style={imgStyle} onClick={() => openLightboxOnSlide(i+1)} />
                                                            </div>
                                                        )))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    :
                                        <p className="ms-2 ps-5 pt-0 pb-2 mb-0">{data.propertyDetail.fullAddress}</p>
                                    }
                                </>
                            }
                        
                    </div>
                    :
                    <></>
                }
                

            </div>
        )
    }

    function toggleChecklistModal() {
        setIsChecklistModalOpen(!isChecklistModalOpen);
    }

    return (
        <div className="page-content">
            <div className='container-fluid'>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Assignment Detail</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/cleaner/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/cleaner/assignment/list">Assignments</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Assignment Detail</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                {data.cleaners.map((item, i) => (
                    <React.Fragment key={i}>
                        {(cleanerId === item.value) ?
                        (item.status === 0  && item.is_bargaining !== 1) ?
                            <>
                            {/* Render accept, reject and barganing buttons */}
                            </>
                            :
                            (item.status === 2) ? 
                                <AssignmentAlertMessage keyIndex={i} message="You declined this invitation" />
                            :
                            (data.cleanerId === cleanerId) ?
                                <AssignmentAlertMessage 
                                    keyIndex={i}
                                    message={
                                            (data.status === 2) ? 'Congratulations, this assignment has been successfully assigned to you.' : 
                                            (data.status === 3) ? 'This assignment is running.' :
                                            (data.status === 5) ? 'This assignment has been cancelled.' : 
                                            (data.status === 4) ? 'Congratulations, assignment has been completed sucessfully!' : 'Assignment has been successfully assigned to you'} /> :
                                <AssignmentAlertMessage keyIndex={i} message="You accepted this inviation, Waiting for host approval" />
                        :
                        <></>}
                    </React.Fragment>
                ))}
                <div className="row">
                    <div className="col-xl-8 col-lg-8">
                        <div className="card product">
                            <div className="card-body">
                                <div className="row gy-3">
                                    <div className="col-sm-auto col-md-5">

                                        <Swiper
                                            style={{
                                                '--swiper-navigation-size': '25px'
                                            }}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation={true}
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                            // onSwiper={(swiper) => console.log(swiper)}
                                            // onSlideChange={() => console.log('slide change')}
                                            
                                        >
                                            {(data.propertyDetail.images && data.propertyDetail.images.length > 0) ? 
                                            data.propertyDetail.images.map((img, i) => (
                                            
                                            <SwiperSlide key={img.filename}>
                                                <img  src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} key={i} alt={img.filename} onClick={() => openPropertyImgLightbox(i + 1)} />
                                            </SwiperSlide>

                                            ))
                                            : <></>}
                                        </Swiper>

                                        {(data.propertyDetail.images && data.propertyDetail.images.length > 0) &&
                                            <>
                                                <FsLightbox
                                                    toggler={propertyImgController.toggler}
                                                    slide={propertyImgController.slide}
                                                    sources={propertyImagesArr}
                                                />
                                            </>
                                        }

                                        <div className='pt-2'>
                                            <b>Address:</b> <Link target="_blank" to={`http://maps.google.com/maps?q=${data.propertyDetail.lat},${data.propertyDetail.lng}&ll=${data.propertyDetail.lat},${data.propertyDetail.lng}&z=17`}>{data.propertyDetail.fullAddress}</Link>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <h5 className="fs-14 text-truncate">
                                            <a href="#javascript" className="text-dark">{data.propertyDetail.propertyName}</a>
                                        </h5>
                                        <ul className="list-inline text-muted assigment-detail-list">
                                            <li>Name : <span className="fw-medium fw-semibold">{data.title}</span></li>
                                            <li>Type :  <span className="fw-medium fw-semibold">
                                                    {
                                                        (data.propertyDetail.subType > 0) ?
                                                            subTypes.map((item, i) => (
                                                                (data.propertyDetail.subType === item.id) && <span className="fw-medium fw-semibold" key={i}>{item.name}</span>
                                                            ))
                                                        :<></>
                                                    }
                                                </span>
                                            </li>
                                            <li>Size : <span className="fw-medium number">{data.propertyDetail.size} {(data.propertyDetail.sizeType === "m") ? "m²" : "ft²"}</span></li>
                                            <li>Date : <span className="fw-medium number">{ (data.date) ? moment(data.date, "M/D/YYYY").format('MMM Do YYYY') : "" }</span> </li>
                                            <li>Time : <span className="fw-medium number">Between {moment(data.startTime).format('hh:mm A')} - {moment(data.endTime).format('hh:mm A')}</span></li>
                                            <li>Price : <span className="fw-medium number">${data.price}</span></li>
                                            { (typeof data.checklist !== "undefined") ?
                                                <li key={0}>Checklist :
                                                    <ul className="checklist-list">
                                                        {data.checklist.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                            {(i < 3) &&
                                                            <div key={i} className="fw-medium">- {item.value}{data.checklist.length > i+1 ? ', ' : ''}</div>
                                                            }
                                                            </React.Fragment>
                                                        ))}

                                                        {
                                                            (data.checklist.length > 3) &&
                                                            <Link onClick={toggleChecklistModal} className="edit-icon fw-bold"> View all items</Link>
                                                        }
                                                    </ul>
                                                </li>
                                            : <></>
                                            }
                                          
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="card-footer">

                            <div className="row align-items-start gy-3">
                                        <div className="text-muted col-7 border-end">
                                            <h6 className="mb-1 fw-semibold text-uppercase">Specific Requests</h6>
                                            <p>{data.specificRequest ?? ""}</p>
                                        </div>
                                        {/* Profile Image */}
                                        <div className="col-5">
                                            <div className="card-body mb-0 p-2">
                                                <div className="d-flex mb-2 align-items-start">
                                                    <div className="flex-shrink-0">
                                                        {data.hostProfile.image && Object.keys(data.hostProfile.image).length >0 ?
                                                            <img src={process.env.REACT_APP_BACKENDURL+"images/"+data.hostProfile.image.filename} alt="hostProfileImage" className="avatar-sm rounded-circle" />
                                                            :
                                                            <img src="/assets/images/image-placeholder.png" alt="hostPlaceholderImage" className="avatar-sm rounded-circle" />
                                                        }
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h5 className=" mb-1 fs-14">{data.hostDetail.first_name} {data.hostDetail.last_name}</h5>
                                                        {/* <p className="text-muted d-flex align-items-center  gap-2 number mb-1">
                                                            <i className="ri-map-pin-fill"></i> 5 Miles Away
                                                        </p> */}
            
                                                        {(data.hostReviews && data.hostReviews?.totalStars > 0) ? 
                                                            <>
                                                                <div className="hstack gap-1 flex-wrap rating-icon">
                                                                    {[...Array(5)].map((star, index) => {
                                                                        index += 1;
                                                                        return (
                                                                            <i key={index} className={ 'bx fs-16 ' + (index <= (data.hostReviews?.totalStars) ? "bxs-star" : "bx-star")} ></i>  
                                                                        );
                                                                    })}
                                                                </div>
                                                                <div className='pb-1 text-muted' style={{ fontSize: '12px' }}>Rating</div>
                                                            </>
                                                            : 
                                                            <div className='py-1 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                        }

                                                        <div className=" hstack gap-2 mt-3">
                                                        { (data.hostDetail.firebase_uid !== "") ?
                                                            <> 
                                                                <Link to={'/cleaner/host-detail/'+data.hostDetail._id} className="btn btn-add btn-sm w-100">View Profile</Link>
                                                                <Link to={'/cleaner/messages/' + data.hostDetail.firebase_uid } className="btn btn-add btn-sm w-100"> Message</Link>
                                                            </>
                                                            :
                                                            <>
                                                                <button className="btn btn-add btn-sm w-100" disabled>View Profile</button>
                                                                <button className="btn btn-add btn-sm w-100" disabled> Message</button>
                                                            </>
                                                        }
                                                        </div>
            
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4">
                        {/* Procedure Logic */}
                        {
                            (isButtonsNeed === true) ? 
                            <div className="card-body mb-0 p-2 text-end mb-2">
                                <button 
                                    type="button"
                                    onClick={manageStatus}
                                    value={1}
                                    className="btn btn-add w-100 mb-2 me-2">ACCEPT</button>
                                <button 
                                    type="button"
                                    onClick={manageStatus}
                                    value={2}
                                    className="btn btn-add w-100 mb-2 me-2">DECLINE</button>
                                <button 
                                    type="button"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#myModal"
                                    className="btn btn-add w-100 mb-2 me-2">NEGOTIATION</button>
                            </div>
                            :
                            <></>
                        }
                        {
                        (data.cleanerId === cleanerId && ([5,6]).indexOf(data.status) === -1 ) ?
                            <div className="profile-timeline">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    {/* Images */}

                                    { (data.status === 4) ?
                                            <div className="accordion-item border-0">
                                                <AccordionHeader 
                                                    keyName={"review"} 
                                                    name={(Object.keys(data.review).length > 0) ? "Reviews" : "Add Your Review"}
                                                    isApproved={(Object.keys(data.review).length > 0) ? 1 : 0 }
                                                    />

                                                <div id="review" className="accordion-collapse collapse show" >
                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                            { (Object.keys(data.review).length < 1) ?
                                                                <>
                                                                    {(addReview === true) &&
                                                                    <>
                                                                        <div className="hstack gap-1 flex-wrap rating-icon mb-3">
                                                                            
                                                                            {[...Array(5)].map((star, index) => {
                                                                                index += 1;
                                                                                return (
                                                                                    <i 
                                                                                        key={index}
                                                                                        className={ 'bx fs-16 ' + (index <= (hover || rating) ? "bxs-star" : "bx-star")}
                                                                                        onClick={() => setRating(index)}
                                                                                        onMouseEnter={() => setHover(index)}
                                                                                        onMouseLeave={() => setHover(rating)}
                                                                                    ></i>  
                                                                                );
                                                                            })}

                                                                        </div>
                                                                        <textarea className="form-control mb-1" rows="3" placeholder="Comments"  onChange={(e) => setComment(e.target.value)} maxLength={250}></textarea>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className="float-end me-1 word-limit-count">{comment.length}/250 Characters</div>
                                                                            </div>
                                                                        </div>
                                                                        {reviewError && <div className="text-danger">{reviewError}</div>}
                                                                        <div className="hstack gap-2 mt-3">
                                                                            <button 
                                                                                className="btn btn-add btn-sm w-100"
                                                                                onClick={(e) => SubmitRating(e) }
                                                                            >Add a Review</button>
                                                                        </div>
                                                                    </>
                                                                    }
                                                                    {(addReview === false) &&
                                                                        <div className="hstack gap-1 flex-wrap rating-icon mb-3">
                                                                            You can't add review for an older assigment.
                                                                        </div>
                                                                    }
                                                                </>

                                                            :
                                                                <>
                                                                <h6 className='fs-14 mb-0 fw-semibold'>Your Feedback To Host</h6>         
                                                                    <div className="hstack gap-1 flex-wrap rating-icon mb-1"> 
                                                                        {[...Array(5)].map((star, index) => {
                                                                            index += 1;
                                                                            return (
                                                                                <i key={index} className={ 'bx fs-16 ' + (index <= (hover || rating) ? "bxs-star" : "bx-star")} ></i>  
                                                                            );
                                                                        })}
                                                                    </div>
                                                                    <div className='ps-1 mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                                    <p>{data.review.comment}</p>
                                                                    {(data.hostReview.stars > 0) &&
                                                                        <>
                                                                            <h6 className='fs-14 mb-0 fw-semibold'>Host's Feedback To You</h6>
                                                                            <div className="hstack gap-1 flex-wrap rating-icon mb-1"> 
                                                                                {[...Array(5)].map((star, index) => {
                                                                                    index += 1;
                                                                                    return (
                                                                                        <i key={index} className={ 'bx fs-16 ' + (index <= data.hostReview.stars ? "bxs-star" : "bx-star")} ></i>  
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                            <div className='ps-1 mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                                            <p>{data.hostReview.comment}</p>
                                                                        </>
                                                                    }
                                                                </>
                                                             }
                                                        </div>
                                                   

                                                </div>
                                            </div>
                                        : <></>
                                        }
                                    {(data.status === 4) ? 
                                        <ProcedureList 
                                            name={'Assignment Completed'} 
                                            needCheck={data.procedure.gettingInside.status}
                                            keyName={'confirmation'}
                                            message={(data.status === 4) ? 'Congratulations your assignment has been Completed' : 'Your assignment is in process'}
                                            status={(data.status === 4) ? 'Completed' : 'Running'}
                                            timestamp={(data.status === 4) ? data.updatedAt : ''}
                                            isApproved={data.status === 4 ? 1 : 0 }/>
                                    : <></>
                                    }
                                    {/* Show Waiting approval message untill host not approve the assignment */}
                                    {
                                        data.procedure.checkListSubmitted === 1 && imagesComplete === 1 && data.status === 3  ?   
                                        <div className="accordion-item border-0">
                                            <AccordionHeader 
                                                keyName={'waiting'}
                                                name={"Waiting For approval"} 
                                                isApproved={0}
                                                />
                                                <div id="waiting" className="accordion-collapse collapse show"
                                                    aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body ms-2 ps-5 pt-0 assigment-siderbar-image">
                                                        <p>Please wait for the next 30 minutes for the host to approve the payout. If the approval is not granted within this timeframe, the assignment will be automatically approved.</p>
                                                    </div>
                                                </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {data.procedure.checkListSubmitted === 1   ?    
                                        <div className="accordion-item border-0">
                                            <AccordionHeader 
                                                keyName={"images"} 
                                                name={"Photo Confirmation"} 
                                                // isApproved={(data.procedure.imagesSubmitted && isImageDecline === false) ? 1 : 0}
                                                isApproved={imagesComplete }
                                                />
                                            <div id="images" className="accordion-collapse collapse show"
                                                aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div className="accordion-body ms-2 ps-5 pt-0 assigment-siderbar-image">
                                                <form 
                                                    onSubmit={submitCheckListImages} 
                                                    id="submit-identity-proof-data" 
                                                    method="post" 
                                                    encType="multipart/form-data">
                                                        {
                                                            data.procedure.imagesSubmitted === 0 ?
                                                                <p>Take 5 Photos and submit them for your client to review and approve</p>
                                                            : 
                                                            isImageDecline === true && data.procedure.moreImagesRequired === 1 ?
                                                                <p>Some of your images has been decline by host, Please upload new images</p>
                                                            :
                                                            imagesComplete === 1 ?
                                                            <p>All of your images has been accepted by host!</p> :
                                                            <p>Your images has been uploaded successfully, please wait for host approval</p>
                                                        }
                                                    {((isImageDecline === true && data.procedure.moreImagesRequired) || data.procedure.imagesSubmitted === 0) ? 
                                                        <>
                                                        <div className="dropzone d-flex align-items-center">
                                                            <div className="fallback">
                                                                <input 
                                                                    name="images" 
                                                                    type="file" 
                                                                    multiple={true}
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={(e) => handleFileChange(e,'0')}
                                                                    />
                                                            </div>
                                                            <div className="dz-message needsclick text-center">
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                                                                </div>
            
                                                                <h4 className="fs-10">Click to upload</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row border border-dashed rounded gx-2 mt-2">
                                                        {(procedureImages.length > 0 ) ? 
                                                            procedureImages.map((img, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div className="col-6 mb-2">
                                                                        <div className='icon-container' style={{'width': '100', 'height': '100'}}>
                                                                            <span style={thumbInner}
                                                                                className="image-wrap"
                                                                                onClick={(e) => handleDeleteImage(index)}
                                                                            >
                                                                                <img src={img} alt="" className="img-fluid rounded" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            ))
                                                            : <></>
                                                        }
                                                        </div>
                                                        <div className="hstack gap-2">
                                                            <button 
                                                                type='submit'
                                                                disabled={(data.procedure.imagesSubmitted === 1 && isImageDecline === false) ? true : false}
                                                                value={'checkList'}
                                                                className="mt-2 btn btn-add btn-sm w-100">Submit Images</button>
                                                        </div></> : <></>
                                                    }

                                                    <div className="row border border-dashed rounded gx-2 mt-2">
                                                            {/* <p>Uploaded Images</p> */}
                                                            {data.procedure.images.map((img, index) => 
                                                                {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div className="col-6 mb-2">
                                                                                <div className={img.status === '2' || img.status === '3' ? 'icon-container decline-img' : 'icon-container'} 
                                                                                    style={{'width': '100', 'height': '100'}}>
                                                                                    <div className="message-img-link text-end" style={{marginBottom: '-27px', marginTop: '0px'}}>
                                                                                        <ul className="list-inline mb-0">
                                                                                            <li className="list-inline-item dropdown">
                                                                                                <span className="dropdown-item" onClick={() => openLightboxOnSlide2(index + 1)}>
                                                                                                    <span className="ri-zoom-in-line mx-1 text-muted align-bottom color-white font-18"></span>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <img src={process.env.REACT_APP_BACKENDURL+'assignments/'+img.filename} alt="" className="img-fluid rounded" onClick={() => openLightboxOnSlide2(index + 1)} />
                                                                                </div>
                                                                                <div className=" hstack gap-1 mt-2 mb-2">
                                                                                    {img.status === '1' ? 
                                                                                        <button type='button' 
                                                                                            className="btn btn-add btn-sm w-100" 
                                                                                            onClick={(e) => e.preventDefault()}>Approved</button>
                                                                                        :
                                                                                        (img.status === '2' || img.status === '3') ?
                                                                                            <>

                                                                                                {(img.message.length > 42) ?
                                                                                                    <p>
                                                                                                        { img.message.substring(0, 42)} 
                                                                                                    
                                                                                                        <span className="text-primary cursor-pointer" onClick={(e) => viewMessage(img.message) } > ...view more</span>
                                                                                                    </p>
                                                                                                : 
                                                                                                    <p>{img.message}</p>
                                                                                                }
                                                                                                {/* <button type='button' 
                                                                                                    className="btn btn-outline-list btn-sm w-100" 
                                                                                                    value={img.message}
                                                                                                    onClick={viewMessage}>Message</button> */}
                                                                                            </>
                                                                                        :
                                                                                        <button type='button' 
                                                                                            className="btn btn-orange btn-sm w-100" 
                                                                                            onClick={(e) => e.preventDefault()}>Pending by host</button>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                )
                                                            }

                                                            {(procedureImagesArray.length > 0) ?
                                                            <>
                                                                <FsLightbox
                                                                    toggler={lightboxController2.toggler}
                                                                    sources={procedureImagesArray}
                                                                    slide={lightboxController2.slide}
                                                                />
                                                                </>
                                                                : <></>
                                                            }
                                                        </div>
                                                        
                                                </form>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                    {/* CheckList */}
                                    {data.procedure.arrivalConfirm.status === 1  ? 
                                        <div className="accordion-item border-0">
                                            <AccordionHeader keyName={"checkList"} name={"Project Checklist"} isApproved={data.procedure.checkListSubmitted}/>
                                            <div id="checkList" className="accordion-collapse collapse show" aria-labelledby="checkList-heading" data-bs-parent="#accordionExample">
                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                    <div className="list-group">
                                                        {checkedList.map((it, index) => (
                                                            <label className="" key={index}>                                                            
                                                                <Checkbox
                                                                    key={it.value}       
                                                                    readOnly={data.procedure.checkListSubmitted}                                                         
                                                                    isChecked={(it.status === 1) ? true : false}
                                                                    checkHandler={() => updateCheckStatus(index)}
                                                                    index={index}
                                                                    />{it.label}
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {data.procedure.checkListSubmitted === 0 ?
                                                        <div className="hstack gap-2">
                                                            <button 
                                                                onClick={manageProcedureStatus}
                                                                disabled={allChecked}
                                                                value={'checkList'}
                                                                className="btn btn-add btn-sm w-100">Completed</button>
                                                        </div> 
                                                        : <></>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {
                                        data.procedure.gettingInside.status === 1 ?
                                            <ProcedureList 
                                                name={'Arrival Confirm'} 
                                                needCheck={data.procedure.gettingInside.status}
                                                keyName={'arrival_confirm'}
                                                timestamp={(data.procedure.arrivalConfirm.status === 1) ? data.procedure.arrivalConfirm.timestamp : ''}
                                                isApproved={data.procedure.arrivalConfirm.status }/>
                                        : <></>
                                    }
                                    {
                                        data.procedure.gettingThere.status === 1 ?
                                            <ProcedureList 
                                                name={'Getting Inside'} 
                                                needCheck={data.procedure.gettingThere.status}
                                                keyName={'getting_inside'}
                                                timestamp={(data.procedure.gettingInside.status === 1) ? data.procedure.gettingInside.timestamp : ''}
                                                isApproved={data.procedure.gettingInside.status }
                                                instructions={data.propertyDetail.accessibility} 
                                                instructionPhoto={data.propertyDetail.accessibilityImages} 
                                            />
                                        :<></>
                                    }
                                     <ProcedureList 
                                        name={'Getting There'} 
                                        needCheck={1}
                                        keyName={'getting_there'}
                                        timestamp={(data.procedure.gettingThere.status === 1) ? data.procedure.gettingThere.timestamp : ''}
                                        isApproved={data.procedure.gettingThere.status}/>
                                </div>
                            </div> : <></>
                        }
                        {/* Procedure Logic */}
                        
                    </div>

                </div>

                {/* <div className="d-flex justify-content-center align-items-center gap-3">
                    {data.cleaners.map((item, i) => (
                        (cleanerId === item.value) ?
                        (item.status === 0  && item.is_bargaining !== 1) ?
                            <>
                                <button 
                                    type="button"
                                    onClick={manageStatus}
                                    value={1}
                                    className="btn btn-add">ACCEPT</button>
                                <button 
                                    type="button"
                                    onClick={manageStatus}
                                    value={2}
                                    className="btn btn-add">DECLINE</button>
                                <button 
                                    type="button"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#myModal"
                                    className="btn btn-add">BARGANING</button>
                            </>
                            :
                            (item.status === 2) ? 
                                setAssignmentMessage("You declined this invitation")
                            :
                            (data.cleanerId === cleanerId) ?
                                setAssignmentMessage("Congratulations, this assignment has been successfully assigned to you.") :
                                setAssignmentMessage("You accepted this inviation, Waiting for host approval")
                        :
                        <></>
                    ))}
                    
                </div> */}

                <div 
                    id="myModal" 
                    className="modal fade" 
                    tabIndex="-1"
                    aria-labelledby="myModalLabel" 
                    aria-hidden="true"
                    style={{'display': 'none'}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="myModalLabel">Proposed Payout </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div className="modal-body">
                                <div className="new-payout d-flex align-items-center justify-content-left gap-3 pt-3">
                                    <h3>Current Payout:</h3>
                                    <input 
                                        style={readonlyInputStyle}
                                        type='text'
                                        readOnly
                                        value={parseFloat(data.price).toFixed(2)}
                                    />
                                </div>
                                <div
                                    className="new-payout d-flex align-items-center justify-content-left gap-3 pt-3">
                                    <h3>Payout:</h3>
                                    <input 
                                        style={inputStyle}
                                        type='text' 
                                        maxLength={5}
                                        onChange={(e) => setBarganingPrice(parseInt(e.target.value.replace(/\D/g, '') ? e.target.value.replace(/\D/g, '') : 0))}
                                        value={(typeof barganingPrice !== "undefined") ? barganingPrice : 0 }></input>
                                </div>
                                <div className="new-payout d-flex align-items-center justify-content-left gap-3 pt-3">
                                    <h3>Reason:</h3>
                                    <textarea 
                                        style={inputStyle}
                                        type='text' 
                                        maxLength={200}
                                        onChange={(e) => setReason(e.target.value)}
                                        placeholder='Please enter your reason for negotiation'
                                        value={(typeof reason !== "undefined") ? reason : "" }></textarea>
                                </div>
                                <div className="float-end me-1 word-limit-count">{reason.length}/200 Characters</div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button 
                                    type="button"
                                    onClick={manageBarganing}
                                    className="btn btn-add added-btn  waves-effect waves-light">Submit</button>
                                <button 
                                    type="button"
                                    ref={closeModalButton}
                                    data-bs-dismiss="modal" 
                                    aria-label="Close"
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div>
                    {(instructionImages.length > 0) &&
                        <FsLightbox
                        toggler={lightboxController.toggler}
                        sources={instructionImages}
                        slide={lightboxController.slide}
                        />
                    }
                </div>

                <Modal isOpen={isChecklistModalOpen}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Checklist</h5>
                                <button type="button" className="btn-close" onClick={toggleChecklistModal}></button>
                            </div>
                            <div className="modal-body">
                                {data.checklist.map((item, i) => (
                                    <React.Fragment key={i}>
                                        <div key={i} className="fw-medium">- {item.value}{data.checklist.length > i+1 ? ', ' : ''}</div>
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className="modal-footer justify-content-center gap-3 mt-2">
                                <button 
                                    type="button"
                                    onClick={toggleChecklistModal}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                            </div>

                        </div>
                    </div>
			    </Modal>
           </div>
        </div>
    )
}

export default AssignmentDetail;
