import React, { useEffect } from 'react'
import { useWizard } from 'react-use-wizard';
import ProgressBar from '../../../../components/common/ProgressBar'
import { useDropzone } from 'react-dropzone'
import helper from '../../../../helper';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const thumbInner2 = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width: '100%'
};

const imgStyle = {
  display: 'block',
  width: '100%',
  height: '100%'
};


const ImageUpload = (props) => {
  const { previousStep, nextStep } = useWizard();

  useEffect(() => {
    validateImageCount(props.files.length + props.uploadedFiles.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files, props.uploadedFiles])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpeg', '.jpg'],
    },
    maxFiles: 15,
    onDrop: (acceptedFiles, fileRejections) => {
      // Manage rejected files
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            helper.Toast().fire({ icon: 'error', title: "File is too large to upload" })
          }
          if (err.code === "file-invalid-type") {
            helper.Toast().fire({ icon: 'error', title: "Only .png and .jpg files are allowed" })
          }
          if(err.code === "too-many-files") {
            helper.Toast().fire({ icon: 'error', title: "You can upload between 1 to 15 images" })
          }
        });
      });
      // rejected files logic ends

      if ((props.files.length + props.uploadedFiles.length + acceptedFiles.length) > 15) {
        return helper.Toast().fire({ icon: 'error', title: "You can upload between 1 to 15 images" })
      }

      // props.setSelectedFiles(acceptedFiles);
      var newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));

      props.setFiles(prevState => [...prevState, ...newFiles]);
      props.setSelectedFiles(prevState => [...prevState, ...acceptedFiles]);

      // validateImageCount(props.files.length + props.uploadedFiles.length) 
    }
  });

  const validateImageCount = (count) => {
    count >= 1 ? props.setStep4Validation(true) : props.setStep4Validation(false);
    return;
  }



  return (
    <>
      <ProgressBar stepNames={props.stepNames} />
      <div className="card">
        <div className="card-body">
          <div className="live-preview">
            <section className="row px-3">
              <h3>Add some photos of your house</h3>
              <p className="text-muted">You’ll need 5 photos to get started.You can add more and make changes later</p>

              <div {...getRootProps({ className: 'dropzone' })}>
                <div className='file-uplod-text'>
                  <input {...getInputProps()} />
                  <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                  <h4>Drag 'n' drop some files here, or click to select files</h4>
                  {/* <p className='note'>(**Upload Image between 1-15**)</p> */}
                </div>
              </div>
              <aside style={thumbsContainer}>
                {props.files.length > 0 &&
                  props.files.map((image, index) => (
                    <div style={thumb} key={image.name + index}>
                      <div style={thumbInner} >
                        <span style={thumbInner2}
                          className="image-wrap"
                          onClick={(e) => props.setFiles(crt => {
                            return crt.filter((_el, ind) => (ind !== index))
                          })}
                        >
                          <img src={`${URL.createObjectURL(image)}`} style={imgStyle} key={index} alt={image.name} />
                        </span>
                      </div>
                    </div>
                  ))}
                {/* Render already uploaded images */}
                {(props.pageName === "edit property" && props.uploadedFiles && props.uploadedFiles.length > 0) ?
                  props.uploadedFiles.map((img, i) => (
                    img && img.filename ? (
                      <div style={thumb} key={img.filename}>
                        <div
                          style={thumbInner2}
                          className="image-wrap"
                          onClick={(e) => props.setUploadedFiles(crt => {
                            return crt.filter((_el, index) => (index !== i))
                          })}
                        >
                          {/* <span className="close-btn">&times;</span> */}
                          <img src={process.env.REACT_APP_BACKENDURL + 'images/' + img.filename} style={imgStyle} key={i} alt={img.filename} />
                        </div>
                      </div>
                    ) : null
                  ))
                  : <></>}
              </aside>

              <div className="d-flex align-items-start gap-3 mt-4">
                <button type="button" className="btn btn-outline-list btn-label" onClick={() => previousStep()} ><i
                  className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                  Previous</button>
                <button type="button" className="btn btn-add btn-label right ms-auto" disabled={!props.step4Validation ? true : false}
                  onClick={() => nextStep()}><i
                    className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next</button>
              </div>

            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageUpload;