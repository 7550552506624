import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import helper from '../../../helper/index.js';
import Cookies from 'js-cookie';

const Password = (props) => {
    const token = Cookies.get('token');
    const [authData, setAuthData] = useState({
        password: '',
        confirm_password: ''
    });
    const [errorPassword, setErrorPassword] = useState("");
    const [errorCPassword, setErrorCPassword] = useState("");
    const [type, setType] = useState('password');
    const [ctype, setCType] = useState('password');

    const manageAuthState = (e) => {
        let keyValue = e.target.name;
        let value = e.target.value;
        authData[keyValue] = value
        setAuthData({ ...authData })
    }

    const submitFormData = async (event) => {
        event.preventDefault();
        setErrorPassword("");
        setErrorCPassword("");
        let error = 0;
        if (authData.password.trim().length === 0) {
            setErrorPassword('Password field is required.');
            error = 1;
        }
        if (authData.confirm_password.trim().length === 0) {
            setErrorCPassword('Confirm password field is required.');
            error = 1;
        } else if(authData.password !== authData.confirm_password) {
            setErrorCPassword('Confirm password should be same as password.');
            error = 1;
        }

        if (error === 1) {
            return;
        }
        
        const data = {
            password: authData.password,
            confirm_password: authData.confirm_password
        };
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL + 'api/host/update-user-password', data, {
            headers: {
                'token': token,
            }
        })
            .then(function (response) {
                props.setLoading(false)
                if (response.data.status === 1) {
                    helper.Toast().fire({
                        icon: 'success',
                        title: response.data.message
                    })
                } else {
                    helper.Toast().fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(function (error) {
                props.setLoading(false)
                console.log(error);
            });
    };

    const handlePassword = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }
  
    const handleCPassword = () => {
        if (ctype === 'password') {
          setCType('text')
        } else {
          setCType('password')
        }
    }

    return (
        <div className="page-content host-profile-page">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Update Password</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/host/dashboard">Overview</Link></li>
                                    <li className="breadcrumb-item"><Link to="/host/settings">Settings</Link></li>
                                    <li className="breadcrumb-item active">Update Password</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header mb-2">
                                <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 profile-list"
                                    role="tablist">
                                    <li className="nav-item">
                                        <a className='nav-link active' data-bs-toggle="tab" href="#pills-basic-info" role="tab">
                                            <i className="fas fa-home"></i> Update Password
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="card-body">
                                <div className="tab-content">
                                    <div className='tab-pane active'  id="pills-basic-info" role="tabpanel">

                                        <form onSubmit={submitFormData} method="post" encType="multipart/form-data">
                                            <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <div className='row'>
                                                        <div className="mb-3">
                                                            <label htmlFor="password-addon" className="form-label">Password</label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <input type={type} name="password" className="form-control" value={authData.password} onChange={manageAuthState} maxLength={50} autoComplete="off" />
                                                                {errorPassword && <div className="text-danger"> {errorPassword} </div>}
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={handlePassword} ><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="mb-3">
                                                            <label htmlFor="cpassword-addon" className="form-label">Confirm Password</label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <input type={ctype} name="confirm_password" className="form-control" value={authData.confirm_password} onChange={manageAuthState} maxLength={50} autoComplete="off" />
                                                                {errorCPassword && <div className="text-danger"> {errorCPassword} </div>}
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted cpassword-addon" type="button" id="cpassword-addon" onClick={handleCPassword} ><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button type="submit" className="btn btn-add">Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Password;
