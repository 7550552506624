import React, { useState } from 'react'
import HostHome from './screens/host/home';
import Settings from './screens/host/settings';
import CleanerSettings from './screens/cleaner/settings';
import HostProfile from './screens/host/profile/index';
import HostPassword from './screens/host/profile/password';
import HostNotification from './screens/host/notification';
import Payment from './screens/host/payment/index';
import TransactionInvoice from './screens/host/payment/additional/TransactionInvoicePdf';
import CleanerPayment from './screens/cleaner/payment/index';
import CleanerHome from './screens/cleaner/home';
import CleanerInsight from './screens/cleaner/insight/index.js';
import Layout from './components/layout/admin';
import PDFLayout from './components/layout/pdf';
import Login from './screens/login';
import VerifyOtp from './screens/otp';
import Register from './screens/register';
import ForgotPassword from './screens/forgotPassword';
import Chat from './screens/host/chat';
import CleanerChat from './screens/cleaner/chat';
import AdminChat from './screens/admin/chat';

import Profile from './screens/cleaner/profile';
import CleanerPassword from './screens/cleaner/password';
import Notification from './screens/cleaner/notification';

import PropertyAdd from './screens/host/property/add';
import PropertyList from './screens/host/property/list';
import PropertyEdit from './screens/host/property/edit';
import PropertyDetail from './screens/host/property/detail';
import CleanerList from './screens/host/cleaner/list-latest'
import Partners from './screens/host/cleaner/partners-latest'
import CleanerDetail from './screens/host/cleaner/detail'
import HostDetail from './screens/cleaner/host/detail.js'
import Reviews from './screens/host/cleaner/reviews'
import HostReviews from './screens/cleaner/host/reviews'
import ProfileDetail from './screens/host/myProfile'

import Checklist from './screens/host/checklist/checklist'
import AddChecklist from './screens/host/checklist/add'

import CreateAssignment from './screens/host/assignment/add';
import AssignmentList from './screens/host/assignment/list';
import AssignmentEdit from './screens/host/assignment/edit';
import AssignmentDetail from './screens/host/assignment/detail';
import InvitedCleanersList from './screens/host/assignment/invitedCleaners';
import Calendar from './screens/host/assignment/calendar';
import HostAllNotification from './screens/host/allNotification';

import CleanerAssignmentList from './screens/cleaner/assignment/list';
import CleanerAssignmentDetail from './screens/cleaner/assignment/detail';
import CleanerCalendar from './screens/cleaner/assignment/calendar';
import CleanerAllNotification from './screens/cleaner/allNotification';

import CleanerEarning from './screens/cleaner/insight/earning.js';
import CleanerPerfomance from './screens/cleaner/insight/perfomance.js';
import CleanerProject from './screens/cleaner/insight/project.js';
import CleanerCustomerInsight from './screens/cleaner/insight/customers.js';
import CProfileDetail from './screens/cleaner/myProfile'

import HostInsight from './screens/host/insight/index.js';
import HostEarning from './screens/host/insight/earning.js';
import HostProject from './screens/host/insight/project.js';
import HostPropertyInsight from './screens/host/insight/property.js';
import HostCustomerInsight from './screens/host/insight/customers.js';
import FeedbackList from './screens/contact/list.js';
import WorkingHours from './screens/cleaner/workingHours';
// import Help from './screens/contact/help.js';
import TaxInfo from './screens/tax/index.js';
import AddTaxInfo from './screens/tax/add';
import EditTaxInfo from './screens/tax/edit';
import PrivacyPolicies from './screens/privacyPolicies.js';
import TermsAndConditions from './screens/termsAndConditions.js';

import { ProtectRoutes } from './hooks/protectRoutes'; 
import { Navigate, Route, Routes } from "react-router-dom";

function App() {
const [progress, setProgress] = useState(0);
let [loading, setLoading] = useState(false);
  return (
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify/otp" element={<VerifyOtp />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route element={<ProtectRoutes module="" name="" />}>
            <Route element={<Layout
                                loading={loading}
                                progress={progress} 
                                setProgress={setProgress} />}>
            </Route>
          </Route>

          {/* For host Routes */}
          <Route element={<ProtectRoutes module={2} name="" />}>
            {/* Host Pdf Routes Start */}
            <Route element={<PDFLayout
                                loading={loading}/>}>
              <Route path="/host/transaction/:tranasctionId" element={<TransactionInvoice setLoading={setLoading} />} />
            </Route>
            {/* Host Pdf Routes Finish */}

            <Route element={<Layout
                                loading={loading}
                                progress={progress} 
                                setProgress={setProgress} />}>
              
                <Route path="/host/dashboard" element={<HostHome setLoading={setLoading} />} />
                <Route path="/host/settings" element={<Settings setLoading={setLoading} />} />
                <Route path="/host/payments-and-transactions/:step?" element={<Payment setLoading={setLoading} />} />
                <Route path="/host/calendar" element={<Calendar setLoading={setLoading} />} />
                <Route path="/host/profile/:step?" element={<HostProfile setLoading={setLoading} />} />
                <Route path="/host/update-password" element={<HostPassword setLoading={setLoading} />} />
                <Route path="/host/notification" element={<HostNotification setLoading={setLoading} />} />
                {/* Partners (Cleaners) Routes */}
                <Route path="/find/cleaners" element={<CleanerList setLoading={setLoading} />} />
                {/* get-host-cleaners */}
                <Route path="/host/partner/list" element={<Partners setLoading={setLoading} />} />
                {/* Property Routes */}
                <Route path="/host/property/list" element={<PropertyList setLoading={setLoading} />} />
                <Route path="/host/property/add" element={<PropertyAdd setLoading={setLoading} />} />
                <Route path="/host/property-edit/:propertyId" element={<PropertyEdit setLoading={setLoading}/>} />
                <Route path="/host/property-detail/:propertyId" element={<PropertyDetail setLoading={setLoading}/>} />
                {/* Checklist Routes */}
                <Route path="/host/checklist" element={<Checklist setLoading={setLoading} />} />
                <Route path="/host/checklist/add" element={<AddChecklist setLoading={setLoading} />} />
                <Route path="/host/checklist/edit/:checkListId" element={<AddChecklist setLoading={setLoading} />} />
                {/* Assignment Routes */}
                <Route path="/host/assignment/add" element={<CreateAssignment setLoading={setLoading} />} />
                <Route path="/host/assignment/list/:step?" element={<AssignmentList setLoading={setLoading} />} />
                <Route path="/host/assignment/edit/:hostid/:assignmentId" element={<AssignmentEdit setLoading={setLoading} />} />
                <Route path="/host/assignment/detail/:assignmentId" element={<AssignmentDetail setLoading={setLoading} />} />
                <Route path="/host/invited/cleaners/:assignmentId" element={<InvitedCleanersList setLoading={setLoading} />} />
                <Route path="/host/cleaner-detail/:cleanerId" element={<CleanerDetail setLoading={setLoading} />} />
                <Route path="/host/cleaner-reviews/:cleanerId" element={<Reviews setLoading={setLoading} />} />
                <Route path="/host/messages/:id?" element={<Chat setLoading={setLoading} />} />
                <Route path="/host/all-notification" element={<HostAllNotification setLoading={setLoading} />} />
                <Route path="/host/my-profile" element={<ProfileDetail setLoading={setLoading} />} />
                <Route path="/host/my-reviews" element={<Reviews setLoading={setLoading} />} />

                <Route path="/host/insight" element={<HostInsight setLoading={setLoading} />} />
                <Route path="/host/insight/cleaner" element={<HostCustomerInsight setLoading={setLoading} />} />
                <Route path="/host/insight/earning" element={<HostEarning setLoading={setLoading} />} />
                <Route path="/host/insight/project" element={<HostProject setLoading={setLoading} />} />
                <Route path="/host/insight/property" element={<HostPropertyInsight setLoading={setLoading} />} />
                {/* Admin Chat route */}
                <Route path="/host/admin/messages/:id?" element={<AdminChat setLoading={setLoading} />} />
                <Route path="/host/feedback" element={<FeedbackList setLoading={setLoading} />} />
                {/* <Route path="/host/help" element={<Help setLoading={setLoading} />} /> */}

                <Route path="/host/taxes" element={<TaxInfo setLoading={setLoading} />} />
                <Route path="/host/edit-tax" element={<EditTaxInfo setLoading={setLoading} />} />
                <Route path="/host/add-taxes" element={<AddTaxInfo setLoading={setLoading} />} />
                <Route path="/host/privacy-policy" element={<PrivacyPolicies setLoading={setLoading} />} />
                <Route path="/host/terms-and-conditions" element={<TermsAndConditions setLoading={setLoading} />} />
            </Route>
          </Route>
          {/* ********************************************* */}




          {/* For cleaner Routes */}
          <Route element={<ProtectRoutes module={3} name="" />}>
            <Route element={<Layout
                                loading={loading}
                                progress={progress} 
                                setProgress={setProgress} />}>
              <Route path="/cleaner/dashboard" element={<CleanerHome setLoading={setLoading} />} />
              <Route path="/cleaner/insight" element={<CleanerInsight setLoading={setLoading} />} />
              <Route path="/cleaner/calendar" element={<CleanerCalendar setLoading={setLoading} />}  />
              <Route path="/cleaner/settings" element={<CleanerSettings setLoading={setLoading} />} />
              <Route path="/cleaner/payments-and-transactions/:step?" element={<CleanerPayment setLoading={setLoading} />} />
              <Route path="/cleaner/profile/:step?" element={<Profile setLoading={setLoading}/>} />
              <Route path="/cleaner/update-password" element={<CleanerPassword setLoading={setLoading} />} />
              <Route path="/cleaner/notification" element={<Notification setLoading={setLoading}/>} />
              <Route path="/cleaner/assignment/list/:step?" element={<CleanerAssignmentList setLoading={setLoading} />} />
              <Route path="/cleaner/assignment/detail/:assignmentId" element={<CleanerAssignmentDetail setLoading={setLoading} />} />
              <Route path="/cleaner/host-detail/:hostId" element={<HostDetail setLoading={setLoading} />} />
              <Route path="/cleaner/host-reviews/:hostId" element={<HostReviews setLoading={setLoading} />} />
              <Route path="/cleaner/messages/:id?" element={<CleanerChat setLoading={setLoading} />} />
              <Route path="/cleaner/all-notification" element={<CleanerAllNotification setLoading={setLoading} />} />
              <Route path="/cleaner/insight/earning" element={<CleanerEarning setLoading={setLoading} />} />
              <Route path="/cleaner/insight/perfomace" element={<CleanerPerfomance setLoading={setLoading} />} />
              <Route path="/cleaner/insight/customer" element={<CleanerCustomerInsight setLoading={setLoading} />} />
              <Route path="/cleaner/insight/project" element={<CleanerProject setLoading={setLoading} />} />
              <Route path="/cleaner/my-profile/" element={<CProfileDetail setLoading={setLoading} />} />
              <Route path="/cleaner/my-reviews" element={<HostReviews setLoading={setLoading} />} />
              <Route path="/cleaner/admin/messages/:id?" element={<AdminChat setLoading={setLoading} />} />
              <Route path="/cleaner/feedback" element={<FeedbackList setLoading={setLoading} />} />
              <Route path="/cleaner/working-hours" element={<WorkingHours setLoading={setLoading} />} />
              <Route path="/cleaner/taxes" element={<TaxInfo setLoading={setLoading} />} />
              <Route path="/cleaner/edit-tax" element={<EditTaxInfo setLoading={setLoading} />} />
              <Route path="/cleaner/add-taxes" element={<AddTaxInfo setLoading={setLoading} />} />
              {/* <Route path="/cleaner/help" element={<Help setLoading={setLoading} />} /> */}
              <Route path="/cleaner/privacy-policy" element={<PrivacyPolicies setLoading={setLoading} />} />
              <Route path="/cleaner/terms-and-conditions" element={<TermsAndConditions setLoading={setLoading} />} />
            </Route>
          </Route>
          {/* ********************************************* */}  
          
        </Routes>
  );
}

export default App;
