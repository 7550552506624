/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { HostContext } from '../../../Contexts/HostContext';
import helper from '../../../helper/index';
let limit = 10;

const ClenerList = (props) => {
    const [selectedCleaners, setSelectedCleaners] = useState([]);

    const handleSelect = (ev) => {
        let item = ev.target.value;
        if(selectedCleaners.indexOf(item) === -1 && !ev.target.className.includes('added-btn')) {
            selectedCleaners.push(item);
        }else if(ev.target.className.includes('added-btn')){
            selectedCleaners.splice(selectedCleaners[item], 1);
        }
        addPatners(ev)
        setSelectedCleaners(selectedCleaners);
    }

    const addPatners = (ev) => {
        props.setLoading(true)
        axios.post(process.env.REACT_APP_BACKENDURL+'api/host/add-cleaners',
            {cleaners_ids: selectedCleaners},
            {headers: {'Content-Type': 'application/json', 'token': token}}
        )
        .then(function (response) {
            props.setLoading(false)
            if(response.data.status === 1){
                // /TODO
                if(!ev.target.className.includes('added-btn')){
                    ev.target.className = "btn added-btn btn-add btn-label-2 waves-effect waves-light"
                    ev.target.innerHTML = `<i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i> Added`
                }else if(ev.target.className.includes('added-btn')){
                    ev.target.className = "btn btn-outline-list add-btn waves-effect waves-light"
                    ev.target.innerHTML = `Add`
                }
            }else{
                helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again." });            
            }
        })
        .catch(function (error) {
            props.setLoading(false)
            console.log(error);
        });
    }

    useEffect(() => {
        setSelectedCleaners(selectedCleaners)
    }, [selectedCleaners])
    const {token} = useContext(HostContext)
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [filterRecords, setFilterRecords] = useState({
        sortBy: "desc",
        search_string: "",
        rating: 0
    });

    useEffect(() => {
        getData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRecords]);

    const getData = (datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'api/host/find-cleaners',{
            params: {
                page: pageNo,
                limit: limit,
                sortBy: filterRecords.sortBy,
                search_string: filterRecords.search_string,
                rating: filterRecords.rating
            },
            headers: {'Content-Type': 'application/json', 'token': token}
        }
        )
        .then(function (response) {
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
                response.data.patnersList.patnersList.map((item) => {
                    selectedCleaners.push(item)
                })
                // setIsVisible(selectedCleaners.length > 0 ? true : false)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleFilters = ( keyValue, value) => {
        filterRecords[keyValue] = value
        setFilterRecords({ ...filterRecords })
    }

  return (
    <div className="page-content">
        <div className="container-fluid">

            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Add New Partners</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/host/dashboard">Overview</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/host/partner/list">Partners List</Link>
                                </li>
                                <li className="breadcrumb-item active">Add New Partners</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {/* Filters to find Partners */}
            <div className="accordion mb-4" id="default-accordion-example">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Filter cleaners by using search
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#default-accordion-example">
                        <div className="accordion-body">
                            <form>
                                <div className="row">
                                    <div className="col-6">
                                        <input type="text" className="form-control" name="search_string" placeholder="Search by name, email and phone number" onChange={(e) => handleFilters('search_string', e.target.value)} value={filterRecords.search_string}/>
                                    </div>
                                    <div className="col-3">
                                        <select 
                                            className="form-select"
                                            value={filterRecords.rating}
                                            onChange={(e) => handleFilters('rating', e.target.value)}
                                            >
                                            <option value={0}>Select cleaners by rating</option>
                                            <option value={1}>1 * and above</option>
                                            <option value={2}>2 * and above</option>
                                            <option value={3}>3 * and above</option>
                                            <option value={4}>4 * and above</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <select 
                                            className="form-select"
                                            value={filterRecords.sortBy}
                                            onChange={(e) => handleFilters('sortBy', e.target.value)}
                                            >
                                            <option value="">Sort By</option>
                                            <option value="desc">Z to A</option>
                                            <option value="asc">A to Z</option>
                                        </select>
                                    </div>
                                </div>
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
            
            {(data.length > 0) ?
                <div className="col-xl-12 col-md-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <div className="d-flex align-items-center gap-3 ">
                                <h4 className="card-title mb-0 flex-grow-1">Cleaning Partners</h4>
                            </div>
                            {/* <div className="flex-shrink-0">
                                <div className="dropdown card-header-dropdown">
                                    <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span className="fw-semibold text-capitalize fs-12 fillter-heading">Show:
                                        </span><span className="active-text">Show All<i
                                                className="mdi mdi-chevron-down ms-1"></i></span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#">Active</a>
                                        <a className="dropdown-item" href="#">Busy</a>
                                        <a className="dropdown-item" href="#">Diactive</a>
                                        <a className="dropdown-item" href="#">Show All</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="card-body">
                        {(data.length > 0) ?
                            <>
                            <div className="table-responsive table-card data-table">
                                <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                    <thead className="">
                                        <tr className="text-muted">
                                            <th scope="col" style={{ 'width': '30%'}}>Cleaner</th>
                                            <th scope="col">Rating</th>
                                            <th scope="col" style={{ 'width': '28%'}}>Description</th>
                                            <th scope="col" style={{ 'width': '12%'}}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {data.map((item, i) => { 
                                        return ( 
                                            <tr key={i}>
                                                <td>
                                                    <div className="d-flex gap-2 align-items-center">
                                                    <Link to={'/host/cleaner-detail/'+item._id} 
                                                        target="_blank"
                                                        title={item.first_name+" "+item.last_name}>
                                                        {
                                                            (item.image) ?
                                                                <img src={item.image} alt="profileImage" className="rounded-circle avatar-sm me-2" />
                                                            : 
                                                                <img src="/assets/images/image-placeholder.png" alt="profileImage" className="rounded-circle avatar-sm me-2" />
                                                        }
                                                        </Link>
                                                    <div >
                                                        <a href="#javascript: void(0);" className="text-body fw-medium">{item.first_name} {item.last_name}</a>
                                                        {
                                                            (typeof item.address.fullAddress !== "undefined") ?
                                                                <p className="mb-0 pt-1 number">{item.address.fullAddress}</p>
                                                            : <></>
                                                        }
                                                    </div>

                                                    </div>
                                                    
                                                </td>
                                                <td className="rating-icon">
                                                    {(item.stars > 0) ?
                                                        <>
                                                        <div className="hstack gap-1 flex-wrap">
                                                            {[...Array(5)].map((star, index) => {
                                                                index += 1;
                                                                return (
                                                                    <i 
                                                                        key={index}
                                                                        className={ 'bx fs-16 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")}
                                                                        
                                                                    ></i>  
                                                                );
                                                            })}                                                    
                                                        </div>
                                                        <div className='pb-1 text-muted' style={{ fontSize: '12px' }}>Rating</div>
                                                        </>
                                                        :
                                                        <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                    }
                                                </td>
                                                <td> {item.description}</td>
                                                <td>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-outline-list add-btn waves-effect waves-light"
                                                        onClick={handleSelect}
                                                        value={item._id}>Add</button>
                                                </td>
                                            </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            </>
                            :
                            <>
                            <h5>No cleaner found</h5>
                            </>
                            }
                        </div>
                    </div>

                    {(data.length > 0) ?
                    <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel="..."
                        pageCount={PageCount}
                        pageRangeDisplayed={3}
                        onPageChange={getData}
                        containerClassName={'pagination pagination-rounded mt-5 mb-5 justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        renderOnZeroPageCount={null}
                        activeClassName={'active'}
                    />

                    : <></> }
                </div>
            :
                <div className="col-xl-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            
                            <div className="default-item">
                                <img src="/assets/images/deafult-logo.png" />
                                <h6 className="default-dis py-2">No cleaner found.</h6>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
  )
}

export default ClenerList;
