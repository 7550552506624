import React,{ useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { socket } from '../../socket';

let limit = 10;
const AllNotification = (props) => {
    const token = Cookies.get('token');
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [notificationSetting, setNotificationSetting] = useState({
        sms: 0,
        email: 0,
        onApp: 0
    });
    const [userNotifications, setUserNotifications] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const defaultProfile =  '/assets/images/users/user-dummy-img.jpg';
    const filePath = process.env.REACT_APP_BACKENDURL+'images/';

    const fetchNotificationSettings = async() => {
        await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-notification-settings',
        {
            params: {
                "role_id": 3
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        }).then((response) => {
            if(response.data.status === 1) {
                setNotificationSetting(response.data.data.notificationSettings);
            }
        })
        .catch((error) => {
            console.error("Error: Something went wrong", error);
        });
    }

    const fetchNotifications = async(datas) => {
        let pageNo = (datas) ? datas.selected : 0;
        setCurrentPage(pageNo);
        props.setLoading(true);
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-all-notification',
                        {
                            params: {
                                page: pageNo,
                                limit: limit,
                                role_id: 3,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                'token': token
                            }
                        })
                        .catch(function (error) {
                            console.log('Something went wrong');
                        });
        props.setLoading(false);
        if(data.status === 1){
            setPageCount(data.total_pages);
            setUserNotifications(data.data);
        }
           
    }

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckedItems([...checkedItems, value]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== value));
        }
    };

    const markAsRead = async(e) => {
        e.preventDefault();
        if(checkedItems.length > 0) {
            await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-bulk-notifications-status', {
                notificationIds: checkedItems,
                role_id: 3
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }).then((response) => {
                if(response.data.status === 1) {
                    setCheckedItems([]);
                    const datas = {
                        selected: currentPage
                    } 
                    fetchNotifications(datas);
                    sendNotificationUpdateSignal();
                }
            })
            .catch((error) => {
                console.error("Error: Something went wrong", error);
            });
        }
    }

    const sendNotificationUpdateSignal = () => {
        // Connect Socket if not connected
        if(!isConnected){
            socket.connect();
        }
        socket.emit('send-notification-update-signal');
    }

    useEffect( () => {
        fetchNotificationSettings();

        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
    }, []);

    useEffect(() => {
        if(notificationSetting.onApp === 1) {
            fetchNotifications();
        }
    }, [notificationSetting]);

    return(
        <div className="page-content">
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="card-body">
                        <div className="row mt-4">
                            <div className="col-lg-6">
                                <div className="d-lg-flex align-items-center mb-4">
                                    <h2>All Notifications</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="float-end mb-4">
                                    <button type="button" className='btn btn-soft-success' onClick={(e) => markAsRead(e)}>
                                        <i className='ri-mail-check-line'></i> Mark as read
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                        {(
                        (userNotifications.length === 0) ? <div className="text-reset notification-item d-block dropdown-item position-relative p-3 bg-white">
                                <div className="text-center">
                                    No new notification
                                </div>
                            </div> 
                        :
                        <>
                        {
                        <div className="table-responsive table-card data-table">
                            <table className="table table-borderless table-nowrap align-middle mb-0 " style={{borderSpacing: '0 6px'}}>
                                <tbody>
                                    {userNotifications.map((value, i) => {
                                        const daysBefore = moment().diff(moment(value.createdAt), 'days');
                                        let timestamp = "";
                                        if(daysBefore > 7) {
                                            timestamp = moment(value.createdAt).format('DD-MM-YYYY');
                                        } else {
                                            timestamp = moment(value.createdAt).fromNow();
                                        }
                                        const profileImg = value.userProfile.image ? filePath+value.userProfile.image.filename : defaultProfile;
                                        let linkTo = "";
                                        if(value.type === "Assignment" || value.type === "Payment") {
                                            linkTo = `/cleaner/assignment/detail/${value.redirect_id}`;
                                        }
                                        return ( 
                                            <tr key={i} className={`${value.is_read === false ? 'unread-notification' : 'bg-white'}`}>
                                                <td>
                                                    <Link to={linkTo}>
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <img src={profileImg} alt="" className="rounded-circle avatar-sm me-2" />
                                                            <div>
                                                                <span className="text-body fw-medium">{value.userDetail.name}</span>
                                                                <small className="font-italic"> {timestamp}</small>
                                                                <p className="mb-0 pt-1 number">
                                                                    {value.message}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </td>
                                                <td className="note-icon">
                                                    <div className="hstack gap-3 flex-wrap">
                                                        { 
                                                            value.is_read === false &&
                                                            <input className="form-check-input" type="checkbox" id={`formCheck${i}`} value={value._id} checked={checkedItems.includes(value._id)} onChange={(event) => handleCheckboxChange(event)} />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    
                                    })}
                                </tbody>
                            </table>
                        </div>
                        }
                        
                        <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel="..."
                        pageCount={PageCount}
                        pageRangeDisplayed={3}
                        onPageChange={fetchNotifications}
                        containerClassName={'pagination pagination-rounded mt-5 mb-5 justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        renderOnZeroPageCount={null}
                        activeClassName={'active'}
                        />

                        </>
                        )}

                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    )
}

export default AllNotification;