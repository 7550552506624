import React, { useState, useMemo, useContext, useEffect } from 'react'
import ProgressBar from '../../../../components/common/ProgressBar'
import {  useWizard } from 'react-use-wizard';
import parse  from 'html-react-parser';
import { PropertyContext } from '../../../../Contexts/PropertyContext';
import helper from '../../../../helper/index.js'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import FsLightbox from "fslightbox-react";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const imgContainer = {
  // height: '120px'
  height: '90px',
  width: '150px',
}

const imgStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}

const Preview = (props) => {
  const [ mapCenter, setMapCenter ] = useState({ lat: 37.09024, lng: -95.712891 });
    const center = useMemo(() => ({ lat: parseFloat(props.addressArray.lat), lng: parseFloat(props.addressArray.lng) }), [props.addressArray.lat, props.addressArray.lng]);

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
    })

    const containerStyle = {
      width: '100%',
      // height: '60vh'
      height: '230px'
    };

    // eslint-disable-next-line no-unused-vars
    const [map, setMap] = React.useState(null)
    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1
    });
    const [instructionImagesArray, setInstructionImagesArray] = useState([]);

    function openLightboxOnSlide(number) {
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number
      });
    }

    const onLoad = React.useCallback(function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      var listener = new window.google.maps.event.addListener(map, "idle", function() { 
        if (map.getZoom() > 17) map.setZoom(17);
        new window.google.maps.event.removeListener(listener); 
      });
  
      setMap(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])

    useEffect(() => {
      setMapCenter(center);
    }, [center, map])

    useEffect(() => {
      let imgurl = [];
      let path = process.env.REACT_APP_BACKENDURL+'images/';
      if(props.uploadedInstructionImages.length > 0) {
        props.uploadedInstructionImages.map((img, i) => (
            imgurl.push(path+img.filename)
        ));
      }
      if(props.instructionImages.length > 0) {
        props.instructionImages.map((img, i) => (
            imgurl.push(img.preview)
        ));
      }
      setInstructionImagesArray(imgurl);
    }, [props.uploadedInstructionImages, props.instructionImages])

    // store thumbs swiper instance
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    
    const {types, subTypes} = useContext(PropertyContext);
    const { previousStep } = useWizard();
   

  return (
    <>
    <ProgressBar/>
    <div className="card">
        <div className="card-body">
            <div className="live-preview">
              <div className="row gx-lg-5">
                  <div className="col-xl-5 col-md-5 mx-auto" style={{'height': '540px'}}>
                    <>
                      <Swiper
                        style={{
                          '--swiper-navigation-color': '#fff',
                          '--swiper-pagination-color': '#fff',
                          '--swiper-navigation-size': '25px'
                        }}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                      >
                        {props.files.length > 0 && props.files.map((image, index) => (
                          <SwiperSlide key={image.name}>
                              <img src={`${URL.createObjectURL(image)}`} key={index} alt={image.name} />
                          </SwiperSlide>
                        ))}

                        {(props.pageName === "edit property" && props.uploadedFiles && props.uploadedFiles.length > 0) ? 
                            props.uploadedFiles.map((img, i) => (
                                img && img.filename ? (
                                    <SwiperSlide key={img.filename}>
                                        <img src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} alt={img.filename} />
                                    </SwiperSlide>
                                ) : null
                            ))
                            : <></>
                        }

                      </Swiper>
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={3}
                        freeMode={true}
                        watchSlidesProgress={true}
                        className="mySwiper"
                        style={{'height': '100px'}}
                      >
                        {props.files.length > 0 && props.files.map((image, index) => (
                            <SwiperSlide key={image.name}>
                                <img src={`${URL.createObjectURL(image)}`} key={index} alt={image.name} />
                            </SwiperSlide>
                        ))}

                        {props.pageName === "edit property" && props.uploadedFiles && props.uploadedFiles.length > 0 && (
                            props.uploadedFiles.map((img, i) => (
                                img && img.filename ? (
                                    <SwiperSlide key={img.filename}>
                                        <img src={`${process.env.REACT_APP_BACKENDURL}images/${img.filename}`} alt={img.filename} />
                                    </SwiperSlide>
                                ) : null
                            ))
                        )}
                      </Swiper>
                    </>
                  </div>
                  

                  <div className="col-xl-7">
                      <h4 className="card-title mb-3">{props.basicDetailArray.propertyName}</h4>
                      <div className="row">
                          <div className="col-md-6 pro-type">
                              <h5>Property Type</h5>
                              <p className="number">{ types[(props.basicDetailArray.type - 1)].name } | { subTypes[(props.basicDetailArray.subType - 1)].name }</p>
                          </div>
                          <div className="col-md-6 pro-dimensions">
                              <h5>Dimensions</h5>
                              <p className="number">{props.propertySize} {(props.selectedSizeType === "m") ? "m²" : "ft²"}</p>
                          </div>
                          <div className="col-md-6 pro-sum">
                              <h5>Property Summary</h5>
                              <ul className="number">
                                  {Object.keys(props.additionalArray).map((x, i) =>
                                      <li key={x}> {props.additionalArray[x]} {helper.captilizeString(x)}</li>
                                  )}
                              </ul>
                          </div>
                          <div className="col-md-6 pro-address">
                              <h5>Location</h5>
                              <div className="full-addres">
                                  {Object.keys(props.addressArray).map((x, i) => 
                                      <div key={x} className={"d-flex align-items-center gap-1 " + x }>
                                          <h6>{helper.captilizeString(x)}:</h6>
                                          <p> {props.addressArray[x]}</p>
                                      </div>
                                  )}
                              </div>
                          </div>
                          <div className="col-md-12">
                              
                              <div>
                                  <h5>Description</h5>
                                  <span>{parse(props.basicDetailArray.description)}</span>
                              </div>
                          </div>
                          <div className="col-md-12">
                              
                              <div>
                                  <h5>Instructions on how cleaners can access your property</h5>
                                  <p>{props.basicDetailArray.accessibility}</p>
                                  
                                  <div className='row'>
                                    {instructionImagesArray.length > 0 && (
                                      instructionImagesArray.map((img, i) => (
                                        <div className='col-4 mt-1' style={imgContainer} key={i}>
                                          <img src={img} alt={img} style={imgStyle} onClick={() => openLightboxOnSlide(i+1)}/>
                                        </div>
                                      ))
                                    )}
                                  </div>
                              </div>
                              <div>
                                {(instructionImagesArray.length > 0) &&
                                  <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={instructionImagesArray}
                                    slide={lightboxController.slide}
                                  />
                                }                                
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col-xl-12 mt-4">
                        { isLoaded ? (
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={mapCenter}
                                zoom={17}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                              >
                                <MarkerF position={mapCenter} />
                              </GoogleMap>
                          ) : <></>
                        }
                  </div>
            

                  <div className="d-flex align-items-start gap-3 mt-4">
                      <button type="button" className="btn btn-outline-list btn-label" onClick={() => previousStep()} ><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>Previous</button>
                      <button type="submit" className="btn btn-add btn-label right ms-auto"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Finish</button>
                  </div>


              </div>

            </div>
        </div>
    </div>
    </>
  )
}

export default Preview;