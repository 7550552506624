import React , { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Cookies from 'js-cookie';
import moment from 'moment';
import { HostContext } from '../../../../Contexts/HostContext';
import { Link } from 'react-router-dom';
import helper from '../../../../helper/index';

let limit = 10;
const Tabs = (props) => {
    const token = Cookies.get('token');
    const {decoded} = useContext(HostContext)
    const segment = decoded.user_id; 

    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [activetype, setActivetype] = useState('running');

    // const handleFilters = ( keyValue, value) => {
    //     filterRecords[keyValue] = value
    //     setFilterRecords({ ...filterRecords })
    // }

    
    // const [filterRecords, setFilterRecords] = useState({
    //     propertyId: "",
    //     sortBy: "asc",
    //     searchText: ""
    // });

    // useEffect(() => {
    //     getData([]);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [filterRecords]);

    useEffect(() => {
        // getData([], 'running', true);
        const step = props.step;
        getData([], step, true);
    }, [])

    const getData = (datas, assignmentType = 'running', isInit = false) => {

        let assTypeV = assignmentType
        
        if(activetype === assTypeV && isInit === false){ return false }

        props.setLoading(true)
        let pageNo = (datas) ? datas.selected : 0;

        if(typeof datas.selected === "undefined"){
            // When tab change, update new tab
            setActivetype(assTypeV);
        }else{
            // When page no is selected, acive current tab
            assTypeV =  activetype;
        }

        axios.get(process.env.REACT_APP_BACKENDURL+'api/host/get-assignment',
        {
            params: {
                page: pageNo,
                limit: limit,
                hostId: segment,
                sortBy: 'desc',
                // filterRecords: filterRecords,
                assignmentType: assTypeV
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
                props.setLoading(false)
            }else{
                props.setLoading(false)
            }
        })
        .catch(function (error) {
            console.log(error);
            props.setLoading(false);
        });
    }

    const onDelete = (id) => {
        Swal.fire({
            text: 'Are you want to delete?',
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_BACKENDURL}assignment/delete/${id}`)
                .then(function (response) {
                    if(response.data.status === 1){
                        helper.Toast().fire({ icon: 'success', title: response.data.message });
                        getData();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else if (result.isDenied) {
                
            }
        })
        
    }
  return (
    <div className="card-body">
    <ul className="nav nav-pills nav-justified mb-3 assignment-tab" id="pills-tab"
        role="tablist">
            
        <li className="nav-item" role="presentation">
            <button className={'nav-link running ' +  ((activetype === 'running') ? 'active' : '' )} type="button" onClick={(e) => getData([],'running')} >Ongoing</button>
        </li>
        <li className="nav-item" role="presentation">
            <button className={'nav-link pending ' +  ((activetype === 'pending') ? 'active' : '' )}  type="button" onClick={(e) => getData([],'pending')} >Pending</button>
        </li>
        <li className="nav-item" role="presentation">
            <button className={'nav-link upcoming ' +  ((activetype === 'upcoming') ? 'active' : '' )} type="button" onClick={(e) => getData([],'upcoming')}  >Upcoming</button>
        </li>
        <li className="nav-item" role="presentation" type="button">
            <button className={'nav-link completed ' +  ((activetype === 'completed') ? 'active' : '' )} type="button" onClick={(e) => getData([],'completed')}>Completed</button>
        </li>
    </ul>
    <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-run" role="tabpanel" aria-labelledby="pills-run-tab">
                {data.length > 0 ?
                    <>
                        <div className="table-responsive table-card data-table">
                            <table className="table table-borderless table-nowrap align-middle mb-0 ">
                                <thead className="">
                                    <tr className="text-muted">
                                        <th scope="col">Property</th>
                                        {(activetype !== 'pending') ?
                                            <th scope="col">Cleaner Name</th>
                                        :<></>
                                        }
                                        <th scope="col">Date</th>
                                        <th scope="col">Total Price</th>
                                        {
                                            (activetype === 'completed') ? 
                                                <th scope="col">Status</th>
                                            : <></> 
                                        }
                                        
                                        <th scope="col">Action
                                        </th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {data.map((item, i) => { 
                                        return ( 
                                            <tr key={i}>
                                                <td>
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <img src={(item.propertyDetail.image) ? process.env.REACT_APP_BACKENDURL+'images/'+item.propertyDetail.image.filename : "/assets/images/propery.png"} alt=""
                                                            className="rounded avatar-sm me-2" />
                                                        <div>
                                                            <div className='d-flex'>
                                                                <a href="#javascript" className="text-body fw-medium">{item.title}</a>
                                                                {
                                                                    (item.status < 4 && item?.isRead === 0) &&
                                                                    <i className="fs-10 badge rounded-pill assignment-list-unread"><span className='d-none'>{item?.isRead}</span></i>
                                                                }
                                                            </div>
                                                            <p className="mb-0 pt-1 number">
                                                            {item.propertyDetail?.fullAddress}
                                                            </p>
                                                        </div>

                                                    </div>

                                                </td>
                                                {((activetype !== 'pending') || (typeof item.cleanerDetail !== "undefined" && Object.keys(item.cleanerDetail).length > 0)) ?
                                                    <td>{typeof item.cleanerDetail !== "undefined" ? item.cleanerDetail.first_name+" "+item.cleanerDetail.last_name : 'No Cleaner Assigned'}</td>
                                                :<></>
                                                }
                                                {/* <td className="number">{moment(item.date).format('MMM Do YYYY, hh:mm A') }</td> */}
                                                <td className="number">{moment(item.date).format('MMM Do YYYY') }</td>
                                                <td className="number">${parseFloat(item.price).toFixed(2)}</td>
                                                {
                                                    (activetype === 'completed') ? 
                                                        (item.status === 4) ?
                                                            <td>
                                                                <span className="badge completed p-2 text-capitalize">Completed</span>
                                                            </td>
                                                        :
                                                            <td>
                                                                <span className="badge cancelled bg-danger p-2 text-capitalize">Cancelled</span>
                                                            </td>
                                                    : <></> 
                                                }
                                                {/* <td><span
                                                        className="badge completed p-2 text-capitalize">Running</span>
                                                </td> */}
                                                <td className="note-icon">
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <Link to={`/host/assignment/detail/${item._id}`} className="edit-icon fs-18">
                                                            <img src="/assets/images/sidebar-icon/edit.svg"
                                                                alt="" height="30" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel="..."
                            pageCount={PageCount}
                            pageRangeDisplayed={3}
                            onPageChange={getData}
                            containerClassName={'pagination pagination-rounded mt-3 mb-3 justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            renderOnZeroPageCount={null}
                            activeClassName={'active'}
                        />
                    </>

                :
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="alert alert-danger" role="alert">No assignment yet.</div>
                        </div>
                    </div>
                }
        </div>
        
    </div>
    
</div>
  )
}

export default Tabs