import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import helper from '../../helper/index.js';

const Notification = (props) => {
    const token = Cookies.get('token');
    const [allChecked, setAllChecked] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        sms: 0,
        email: 0,
        onApp: 0,
        push: 0
    });

    const fetchNotificationSettings = async() => {
        await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/fetch-notification-settings',
        {
            params: {
                "role_id": 3
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        }).then((response) => {
            if(response.data.status === 1) {
                setCheckboxes(response.data.data.notificationSettings);
            }
        })
        .catch((error) => {
            console.error("Error: Something went wrong", error);
        });
    }

    const updateNotificationSetting = async (event) => {
        event.preventDefault();
        const data = {
            "role_id": 3,
            notificationSettings: checkboxes
        };
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/manage-notification-settings', data,
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        }).then((response) => {
            if(response.data.status === 1) {
                helper.Toast().fire({ icon: 'success', title: response.data.message });
            } else {
                helper.Toast().fire({ icon: 'error', title: "Something went wrong. Please try again later." });
            }
        })
        .catch((error) => {
            console.error("Error: Something went wrong", error);
        });
    }

    function handlechange(e) {
        setCheckboxes({ ...checkboxes, [e.target.id]: ((e.target.checked === true) ? 1 : 0) })
    }

    useEffect(() => {
        const result = Object.values(checkboxes).every(v => v);
        setAllChecked(result);
    }, [checkboxes]);

    useEffect(() => {
        fetchNotificationSettings();
    }, []);

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Notification Settings</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/cleaner/dashboard">Overview</Link></li>
                                    <li className="breadcrumb-item"><Link to="/cleaner/settings">Settings</Link></li>
                                    <li className="breadcrumb-item active">Notification Settings</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            {/* <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Notifications</h4>
                            </div> */}

                            <div className="card-body">
                                <div className="live-preview">
                                    <div className="row">
                                        <div className="col-xl-12">            
                                            <p> Enable the toggles in which way you want to get notifications. </p>
                                            <form id="notification-setting-form" onSubmit={(event) => updateNotificationSetting(event)}>
                                                
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Enable / Disable</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <th>SMS Notification</th>
                                                        <td>
                                                            <div className="flex-shrink-0 pl-3">
                                                                <div className="form-check form-switch form-switch-right form-switch-md">
                                                                    <input className="form-check-input code-switcher" type="checkbox" value={checkboxes.sms} checked={checkboxes.sms === 1 ? true : false} id="sms" onChange={handlechange} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email Notification</th>
                                                        <td>
                                                        <div className="flex-shrink-0 pl-3">
                                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                                <input className="form-check-input code-switcher" type="checkbox" value={checkboxes.email} checked={checkboxes.email === 1 ? true : false} id="email" onChange={handlechange} />
                                                            </div>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Web Notification</th>
                                                        <td>
                                                        <div className="flex-shrink-0 pl-3">
                                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                                <input className="form-check-input code-switcher" type="checkbox" value={checkboxes.onApp} checked={checkboxes.onApp === 1 ? true : false} id="onApp" onChange={handlechange} />
                                                            </div>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile Push Notification</th>
                                                        <td>
                                                        <div className="flex-shrink-0 pl-3">
                                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                                <input className="form-check-input code-switcher" type="checkbox" value={checkboxes.push} checked={checkboxes.push === 1 ? true : false} id="push" onChange={handlechange} />
                                                            </div>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                
                                                <div className='float-end'>
                                                    <button type="submit" className="btn btn-success waves-effect waves-light">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification;