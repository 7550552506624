import React, { useState, useEffect } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate,Link,useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from '../fireabse';
import { doc, setDoc } from 'firebase/firestore';
import { socket } from '../socket';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from "../hooks/auth";
import helper from '../helper/index.js';
import Modal from '../components/common/Modal.js';
import parse from 'html-react-parser';

const Register = () => {
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams()
    const { login } = useAuth();

    const [ userG, setUserG ] = useState({});
    const [userType, setUserType] = useState("");
    const [firstName, setFirstName] = useState((queryParameters.get("first_name") ? queryParameters.get("first_name") : ""));
    const [lastName, setLastName] = useState((queryParameters.get("last_name") ? queryParameters.get("last_name") : ""));
    const [EmailAddress, setEmailAddress] = useState((queryParameters.get("email") ? queryParameters.get("email") : ""));
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, SetConfirmPassword] = useState("");
    const [type, setType] = useState('password');
    const [contype, setCType] = useState('password');

    const [errorUserType, setUserTypeErrorMessage] = useState("");
    const [errorFirstName, setFNErrorMessage] = useState("");
    const [errorLastName, setLNErrorMessage] = useState("");
    const [errorEmail, setEErrorMessage] = useState("");
    const [errorPhone, setPErrorMessage] = useState("");
    const [errorPassword, setPDErrorMessage] = useState("");
    const [errorConfirmPassword, setCPErrorMessage] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [termsErrorMsg, setTermsErrorMsg] = useState("");
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [policyContent, setPolicyContent] = useState(null);
    const [termsContent, setTermsContent] = useState(null);

    // eslint-disable-next-line no-unused-vars
    let [loading, setLoading] = useState(false)

    function capitalizeFirstLetter(str) {
        return str.trim().charAt(0).toUpperCase() + str.slice(1);
    }

    const handleTermsChange = () => {
        setAcceptTerms(!acceptTerms);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setUserTypeErrorMessage("");
        setFNErrorMessage("");
        setLNErrorMessage("");
        setEErrorMessage("");
        setPErrorMessage("");
        setPDErrorMessage("");
        setCPErrorMessage("");
        setTermsErrorMsg("");

        let error = 0;
        if(userType === "") {
            setUserTypeErrorMessage('User type field is required.');
            error = 1;
        }
        
        if(firstName.trim().length === 0) {
            setFNErrorMessage('First Name field is required.');
            error = 1;
        } else if (!helper.validateOnlyAlphabetInput(firstName)) {
            setFNErrorMessage('First Name should contain only alphabets.');
            error = 1;
        }

        if(lastName.trim().length === 0) {
            setLNErrorMessage('Last Name field is required.');
            error = 1;
        }else if(!helper.validateOnlyAlphabetInput(lastName)) {
            setLNErrorMessage('Last Name should contain only alphabets.');
            error = 1;
        }

        if(EmailAddress.trim().length === 0) {
            setEErrorMessage('Email Address field is required.');
            error = 1;
        } else if(!helper.isValidEmail(EmailAddress)) {
            setEErrorMessage('Email Address format is not correct.');
            error = 1;
        }

        if(phone.trim().length === 0) {
            setPErrorMessage('Phone Number field is required.');
            error = 1;
        } else if(phone.trim().length !== 10) {
            setPErrorMessage('Phone Number should be 10 digits.');
            error = 1;
        }

        if(password.trim().length === 0) {
            setPDErrorMessage('Password field is required.');
            error = 1;
        }
        if(confirmPassword.trim().length === 0) {
            setCPErrorMessage('Confirm Password field is required.');
            error = 1;
        }else{
            if(password.trim() !== confirmPassword.trim()){
                setCPErrorMessage('Confirm Password is not matched with password field.');
                error = 1;
            }
        }

        if(!acceptTerms) {
            setTermsErrorMsg("Please accept terms & conditions and privacy policy");
            error = 1;
        }

        if(error === 1){
            return;
        }

        setLoading(true)
        try {
            // Create Host User
            const response = await axios.post(process.env.REACT_APP_BACKENDURL+'api/auth/app-register', {
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                phone_number: phone.trim(), 
                email: EmailAddress.trim(),
                password: password.trim(),
                type: userType,
                isAgreeToTerms: acceptTerms
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if(response.data.status === 1){
                socket.emit('send-notification-signal-to-admin',1)

                // Authenticate firebase user
                const userCredential =  await createUserWithEmailAndPassword(auth, EmailAddress, response.data.id);
                const user = userCredential.user;

                // Save firebase uid in the database
                const resp = await axios.post(process.env.REACT_APP_BACKENDURL+'user/update-firebase-uid', {
                    uid: user.uid,
                    user_id: response.data.id,
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                

                if(resp.data.status === 1){

                    // Save name in firebase user
                    await updateProfile(user, {
                        displayName: firstName + ' ' + lastName,
                    });

                    await setDoc(doc(db, "users", user.uid), {
                        uid: user.uid,
                        name: firstName + ' ' + lastName,
                        email: EmailAddress,
                        role_id: parseInt(userType)
                    });

                    Swal.fire("","Congratulation, Your account is successfully created with housekeeper. Please login with your account information.","").then((result) => {
                        navigate('/login');
                    });

                }else{
                    Swal.fire("","Congratulation, Your account is successfully created with housekeeper. Please login with your account information.","").then((result) => {
                        navigate('/login');
                    });
                }
                setLoading(false)
            }else{
                setLoading(false)
                if(response.data.status === 2) {
                    const matchType = response.data.type;
                    if(matchType === "email") {
                        setEErrorMessage('This email address is already exist.');
                    } else if(matchType === "phone") {
                        setPErrorMessage('Account with this number already exist.');
                    }
                } else {
                    helper.Toast().fire({ icon: 'error', title: response.data.message });
                }
            }
        } catch (error) {
            console.log('Error',error);
            setLoading(false);
            // helper.Toast().fire({ icon: 'error', title: "Due to some error this account is not registered. Please try again or contact with administrator."});
        }
    };

    const handlePassword = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }
    const handleCPassword = () => {
        if (contype==='password'){
            setCType('text')
        } else {
            setCType('password')
        }
    }
    const handlePhone = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPhone(result);
    };

    const loderWrap  = {
        position: "absolute",
        width: "100%",
        background: "rgba(237, 232, 232, 0)",
        height: "100%",
        zIndex: "99999999"
    };

    const override = {
        display: "block",
        position: "fixed",
        top: "47%",
        left: "47%",
        zIndex: "99999",
    };

    const glogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUserG(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
        
            if (Object.keys(userG).length > 0) {
              
                setLoading(true)
                axios.post(process.env.REACT_APP_BACKENDURL + 'api/auth/validate-google-token', 
                    {
                        token: userG.access_token
                    },
                    {
                        headers: {'Content-Type': 'application/json'}
                    }
                )
                .then((Gres) => {
    
                    if(Gres.data.status === 1){
                        // valid token
    
                        if(Gres.data.data.is_register === true){
                            // already connect with housekeeper, now login
                            googleLoginSubmission(Gres.data.data.email,userG.access_token);
                        }else{
                            // autofill data with register
                            setFirstName(Gres.data.data.given_name);
                            setLastName(Gres.data.data.family_name);
                            setEmailAddress(Gres.data.data.email);
    
                        }
                    }else{
                        // Invalid token
                    }
    
                    setLoading(false)
                    
                })
                .catch((err) => {
                    console.log(err)
                });
            }
        },
        [ userG ]
    );

    useEffect(() => async() => {
        getPolicyContent();
        getTermsAndConditionContent();
    }, [])

    const googleLoginSubmission = async (email, token) => {
        let pwd = '';
        let socialLogin = true;
        let res = await login({ email,pwd,socialLogin,token});
        console.log('res', res);
        setLoading(false)
        if(res.status === 1 && res.token !== ""){
            navigate('/verify/otp');
        }else{
            console.log('in else');
        }
    }

    const togglePrivacyModel = async() => {
        setIsPrivacyModalOpen(!isPrivacyModalOpen);
    }

    const toggleTermsModel = async() => {
        setIsTermsModalOpen(!isTermsModalOpen);
    }

    const handlePrivacyModalSubmit = async() => {
        handleTermsChange();
        togglePrivacyModel();
    }
    const handleTermsModalSubmit = async() => {
        handleTermsChange();
        toggleTermsModel();
    }

    const getPolicyContent = async() => {
        const {data} = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/get-privacy-policy-content', {
                            headers: {
                                'Content-Type': 'text/html',
                            }
                        })
                        .catch(function (error) {
                            console.log('Something went wrong');
                        });
        if(data.status === 1) {
            setPolicyContent(data.content);
        }
    }
    
    const getTermsAndConditionContent = async() => {
        const {data} = await axios.get(process.env.REACT_APP_BACKENDURL+'api/common/get-terms-condition', {
                            headers: {
                                'Content-Type': 'text/html',
                            }
                        })
                        .catch(function (error) {
                            console.log('Something went wrong');
                        });
        if(data.status === 1) {
            setTermsContent(data.content);
        }
    }

    return (
        <>
            {loading &&  <div style={loderWrap}>
                <ClipLoader
                    color="#8F368D"
                    cssOverride={override}
                    loading
                    size={45}
                    speedMultiplier={1}
                    />
                </div>
            }
            <div id="login-bg"
                className="auth-page-wrapper auth-bg-cover py-3 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>

                <div className="auth-page-content overflow-hidden ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card overflow-hidden">
                                    <div className="row justify-content-center g-0">
                                        <div className="col-lg-6">
                                            <div className="p-lg-5 p-4 left-icon h-100">
                                                <div className="bg-overlay"></div>
                                                <div
                                                    className="position-relative h-100 d-flex justify-content-center align-items-center flex-column ">
                                                    <div className="mx-auto">
                                                        <img src="assets/images/logo-hk.png" alt="" />
                                                    </div>
                                                    <div className="mt-2">
                                                        <p>simplified cleaning</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="px-lg-5  p-4">
                                                <div className="contant-area">
                                                    <h1 className="">Create an Account</h1>
                                                    <p className="form-sub-title">Enter your email and password to register.</p>

                                                    <h2 className="sinup-sub-tittle">Which represents you best?</h2>
                                                </div>
                                                <div className="chois-user">
                                                    <div className="user-check">
                                                        <input id="host" type="radio" className="form-check-input bg-white dark:bg-black" name="userType" value="2" onChange={(e) => setUserType("2") } />
                                                        <div className="user-tiles">
                                                            <img src="assets/images/Host_icon.png" alt="Host Icon"
                                                                className="img-fluid" />
                                                            <label htmlFor="host">I’m a Host</label>
                                                        </div>
                                                    </div>
                                                    <div className="user-check">
                                                        <input id="cleaner" type="radio" className="form-check-input bg-white dark:bg-black" name="userType" value="3" onChange={(e) => setUserType("3") } />
                                                        <div className="user-tiles">
                                                            <img src="assets/images/Cleaner_icon.png" alt="Cleaner Icon"
                                                                className="img-fluid" />
                                                            <label htmlFor="cleaner">I’m a Cleaner</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errorUserType && <div className="text-danger"> {errorUserType} </div>}

                                                <div className="mt-2">
                                                    <form className="singup-form login-form" onSubmit={handleSubmit} >

                                                        <div className="mb-1">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">First Name</label>
                                                                        <input type="text" 
                                                                            className="form-control" 
                                                                            value={firstName}
                                                                            onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)} 
                                                                            onChange={(e) => setFirstName(e.target.value = capitalizeFirstLetter(e.target.value))} 
                                                                            maxLength={30}/>
                                                                        {errorFirstName && <div className="text-danger"> {errorFirstName} </div>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Last Name</label>
                                                                        <input type="text" 
                                                                            className="form-control" 
                                                                            value={lastName} 
                                                                            onKeyDown={(e) => helper.checkOnlyAlphabetInput(e)}
                                                                            onChange={(e) => setLastName(e.target.value = capitalizeFirstLetter(e.target.value))} 
                                                                            maxLength={30} />
                                                                        {errorLastName && <div className="text-danger"> {errorLastName} </div>}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="mb-2">
                                                                        <label htmlFor="email" className="form-label">Email Address</label>
                                                                        <input type="text" 
                                                                            className="form-control" 
                                                                            value={EmailAddress}
                                                                            onKeyDown={(e) => helper.checkSpaceInput(e)}
                                                                            onChange={(e) => setEmailAddress(e.target.value)} 
                                                                            maxLength={50} 
                                                                        />
                                                                        {errorEmail && <div className="text-danger"> {errorEmail} </div>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-2">
                                                                        <label htmlFor="phone" className="form-label">Phone Number</label>
                                                                        <input type="text" 
                                                                            className="form-control" 
                                                                            value={phone}
                                                                            onKeyDown={(e) => helper.checkNumericInput(e)}
                                                                            onChange={handlePhone} 
                                                                            maxLength={10} />
                                                                        {errorPhone && <div className="text-danger"> {errorPhone} </div>}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="mb-1">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-2">
                                                                        <label className="form-label" htmlFor="password-input">Password</label>
                                                                        <div className="position-relative auth-pass-inputgroup">
                                                                            <input type={type} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={50} />
                                                                            {errorPassword && <div className="text-danger"> {errorPassword} </div>}
                                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={handlePassword} ><i className="ri-eye-fill align-middle"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <div className="mb-2">
                                                                        <label className="form-label" htmlFor="password-input">Confirm Password</label>
                                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                                            <input type={contype} className="form-control" value={confirmPassword} onChange={(e) => SetConfirmPassword(e.target.value)} maxLength={50} />
                                                                            {errorConfirmPassword && <div className="text-danger"> {errorConfirmPassword} </div>}

                                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon" onClick={handleCPassword} ><i className="ri-eye-fill align-middle"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-1">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-2 d-flex">
                                                                        <input type='checkbox' checked={acceptTerms} onChange={(e) => handleTermsChange()} />
                                                                        <div className='ps-2 fw-6'> 
                                                                            I have read and agree to the <span className='btn btn-link fw-6 p-0' style={{'display':'contents'}} onClick={(e) => toggleTermsModel()}>Terms & Conditions </span> 
                                                                            and <span className='btn btn-link fw-6 p-0' style={{'display':'contents'}} onClick={(e) => togglePrivacyModel()}> Privacy Policy </span>
                                                                        </div>
                                                                    </div>
                                                                    {termsErrorMsg && <span className="text-danger"> {termsErrorMsg} </span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    

                                                        <div className="mt-3">
                                                            <button className="btn primary-btn btn-shadow w-100" type="submit">Sign Up</button>
                                                        </div>

                                                        <div className="mt-3 text-center">
                                                            <div className="signin-other-title">
                                                                <h5 className="fs-13 mb-3 title">OR</h5>
                                                            </div>

                                                            <div className="icon-group d-flex gap-2 justify-content-center">
                                                                <button type="button" className="btn btn-danger btn-label"  onClick={() => glogin()} ><i className="ri-google-fill label-icon align-middle fs-16 me-2"></i> Login With Google</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="mt-4 text-center singup-text">
                                                    <p className="mb-0">Already have an account?&nbsp; 
                                                        <Link to="/login" className="fw-semibold text-primary primary-text ">
                                                            SIGN IN 
                                                        </Link> 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal isOpen={isPrivacyModalOpen} isName='privacyPolicy'>
                    <div className="modal-dialog" style={{'height': '100%'}}>
                        <div className="modal-content" style={{'height': '100%'}}>
                            <div className="modal-header">
                                <h5 className="modal-title">Privacy Policy</h5>
                                <button type="button" className="btn-close"  onClick={togglePrivacyModel}></button>
                            </div>
                            <div className="modal-body p-2" style={{'overflowY': 'auto'}}>
                                <div className="policy-section" style={{'height': 'auto'}}>
                                    {
                                        policyContent && parse(policyContent)
                                    }
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end gap-3 mt-2">
                                <button 
                                    type="button"
                                    onClick={(e) => handlePrivacyModalSubmit()}
                                    className="btn btn-add added-btn  waves-effect waves-light">Agree</button>
                                <button 
                                    type="button"
                                    onClick={togglePrivacyModel}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Cancel</button>
                            </div>

                        </div>
                    </div>
			    </Modal>

                <Modal isOpen={isTermsModalOpen} isName='privacyPolicy'>
                    <div className="modal-dialog" style={{'height': '100%'}}>
                        <div className="modal-content" style={{'height': '100%'}}>
                            <div className="modal-header">
                                <h5 className="modal-title">Terms & conditions</h5>
                                <button type="button" className="btn-close"  onClick={toggleTermsModel}></button>
                            </div>
                            <div className="modal-body p-2" style={{'overflowY': 'auto'}}>
                                <div className="policy-section" style={{'height': 'auto'}}>
                                    {
                                        termsContent && parse(termsContent)
                                    }
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end gap-3 mt-2">
                                <button 
                                    type="button"
                                    onClick={(e) => handleTermsModalSubmit()}
                                    className="btn btn-add added-btn  waves-effect waves-light">Agree</button>
                                <button 
                                    type="button"
                                    onClick={toggleTermsModel}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Cancel</button>
                            </div>

                        </div>
                    </div>
			    </Modal>
            </div>
        </>
    )
}

export default Register;
