import React,{useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import helper from '../../../helper';
import Swal from 'sweetalert2';
import FsLightbox from "fslightbox-react";
import Modal from '../../../components/common/Modal.js';

// import required modules
import { Navigation, Pagination, Scrollbar, A11y  } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { socket } from '../../../socket';
import { decodeToken } from 'react-jwt';

const AssignmentDetail = (props) => {
    
    const [isConnected, setIsConnected] = useState(socket.connected);
    const token = Cookies.get('token');
    const decoded = decodeToken(token);
    const userId = decoded.user_id;
    const navigate = useNavigate();
    const params = useParams();
    const subTypes = helper.subTypes
    const statusData = helper.status
    const [cleanerTransactionFee, setCleanerTransactionFee] = useState(0);
    const [hostTransactionFee, setHostTransactionFee] = useState(0);

    const assignmentId = params.assignmentId;

    const [ assDetail, setAssDetail ] = useState({
        propertyDetail : {},
    });

    const [ procedure, setProcedure ] = useState({
        checkList: [],
        images: [],
        gettingThere: {},
        gettingInside: {},
        arrivalConfirm: {}
    });
    const [ procedureImages, setProcedureImages ] = useState([]);
    const [ isImgCompleted, setIsImgCompleted ] = useState(0);
    const [ pendingCleaners, setPendingCleaners ] = useState([]);
    const [ acceptedcleaners, setAcceptedCleaners ] = useState([]);
    const [ declinedCleaners, setDeclinedCleaners ] = useState([]);
    const [ bargainedCleaners, setBargainedCleaners ] = useState([]);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [ comment, setComment ] = useState('');
    const [addReview, setAddReview] = useState(false);
    const [reviewError, setReviewError] = useState("");
    const [propertyImagesArr, setPropertyImagesArr] = useState([]);
    const [pendingProcedureImages, setPendingProcedureImages] = useState([]);
    
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

    const [propertyImgController, setPropertyImgController] = useState({
		toggler: false,
		slide: 1
	});

    const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	}

	function openPropertyImgLightbox(number) {
		setPropertyImgController({
			toggler: !propertyImgController.toggler,
			slide: number
		});
	}

    //Fetch transaction fee details
    const fetchTransactionFee = async () => {
        const { data } = await axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/transaction-fees', {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        if (data.status === 1) {
            setCleanerTransactionFee(data.data.cleaner_fee);
            setHostTransactionFee(data.data.host_fee);
        }
    };
    
    const fetchAssessmentDetail = async () => {
        props.setLoading(true);
        const { data } = await axios.get(process.env.REACT_APP_BACKENDURL+'api/host/get-assignment-detail?id=' + assignmentId, 
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .catch(function (error) {
            console.log('Something went wrong');
        });
       
        if(data.status === 1){
            setAssDetail(data.data);
            if(data.data.propertyDetail.images && data.data.propertyDetail.images.length > 0) {
                let propertyImg = [];
                data.data.propertyDetail.images.map((img, i) => {
                    propertyImg.push(process.env.REACT_APP_BACKENDURL+'images/'+img.filename);
                });
                setPropertyImagesArr(propertyImg);
            }

            if(typeof data.data.procedure.images !== "undefined" && data.data.procedure.images.length > 0){
                let isImagesComplete = 1;
                data.data.procedure.images.map((img, i) => {
                    if(img.status === "0" || img.status === "2"){
                        isImagesComplete = 0;
                        return;
                    }
                });


                setIsImgCompleted(isImagesComplete);
            }

            if(Object.keys(data.data.review).length > 0){
                setRating(data.data.review.stars);
            }

            if((typeof data.data.cleaners !=="undefined") && data.data.cleaners.length > 0){

                let accepted_cleaners = [];
                let rejected_cleaners = [];
                let bargained_cleaners = [];
                let pending_cleaners = [];

                // Set Assigment procedure
                setProcedure(data.data.procedure)

                // Images Array
                if(data.data.procedure.images.length > 0){
                    let pImg = [];
                    let pendImg     = [];
                    data.data.procedure.images.map((img, i) => {
                        if(img.status === "0"){
                            pendImg.push(img._id)
                        }
                        pImg.push(process.env.REACT_APP_BACKENDURL+'assignments/'+img.filename);
                    });
                    
                    setProcedureImages(pImg);
                    setPendingProcedureImages(pendImg);
                }
                

                data.data.cleaners.map((item, i) => {
                    if(item.status === 0 && item.is_bargaining === 0){
                        pending_cleaners.push(item);
                    }else if(item.status === 1 && item.is_bargaining == 0){
                        accepted_cleaners.push(item);
                    }else if(item.status === 2){
                        rejected_cleaners.push(item);
                    }else if(item.is_bargaining === 1){
                        bargained_cleaners.push(item);
                    }
                });
                

                setPendingCleaners(pending_cleaners);
                setAcceptedCleaners(accepted_cleaners);
                setDeclinedCleaners(rejected_cleaners);
                setBargainedCleaners(bargained_cleaners);

            }
            props.setLoading(false);
        }else{
            props.setLoading(false);
            navigate('/host/assignment/list');
        }
    };

    const hireJob = async (data ,is_bargaining = 0) => {
        let swalText = '';
        let final_price = (is_bargaining === 1) ? data.price : assDetail.price;
        let transaction_fee = helper.calculateTransactionFee(final_price, hostTransactionFee);
        let total_deduct_amt = parseFloat(final_price) + parseFloat(transaction_fee);
        if(is_bargaining === 1){
            swalText = `The new payout price is $${total_deduct_amt.toFixed(2)} including transaction charges(${hostTransactionFee}%). Are you sure you want to hire ${data.label} for this job?`
        }else{
            swalText = `Are you sure you want to hire ${data.label} for this job? $${total_deduct_amt.toFixed(2)} will deduct from your account including transaction charges(${hostTransactionFee}%).`
        }
        Swal.fire({
            text: swalText,
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                props.setLoading(true)

                 // update-invitation-status
                axios.post(
                process.env.REACT_APP_BACKENDURL+'api/host/assignment/accept-invitation', 
                {
                    cleanerId: data.value,
                    id: assignmentId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }
                )
                .then(() => {
                    sendNotificationToCleaner(data.value)
                    sendSignalToCleaner()
                    props.setLoading(false)
                    helper.Toast()
                        .fire({ icon: 'success', title: `Assigment has been successfully assigned to ${data.label}` })
                        .then(() => { fetchAssessmentDetail(); });
                    
                })
                .catch((err) => {
                    props.setLoading(false)
                });
            }else{
                props.setLoading(false)
            }
        })
       
    }

    const updateImageStatus = async (imageId, status) => {
        let statusTxt = "decline";
        let SwalObject = {
            text: `Are you sure you want to ${statusTxt} this image?`,
            input: "textarea",
            inputLabel: "",
            inputPlaceholder: "Type your reason for decline...",
            inputAttributes: {
                maxlength: "250",
            },
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }
        Swal.fire(SwalObject).then((result) => {
            if (result.isConfirmed) {
                if(status === 2){
                    if(result.value === ""){ return false;  }
                }
                handleUpdateImageStatus(imageId, status, result.value);
            }
        })
    }

    const handleUpdateImageStatus = async (imageId, status, message = "") => {
        props.setLoading(true);
        let statusTxt = "";
        if(status === 1) {
            statusTxt = "approved";
        } else {
            statusTxt = "declined";
        }
        await axios.post(
            process.env.REACT_APP_BACKENDURL+'api/host/assignment/update-invitation-status', 
            {
                id: imageId,
                assignmentId: assignmentId,
                status:status,
                message: message
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
        )
        .then((response) => {
            sendNotificationToCleaner(assDetail.cleanerId)
            sendSignalToCleaner()
            props.setLoading(false)
            procedure['images'] =  response.data.data.procedure.images;
                
            setProcedure({...procedure});

            if(typeof response.data.data.procedure.images !== "undefined" && response.data.data.procedure.images.length > 0){
                let isImagesComplete = 1;
                let pendImg     = [];
                response.data.data.procedure.images.map((img, i) => {
                    if(img.status === "0" || img.status === "2"){
                        isImagesComplete = 0;
                        // eslint-disable-next-line array-callback-return
                        return;
                    }
                });
                // Update pending procedure images state
                response.data.data.procedure.images.map((img, i) => {
                    if(img.status === "0"){
                        pendImg.push(img._id)
                    }
                });
                setIsImgCompleted(isImagesComplete);
                setPendingProcedureImages(pendImg);
            }
            helper.Toast().fire({ icon: 'success', title: `Image has been successfully ${statusTxt}` });
        })
        .catch((err) => {
            props.setLoading(false)
        });
    }
    // THis function will approve all pending images
    const handleBulkApprove = async () => {
        let statusTxt = "approved";
        props.setLoading(true);
        await axios.post(
            process.env.REACT_APP_BACKENDURL+'api/host/assignment/update-bulk-images-status', 
            {
                ids: pendingProcedureImages,
                assignmentId: assignmentId,
                status: 1,
                message: ""
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
        )
        .then((response) => {
            sendNotificationToCleaner(assDetail.cleanerId)
            sendSignalToCleaner()
            props.setLoading(false)
            procedure['images'] =  response.data.data.procedure.images;
            setProcedure({...procedure});

            if(typeof response.data.data.procedure.images !== "undefined" && response.data.data.procedure.images.length > 0){
                let isImagesComplete = 1;
                let pendImg     = [];
                response.data.data.procedure.images.map((img, i) => {
                    if(img.status === "0" || img.status === "2"){
                        isImagesComplete = 0;
                        // eslint-disable-next-line array-callback-return
                        return;
                    }
                });
                // Update pending procedure images state
                response.data.data.procedure.images.map((img, i) => {
                    if(img.status === "0"){
                        pendImg.push(img._id)
                    }
                });
                setIsImgCompleted(isImagesComplete);
                setPendingProcedureImages(pendImg);
            }
            
            helper.Toast().fire({ icon: 'success', title: `Images has been successfully ${statusTxt}` });
        })
        .catch((err) => {
            props.setLoading(false)
        });
    }

    const completeAssignment = async () => {
       
        Swal.fire({
            text: `Are you sure you want to complete this assignment?`,
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: 'Complete',
            denyButtonText: `Close`,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        }).then((result) => {
           
            if (result.isConfirmed) {

                axios.post(
                    process.env.REACT_APP_BACKENDURL+'api/host/assignment/complete', 
                    {
                        id: assignmentId
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': token
                        }
                    }
                )
                .then((response) => {
                    sendNotificationToCleaner(assDetail.cleanerId)
                    sendSignalToCleaner()
                    assDetail['status'] = 4;
                    setAssDetail({...assDetail});
                    helper.Toast().fire({ icon: 'success', title: "Assignment has been successfully completed." });
                })
                .catch((err) => {
                });
            }
        })
       
    }

    const viewMessage = async (message) => {
        Swal.fire({
            text: message,
            customClass: {
                confirmButton: 'btn bg-primary btn-outline-list text-white fw-6 ms-2 sweet-alert-confirm-btn',
                cancelButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn',
                denyButton: 'btn bg-dark btn-outline-list text-white fw-6 ms-2 sweet-alert-cancel-btn'
            },
            buttonsStyling: false // Set to false if you want to fully control button styling
        })
    }

    const SubmitRating = async (e) => {
        e.preventDefault();
        setReviewError("");
        let error = 0;
        if(parseInt(rating) < 1) {
            setReviewError("Please select some rating");
            error = 1;
        }

        if(comment.trim().length === 0) {
            setReviewError("Please enter some comment");
            error = 1;
        } else if(comment.trim().length > 250) {
            setReviewError("You can add maximum 250 characters in comment.");
            error = 1;
        }

        if(error === 1) {
            return;
        }
      
        props.setLoading(true)
        axios.post(
            process.env.REACT_APP_BACKENDURL+'api/common/give-review', 
            {
                "role_id": 2,
                "assignmentId":assignmentId,
                "reviewTo": assDetail.cleanerId,
                "stars": rating,
                "comment": comment
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            }
        )
        .then((response) => {
            sendNotificationToCleaner(assDetail.cleanerId)
            sendSignalToCleaner()
            fetchAssessmentDetail();
            props.setLoading(false)
            if(Object.keys(response.data.review).length > 0){
                setRating(response.data.review.stars);

                assDetail['review'] = response.data.review;
                setAssDetail({...assDetail});

            }
            
        })
        .catch((err) => {
            props.setLoading(false)
        });
    }

    const updateNotificationStatus = () => {
        axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-notification-status',
        {
            role_id: 2,
            assessment_id: assignmentId.trim(),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });

        socket.emit('send-notification-update-signal');
    }

    const updateAssignmentReadStatus = async() => {
        await axios.post(process.env.REACT_APP_BACKENDURL+'api/common/update-assignment-read-status',
        {
            role_id: 2,
            assessment_id: assignmentId.trim(),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
    }

    const navigateToChat = (id) => {
        if(id !== 'undefined' && id !== '') {
            navigate('/host/messages/' + id);
        }
    }

    useEffect(() => {
        socket.emit('send-unread-assignment-host',userId);
        fetchAssessmentDetail();
        updateNotificationStatus();
        fetchTransactionFee();
        updateAssignmentReadStatus();
    }, [params.assignmentId]);

    useEffect(() => {
        function onConnect() {
          setIsConnected(true);
        }
    
        function onDisconnect() {
          setIsConnected(false);
        }

        socket.on('connect', onConnect);

        socket.on('disconnect', onDisconnect);

        socket.on('recieve-assignment-signal-from-cleaner', (resData) => {
            if(resData.assignmentId === assignmentId) {
                fetchAssessmentDetail();
                updateNotificationStatus();
                updateAssignmentReadStatus();
            }
        });
        socket.on('save-socket-id', (socketId) => {
            UpdateSocketId(socketId, decoded.userlevel)
        });
    
        return () => {
          socket.off('connect', onConnect);
          socket.off('disconnect', onDisconnect);
          socket.off('recieve-assignment-signal-from-cleaner', onDisconnect);
        };
    }, []);

    useEffect(() => {
        if(Object.keys(assDetail).length > 0) {
            var now = moment(new Date());
            var createdDate = moment(assDetail.date, "M/D/YYYY");
            var duration = moment.duration(now.diff(createdDate));
            var days = duration.asDays();
            if(days >= 14) {
                setAddReview(false);
            } else if(days < 14) {
                setAddReview(true);
            }
        }
    }, [assDetail])
    
    const UpdateSocketId = (socketId, roleId) => {        
        axios.post(
                    process.env.REACT_APP_BACKENDURL+"api/common/update-socket", 
                    {
                        socketId: socketId,
                        role_id: roleId
                    },
                    {
                        headers: {'Content-Type': 'application/json', 'token': token}
                    }
                )
    }


    const sendSignalToCleaner = () => {
        // Connect Socket if not connected
        if(!isConnected){
            socket.connect();
        }
        socket.emit('send-assignment-signal-to-cleaner', assignmentId);
    }

    const sendNotificationToCleaner = (cleanerId) => {
        // Connect Socket if not connected
        if(!isConnected){
            socket.connect();
        }
        socket.emit('send-notification-signal-to-cleaner', cleanerId);
    }

    function toggleChecklistModal() {
        setIsChecklistModalOpen(!isChecklistModalOpen);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Assignment Detail</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/host/assignment/list">Assignments</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Assignment Detail</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                {/* <div className="d-flex align-items-center gap-3 icon-btn mb-4 justify-content-end">
                    { ((typeof assDetail.cleanerId === "") || (assDetail.cleanerId === "")) ?
                        <a href="#" className="btn btn-add  waves-effect waves-light">Invite Cleaner List
                        </a>
                        : <></>
                    }
                    <button className="btn btn-outline-list waves-effect waves-light">View Property Details
                    </button>

                </div> */}

                <div className="row mb-2">
                    <div className="col-xl-8 col-lg-8">
                        <div className="card product">
                            <div className="card-body">
                                <div className="row gy-3">
                                    <div className="col-sm-auto col-md-5">
                                        <Swiper
                                            style={{
                                                '--swiper-navigation-size': '25px'
                                            }}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation={true}
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                            // onSwiper={(swiper) => console.log(swiper)}
                                            // onSlideChange={() => console.log('slide change')}
                                        >
                                            {(assDetail.propertyDetail.images && assDetail.propertyDetail.images.length > 0) ? 
                                            assDetail.propertyDetail.images.map((img, i) => (
                                                <SwiperSlide key={i}>
                                                    {img && img.filename && 
                                                    <img src={process.env.REACT_APP_BACKENDURL+'images/'+img.filename} key={i} alt={img.filename} onClick={() => openPropertyImgLightbox(i + 1)} />
                                                    }
                                                </SwiperSlide>
                                            ))
                                            : <></>
                                            }
                                        </Swiper>
                                        
                                        {(assDetail.propertyDetail.images && assDetail.propertyDetail.images.length > 0) &&
                                            <>
                                                <FsLightbox
                                                    toggler={propertyImgController.toggler}
                                                    slide={propertyImgController.slide}
                                                    sources={propertyImagesArr}
                                                />
                                            </>
                                        }

                                        <div className='pt-2'>
                                            <b>Address:</b> <Link target="_blank" to={`http://maps.google.com/maps?q=${assDetail.propertyDetail.lat},${assDetail.propertyDetail.lng}&ll=${assDetail.propertyDetail.lat},${assDetail.propertyDetail.lng}&z=17`}>{assDetail.propertyDetail.fullAddress}</Link>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <h5 className="fs-14 text-truncate"><Link target='_blank' to={'/host/property-detail/' + assDetail.propertyDetail._id } className="text-dark">{assDetail.propertyDetail.propertyName}</Link></h5>
                                        <ul className="list-inline text-muted assigment-detail-list">
                                            <li>Name : <span className="fw-medium fw-semibold">{assDetail.title}</span></li>
                                            <li>Type : <span className="fw-medium fw-semibold">
                                                    {
                                                        (assDetail.propertyDetail.subType > 0) ?
                                                            subTypes.map((item, i) => (
                                                                (assDetail.propertyDetail.subType === item.id) ? 
                                                                <span key={i} >{item.name}</span> : <span key={i} ></span>
                                                            ))
                                                        :<></>
                                                    }
                                                    </span>
                                            </li>
                                            <li>Size : <span className="fw-medium number">{assDetail.propertyDetail.size} {(assDetail.propertyDetail.sizeType === "m") ? "m²" : "ft²"}</span></li>
                                            <li>Date : <span className="fw-medium number">{ (assDetail.date) ? moment(assDetail.date, "M/D/YYYY").format('MMM Do YYYY') : "" }</span> </li>
                                            <li>Time : <span className="fw-medium number">Between {moment(assDetail.startTime).format('hh:mm A')} - {moment(assDetail.endTime).format('hh:mm A')}</span></li>
                                            <li>Price : <span className="fw-medium number">${assDetail.price}</span></li>
                                            { (typeof assDetail.checklist !== "undefined") ?
                                                <li>Checklist :
                                                    <ul className="checklist-list">
                                                        {assDetail.checklist.map((item, i) => (
                                                            <React.Fragment key={i}>
                                                            {(i < 3) &&
                                                                <div className="fw-medium" key={i} >{item.value}{assDetail.checklist.length > i+1 ? ', ' : ''}</div>
                                                            }
                                                            </React.Fragment>
                                                        ))}

                                                        {
                                                            (assDetail.checklist.length > 3) &&
                                                            <Link onClick={toggleChecklistModal} className="edit-icon fw-bold"> View all items</Link>
                                                        }
                                                    </ul>
                                                </li>
                                            : <></>
                                        }
                                          
                                        </ul>
                                    </div>
                                    <div className="col-sm-auto">
                                        <button type="button" className="btn btn-outline-list waves-effect waves-light">{statusData[assDetail.status]}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer">
                                <div className="row align-items-start gy-3">
                                    <div className="text-muted col-7 border-end">
                                        <h6 className="mb-1 fw-semibold text-uppercase">Specific Request</h6>
                                        <p>{assDetail.specificRequest}</p>
                                    </div>
                                    { ((typeof assDetail.cleanerId !== "undefined") && (assDetail.cleanerId !== "")) ?
                                        <div className="col-5">
                                            <div className="card-body mb-0 p-2">
                                                <div className="d-flex mb-2 align-items-start">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            (assDetail.cleanerImage) ?
                                                                <img src={assDetail.cleanerImage} alt="" className="avatar-sm rounded-circle" />
                                                            : 
                                                            <img src="/assets/images/image-placeholder.png" alt="" className="avatar-sm rounded-circle" />
                                                        }
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h5 className=" mb-1 fs-14">{assDetail.cleanerDetail[0].first_name} {assDetail.cleanerDetail[0].last_name}</h5>
                                                        <p className="text-muted d-flex align-items-center  gap-2 number mb-1">
                                                            <i className="ri-map-pin-fill"></i> {assDetail.cleaners[0].distanceString}
                                                        </p>
            
                                                        {(assDetail.stars > 0) ? 
                                                        <>  
                                                            <div className="hstack gap-1 flex-wrap rating-icon">
                                                                {[...Array(5)].map((star, index) => {
                                                                    index += 1;
                                                                    return (
                                                                        <i key={index} className={ 'bx fs-16 ' + (index <= (assDetail.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className='pb-1 ps-1 text-muted' style={{ fontSize: '12px' }}>Rating</div>
                                                        </> 
                                                        : 
                                                            <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                        }
                                                        <div className=" hstack gap-2 mt-3">
                                                        { (assDetail.cleanerDetail[0].firebase_uid !== "") ?
                                                            <> 
                                                                <Link to={'/host/cleaner-detail/' + assDetail.cleanerDetail[0]._id } target="_blank" className="btn btn-add btn-sm w-100"> View Profile</Link>
                                                                <Link to={'/host/messages/' + assDetail.cleanerDetail[0].firebase_uid } className="btn btn-add btn-sm w-100"> Message</Link>
                                                            </>
                                                            :
                                                            <>
                                                                <button className="btn btn-add btn-sm w-100" disabled> View Profile</button>
                                                                <button className="btn btn-add btn-sm w-100" disabled> Message</button>
                                                            </>
                                                        }
                                                        </div>
            
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    : <></>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>

                    { ((typeof assDetail.cleanerId !== "undefined") && (assDetail.cleanerId !== "") && ([5,6]).indexOf(assDetail.status) === -1) ?
                        <div className="col-xl-4 col-lg-4">
                            <div className="profile-timeline">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item border-0">
                                        { (assDetail.status === 4) ?
                                            <>
                                                <div className="accordion-header" >
                                                    <a className="accordion-button p-2 shadow-none pt-3" data-bs-toggle="collapse" href="#collapseFile"
                                                        aria-expanded="true">
                                                        <div className="d-flex align-items-center">
                                                            
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className={'avatar-title rounded-circle ' + ((Object.keys(assDetail.review).length > 0) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                                    {(Object.keys(assDetail.review).length > 0) ?
                                                                        <i className=" ri-check-double-fill "></i>
                                                                        :  <i className=" ri-check-fill "></i>
                                                                    }
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                {(Object.keys(assDetail.review).length > 0) ?
                                                                    <h6 className="fs-14 mb-0 fw-semibold">Review</h6>
                                                                :
                                                                    <h6 className="fs-14 mb-0 fw-semibold">Add Your Review</h6>
                                                                }
                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>

                                                <div id="collapseSix" className="accordion-collapse collapse show" >
                                                    
                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                            { (Object.keys(assDetail.review).length < 1) ?
                                                                <>
                                                                    {(addReview === true) &&
                                                                    <>
                                                                        <div className="hstack gap-1 flex-wrap rating-icon mb-3">
                                                                            
                                                                            {[...Array(5)].map((star, index) => {
                                                                                index += 1;
                                                                                return (
                                                                                    <i 
                                                                                        key={index}
                                                                                        className={ 'bx fs-16 ' + (index <= (hover || rating) ? "bxs-star" : "bx-star")}
                                                                                        onClick={() => setRating(index)}
                                                                                        onMouseEnter={() => setHover(index)}
                                                                                        onMouseLeave={() => setHover(rating)}
                                                                                    ></i>  
                                                                                );
                                                                            })}

                                                                        </div>
                                                                        <textarea className="form-control mb-1" rows="3" placeholder="Comments"  onChange={(e) => setComment(e.target.value)} maxLength={250}></textarea>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className="float-end me-1 word-limit-count">{comment.length}/250 Characters</div>
                                                                            </div>
                                                                        </div>
                                                                        {reviewError && <div className="text-danger">{reviewError}</div>}
                                                                        <div className="hstack gap-2 mt-3">
                                                                            <button 
                                                                                className="btn btn-add btn-sm w-100"
                                                                                onClick={(e) => SubmitRating(e) }
                                                                            >Add a Review</button>
                                                                        </div>
                                                                    </>
                                                                    }
                                                                    {(addReview === false) &&
                                                                        <div className="hstack gap-1 flex-wrap rating-icon mb-3">
                                                                            You can't add review for an older assigment.
                                                                        </div>
                                                                    }
                                                                </>
                                                            :
                                                                <>
                                                                    <h6 className='fs-14 mb-0 fw-semibold'>Your Feedback To Cleaner</h6>         
                                                                    <div className="hstack gap-1 flex-wrap rating-icon mb-1">
                                                                        {[...Array(5)].map((star, index) => {
                                                                            index += 1;
                                                                            return (
                                                                                <i key={index} className={ 'bx fs-16 ' + (index <= (hover || rating) ? "bxs-star" : "bx-star")} ></i>
                                                                            );
                                                                        })}

                                                                    </div>
                                                                    <div className='ps-1 mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                                    <p>{assDetail.review.comment}</p>
                                                                    
                                                                    {/* Review Detail given by cleaner */}
                                                                    {(assDetail.cleanerReview.stars > 0) &&
                                                                        <>
                                                                            <h6 className='fs-14 mb-0 fw-semibold'>Cleaner's Feedback To You</h6>         
                                                                            {/* <h6 className='fs-14 mb-0 fw-semibold'>{assDetail.cleanerDetail[0].first_name} {assDetail.cleanerDetail[0].last_name}</h6> */}
                                                                            <div className="hstack gap-1 flex-wrap rating-icon mb-1">
                                                                                            
                                                                                {[...Array(5)].map((star, index) => {
                                                                                    index += 1;
                                                                                    return (
                                                                                        <i key={index} className={ 'bx fs-16 ' + (index <= assDetail.cleanerReview.stars ? "bxs-star" : "bx-star")} ></i>
                                                                                    );
                                                                                })}

                                                                            </div>
                                                                            <div className='ps-1 mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                                            <p>{assDetail.cleanerReview.comment}</p>
                                                                        </>
                                                                    }
                                                                </>
                                                             }
                                                        </div>
                                                   

                                                </div>
                                            </>
                                        : <></>
                                        }
                                        
                                        <div className="accordion-header" id="headingsix">
                                            <a className="accordion-button p-2 shadow-none pt-3" data-bs-toggle="collapse" href="#collapseFile"
                                                aria-expanded="true">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className={'avatar-title rounded-circle ' + ((assDetail.status === 4) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                            {(assDetail.status === 4) ?
                                                                <i className=" ri-check-double-fill "></i>
                                                                :  <i className=" ri-check-fill "></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        {(assDetail.status === 4) ?
                                                            <h6 className="fs-14 mb-0 fw-semibold">Completed</h6>
                                                        :
                                                            <h6 className="fs-14 mb-0 fw-semibold">Approval</h6>
                                                        }
                                                    </div>

                                                </div>
                                            </a>
                                        </div>

                                        { (isImgCompleted === 1 && assDetail.status === 3) ?
                                            <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingsix"
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                    <p>Click Here to Confirm Completion </p>
                                                    <div className="hstack gap-2">
                                                        <button 
                                                            className="btn btn-add btn-sm w-100"
                                                            onClick={(e) => completeAssignment()}
                                                        >Complete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        : <></>
                                        }

                                    </div>
                                    <div className="accordion-item border-0">
                                        <div className="accordion-header" id="headingFive">
                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFile"
                                                aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className={'avatar-title rounded-circle ' + ((procedure.imagesSubmitted === 1) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                            {(procedure.imagesSubmitted === 1) ?
                                                                <i className=" ri-check-double-fill "></i>
                                                                :  <i className=" ri-check-fill "></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="fs-14 mb-0 fw-semibold">Photo Confirmation</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {(procedure.images.length > 0) ?
                                            <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive"
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                    <div className="row border border-dashed rounded gx-2">
                                                        {procedure.images.map((img, i) => (
                                                            <div key={i} className="col-6 mb-2">
                                                                <div className={"icon-container " +  ((img.status === "2" || img.status === "3") ? "decline-img" : "" )}>
                                                                    <div className="message-img-link text-end" style={{marginBottom: '-27px', marginTop: '0px'}}>
                                                                        <ul className="list-inline mb-0">
                                                                            <li className="list-inline-item dropdown">
                                                                                <button className="dropdown-item" onClick={() => openLightboxOnSlide(i + 1)}>
                                                                                    <i className="ri-zoom-in-line mx-1 text-muted align-bottom color-white"></i>
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <img src={process.env.REACT_APP_BACKENDURL+'assignments/'+img.filename}  alt="" className="img-fluid rounded" onClick={() => openLightboxOnSlide(i + 1)} />
                                                                </div>

                                                                <div className=" hstack gap-1 mt-2 mb-2">
                                                                {(img.status === "0") ? 
                                                                    <>
                                                                        <button 
                                                                            className="btn btn-add btn-sm w-100"
                                                                            onClick={(e) => handleUpdateImageStatus(img._id, 1) }
                                                                        >Approve</button>
                                                                        <button 
                                                                            className="btn btn-outline-list btn-sm w-100"
                                                                            onClick={(e) => updateImageStatus(img._id, 2) }
                                                                        >
                                                                            Decline</button>
                                                                    </>
                                                                   :
                                                                       (img.status === "1") ? 
                                                                       <button 
                                                                           className="btn btn-add btn-sm w-100"
                                                                       >Approved</button>
                                                               
                                                                       :
                                                                        <>

                                                                            {(img.message.length > 42) ?
                                                                                <p>
                                                                                    { img.message.substring(0, 42)} 
                                                                                
                                                                                    <span className="text-primary cursor-pointer" onClick={(e) => viewMessage(img.message) } > ...view more</span>
                                                                                </p>
                                                                            : 
                                                                                <p>{img.message}</p>
                                                                            }
                                                                       
                                                                            {/* <button 
                                                                                className="btn btn-outline-list btn-sm w-100"
                                                                                onClick={(e) => viewMessage(img.message) }
                                                                            >
                                                                            View Message</button> */}
                                                                       </>
                                                                      
                                                                   }
                                                               
                                                                </div>

                                                            </div>
                                                        ))}

                                                        {
                                                            pendingProcedureImages.length > 0 ?
                                                            <button className="btn btn-outline-list btn-sm w-100" 
                                                                onClick={handleBulkApprove}>Approve All Images</button> :
                                                            <></>
                                                        }

                                                        {(procedureImages.length > 0) ?
                                                            <FsLightbox
                                                                toggler={lightboxController.toggler}
                                                                sources={procedureImages}
                                                                slide={lightboxController.slide}
                                                            />
                                                            : <></>
                                                        }
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                    <div className="accordion-item border-0">
                                        <div className="accordion-header" id="headingFour">
                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour"
                                                aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className={'avatar-title rounded-circle ' + ((procedure.checkListSubmitted === 1) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                            {(procedure.checkListSubmitted === 1) ?
                                                                <i className=" ri-check-double-fill "></i>
                                                                :  <i className=" ri-check-fill "></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="fs-14 mb-0 fw-semibold">Checklist</h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        { (procedure.checkList.length > 0) ?
                                            <div id="collapsefour" className="accordion-collapse collapse show" aria-labelledby="headingFour"
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                    <ul className="checklist-list mb-0">
                                                        {procedure.checkList.map((item, i) => {
                                                            return (
                                                                <li key={i}>{item.label}</li>
                                                              );

                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                    <div className="accordion-item border-0">
                                        <div className="accordion-header" id="headingThree">
                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className={'avatar-title rounded-circle ' + ((procedure.arrivalConfirm.status === 1) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                            {(procedure.arrivalConfirm.status === 1) ?
                                                                <i className=" ri-check-double-fill "></i>
                                                                :  <i className=" ri-check-fill "></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="fs-15 mb-1 fw-semibold">Arrival Confirmed 
                                                        {(procedure.arrivalConfirm.status === 1) ?
                                                            <>
                                                                - <span className="fw-normal number">{moment(procedure.arrivalConfirm.timestamp).format('MMM Do YYYY, hh:mm A')}</span>
                                                            </>
                                                            :<></>
                                                        }
                                                        </h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="accordion-item border-0">
                                        <div className="accordion-header" id="headingTwo">
                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className={'avatar-title rounded-circle ' + ((procedure.gettingInside.status === 1) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                            {(procedure.gettingInside.status === 1) ?
                                                                <i className=" ri-check-double-fill "></i>
                                                                :  <i className=" ri-check-fill "></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="fs-15 mb-1 fw-semibold">Getting Inside 
                                                        {(procedure.gettingInside.status === 1) ?
                                                            <>
                                                                - <span className="fw-normal number">{moment(procedure.gettingInside.timestamp).format('MMM Do YYYY, hh:mm A')}</span>
                                                            </>
                                                            :<></>
                                                        }
                                                        </h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0">
                                        <div className="accordion-header" id="headingOne">
                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className={'avatar-title rounded-circle ' + ((procedure.gettingThere.status === 1) ? 'bg-primary' : 'bg-light text-prime' )}>

                                                            {(procedure.gettingThere.status === 1) ?
                                                                <i className=" ri-check-double-fill "></i>
                                                                :  <i className=" ri-check-fill "></i>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="fs-15 mb-0 fw-semibold">Getting There 
                                                        {(procedure.gettingThere.status === 1) ?
                                                            <>
                                                                - <span className="fw-normal number">{moment(procedure.gettingThere.timestamp).format('MMM Do YYYY, hh:mm A')}</span>
                                                            </>
                                                            :<></>
                                                        }
                                                        </h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }

                </div>

                { ((typeof assDetail.cleanerId === "undefined") || (assDetail.cleanerId === "")) ?
                    <div className="row row-cols-xxl-4 row-cols-lg-4 row-cols-md-2 row-cols-1 mt-3">

                        <div className="col">
                            <div className="card mb-2">
                                <a className="card-body bg-gradient bg-success collapsed d-flex justify-content-between"
                                    data-bs-toggle="collapse" href="#contactInitiated" role="button" aria-expanded="false"
                                    aria-controls="contactInitiated">
                                    <div>
                                        <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15 text-white">Accepted</h5>
                                        <p className="text-white mb-0 number">{acceptedcleaners.length}<span className="fw-medium"> Response</span>
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0 avatar-sm">
                                        <div className="avatar-title bg-soft-danger text-white fs-22 rounded">
                                            <i className="bx bx-like"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="collapse show" id="contactInitiated">
                                {(acceptedcleaners.length > 0) ?
                                    acceptedcleaners.map((item, i) => {
                                        return ( 
                                            <div className="card mb-1 ribbon-box ribbon-fill ribbon-sm" key={i}>

                                                <div className="card-body">
                                                    <a className="d-flex align-items-start collapsed" data-bs-toggle="collapse" href="#contactInitiated1"
                                                        role="button" aria-expanded="false" aria-controls="contactInitiated1">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                (item.image) ?
                                                                    <img src={item.image} className="avatar-xs rounded-circle" alt='' />
                                                                : 
                                                                    <img src="/assets/images/image-placeholder.png" className="avatar-xs rounded-circle" alt='' />
                                                            }
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="fs-13 mb-1">{item.label}</h6>
                                                            
                                                            {(item.stars > 0) ? 
                                                            <>
                                                                <div className="hstack gap-1 flex-wrap rating-icon mb-1">
                                                                    {[...Array(5)].map((star, index) => {
                                                                        index += 1;
                                                                        return (
                                                                            <i key={index} className={ 'bx fs-22 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                        );
                                                                    })}
                                                                </div>
                                                                <div className='mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                            </>
                                                            :
                                                                <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                            }

                                                            <div className=" hstack gap-2">
                                                                <button 
                                                                    className="btn btn-add btn-sm w-100"
                                                                    value={item.label}
                                                                    onClick={(e) => hireJob(item, 0) }
                                                                > Hire</button>
                                                                <button className="btn btn-add btn-sm w-100" onClick={(e) => navigateToChat(item.firebase_id) }> Message </button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>

                                            </div>
                                        )
                                    })
                                : <></>}
                                
                            </div>
                        </div>
                        

                        <div className="col">
                            <div className="card mb-2">
                                <a className="card-body bg-gradient bg-warning  collapsed d-flex justify-content-between"
                                    data-bs-toggle="collapse" href="#needsIdentified" role="button" aria-expanded="false"
                                    aria-controls="needsIdentified">
                                    <div>
                                        <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15 text-white">
                                            Negotiation
                                        </h5>
                                        <p className="mb-0 number text-white">{bargainedCleaners.length} <span className="fw-medium">
                                                Response</span>
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0 avatar-sm">
                                        <div className="avatar-title bg-soft-danger text-white fs-22 rounded">
                                            <i className="ri-hand-coin-line"></i>
                                        </div>
                                    </div>

                                </a>
                            </div>
                            
                            <div className="collapse show" id="needsIdentified">
                                {(bargainedCleaners.length > 0) ?
                                    bargainedCleaners.map((item, i) => {
                                        return (
                                            <div className="card mb-1" key={i} >
                                                <div className="card-body">
                                                    <a className="d-flex align-items-start" data-bs-toggle="collapse" href="#needsIdentified1" role="button"
                                                        aria-expanded="false" aria-controls="needsIdentified1">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                (item.image) ?
                                                                    <img src={item.image} className="avatar-xs rounded-circle" alt='' />
                                                                : 
                                                                <img src="/assets/images/image-placeholder.png" className="avatar-xs rounded-circle" alt='' />
                                                            }
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="fs-13 mb-1">{item.label}</h6>

                                                            {(item.stars > 0) ? 
                                                            <>
                                                                <div className="hstack gap-1 flex-wrap rating-icon mb-1">
                                                                    {[...Array(5)].map((star, index) => {
                                                                        index += 1;
                                                                        return (
                                                                            <i key={index} className={ 'bx fs-22 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                        );
                                                                    })}
                                                                </div>
                                                                <div className='mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                            </> 
                                                            : 
                                                                <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                            }
                                                            <div className="d-flex align-items-center mb-2 gap-1">
                                                                <h5 className="fs-13 mb-0">New Payout:</h5>
                                                                <p className="number mb-0 fw-bold fs-14">${item.price}</p>
                                                            </div>

                                                            <div className="mb-2 gap-1">
                                                                <h5 className="fs-13 mb-0">Reason:</h5>
                                                                <p>
                                                                    { item.bargainingReason.substring(0, 42) }                               
                                                                    <span className="text-primary cursor-pointer" onClick={(e) => viewMessage(item.bargainingReason) } > <strong>...view more</strong></span>
                                                                </p>
                                                            </div>

                                                            <div className=" hstack gap-2">
                                                                <button className="btn btn-add btn-sm w-100"  onClick={(e) => hireJob(item, 1) }> Hire</button>
                                                                <button className="btn btn-outline-list btn-sm w-100" onClick={(e) => navigateToChat(item.firebase_id) }>Message</button>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                : <></>}
                        

                            </div>
                        </div>
                        

                        <div className="col">
                            <div className="card mb-2">
                                <a className="card-body bg-primary collapsed d-flex justify-content-between" data-bs-toggle="collapse"
                                    href="#meetingArranged" role="button" aria-expanded="false" aria-controls="meetingArranged">
                                    <div>
                                        <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15 text-white">Pending
                                        </h5>
                                        <p className="mb-0 number text-white">{pendingCleaners.length} <span className="fw-medium">Response</span></p>
                                    </div>
                                    <div className="flex-shrink-0 avatar-sm">
                                        <div className="avatar-title bg-soft-light text-white fs-22 rounded">
                                            <i className=" bx bx-time-five "></i>
                                        </div>
                                    </div>

                                </a>
                            </div>
                            
                            <div className="collapse show" id="meetingArranged">
                                {(pendingCleaners.length > 0) ?
                                    pendingCleaners.map((item, i) => {
                                        return (
                                            <div className="card mb-1" key={i}>
                                                <div className="card-body">
                                                    <a className="d-flex align-items-center" data-bs-toggle="collapse" href="#meetingArranged1"
                                                        role="button" aria-expanded="false" aria-controls="meetingArranged1">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                (item.image) ?
                                                                    <img src={item.image} className="avatar-xs rounded-circle" alt='' />
                                                                : 
                                                                <img src="/assets/images/image-placeholder.png" className="avatar-xs rounded-circle" alt='' />
                                                            }
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="fs-14 mb-1">{item.label}</h6>
                                                            {(item.stars > 0) ? 
                                                            <>
                                                                <div className="hstack gap-1 flex-wrap rating-icon mb-1">
                                                                    {[...Array(5)].map((star, index) => {
                                                                        index += 1;
                                                                        return (
                                                                            <i key={index} className={ 'bx fs-22 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                        );
                                                                    })}
                                                                </div>
                                                                <div className='mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                            </>
                                                            : 
                                                                <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                            }
                                                        </div>

                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                : <></>}
                            
                                
                            </div>
                            


                        </div>
                        

                        <div className="col">
                            <div className="card mb-2">
                                <a className="card-body bg-gradient bg-danger collapsed d-flex justify-content-between"
                                    data-bs-toggle="collapse" href="#leadDiscovered" role="button" aria-expanded="false"
                                    aria-controls="leadDiscovered">
                                    <div>
                                        <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15 text-white">
                                            Declined</h5>
                                        <p className="mb-0 number text-white">{declinedCleaners.length} <span className="fw-medium">Response</span>
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0 avatar-sm">
                                        <div className="avatar-title bg-soft-warning text-white fs-22 rounded">
                                            <i className=" bx bx-dislike "></i>
                                        </div>
                                    </div>

                                </a>
                            </div>
                            
                            <div className="collapse show" id="leadDiscovered">
                                {(declinedCleaners.length > 0) ?
                                    declinedCleaners.map((item, i) => {
                                        return (
                                            <div className="card mb-1" key={i} >
                                                <div className="card-body">
                                                    <a className="d-flex align-items-start collapsed" data-bs-toggle="collapse" href="#leadDiscovered2"
                                                        role="button" aria-expanded="false" aria-controls="leadDiscovered2">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                (item.image) ?
                                                                    <img src={item.image} className="avatar-xs rounded-circle" alt='' />
                                                                : 
                                                                <img src="/assets/images/image-placeholder.png" className="avatar-xs rounded-circle" alt='' />
                                                            }
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="fs-13 mb-1">{item.label}</h6>
                                                            {(item.stars > 0) ? 
                                                            <>
                                                                <div className="hstack gap-1 flex-wrap rating-icon mb-1">
                                                                    {[...Array(5)].map((star, index) => {
                                                                        index += 1;
                                                                        return (
                                                                            <i key={index} className={ 'bx fs-22 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")} ></i>
                                                                        );
                                                                    })}
                                                                </div>
                                                                <div className='mb-2 text-muted' style={{ fontSize: '12px', marginTop: '-6px' }}>Rating</div>
                                                            </>
                                                            : 
                                                                <div className='py-2 text-muted' style={{ fontSize: '12px' }}>No reviews yet</div>
                                                            }
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                : <></>}
                                
                            
                                
                            </div>
                        </div>
                        
                    </div>
                :   <></>
                }

                <Modal isOpen={isChecklistModalOpen}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Checklist</h5>
                                <button type="button" className="btn-close" onClick={toggleChecklistModal}></button>
                            </div>
                            <div className="modal-body">
                                { (typeof assDetail.checklist !== "undefined") &&
                                    <>
                                        {assDetail.checklist.map((item, i) => (
                                            <React.Fragment key={i}>
                                                <div key={i} className="fw-medium">- {item.value}{assDetail.checklist.length > i+1 ? ', ' : ''}</div>
                                            </React.Fragment>
                                        ))}
                                    </>
                                }
                            </div>
                            <div className="modal-footer justify-content-center gap-3 mt-2">
                                <button 
                                    type="button"
                                    onClick={toggleChecklistModal}
                                    className="btn btn-outline-list added-btn waves-effect waves-light">Close</button>
                            </div>

                        </div>
                    </div>
			    </Modal>
            </div>
        </div>
    )
}

export default AssignmentDetail;
