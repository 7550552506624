import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import helper from '../../helper';
import { decodeToken } from "react-jwt";
import Cookies from 'js-cookie';
// import { socket } from '../../../socket';
import AutoComplete from '../../components/common/AutoComplete';

const TaxClass = (props) => {
  const token = Cookies.get('token');
  // get user data from token in cookie
  const decoded = decodeToken(token);
  const fullurl =  window.location.href;
  const urlSegment = fullurl.split("/"); 
  let role_id = 0;
  let role_name = '';
  if(urlSegment[3] !== "undefined" && urlSegment[3] === "host"){
      role_id = 2;
      role_name = "host";
  }else if(urlSegment[3] !== "undefined" && urlSegment[3] === "cleaner"){
      role_id = 3;
      role_name = "cleaner";
  }

  const navigate = useNavigate();
  const segment = decoded.user_id;

  const [taxNumber, setTaxNumber] = useState("");
  const [selectedClassification, setSelectedClassification] = useState("");
  const [place, setPlace] = useState(null);
  const [fullAddress, setFullAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [street, setStreet] = useState("");
  const [route, setRoute] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const [countryError, setCountryError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [classificationError, setClassificationError] = useState("");
  const [taxNumberError, setTaxNumberError] = useState("");

  const fetchTaxInfo = async() => {
    const {data} = await axios.get(process.env.REACT_APP_BACKENDURL+ 'api/common/fetch-tax-info', {
                    headers: {
                      'Content-Type': 'application/json',
                      'token': token
                    },
                    params: {
                      role_id: role_id
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
    if(data.status === 1) {
      setTaxNumber(data.data[0].taxIdNumber);
      setFullAddress(data.data[0].fullAddress);
      setCountry(data.data[0].country);
      setCity(data.data[0].city);
      setState(data.data[0].state);
      setPincode(data.data[0].pincode);
      setStreet(data.data[0].streetAddress);
      setRoute(data.data[0].route);
      setLat(data.data[0].lat);
      setLng(data.data[0].lng);
      const selectVal = data.data[0].taxClassification;
      const selectedTaxClass = taxClassificationArray.find(data => data.value === selectVal);
      setSelectedClassification(selectedTaxClass);
    }
  }

  useEffect(() => {
    fetchTaxInfo();
  }, [])

  useEffect(() => {
    if(place !== null){
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      setStreet(place.name);
      for (var j = 0; j < place.address_components.length; j++) {
        for (var k = 0; k < place.address_components[j].types.length; k++) {
          if (place.address_components[j].types[k] === "country") {
            setCountry(place.address_components[j].long_name);
          }
          if (place.address_components[j].types[k] === "locality") {
            setCity(place.address_components[j].short_name);
          }
          if (place.address_components[j].types[k] === "route") {
            setRoute(place.address_components[j].short_name);
          }
          if (place.address_components[j].types[k] === "postal_code") {
            setPincode(place.address_components[j].short_name);
          }
          if (place.address_components[j].types[k] === "administrative_area_level_1") {
            setState(place.address_components[j].long_name);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[place])
  
  useEffect(() => {
    let fullAdd = '';
    setFullAddress('');
    if(street !== "" && pincode !== "" && city !== "" && state !== "" && country !== "") {
      fullAdd = street+', '+route+', '+pincode+', '+city+', '+state+', '+country;
      setFullAddress(fullAdd);
    }
  }, [street, route, pincode, city, state, country])


  // const [isConnected, setIsConnected] = useState(socket.connected);

  const [taxClassificationArray, setTaxClassificationArray] = useState([
    {value: 'Sole proprietorship', label: 'Sole proprietorship'},
    {value: 'Partnership', label: 'Partnership'},
    {value: 'Governmental liaisons', label: 'Governmental liaisons'},
    {value: 'C corporation', label: 'C corporation'},
    {value: 'Limited liability company', label: 'Limited liability company'},
    {value: 'Nonprofit', label: 'Nonprofit'},
    {value: 'Individuals', label: 'Individuals'},
    {value: 'Tax-exempt bonds', label: 'Tax-exempt bonds'}
  ]);


  const handleSelectlist = (selectedOption) => {
    setSelectedClassification(selectedOption);
  };

  const submitTaxInfo = async (e) => {
    e.preventDefault();
    props.setLoading(true);
    setCountryError("");
    setAddressError("");
    setClassificationError("");
    setTaxNumberError("");

    let error  = 0;
    if(taxNumber === '') {
      setTaxNumberError("Tax ID Number is required");
      error = 1;
    } else if (taxNumber.length !== 9) {
      setTaxNumberError("Tax ID Number should have 9 digits");
      error = 1;
    }

    if(selectedClassification.value === '') {
      setClassificationError("Tax classification is required");
      error = 1;
    }

    if(fullAddress === '') {
      setAddressError("Address is required");
      error = 1;
    }

    if(country === '') {
      setCountryError("Country is required");
      error = 1;
    }

    if(error === 1) {
      props.setLoading(false);
      return;
    } else {
      const data = {
        role_id: role_id,
        taxClassification: selectedClassification.value,
        taxIdNumber: taxNumber,
        fullAddress: fullAddress,
        city: city,
        route: route,
        state: state,
        pincode: pincode,
        country: country,
        streetAddress: street,
        lat: lat,
        lng: lng
      }

      axios.post(process.env.REACT_APP_BACKENDURL + 'api/common/update-tax-info', 
        data,
        {headers: {'Content-Type': 'application/json', 'token': token}}
      ).then(function (response) {
        props.setLoading(false)
        if (response.data.status === 1) {
          // sendNotificationToCleaners()
          // navigate('/host/assignment/list');
          helper.Toast().fire({ icon: 'success', title: response.data.message });
          setTimeout(() => {
            navigate('/host/taxes');
          }, 1000);
        } else {
          helper.Toast().fire({ icon: 'error', title: response.data.message });
        }
      })
      .catch(function (error) {
        props.setLoading(false)
        helper.Toast().fire({ icon: 'error', title: "Due to some error you tax info is not updated. Please try again." });
      });
    }
  }

//   useEffect(() => {
//     function onConnect() {
//       setIsConnected(true);
//     }

//     function onDisconnect() {
//       setIsConnected(false);
//     }
    
//     socket.on('connect', onConnect);
//     socket.on('disconnect', onDisconnect);


//     socket.on('save-socket-id', (socketId) => {
//         UpdateSocketId(socketId, decoded.userlevel)
//     });

//     return () => {
//       socket.off('connect', onConnect);
//       socket.off('disconnect', onDisconnect);
//     };
// }, []);

  const sendNotificationToCleaners = () => {
    // Connect Socket if not connected
    // if(!isConnected){
    //     socket.connect();
    // }
    // socket.emit('send-notification-signal-to-cleaners', basicDetail.cleanerList);
  }

  const UpdateSocketId = (socketId, roleId) => {        
    axios.post(
              process.env.REACT_APP_BACKENDURL+"api/common/update-socket", 
              {
                  socketId: socketId,
                  role_id: roleId
              },
              {
                  headers: {'Content-Type': 'application/json', 'token': token}
              }
          )
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">Edit Tax Information</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="/host/dashboard">Overview</Link></li>
                  <li className="breadcrumb-item"><Link to={`/${role_name}/settings`}>Settings</Link></li>
                  <li className="breadcrumb-item"><Link to={`/${role_name}/taxes`}>Taxes</Link></li>
                  <li className="breadcrumb-item active">Edit Tax Information</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-xl-12 col-md-12">
            <div className="tab-content">
                <form onSubmit={submitTaxInfo} id="assignment-form">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="mb-sm-0"> Form W-9 </h5>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label className="form-label"><strong> Tax Classification </strong></label>
                          <Select
                              name='type'
                              options={taxClassificationArray}
                              onChange={handleSelectlist}
                              closeMenuOnSelect={true}
                              value={selectedClassification} />                  
                          {classificationError && <div className="text-danger"> {classificationError} </div>}
                        </div>

                        <div className="col-md-6 mb-4">
                          <label className="form-label"><strong> Tax ID Number </strong></label>
                          <input className="form-control"
                            name='title'
                            maxLength={9}
                            placeholder="Enter Tax Id Number"
                            onKeyDown={(e) => helper.checkNumericInput(e)}
                            onChange={(e) => setTaxNumber(e.target.value)}
                            value={taxNumber} />
                          
                          {taxNumberError && <div className="text-danger"> {taxNumberError} </div>}
                        </div>

                        <div className="col-6 mb-0">
                          <label className="form-label"><strong> Search Address </strong></label>
                          <AutoComplete setPlace={setPlace} defaultValue={fullAddress}/>
                        </div>

                        {/* <div className="col-6 mb-4">
                          <label className="form-label"><strong> Address </strong></label>
                          <input
                            type="text"
                            className='form-control'
                            name='address'
                            placeholder='Address'
                            value={fullAddress} 
                            readOnly />
                          {addressError && <div className="text-danger"> {addressError} </div>}
                        </div> */}

                        <div className="col-6 mb-4">
                          <label className="form-label"><strong> Country </strong></label>
                          <input
                            type="text"
                            className='form-control'
                            name='country'
                            placeholder='Country'
                            value={country} 
                            readOnly />
                          {countryError && <div className="text-danger"> {countryError} </div>}
                        </div>
                      </div>

                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button type="submit" className="btn btn-add ms-auto"> Update </button>
                      </div>
                    </div>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaxClass;
