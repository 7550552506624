// import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from '../fireabse.js';
import { getFirestore, doc, setDoc, updateDoc, getDocs, collection, query, where } from "firebase/firestore"; 

export const updateUserName = (obj) => {
    try {
        const {name, email} = obj;
        const docQuery = query(collection(db, "users"), where('email', '==', email));
        getDocs(docQuery).then(response => {
            response.docs.forEach((document) => {
                updateDoc(doc(db, "users", document.id), {
                        name: name
                    }).catch (error => {
                        
                    });
            })
        })
    } catch (err) {
        console.log(err);       
    }
}