import React, { useState, useEffect } from 'react'
import { Link, useParams , useLocation} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { decodeToken  } from "react-jwt";

let limit = 10;
const imgRound = {
    height: "100%",
    width: "100%",
};
function Reviews(props) {  
    const location = useLocation();
    const fromPage = location.state?.fromPage ?? "";
    const params = useParams();
    const token = Cookies.get('token');
    const decoded = decodeToken(token);
    const hostId = (params.hostId) ? params.hostId : decoded.user_id;
    const isHost = (params.hostId) ? 1 : 0;
   
    const [data, setData] = useState([]);
    const [PageCount, setPageCount] = useState(0);

    useEffect(() => {
        getData([]);
    }, []);


    // Fetch Host Details
    const getData = async (datas) => {
        props.setLoading(true)
        let pageNo = (datas) ? datas.selected : 0;
        axios.get(process.env.REACT_APP_BACKENDURL+'api/cleaner/host-reviews',
        {
            params: {
                id: hostId,
                page: pageNo,
                limit: limit,
                sortBy: 'desc',
            },
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
        .then(function (response) {
            if(response.data.status === 1){
                setPageCount(response.data.total_pages);
                setData(response.data.data);
                props.setLoading(false)
            }else{
                props.setLoading(false)
            }
        })
        .catch(function (error) {
            console.log(error);
            props.setLoading(false)
        });
    };


  return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Reviews</h4>

                            <div className="page-title-right">

                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/host/dashboard">Overview</Link>
                                    </li>
                                    {(fromPage === 'insight') ? 
                                        <>
                                            <li className="breadcrumb-item">
                                                <Link to={"/cleaner/insight/"}>Insight</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Reviews</li>
                                        </>
                                        :
                                        <>
                                            {(isHost) ? 
                                            <>
                                                <li className="breadcrumb-item">
                                                    <Link to={"/cleaner/host-detail/" + hostId}>Host Detail</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Host Reviews</li>
                                            </>
                                            :
                                            <>
                                                {(fromPage === 'myProfile') ?
                                                    <>
                                                        <li className="breadcrumb-item">
                                                            <Link to={"/cleaner/my-profile"}>My Public Profile</Link>
                                                        </li>
                                                        <li className="breadcrumb-item active">Reviews</li>
                                                    </>
                                                :
                                                    <li className="breadcrumb-item active">Reviews</li>
                                                }
                                            </>
                                            }
                                        </>
                                    }
                                </ol>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h2>Reviews</h2>
                                {data.length > 0 ?
                                    <div className="row">
                                        {data.map((item, i) => { 
                                            return(
                                                <div key={i} className="col-xxl-3 col-sm-6 mb-3">
                                                    <div
                                                        className="card profile-project-card shadow-none profile-project-success mb-0">
                                                        <div className="card-body p-4">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1 text-muted overflow-hidden">
                                                                    <p className="text-muted mb-0">{item.comment}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex align-items-start gap-2">
                                                                        <div className="avatar-group">
                                                                            <div className="avatar-group-item">
                                                                                <div className="avatar-xs">
                                                                                    {
                                                                                        (typeof item.hostDetail.profile.image.filename !== "undefined") ?
                                                                                            <img src={process.env.REACT_APP_BACKENDURL+'images/' + item.hostDetail.profile.image.filename} alt="profileImage" className="rounded-circle img-fluid" style={imgRound} />
                                                                                        : 
                                                                                            <img src="/assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className="fs-12 text-muted mb-0">
                                                                                {item.hostDetail.first_name + " " + item.hostDetail.last_name}</h5>
                                                                                <p><span className="number"> {moment(item.createdAt).format('MMM Do YYYY') }</span></p>
                                                                            <div className="hstack gap-1 flex-wrap rating-icon">
                                                                                {[...Array(5)].map((star, index) => {
                                                                                    index += 1;
                                                                                    return (
                                                                                        <i key={index} className={ 'bx fs-16 ' + (index <= (item.stars) ? "bxs-star" : "bx-star")}></i>  
                                                                                    );
                                                                                })}                                                      
                                                                            </div>
                                                                            <div className='pb-1 text-muted' style={{ fontSize: '12px' }}>Rating</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            )
                                        })}
                                        
                                        <div className="col-lg-12">
                                            <div className="mt-4">
                                                <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel="..."
                                                    pageCount={PageCount}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={getData}
                                                    containerClassName={'pagination pagination-rounded mt-3 mb-3 justify-content-center'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link'}
                                                    renderOnZeroPageCount={null}
                                                    activeClassName={'active'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                : <>
                                <div className="text-center">
                                    No review found.
                                </div>
                                </>
                                }
                                
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>
  )
}

export default Reviews